import { GENDER } from '../../resources/enums';
import { Person } from '../person/Person';
import { IDriver } from './IDriver';

export class Driver implements Person {
  ref: string;
  id: string;
  firstName: string;
  lastName: string;
  otherName: string;
  driverInsurerName: string;
  driverInsurerAddress: string;
  fullName: string | undefined;
  hasDisease: boolean | undefined;
  diseaseOrComplications: string;
  phoneNumber: string;
  email: string | undefined;
  address: string;
  occupation: string;
  dateOfBirth: Date;
  license: string;
  idType: string | undefined;
  idNumber: string | undefined;
  idExpiryDate: Date | undefined;
  gender: GENDER;

  constructor(Imodel: IDriver) {
    this.ref = Imodel.ref ?? '';
    this.id = Imodel.id ?? '';
    this.firstName = Imodel.firstName ?? '';
    this.lastName = Imodel.lastName ?? '';
    this.driverInsurerName = Imodel.driverInsurerName ?? '';
    this.driverInsurerAddress = Imodel.driverInsurerAddress ?? '';
    this.otherName = Imodel.otherName ?? '';
    this.fullName = Imodel.fullName;
    this.hasDisease = Imodel.hasDisease ?? undefined;
    this.diseaseOrComplications = Imodel.diseaseOrComplications ?? '';
    this.phoneNumber = Imodel.phoneNumber ?? '';
    this.email = Imodel.email;
    this.address = Imodel.address ?? '';
    this.occupation = Imodel.occupation ?? '';
    this.dateOfBirth = new Date(Imodel.dateOfBirth ?? '');
    this.license = Imodel.license ?? '';
    this.idType = Imodel.idType ?? undefined;
    this.idNumber = Imodel.idNumber ?? undefined;
    this.idExpiryDate = Imodel.idExpiryDate ?? undefined;
    this.gender = GENDER[Imodel?.gender as keyof typeof GENDER];
  }
}
