import { ReactElement, ReactNode, useEffect, useState, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';

interface IPOpOver {
  trigger: JSX.Element | ReactElement | ReactNode | null;
  children: JSX.Element | ReactElement | ReactNode | null;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | 'screen-xl'
    | 'screen-2xl'
    | 'fullscreen';
  className?: string;
  closeOnOutsideClick?: boolean;
  show?: boolean; // New prop to control visibility directly
}

function MyPopOver({
  trigger,
  children,
  size = 'lg',
  className,
  closeOnOutsideClick = true,
  show = false
}: IPOpOver) {
  const [visible, setVisible] = useState<boolean>(show);
  const popOverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (closeOnOutsideClick) {
      const handleClickOutside = (event: MouseEvent) => {
        if (popOverRef.current && !popOverRef.current.contains(event.target as Node)) {
          setVisible(false);
        }
      };

      if (visible) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [visible, closeOnOutsideClick]);

  return (
    <Popover as={'div'} className="relative !z-50">
      {({ open = visible }) => (
        <>
          <Popover.Button onClick={() => setVisible(!open)}>{trigger}</Popover.Button>
          <Transition
            show={visible}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <Popover.Panel
              className={clsx(
                'absolute z-10 w-screen mt-2 transform -translate-x-1/2 md:-translate-x-[85%] left-1/2 sm:px-0 bg-white shadow-full ',
                { ['max-w-xs']: size == 'xs' },
                { ['max-w-sm']: size == 'sm' },
                { ['max-w-md']: size == 'md' },
                { ['max-w-lg']: size == 'lg' },
                { ['max-w-xl']: size == 'xl' },
                { ['max-w-2xl']: size == '2xl' },
                { ['max-w-3xl']: size == '3xl' },
                { ['max-w-4xl']: size == '4xl' },
                { ['max-w-5xl']: size == '5xl' },
                { ['max-w-6xl']: size == '6xl' },
                { ['max-w-7xl']: size == '7xl' },
                { ['max-w-screen-xl']: size == 'screen-xl' },
                { ['max-w-screen-2xl']: size == 'screen-2xl' },
                { ['h-screen']: size == 'fullscreen' },
                className
              )}
              ref={popOverRef}>
              <div className="overflow-hidden rounded">
                <div className="">{children}</div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default MyPopOver;
