import React, { FC, useEffect, useState } from 'react';
import Button from '../../../components/form-control/Button';
import Input from '../../../components/form-control/Input';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { addVehicle, createLeads } from '../../../api/requests';
import { LEADS, VEHICLES } from '../../../resources/links';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IcreateLead } from '../../../models/lead/ILead';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import SelectMenu from '../../../components/form-control/SelectMenu';
import { VEHICLE_BODY_TYPES, VEHICLE_BODY_TYPES_LIST } from '../../../resources/enums';
import { IAddSupportedVehicle, ISupportedVehicles } from '../../../resources/interfaces';
import DatePickerInput from '../../../components/form-control/DatePickerInput';
import { getSelectableManufacturingYears } from '../../../utils/miscFunctions';

const MANUFACTURED_YEARS_LIST = getSelectableManufacturingYears();

const AddVehicle = ({ onProceed, onClose }: { onProceed: () => void; onClose: () => void }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload: IAddSupportedVehicle = {
      maker: values.maker,
      model: values.model,
      vehicleTypeName: values.type,
      yearIssued: values.yearIssued,
      maxSeat: values.maxSeat,
      miniSeat: values.miniSeat,
      cc: values.cc
    };

    addVehicle(payload)
      .then((response: any) => {
        toast.success('Team created successfully', {
          autoClose: 5000
        });
        onProceed();
      })
      .catch((error: any) => {
        toast.error(error?.description);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        maker: '',
        model: '',
        type: '',
        yearIssued: '',
        maxSeat: '',
        miniSeat: '',
        cc: ''
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values) => {
        submitHandler(values);
      }}>
      {({
        values,
        errors,
        isSubmitting,
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        touched
      }) => (
        <form className=" bg-white" onSubmit={handleSubmit}>
          <div className=" flex justify-between items-center bg-primary-main px-10 py-[16px] h-[77px]">
            <div className="flex items-center gap-4">
              <div className="w-[45px] h-[45px] bg-white rounded-full flex items-center justify-center">
                <PlusIcon className="h-[20px] w-[20px]" />
              </div>
              <div className="">
                <h1 className="text-[32px]">Add Vehicle</h1>
              </div>
            </div>
            <div className="">
              <XMarkIcon className="h-[20px] w-[20px] cursor-pointer" onClick={onClose} />
            </div>
          </div>

          <div className="px-10 py-[47px]">
            <div className="mb-8">
              <Input
                type={'text'}
                name="maker"
                placeholder="Vehicle"
                value={values.maker}
                onChange={handleChange}
                onBlur={handleChange}
                label="Vehicle Make"
              />
            </div>
            <div className=" grid grid-cols-1 md:gap-8 items-center   md:grid-cols-2 ">
              <div className="">
                <SelectMenu
                  search
                  label="Vehicle Type"
                  name="vehicleTypeName"
                  listSelectedValue={values.type}
                  list={Object.values(VEHICLE_BODY_TYPES_LIST)}
                  onChange={(val: string | null) => setFieldValue('type', val)}
                  onBlur={handleBlur}
                  placeholder="Select vehicle type"
                />
              </div>
              <div className="">
                <Input
                  type={'text'}
                  name="model"
                  value={values.model}
                  onChange={handleChange}
                  onBlur={handleChange}
                  label="Model"
                  placeholder=""
                />
              </div>
              <div className="">
                <Input
                  type={'number'}
                  name="cc"
                  // error={errors.email}
                  // helperText={errors.email}
                  min={50}
                  value={values.cc}
                  onChange={handleChange}
                  onBlur={handleChange}
                  label="Cubic Capacity"
                  placeholder=""
                />
              </div>

              <div className="">
                <Input
                  type="number"
                  name="miniSeat"
                  min={2}
                  value={values.miniSeat}
                  onChange={handleChange}
                  onBlur={handleChange}
                  label="Min. no of seats"
                  placeholder=""
                />
              </div>
              <div className="">
                <Input
                  type="number"
                  name="maxSeat"
                  onChange={handleChange}
                  value={values.maxSeat}
                  min={2}
                  onBlur={handleChange}
                  label="Max. no of seats"
                  placeholder=""
                />
              </div>
              <SelectMenu
                label="Latest model year"
                name="yearIssued"
                listSelectedValue={values.yearIssued}
                onChange={(val: string | null) => {
                  setFieldValue('yearIssued', val);
                }}
                onBlur={handleBlur}
                list={MANUFACTURED_YEARS_LIST}
                placeholder="Select Latest model year"
                // error={touched.manufacturedYear ? errors.manufacturedYear : undefined}
                required
                sort
                emptyListMessage="Select Latest model year to proceed"
              />
            </div>
            <div className="flex  flex-col md:flex-row  justify-between mt-5">
              <div className="">
                <Button
                  label="Back"
                  className="w-[120px] h-[45px] border flex items-center justify-center rounded hover:bg-primary-border border-primary-main p-4"
                  onClick={onClose}
                />
              </div>
              <div className="flex flex-col md:flex-row items-center gap-4">
                <div className="">
                  <Button
                    label="Save"
                    className="min-w-[120px] h-[45px] flex items-center justify-center rounded hover:bg-primary-border p-4"
                    bgColor="primary"
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddVehicle;
