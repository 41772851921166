import { useEffect, useState } from 'react';
import useUserAccount from '../../../../hooks/userAccountHook';
import { User } from '../../../../models/User';
import Loader from '../../../../components/Loader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../../components/form-control/Input';
import TextArea from '../../../../components/form-control/TextArea';
import { CheckBoxInput } from '../../../../components/form-control/CheckBoxInput';
import Button from '../../../../components/form-control/Button';
import { useNavigate } from 'react-router-dom';
import { createRole, getAllPermissions } from '../../../../api/requests';
import { toast } from 'react-toastify';
import BackButton from '../../../../components/BackButton';
import SelectMenu from '../../../../components/form-control/SelectMenu';
import {
  getAccessRightKeyFromString,
  getFormattedAccessRight
} from '../../../../utils/miscFunctions';
import { ACCESS_RIGHTS } from '../../../../resources/enums';
import SelectDropdown, { ISelectDropdownItem } from '../../../../components/form-control/SelectDropdown';

const allAccessRights: Array<ISelectDropdownItem> = Object.values(ACCESS_RIGHTS).map((value) => ({
  name: getFormattedAccessRight(value),
  value: (value)
})
);
const CreateCustomRole = () => {
  const { getProfile } = useUserAccount();
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const user = new User(getProfile());
  const navigate = useNavigate();
  useEffect(() => {
    fetchScopes();
  }, []);

  const fetchScopes = () => {
    setLoading(true);
    getAllPermissions()
      .then((response: any) => {
        setPermissions(response);
      })
      .catch((error: any) => {
        toast.error(error.message);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const _permissions = Object.entries(permissions);
  const transformedArray = _permissions.map(([key, value]) => ({
    key: key,
    value: value
  }));
  const _transformedArray = transformedArray.sort((a, b) => a.key.localeCompare(b.key));

  return (
    <div className="">
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader message="Loading. Please wait..." brollyLoader />
        </div>
      ) : (
        <>
          <div className="p-5 flex gap-5 items-center">
            <BackButton onClick={() => navigate('/admin/settings')} />
          </div>
          <hr />
          <>
            <div className="">
              <Formik
                initialValues={{
                  accessRight: '',
                  description: '',
                  name: '',
                  type: ''
                }}
                validateOnBlur
                validationSchema={Yup.object().shape({
                  name: Yup.string().trim().required('Name is required'),
                  description: Yup.string().trim().required('Description is required'),
                  accessRight: Yup.string().trim().required('Access right is required'),
                  type: Yup.string().trim().required('Type is required')
                })}
                onSubmit={(values) => {
                  const payload: any = {
                    accessRight: values.accessRight,
                    description: values.description,
                    name: values.name,
                    scopes: [...selectedPermissions],
                    type: values.type
                  };
                  createRole(payload)
                    .then((response: any) => {
                      toast.success(response?.data.message, {
                        autoClose: 4000
                      });
                    })
                    .catch((error: any) => {
                      toast.error(error.description, {
                        autoClose: 4000
                      });
                    });
                }}>
                {({
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  values,
                  errors,
                  touched
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-5  justify-around gap-10 p-10">
                      <div className="col-span-2">
                        <hr />

                        <div className="py-5">
                          <>
                            <Input
                              label="Name of role"
                              type="text"
                              name="name"
                              value={values.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={touched.name ? errors.name : undefined}
                              helperText={touched.name ? errors.name : undefined}
                              required
                            />
                          </>
                          <div className="py-5">
                            <TextArea
                              name={'description'}
                              value={values.description}
                              label="Role description"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              error={touched.description ? errors.description : undefined}
                              helperText={touched.description ? errors.description : undefined}
                            />
                          </div>
                          <div className="py-5">
                            <SelectDropdown
                              label="Access Right"
                              name="accessRight"
                              listSelectedValue={values.accessRight}
                              list={allAccessRights}
                              onChange={(val: string) =>
                                setFieldValue('accessRight', val)
                              }
                              onBlur={handleBlur}
                              placeholder="Select access right"
                              error={touched.accessRight ? errors.accessRight : undefined}
                              helperText={touched.accessRight ? errors.accessRight : undefined}
                              required
                              wrapperClasses="w-full"
                            />
                          </div>
                          <div className="py-5">
                            <SelectMenu
                              label="Type"
                              name="type"
                              listSelectedValue={values.type}
                              list={['INTERNAL', 'EXTERNAL']}
                              onChange={(val: string | null) => setFieldValue('type', val)}
                              onBlur={handleBlur}
                              placeholder="Select access type "
                              error={touched.type ? errors.type : undefined}
                              helperText={touched.type ? errors.type : undefined}
                              required
                              wrapperClasses="w-full"
                            />
                          </div>

                          <hr />
                        </div>
                      </div>

                      <div className="border rounded p-5 col-span-3">
                        <h1 className="font-semibold my-2">Role permissions</h1>
                        <hr />

                        <div className="">
                          <div className="my-3">
                            <span className="text-sm">
                              See the list of available permissions for this role
                            </span>
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-2">
                            {_transformedArray.map((permission, index) => (
                              <div key={index}>
                                <ScopeComponent
                                  label={permission.value}
                                  scope={permission.key}
                                  checked={false}
                                  onChange={(isChecked) => {
                                    const updatedPermissions = isChecked
                                      ? [...selectedPermissions, permission.key]
                                      : selectedPermissions.filter(
                                        (perm) => perm !== permission.key
                                      );

                                    setSelectedPermissions(updatedPermissions);
                                  }}
                                />
                              </div>
                            ))}
                          </div>

                          <div className="flex justify-end py-5">
                            <Button
                              label="Save"
                              type="submit"
                              className="px-2"
                              bgColor="primary"
                              curved
                              disabled={loading}
                              loading={loading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default CreateCustomRole;

interface IScopeComponent {
  scope: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export const ScopeComponent = ({ scope, checked, onChange, disabled, label }: IScopeComponent) => {
  // const label = scope
  //   .replace(/_/g, ' ')
  //   .toLowerCase()
  //   .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());

  return (
    <div className="w-fit">
      <CheckBoxInput
        label={label}
        name={''}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};
