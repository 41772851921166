import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

interface TabProps {
  name: string;
  children: React.ReactNode;
}

const TabContent: React.FC<TabProps> = (props) => {
  const { name, children } = props;

  return <div className="w-full h-full">{children}</div>;
};

interface TabsProps {
  tab1: TabProps;
  tab2: TabProps;
  tabGroupTitle?: string;
  activeTab?: string;
}

const DashboardTabs: React.FC<TabsProps> = (props) => {
  const { tab1, tab2, tabGroupTitle } = props;
  const [activeTab, setActiveTab] = useState(props.activeTab ?? tab1.name);
  const [screenWidth, setScreenWidth] = React.useState<number>(
    document.documentElement.clientWidth
  );

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const resizeCallback = () => {
      setScreenWidth(document.documentElement.clientWidth);
    };
    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  const activeButtonClassNames = 'text-black bg-[#FBC02D] px-2  rounded-[6px] font-[500]';
  const inActiveButtonClassNames = 'text-[#5B5B5B] font-[500]';

  return (
    <>
      <div
        className={clsx(
          'flex items-center',
          { ['flex flex-row justify-between items-center mb-5']: screenWidth > 1213 },
          { ['flex-row space-x-2']: screenWidth <= 1213 }
        )}>
        {tabGroupTitle && (
          <div className=" md:text-center md:w-full pb-4 md:pb-2 text-md sm:text-lg md:text-md lg:text-base font-bold ">
            {tabGroupTitle}
          </div>
        )}
        <div className="text-nowrap flex w-fit gap-5 items-center justify-center mb-3 lg:mb-0 rounded-[3px] p-2 bg-[#D1D4D947] font-[500]">
          <button
            className={`${
              activeTab === tab1.name ? activeButtonClassNames : inActiveButtonClassNames
            }`}
            onClick={() => handleTabChange(tab1.name)}>
            {tab1.name}
          </button>
          <button
            className={`${
              activeTab === tab2.name ? activeButtonClassNames : inActiveButtonClassNames
            }`}
            onClick={() => handleTabChange(tab2.name)}>
            {tab2.name}
          </button>
        </div>
      </div>
      <div className="flex justify-center h-full">
        {activeTab === tab1.name && <TabContent name={tab1.name}>{tab1.children}</TabContent>}
        {activeTab === tab2.name && <TabContent name={tab2.name}>{tab2.children}</TabContent>}
      </div>
    </>
  );
};

export default DashboardTabs;
