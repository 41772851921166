import Button from '../../../components/form-control/Button';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { ICreateAdminAccountDTO, IUpdateAdminAccountDTO } from '../../../resources/interfaces';
import { getSlackWorkspaces, getStaffAccountById, inviteMemberToTeam, updateAdminAccount } from '../../../api/requests';
import SelectMenu from '../../../components/form-control/SelectMenu';
import { toast } from 'react-toastify';
import {
    ACCESS_RIGHTS,
    AGENCY_ACCESS_RIGHTS,
    GENDER,
    INSURER_ACCESS_RIGHTS,
    INTERNAL_ACCESS_RIGHTS,
    RIDE_SHARING_ACCESS_RIGHTS,
    TEAM_TYPES
} from '../../../resources/enums';
import {
    getAccessRightKeyFromString,
    getFormattedAccessRight,
    getFormattedPhoneNumber,
    getPhoneNumberFromShortCodeFormat
} from '../../../utils/miscFunctions';
import SelectDropdown, {
    ISelectDropdownItem
} from '../../../components/form-control/SelectDropdown';

const internalAccessRights: Array<ISelectDropdownItem> = Object.values(INTERNAL_ACCESS_RIGHTS).map(
    (value) => ({
        name: getFormattedAccessRight(value),
        value: value
    })
);
const insurerAccessRights: Array<ISelectDropdownItem> = Object.values(INSURER_ACCESS_RIGHTS).map(
    (value) => ({
        name: getFormattedAccessRight(value),
        value: value
    })
);
const agencyAccessRights: Array<ISelectDropdownItem> = Object.values(AGENCY_ACCESS_RIGHTS).map(
    (value) => ({
        name: getFormattedAccessRight(value),
        value: value
    })
);
const ridesharingAccessRights: Array<ISelectDropdownItem> = Object.values(
    RIDE_SHARING_ACCESS_RIGHTS
).map((value) => ({
    name: getFormattedAccessRight(value),
    value: value
}));

const UpdateStaffAccount = ({ onProceed, onClose, staff }: { onProceed: () => void; onClose: () => void; staff: any; }) => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [workspaces, setWorkspaces] = useState<any[]>();

    useEffect(() => {
        getSlackWorkspaces()
            .then((response: any) => {
                setWorkspaces(response?.data);
            })
            .catch((error: any) => {
                toast.error(error.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }, [])

    const submitHandler = (values: any) => {
        setSubmitting(true);
        const payload: IUpdateAdminAccountDTO = {
            email: values.email.trim(),
            firstName: values.firstName,
            lastName: values.lastName,
            phone: getFormattedPhoneNumber(values.phone),
            accessRights: values.accessRight,
            country: 'Ghana',
            gender: values.gender,
            jobTitle: values.jobTitle,
            slackWorkspaceId: values.slackWorkspaceId,
        };
        if (staff)
            updateAdminAccount(staff?.id, payload)
                .then((response: any) => {
                    if (response) {
                        toast.success('Admin account updated successfully', {
                            autoClose: 5000
                        });
                        onProceed();
                    }
                })
                .catch((error: any) => {
                    toast.error(error?.description);
                })
                .finally(() => setSubmitting(false));
    };


    const getAccessRights = () => {
        let accessRights: ISelectDropdownItem[] = [];

        switch (staff?.team?.type) {
            case TEAM_TYPES.INSURER:
                return (accessRights = insurerAccessRights);
                break;
            case TEAM_TYPES.AGENCY:
                return (accessRights = agencyAccessRights);
                break;
            case TEAM_TYPES.RIDE_SHARING:
                return (accessRights = ridesharingAccessRights);
                break;
            case TEAM_TYPES.INTERNAL:
                return (accessRights = internalAccessRights);
                break;
            default:
                break;
        }
    };


    return (
        <div className="w-full">
            <div className="bg-primary-main flex justify-between">
                <div className="p-[1rem] flex justify-between w-full items-center">
                    <div className="flex items-center justify-center gap-5">
                        <div className="flex items-center justify-center bg-white h-[2.5rem] w-[2.5rem] rounded-full gap-3">
                            <PlusIcon className="h-5 w-5" />
                        </div>
                        <h2 className="font-[gilroy-bold] text-lg">Update admin account</h2>
                    </div>
                    <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
                </div>
            </div>

            <div className="p-5">
                <Formik
                    initialValues={{
                        firstName: staff?.firstName,
                        lastName: staff?.lastName,
                        phone: getPhoneNumberFromShortCodeFormat(staff.phone),
                        email: staff?.email,
                        jobTitle: staff?.jobTitle,
                        gender: staff?.gender,
                        accessRight: staff?.accessRight,
                        slackWorkspaceId: staff?.slackWorkspace,
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={Yup.object({
                        firstName: Yup.string().trim().required('First name is required.'),
                        lastName: Yup.string().trim().required('Last name is required.'),
                        email: Yup.string()
                            .trim()
                            .email('Email provided invalid.')
                            .required('Email is required.'),
                        phone: Yup.string()
                            .matches(/^[0-9]+$/, 'Must be only digits')
                            .min(10, 'Must be exactly 10 digits')
                            .max(10, 'Must be exactly 10 digits')
                            .trim()
                            .required('Phone number of contact person is required.'),
                        jobTitle: Yup.string().required('Job title is required.'),
                        gender: Yup.string().trim().required('Gender is required'),
                        accessRight: Yup.string().trim().required('Access right is required')
                    })}
                    onSubmit={(values) => {
                        const payload = {
                            ...values,
                            accessRight: values.accessRight,
                            phone: getFormattedPhoneNumber(values.phone),
                        };
                        submitHandler(payload);
                    }}>
                    {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-5">
                                <Input
                                    name="firstName"
                                    label="First name"
                                    value={values.firstName}
                                    type="text"
                                    wrapperClasses=""
                                    required
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />

                                <Input
                                    name="lastName"
                                    label="Last name"
                                    value={values.lastName}
                                    type="text"
                                    wrapperClasses=""
                                    required
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                />

                                <Input
                                    name="email"
                                    label="Email"
                                    value={values.email?.trim()}
                                    type="email"
                                    wrapperClasses=""
                                    required
                                    onChange={handleChange}
                                />

                                <Input
                                    type={'tel'}
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleChange}
                                    label="Phone number"
                                    placeholder="024 444 4444"
                                    required

                                    className="rounded"
                                />
                            </div>

                            <div className="flex gap-x-8 justify-between">
                                <SelectMenu
                                    label="Gender"
                                    name="gender"
                                    listSelectedValue={values.gender}
                                    list={Object.values(GENDER)}
                                    onChange={(val: string | null) => setFieldValue('gender', val)}
                                    onBlur={handleBlur}
                                    placeholder="Select gender"
                                    // error={touched.gender ? errors.gender : undefined}
                                    // helperText={touched.gender ? errors.gender : undefined}
                                    required
                                    wrapperClasses="w-full"
                                />
                                <SelectDropdown
                                    label="Access Right"
                                    name="accessRight"
                                    listSelectedValue={values.accessRight}
                                    list={getAccessRights()}
                                    onChange={(val: string | null) => setFieldValue('accessRight', val)}
                                    onBlur={handleBlur}
                                    placeholder="Select access right"
                                    // error={touched.accessRight ? errors.accessRight : undefined}
                                    // helperText={touched.accessRight ? errors.accessRight : undefined}
                                    required
                                    wrapperClasses="w-full"
                                />
                            </div>

                            <div className="">
                                <Input
                                    name="jobTitle"
                                    label="Job Title"
                                    value={values.jobTitle}
                                    type="text"
                                    wrapperClasses=""
                                    required
                                    onChange={handleChange}
                                    className=""
                                />
                            </div>

                            <div className="">
                                <SelectDropdown
                                    name="slackWorkspaceId"
                                    label="Slack Workspace"
                                    placeholder="Select workspace "
                                    listSelectedValue={values.slackWorkspaceId}
                                    wrapperClasses=""
                                    required
                                    onChange={(value: any) => {
                                        setFieldValue('slackWorkspaceId', value);
                                    }}
                                    list={workspaces?.map((workspace: any) => ({
                                        name: workspace?.name,
                                        value: workspace?.id
                                    })
                                    )}
                                />
                            </div>

                            <div className="flex justify-end">
                                <Button
                                    type="submit"
                                    label="Submit"
                                    bgColor="primary"
                                    className="w-fit px-6 text-[14px]"
                                    loading={submitting}
                                    disabled={submitting}
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default UpdateStaffAccount;
