import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import Input from '../../../components/form-control/Input';
import Button from '../../../components/form-control/Button';
import useModal from '../../../hooks/modalHook';
import { MdOutlineSecurity } from 'react-icons/md';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Policy } from '../../../models/policy/Policy';
import { getFormattedPhoneNumber } from '../../../utils/miscFunctions';
import { IVerifyCustomerDTO } from '../customers/ViewCustomer';
import { sendCustomerEmailOTP, sendCustomerOTP, verifyPhoneOTP } from '../../../api/requests';
import { toast } from 'react-toastify';

export interface ICustomerEmailVerificationModal {
  policy: any;
  close: () => void;
  onProceed: () => void;
}

const CustomerEmailVerificationModal = ({
  policy,
  close,
  onProceed
}: ICustomerEmailVerificationModal) => {
  const resendCustomerOTP = () => {
    if (policy && policy?.applicant?.email) {
      let email = policy?.applicant?.email;
      sendCustomerEmailOTP(email)
        .then((response: any) => {
          toast.success(response.message, {
            autoClose: 5000
          });
        })
        .catch((error: any) => {
          toast.error(error.description, {
            autoClose: 5000
          });
        });
    }
  };

  const verifyCustomerOTP = (values: any) => {
    if (policy && policy?.applicant?.email) {
      let email = policy?.applicant?.email;
      const VERIFICATION_CODE_CONSTANT = 'B-';
      const payload: IVerifyCustomerDTO = {
        verificationCode: VERIFICATION_CODE_CONSTANT + values.code,
        customerId: policy?.applicant?.customerId,
        accountProviders: 'SSO',
        identifier: email
      };

      verifyPhoneOTP(payload)
        .then((response: any) => {
          if (response)
            toast.success(response.description, {
              autoClose: 5000
            });
          onProceed();
        })
        .catch((error: any) => {
          toast.error(error.description, {
            autoClose: 5000
          });
        });
    }
  };

  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-1">
            <div className="flex items-center justify-center bg-white h-[45px] w-[45px] rounded-full">
              <MdOutlineSecurity className="h-[24px] w-[24px]" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Verify Email</h2>
          </div>
          <XMarkIcon onClick={close} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className=" mt-[26px]">
        <span className="text-center px-4">
          Enter the verification code provided by the customer
        </span>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            code: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            code: Yup.string()
              .trim()
              .min(6, 'Invalid verification code')
              .max(6, 'Invalid verification code')
              .required('Verification code is required')
          })}
          onSubmit={(values) => {
            // submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-5">
                <div className="flex">
                  <div className="">
                    <Input
                      type="text"
                      name="code"
                      label="Verification code"
                      placeholder=""
                      className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border "
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      prepend={'B-'}
                    />
                  </div>

                  <div className="flex items-end ">
                    <Button
                      label="Verify OTP"
                      title=" "
                      bgColor="primary"
                      className="rounded"
                      onClick={() => verifyCustomerOTP(values)}
                    />
                  </div>
                </div>
                <div className="">
                  <span
                    className="text-blue-700 cursor-pointer hover:underline"
                    onClick={() => resendCustomerOTP()}>
                    Resend verification code
                  </span>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CustomerEmailVerificationModal;
