import React, { useState } from 'react';

interface ColorPickerProps {
  initialColor: string;
  className?: string;
  onColorChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ initialColor, onColorChange, className }) => {
  const [currentColor, setCurrentColor] = useState(initialColor);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value;
    setCurrentColor(newColor);
    onColorChange(newColor);
  };

  return (
    <div>
      <input type="color" value={currentColor} onChange={handleChange} className={className} />
    </div>
  );
};

export default ColorPicker;
