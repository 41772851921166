import React, { useMemo, useState } from 'react';
import Modal, { IModalProps } from '../components/elements/modal';

type IModalStates = Pick<
  IModalProps,
  | 'show'
  | 'children'
  | 'backdrop'
  | 'scrollable'
  | 'size'
  | 'title'
  | 'onClose'
  | 'showCloseButton'
  | 'autoCloseAfter'
  | 'showBackButton'
  | 'onBack'
  | 'classes'
  | 'showTitleSection'
> & { static?: boolean };

function useModal() {
  const [modalState, setModalState] = useState<IModalStates>({
    show: false,
    children: null,
    backdrop: true,
    scrollable: false,
    size: 'md',
    title: '',
    onClose: () => { },
    showCloseButton: false,
    static: false,
    autoCloseAfter: undefined,
    showBackButton: false,
    onBack: () => { },
    classes: '',
    showTitleSection: false
  });

  const updateModalStates = (states: Partial<IModalStates>) => {
    setModalState({ ...modalState, ...states });
  };

  const showModal = () => {
    setModalState({
      ...modalState,
      show: true
    });
  };

  const closeModal = () => {
    setModalState({
      ...modalState,
      show: false
    });
  };

  const ModalComponent = () => (
    <Modal
      show={modalState.show ?? false}
      onClose={closeModal}
      title={modalState.title}
      scrollable={modalState.scrollable}
      size={modalState.size}
      backdrop={modalState.backdrop}
      showCloseButton={modalState.showCloseButton}
      staticMode={modalState.static}
      autoCloseAfter={modalState.autoCloseAfter}
      showBackButton={modalState.showBackButton}
      onBack={modalState.onBack}
      classes={modalState.classes}
      showTitleSection={modalState.showTitleSection}>
      {modalState.children}
    </Modal>
  );
  return { ModalComponent, showModal, closeModal, updateModalStates };
}
export default useModal;
