import React from "react";
import clsx from "clsx";


export interface IToastContentProps {
    title: string;
    status?: "error" | "success" | "info"
    description?: string | null;
}

function ToastContent({
    title,
    status="error",
    description
}: IToastContentProps): JSX.Element {

    return (
        <div>
            <h3 className={clsx(
                {["text-danger-hover"]: status=="error"}
            )}>{title}</h3>
            {description && (<p className="text-xs">{description}</p>)}
        </div>
    )
}

export default ToastContent;