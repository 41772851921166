import { Formik } from "formik";
import * as Yup from 'yup';
import { IDownloadData } from "../../resources/interfaces";
import { DOWNLOAD_FILE_TYPES, DOWNLOAD_RESOURCE_TYPES } from "../../resources/enums";
import SelectMenu from "../form-control/SelectMenu";
import DatePickerInput from "../form-control/DatePickerInput";
import { subDays } from "date-fns";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Button from "../form-control/Button";
import moment from "moment";
import { INPUT_DATE_FORMAT } from "../../resources/constants";
import { getDownloadableFile } from "../../api/requests";
import { useState } from "react";
import { toast } from "react-toastify";

interface IDownloadPromptProps {
  onProceed: (data: IDownloadData) => void;
  onClose: () => void;
  resourceType: DOWNLOAD_RESOURCE_TYPES;
}

interface IFormData {
  fileType: string;
  from: Date;
  to: Date;
}

const DownloadPrompt = (props: IDownloadPromptProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const submitHandler = (values: IFormData) => {
    setSubmitting(true);

    const data: IDownloadData = {
      fileType: values.fileType as DOWNLOAD_FILE_TYPES,
      from: moment(values.from).format(INPUT_DATE_FORMAT),
      to: moment(values.to).format(INPUT_DATE_FORMAT),
      resourceType: props.resourceType
    }

    getDownloadableFile(data)
      .then((response: any) => {
        window.open(response.documentUrl, "_blank");
        props.onProceed(data);
      }).catch(() => {
        toast.error("Failed to export file. Please try again later.", {
          autoClose: 3000
        })
      }).finally(() => setSubmitting(false))
  }

  return (
    <>
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <h2>Confirm Export</h2>
          <XMarkIcon onClick={props.onClose} width={'11px'} height={'11px'} className="cursor-pointer" />
        </div>
      </div>
      <Formik
        initialValues={{
          fileType: DOWNLOAD_FILE_TYPES.CSV,
          from: subDays(new Date(), 7),
          to: new Date()
        }}
        // validateOnBlur
        validationSchema={Yup.object().shape({
          fileType: Yup.string().required('File type required'),
          from: Yup.date().typeError("Date type error").required("Date from required")
        })}
        onSubmit={(values) => {
          submitHandler(values);
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="px-4 py-[30px] bg-white space-y-4" onSubmit={handleSubmit}>

            <div className="flex space-x-4 w-full justify-between">
              <DatePickerInput
                id={"from"}
                label="From"
                selected={values.from}
                onChange={(date: Date | null) => {
                  setFieldValue("from", date);
                }}
                classes="w-full"
                showYearDropdown
                allowFutureSelections={false}
                error={touched.from && errors.from !== undefined ? String(errors.from) : undefined}
                helperText={touched.from && errors.from !== undefined ? String(errors.from) : undefined}
                dateFormat={"dd/MM/yyyy"}
              />

              <DatePickerInput
                id={"to"}
                selected={values.to}
                onChange={(date: Date | null) => {
                  setFieldValue("to", date);
                }}
                label="To"
                classes="w-full"
                showYearDropdown
                allowFutureSelections={false}
                error={touched.to && errors.to !== undefined ? String(errors.to) : undefined}
                helperText={touched.to && errors.to !== undefined ? String(errors.to) : undefined}
                dateFormat={"dd/MM/yyyy"}
              />
            </div>

            <SelectMenu
              name="fileType"
              label="File Type"
              listSelectedValue={values.fileType}
              onChange={(value: string) => {
                setFieldValue("fileType", value);
              }}
              list={Object.values(DOWNLOAD_FILE_TYPES)}
              error={touched.fileType ? errors.fileType : undefined}
              helperText={touched.fileType ? errors.fileType : undefined}
            />

            <div className="flex justify-end w-full">
              <Button
                label="Submit"
                type="submit"
                className="bg-primary-main px-6 rounded border-none"
                loading={submitting}
                disabled={submitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default DownloadPrompt;
