import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { setCommissionRate } from '../../../api/requests';
import SelectDropdown, {
  ISelectDropdownItem
} from '../../../components/form-control/SelectDropdown';

export interface ISetCommission {
  rate: string;
  type: string;
  teamId: string;
}

const policyTypes = [
  {
    name: 'Comprehensive',
    value: 'COMPREHENSIVE'
  },
  {
    name: 'Third Party',
    value: 'THIRD_PARTY'
  }
];
const SetCommission = ({
  onProceed,
  onClose,
  teamID
}: {
  onProceed: () => void;
  onClose: () => void;
  teamID: string;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload: ISetCommission = {
      teamId: teamID,
      rate: values.rate,
      type: values.type
    };

    setCommissionRate(payload)
      .then((response: any) => {
        toast.success('Agency commsission rate set successfully', {
          autoClose: 5000
        });
        onProceed();
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center ">
            <h2 className="font-bold text-lg ">Commission Rate </h2>
          </div>

          <XMarkIcon onClick={onClose} width={'20px'} height={'20px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            type: '',
            rate: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            rate: Yup.string().required('Commission rate is required.'),
            type: Yup.string().required('Policy type is required.')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="">
                <div className="mb-4">
                  <SelectDropdown
                    label="Policy Type"
                    name="type"
                    list={policyTypes?.map((policyType: any) => ({
                      name: policyType.name,
                      value: policyType.value
                    }))}
                    listSelectedValue={values.type}
                    onChange={(val: ISelectDropdownItem | null) => {
                      setFieldValue('type', val);
                    }}
                    onBlur={handleBlur}
                    placeholder="Select Policy Type"
                    error={touched.type ? errors.type : undefined}
                    helperText={touched?.type ? errors.type : undefined}
                    required
                  />
                </div>
                <div className="">
                  <Input
                    label="Commission rate"
                    placeholder="Enter a commission percentage"
                    name="rate"
                    value={values.rate}
                    type="number"
                    wrapperClasses=""
                    required
                    error={errors.rate}
                    onChange={handleChange}
                    onBlur={handleChange}
                    helperText={errors.rate}
                  />
                </div>
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  className="w-fit px-6 text-[14px]"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SetCommission;
