import { Formik } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { hubtelPreapprovalVerify } from '../../../api/requests';
import { toast } from 'react-toastify';
import Input from '../../../components/form-control/Input';
import Button from '../../../components/form-control/Button';
import ToastContent from '../../../components/ToastContent';
import { IRequestErrorResponse } from '../../../resources/interfaces';
import { getPhoneNumberFromShortCodeFormat } from '../../../utils/miscFunctions';

interface IVerifyHubtelPreapprovalOTP {
  policyNumber: string;
  phone: string;
  otpPrefix: string;
  onProceed: () => void;
}

function VerifyHubtelPreapprovalOTP(props: IVerifyHubtelPreapprovalOTP) {
  return (
    <div className="lg:px-[10%]">
      <h1 className="font-headings text-[26px] leading-[40px] text-center font-bold">
        2-Step Verification
      </h1>
      <p className="font-medium text-center text-sm text-gray-500">
        Get the verification code sent to <b>{getPhoneNumberFromShortCodeFormat(props.phone)}</b> to
        confirm payment.
      </p>

      <div className="py-[1.5rem]">
        <Formik
          initialValues={{ code: '' }}
          validationSchema={Yup.object().shape({
            code: Yup.string()
              .trim()
              .min(4, 'Invalid verification code')
              .max(4, 'Invalid verification code')
              .required('Verification code is required')
          })}
          onSubmit={async (values) => {
            try {
              const payload: any = {
                ...props,
                policyNumber: props.policyNumber,
                otpCode: `${props.otpPrefix}-${values.code}`
              };

              hubtelPreapprovalVerify(payload.otpCode, payload.policyNumber).then(
                (response: any) => {
                  toast.success(
                    (
                      <ToastContent title={response?.message} description={response?.description} />
                    ) ?? 'Payment made successfully',
                    {
                      autoClose: 3000
                    }
                  );
                  props.onProceed();
                }
              );
            } catch (error: IRequestErrorResponse | AxiosError | any) {
              toast.error(<ToastContent title={error?.message} description={error?.description} />);
            }
          }}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="mt-[1rem]">
                <Input
                  type="text"
                  id="code"
                  label="Enter verification code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="code"
                  error={touched.code ? errors.code : undefined}
                  helperText={touched.code ? errors.code : undefined}
                  required
                  prepend={props.otpPrefix + '-'}
                  className={'pl-16'}
                />
              </div>

              <Button
                type="submit"
                label=""
                className="w-full mt-[2rem] py-[1rem] bg-primary-main"
                loading={isSubmitting}>
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default VerifyHubtelPreapprovalOTP;
