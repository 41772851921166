import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RootState } from '..';
import { IUserAccount } from '../../models/UserAccount';
import { IUser } from '../../models/User';
import { signoutUser } from '../../api/requests';
import { IInsurer } from '../../models/insurer/IInsurer';

interface IInitialState {
  PROFILE: IUser;
  ACCOUNT: IUserAccount;
  INSURER: IInsurer;
  USER_ID: string | null;
}

const initialState: IInitialState = {
  PROFILE: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    gender: undefined,
    profileImage: undefined,
    team: undefined
  },
  ACCOUNT: {
    isNew: false,
    accountSession: '',
    accessRight: null,
    userId: '',
    token: '',
    sessionExpired: false
  },
  INSURER: {
    status: '',
    website: ''
  },
  USER_ID: null
};

const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState,
  reducers: {
    UPDATE_PROFILE: (state, action: PayloadAction<Partial<IUser>>) => {
      state.PROFILE = {
        ...state.PROFILE,
        ...action.payload
      };
      return state;
    },
    UPDATE_ACCOUNT: (state, action: PayloadAction<Partial<IUserAccount>>) => {
      state.ACCOUNT = {
        ...state.ACCOUNT,
        ...action.payload
      };
      return state;
    },
    UPDATE_INSURER: (state, action: PayloadAction<Partial<IInsurer>>) => {
      state.INSURER = {
        ...state.INSURER,
        ...action.payload
      };
      return state;
    },
    SIGN_OUT: (state) => {
      // signoutUser(state.PROFILE.accessRight);
      state = initialState;
      return state;
    },
    USER_ID: (state, action: PayloadAction<string>) => {
      state.USER_ID = action.payload;
    }
  }
});

export const getUserAccountState = (state: RootState): RootState => {
  return state;
};

export const getUserProfile = (state: RootState): IUser => {
  return state.userAccount.PROFILE;
};

export const getUserAccount = (state: RootState): IUserAccount => {
  return state.userAccount.ACCOUNT;
};

export const isAuthenticated = (state: RootState): boolean => {
  return !!state.userAccount.ACCOUNT.token;
};

export const getInsurerFromState = (state: RootState): IInsurer => {
  return state.userAccount.INSURER;
};

export const getPreviousUserId = (state: RootState): string => state.userAccount.USER_ID;

export const { UPDATE_PROFILE, UPDATE_ACCOUNT, SIGN_OUT, UPDATE_INSURER, USER_ID } =
  userAccountSlice.actions;
export default userAccountSlice.reducer;
