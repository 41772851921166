import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { ScrollToFormikError } from "../elements/ScrollToFormikError";

const DatePickerInput = ({
    selected,
    onChange,
    showTimeSelect = false,
    showTimeSelectOnly = false,
    timeInterval = 30,
    timeCaption = "Time",
    classes,
    dateFormat,
    disabled = false,
    placeholder = "Select date",
    label,
    id,
    required = false,
    error,
    description,
    showYearDropdown = false,
    helperText,
    maxDate,
    minDate,
    allowFutureSelections = true,
    allowEarlierSelections = true,
    showMonthYearPicker = false,
    showMonthDropdown = true,
}: IDatePickerInput): JSX.Element => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(selected);

    const fieldRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        onChange(selectedDate);
    }, [selectedDate])

    return (
        <div 
            className="w-full"
            ref={fieldRef}
        >
            {label && (
                <label
                    htmlFor={id}
                    className={clsx(
                        "block text-sm font-medium text-gray-700",
                        { ["text-danger-main"]: error && required }
                    )}
                >
                    {label} <span className="text-danger-main">{required && "*"}</span>
                    {description && (<p className={clsx("text-xs text-gray-400 italic", { ["text-danger-border"]: error && required })}>{description}</p>)}
                </label>
            )}
            <DatePicker
                selected={selected}
                className={clsx("relative w-full border border-gray-300 rounded-md shadow-sm py-2 text-left cursor-default",
                    "focus:outline-none focus:ring-1 focus:ring-primary-main focus:border-primary-border sm:text-sm",
                    "flex items-center px-2",
                    classes,
                    disabled ? "bg-gray-300 cursor-not-allowed" : "bg-white",
                )}
                onChange={time => setSelectedDate(time)}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeIntervals={timeInterval}
                timeCaption={timeCaption}
                // filterTime={filterPassedTime}]
                maxDate={maxDate ? maxDate : !allowFutureSelections ? new Date : null}
                minDate={minDate ? minDate : !allowEarlierSelections ? new Date : null}
                dateFormat={dateFormat}
                // onInputClick={() => setSelectStartTime(false)}
                onChangeRaw={((e) => e.preventDefault())}
                disabled={disabled}
                placeholderText={placeholder}
                showYearDropdown={showYearDropdown}
                showMonthYearPicker={showMonthYearPicker}
                showMonthDropdown={showMonthDropdown}
                useShortMonthInDropdown
                yearDropdownItemNumber={50}
                scrollableYearDropdown
            // withPortal
            />
            {/* Helper Text */}
            <div className={clsx("text-xs text-gray-400", { ["text-danger-main"]: error })}>{helperText}</div>
            <ScrollToFormikError
                fieldName={id ?? ""}
                fieldRef={fieldRef}
            />
        </div>
    )
}
export default DatePickerInput;

interface IDatePickerInput {
    id: string;
    selected: Date | null;
    onChange: (date: Date | null) => void;
    showTimeSelect?: boolean;
    timeInterval?: number;
    timeCaption?: string;
    showTimeSelectOnly?: boolean;
    classes?: string;
    dateFormat?: string;
    disabled?: boolean;
    placeholder?: string;
    label?: string;
    required?: boolean;
    error?: string;
    description?: string;
    showYearDropdown?: boolean;
    helperText?: string;
    maxDate?: Date;
    minDate?: Date;
    allowFutureSelections?: boolean;
    allowEarlierSelections?: boolean;
    showMonthYearPicker?: boolean;
    showMonthDropdown?: boolean;
}
