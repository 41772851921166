import { GENDER } from '../../resources/enums';
import { getIdTypeFromValue } from '../../utils/miscFunctions';
import { IUser } from '../User';
import UserAccount from '../UserAccount';
import { Person } from '../person/Person';
import { IApplicant } from './IApplicant';

export class Applicant implements Person {
  id: string;
  ref: string;
  customerId: string;
  firstName: string | undefined;
  lastName: string | undefined;
  otherName: string | undefined;
  fullName: string | undefined;
  hasDisease: boolean;
  diseaseOrComplications: string;
  phoneNumber: string | undefined;
  phoneVerified: boolean;
  otherPhone: string | undefined;
  otherPhoneVerified: boolean;
  emailVerified: boolean;
  email: string | undefined;
  address: string;
  occupation: string;
  dateOfBirth: Date | undefined;
  idType: string | undefined;
  idNumber: string | undefined;
  idExpiryDate: Date | undefined;
  idVerified: boolean;
  employerName: string;
  employerAddress: string;
  employerPhoneNumber: string;
  monthlyIncome: string | undefined;
  socialMedia: string | undefined;
  gender: GENDER;
  account: UserAccount | undefined;
  rideSharePeriod: number | undefined;
  liability?: boolean;
  liabilityDetails?: string;
  liabilityDebt?: string;
  liabilityModeOfPayment?: string;

  constructor(Imodel: IApplicant) {
    this.id = Imodel.id ?? '';
    this.ref = Imodel.ref ?? '';
    this.customerId = Imodel.customerId ?? '';
    this.firstName = Imodel.firstName ?? undefined;
    this.lastName = Imodel.lastName ?? undefined;
    this.otherName = Imodel.otherName ?? undefined;
    this.fullName = Imodel.fullName ?? `${this.firstName} ${this.lastName}`;
    this.hasDisease = Imodel.hasDisease ?? false;
    this.diseaseOrComplications = Imodel.diseaseOrComplications ?? '';
    this.phoneNumber = Imodel.phoneNumber;
    this.phoneVerified = Imodel.phoneVerified ?? false;
    this.otherPhone = Imodel.otherPhone;
    this.phoneVerified = Imodel.phoneVerified ?? false;
    this.otherPhoneVerified = Imodel.otherPhoneVerified ?? false;
    this.emailVerified = Imodel.emailVerified ?? false;
    this.email = Imodel.email;
    this.address = Imodel.address ?? '';
    this.occupation = Imodel.occupation ?? '';
    this.dateOfBirth = Imodel.dateOfBirth ? new Date(Imodel.dateOfBirth) : undefined;
    this.idType = getIdTypeFromValue(Imodel.idType ?? '');
    this.idNumber = Imodel.idNumber ?? undefined;
    this.idVerified = Imodel.idVerified ?? false;
    this.idExpiryDate = Imodel.idExpiryDate ? new Date(Imodel.idExpiryDate) : undefined;
    this.employerName = Imodel.employerName ?? '';
    this.employerAddress = Imodel.employerAddress ?? '';
    this.employerPhoneNumber = Imodel.employerPhoneNumber ?? '';
    this.monthlyIncome = Imodel.monthlyIncome ?? undefined;
    this.socialMedia = Imodel.socialMedia ?? undefined;
    this.gender = GENDER[Imodel?.gender as keyof typeof GENDER];
    this.account = Imodel.account ? new UserAccount(Imodel.account) : undefined;
    this.rideSharePeriod = Imodel.rideSharePeriod ?? undefined;
    this.liability = Imodel.liability ?? undefined;
    this.liabilityDetails = Imodel.liabilityDetails ?? undefined;
    this.liabilityDebt = Imodel.liabilityDebt ?? undefined;
    this.liabilityModeOfPayment = Imodel.liabilityModeOfPayment ?? undefined;
  }
}
