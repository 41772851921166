/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../components/form-control/Input';
import Button from '../../components/form-control/Button';
import Page from '../../components/PageWrapper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetUserPassword } from '../../api/requests';
import { toast } from 'react-toastify';
import { IResetPasswordDTO } from '../admin/settings/Password';
import { SIGN_IN } from '../../resources/links';
import clsx from 'clsx';
import useLayoutHook from '../../hooks/layoutHook';
import { IRequestResponse } from '../../resources/interfaces';

function ResetPassword() {
  const navigate = useNavigate();
  const { screenWidth } = useLayoutHook();
  const [searchParams] = useSearchParams();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const userEmail = searchParams.get('email');
  const token = searchParams.get('ref');

  const submitHandler = (values: any) => {
    if (userEmail && token) {
      const payload: IResetPasswordDTO = {
        password: values.password,
        ref: token
      };

      setSubmitting(true);
      resetUserPassword(payload)
        .then((response: IRequestResponse) => {
          toast.success(response?.message ?? 'Password reset successfully. Log in to continue. ', {
            autoClose: 5000
          });
          navigate(SIGN_IN, { replace: true });
        })
        .catch((error: any) => {
          toast.error(error.description);
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Page title="Reset Password">
      <div
        className={clsx(
          '  bg-white mx-1 rounded-md py-10 md:mx-14 font-poppins',
          screenWidth < 2100 ? 'w-full' : 'w-1/2'
        )}>
        <p className="text-sm sm:text-base py-2 text-center  text-[#9CA3AF]">
          Assign new password to account
        </p>

        <Formik
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          validateOnChange={false}
          validateOnBlur={true}
          validationSchema={Yup.object({
            password: Yup.string()
              .trim()
              .required('Password is required')
              .min(9, 'A minimum of 9 characters required')
              .test({
                message: 'User email cannot be used as password',
                test: (value) => value !== userEmail
              })
              .matches(
                /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
                'Password must include at least two of the following: an uppercase character, a number, a special character'
              ),
            confirmPassword: Yup.string()
              .required('Password confirmation is required')
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, handleBlur }) => (
            <form className="px-2 md:px-24 my-7" onSubmit={handleSubmit}>
              <h3 className="text-center text-lg font-bold">{userEmail}</h3>

              <div className="my-5">
                <Input
                  name="password"
                  label="Password"
                  value={values.password}
                  type="password"
                  wrapperClasses="my-5"
                  showPasswordIcon
                  required
                  error={errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={errors.password}
                  description={`Must be more than 8 characters. Include an uppercase character, a number and a special character`}
                />
                {errors.password && (
                  <div className="flex flex-col font-bold text-gray-500 text-sm">
                    <div className="">
                      <span> Password must:</span>
                      <div className="flex items-center gap-2">
                        <div className="h-2 w-2 bg-gray-500 rounded-full  "></div>{' '}
                        <span>Be between 9 and 64 characters</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="h-2 w-2 bg-gray-500 rounded-full  "></div>{' '}
                        <span>Includes at least two of the following:</span>
                      </div>
                    </div>

                    <div className="ml-5 flex flex-col">
                      <span>&#x2022; An uppercase character</span>
                      <span>&#x2022; A number</span>
                      <span>&#x2022; A special character</span>
                    </div>
                  </div>
                )}

                <Input
                  name="confirmPassword"
                  label="Confirm Password"
                  value={values.confirmPassword}
                  type="password"
                  wrapperClasses="my-5"
                  showPasswordIcon
                  required
                  error={errors.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={errors.confirmPassword}
                />

                <Button
                  type="submit"
                  label="Submit"
                  className="mt-5 flex justify-center rounded px-6 w-full bg-black text-white"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Page>
  );
}

export default ResetPassword;

{
  /* <div className="flex justify-center my-8">
          <Logo rounded />
        </div> */
}
