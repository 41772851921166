import { useState } from 'react';
import useModal from '../../../../hooks/modalHook';
import useRoute from '../../../../hooks/routesHook';
import useUserAccount from '../../../../hooks/userAccountHook';
import { User } from '../../../../models/User';
import InsurerStore from './InsurerStore';

interface IInsurerOnboardingStep {
  progress: number;
  component: JSX.Element;
  key: string;
}

function InsurerStorePreview(): JSX.Element {
  const [progress, setProgress] = useState<number>(1);
  const { updateModalStates, ModalComponent } = useModal()

  const { getProfile } = useUserAccount();
  const { getDashboardBase } = useRoute();

  const user = new User(getProfile());

  return (
    <div className='flex items-center flex-col text-dark'>
      <InsurerStore />

    </div>
  );
}

export default InsurerStorePreview;
