import { useEffect, useState } from 'react';
import useUserAccount from '../../../hooks/userAccountHook';
import { User } from '../../../models/User';
import Button from '../../../components/form-control/Button';
import { updateAgencyName } from '../../../api/requests';
import { FaEdit } from 'react-icons/fa';
import Input from '../../../components/form-control/Input';
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';

interface ITeamNameProps {
  name: string;
  onNameChange: (name: string) => void;
  teamId: string;
}

const TeamName = (props: ITeamNameProps) => {
  const { getProfile } = useUserAccount();

  const user = new User(getProfile());

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [agencyName, setAgencyName] = useState<string>(props.name);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    setAgencyName(props.name);
  }, [props.name]) 


  const changeAgencyName = () => {
    setSaving(true);
    updateAgencyName(props.teamId, { name: agencyName })
      .then((response: any) => {
        if (response) {
          toast.success("Team name updated successfully.", {
            autoClose: 3000
          })
          props.onNameChange(agencyName);
        }
        setIsEdit(false);
      }).finally(() => setSaving(false))
  }

  return (
    <div className='w-full'>
      {isEdit ? (
        <div className='flex space-x-4 items-center w-[300px] md:w-[50%]'>
          <Input
            name='agencyName'
            placeholder='Enter agency name'
            value={agencyName}
            className={"w-full"}
            onChange={(e: any) => {
              setAgencyName(e.target.value);
            }}
          />
          <Button
            label={`${saving ? "Saving..." : "Save"}`}
            onClick={changeAgencyName}
            className={"py-[8px] bg-primary-main w-fit px-6"}
            disabled={saving}
          />
          <IoClose
            className='w-[50px] cursor-pointer'
            onClick={() => setIsEdit(false)}
          />
        </div>
      ) : (
        props.name && (
          <div className='flex space-x-4 items-center'>
            <h1 className="text-4xl font-semibold">{props.name}</h1>

            {(user.isSuperAdmin() || user.isAgencyAdmin()) && (
              <FaEdit className='text-[20px] cursor-pointer'
                onClick={() => setIsEdit(true)}
              />
            )}
          </div>
        )
      )}
    </div>
  );
};

export default TeamName;
