import React from 'react';
import { IGeneralMetrics } from '../../../models/generalMetrics/IGeneralMetrics';
import { numberWithCommas } from '../../../utils/miscFunctions';
import DateRangeSelector from '../date-range-selector/RangeSelector';

interface SalesSummaryProps {
  generalMetrics: IGeneralMetrics;
  onDateRangeChange: (e: any) => void;
  titleOne?: string;
  titleTwo?: string;
  titleThree?: string;
  titleFour?: string;
}

const SalesSummaryOld: React.FC<SalesSummaryProps> = (props) => {
  const { generalMetrics, onDateRangeChange } = props;

  return (
    <div className="border border-[#FFFCF3] rounded-lg shadow-full mb-1 justify-between px-4 sm:px-16 py-10 pt-7 align-items-center bg-[#F5DB99]">
      <div className="flex-col min-[640px]:flex min-[640px]:flex-row justify-between mb-6 items-center">
        <h2 className="font-bold text-lg pt-1 ml-7 w-40">{`Summary`}</h2>
        <DateRangeSelector onChange={onDateRangeChange} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 mt-18 sm:mt-0">
        <div className="md:border-r-[1px] border-[#FEFEFE] flex flex-col justify-between items-center sm:items-start h-full pl-0 sm:pl-12  mx-auto sm:mx-0">
          <div className="h-[40px] w-[40px] bg-[#FFFFFF99] rounded-full flex justify-center items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.89701 18.4622L7.80701 14.6123L10.636 17.3968L15.207 12.8971L17 14.6616V9.7385H12L13.793 11.5039L10.636 14.6123L7.80801 11.8269L2.86201 16.6968C2.29194 15.4365 1.99821 14.072 2.00001 12.6923C2.00001 7.25431 6.47701 2.84619 12 2.84619C17.523 2.84619 22 7.25431 22 12.6923C22 18.1304 17.523 22.5385 12 22.5385C10.418 22.5395 8.85839 22.1705 7.44962 21.4618C6.04084 20.7531 4.82321 19.725 3.89701 18.4622Z"
                fill="#79CB6B"
              />
            </svg>
          </div>
          <div className="mt-3 text-center sm:text-left">
            <h1 className="text-2xl md:text-2xl mb-1">{generalMetrics?.sales?.toFixed(2)}</h1>
            <p>Sales (GHS)</p>
          </div>
        </div>
        <div className="md:border-r-[1px] border-[#FEFEFE] flex flex-col justify-between items-center sm:items-start h-full pl-0 sm:pl-12  mx-auto sm:mx-0">
          <div className="h-[40px] w-[40px] bg-[#FFFFFF99] rounded-full flex justify-center items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 13.85C12.55 13.85 13.021 13.654 13.413 13.262C13.8043 12.8707 14 12.4 14 11.85C14 11.3 13.8043 10.829 13.413 10.437C13.021 10.0457 12.55 9.85 12 9.85C11.45 9.85 10.9793 10.0457 10.588 10.437C10.196 10.829 10 11.3 10 11.85C10 12.4 10.196 12.8707 10.588 13.262C10.9793 13.654 11.45 13.85 12 13.85ZM12 21.7C11.8167 21.7 11.646 21.6873 11.488 21.662C11.3293 21.6373 11.1833 21.6 11.05 21.55C8.98333 20.7833 7.29167 19.4167 5.975 17.45C4.65833 15.4833 4 13.3167 4 10.95V6.225C4 5.80833 4.121 5.43333 4.363 5.1C4.60433 4.76667 4.91667 4.525 5.3 4.375L11.3 2.125C11.5333 2.04167 11.7667 2 12 2C12.2333 2 12.4667 2.04167 12.7 2.125L18.7 4.375C19.0833 4.525 19.396 4.76667 19.638 5.1C19.8793 5.43333 20 5.80833 20 6.225V10.95C20 11.95 19.8667 12.9583 19.6 13.975C19.3333 14.9917 18.9333 15.95 18.4 16.85L15.45 13.9C15.6333 13.5833 15.771 13.254 15.863 12.912C15.9543 12.5707 16 12.2167 16 11.85C16 10.75 15.6083 9.80833 14.825 9.025C14.0417 8.24167 13.1 7.85 12 7.85C10.9 7.85 9.95833 8.24167 9.175 9.025C8.39167 9.80833 8 10.75 8 11.85C8 12.95 8.39167 13.8917 9.175 14.675C9.95833 15.4583 10.9 15.85 12 15.85C12.35 15.85 12.696 15.8043 13.038 15.713C13.3793 15.621 13.7 15.4833 14 15.3L17.225 18.525C16.6417 19.225 15.9917 19.8293 15.275 20.338C14.5583 20.846 13.7833 21.25 12.95 21.55C12.8167 21.6 12.6707 21.6373 12.512 21.662C12.354 21.6873 12.1833 21.7 12 21.7Z"
                fill="#FBC02D"
              />
            </svg>
          </div>
          <div className="mt-3 text-center sm:text-left">
            <h1 className="text-2xl md:text-2xl mb-1">{generalMetrics.policy}</h1>
            <p>Policies</p>
          </div>
        </div>
        <div className="md:border-r-[1px] border-[#FEFEFE] flex flex-col justify-between items-center sm:items-start h-full pl-0 sm:pl-12  mx-auto sm:mx-0">
          <div className="h-[40px] w-[40px] bg-[#FFFFFF99] rounded-full flex justify-center items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 4C12.2 4 14 5.8 14 8C14 10.2 12.2 12 10 12C7.8 12 6 10.2 6 8C6 5.8 7.8 4 10 4ZM17 21L18.8 22.77C19.3 23.27 20 22.87 20 22.28V18L22.8 14.6C22.9114 14.4514 22.9793 14.2748 22.996 14.0898C23.0126 13.9048 22.9775 13.7189 22.8944 13.5528C22.8114 13.3867 22.6837 13.247 22.5257 13.1493C22.3678 13.0517 22.1857 13 22 13H15C14.2 13 13.7 14 14.2 14.6L17 18V21ZM15 18.7L12.7 15.9C12.3 15.4 12.1 14.8 12.1 14.2C11.4 14 10.7 14 10 14C5.6 14 2 15.8 2 18V20H15V18.7Z"
                fill="#4A839B"
              />
            </svg>
          </div>
          <div className="mt-3 text-center sm:text-left">
            <h1 className="text-2xl md:text-2xl mb-1">{generalMetrics.leads}</h1>
            <p>Signups</p>
          </div>
        </div>
        <div className="md:border-r-0 border-[#FEFEFE] flex flex-col justify-between items-center sm:items-start h-full pl-0 sm:pl-12  mx-auto sm:mx-0">
          <div className="h-[40px] w-[40px] bg-[#FFFFFF99] rounded-full flex justify-center items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3227_2622)">
                <path
                  d="M7.99932 10.7601H7.41932C6.62783 10.7315 5.8389 10.8659 5.10149 11.1549C4.36409 11.4439 3.69395 11.8813 3.13266 12.4401L2.97266 12.6268V18.1468H5.69266V15.0135L6.05932 14.6001L6.22599 14.4068C7.09394 13.5151 8.17451 12.859 9.36599 12.5001C8.76945 12.0462 8.29898 11.4472 7.99932 10.7601Z"
                  fill="#1D2F37"
                />
                <path
                  d="M20.8935 12.4201C20.3323 11.8613 19.6621 11.4239 18.9247 11.1349C18.1873 10.8459 17.3984 10.7115 16.6069 10.7401C16.3641 10.7408 16.1216 10.7541 15.8802 10.7801C15.5749 11.4247 15.1173 11.9853 14.5469 12.4134C15.8189 12.7653 16.9711 13.4567 17.8802 14.4134L18.0469 14.6001L18.4069 15.0134V18.1534H21.0335V12.6068L20.8935 12.4201Z"
                  fill="#1D2F37"
                />
                <path
                  d="M7.39992 9.46011H7.60658C7.51056 8.63561 7.65523 7.80093 8.02314 7.05684C8.39104 6.31275 8.96645 5.69105 9.67992 5.26678C9.42129 4.87168 9.06449 4.55052 8.64445 4.33474C8.22441 4.11897 7.75554 4.01598 7.28374 4.03587C6.81194 4.05575 6.35339 4.19782 5.95299 4.44817C5.5526 4.69851 5.22408 5.04856 4.99962 5.46401C4.77515 5.87947 4.66243 6.3461 4.67249 6.81821C4.68256 7.29033 4.81506 7.75173 5.05702 8.15725C5.29898 8.56277 5.64211 8.89849 6.05281 9.13155C6.46351 9.36461 6.9277 9.48702 7.39992 9.48678V9.46011Z"
                  fill="#1D2F37"
                />
                <path
                  d="M16.2858 8.96004C16.2939 9.11326 16.2939 9.26681 16.2858 9.42004C16.4138 9.44032 16.543 9.45146 16.6725 9.45337H16.7992C17.2693 9.4283 17.725 9.28193 18.1217 9.02849C18.5185 8.77506 18.8429 8.4232 19.0633 8.00718C19.2838 7.59116 19.3927 7.12515 19.3796 6.65453C19.3665 6.1839 19.2317 5.72469 18.9884 5.32161C18.7451 4.91853 18.4016 4.58531 17.9913 4.35439C17.581 4.12347 17.1179 4.00273 16.6471 4.00391C16.1763 4.0051 15.7138 4.12817 15.3047 4.36115C14.8956 4.59413 14.5538 4.92907 14.3125 5.33337C14.9159 5.72733 15.412 6.26491 15.7565 6.89788C16.1009 7.53086 16.2828 8.23943 16.2858 8.96004Z"
                  fill="#1D2F37"
                />
                <path
                  d="M11.9136 11.9466C13.5594 11.9466 14.8936 10.6124 14.8936 8.96657C14.8936 7.32076 13.5594 5.98657 11.9136 5.98657C10.2678 5.98657 8.93359 7.32076 8.93359 8.96657C8.93359 10.6124 10.2678 11.9466 11.9136 11.9466Z"
                  fill="#1D2F37"
                />
                <path
                  d="M12.0727 13.5333C11.202 13.4982 10.3333 13.6395 9.51871 13.9489C8.70412 14.2582 7.96053 14.7291 7.33268 15.3333L7.16602 15.52V19.74C7.16862 19.8774 7.19827 20.013 7.25329 20.139C7.30831 20.265 7.38761 20.379 7.48666 20.4743C7.58572 20.5696 7.70259 20.6445 7.83059 20.6947C7.95859 20.7449 8.09523 20.7693 8.23268 20.7667H15.8927C16.0301 20.7693 16.1668 20.7449 16.2948 20.6947C16.4228 20.6445 16.5396 20.5696 16.6387 20.4743C16.7378 20.379 16.8171 20.265 16.8721 20.139C16.9271 20.013 16.9567 19.8774 16.9593 19.74V15.5333L16.7993 15.3333C16.1756 14.7273 15.4346 14.255 14.6218 13.9455C13.809 13.636 12.9416 13.4957 12.0727 13.5333Z"
                  fill="#1D2F37"
                />
              </g>
              <defs>
                <clipPath id="clip0_3227_2622">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="mt-3 text-center sm:text-left">
            <h1 className="text-2xl md:text-2xl mb-1">{generalMetrics.customers || 0}</h1>
            <p>{props.titleFour ?? 'Customers'} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesSummaryOld;
