import { IInstallmentData, IVehicleProtectionType } from '../../resources/interfaces';
import { getVehicleProtectionFromKey } from '../../utils/miscFunctions';
import { ILeadActivityStreams } from '../lead/ILead';
import { IQuote } from './IQuote';

export class Quote {
  id: string;
  ref: string;
  vehicleModel: string | null;
  numOfPassenger: number;
  manufacturedYear: number | null;
  cc: number;
  registrationYear: string | null;
  vehicleUse: string | null;
  insuredValue: number;
  protectionType: IVehicleProtectionType | null;
  employer: string | null;
  phone: string | undefined;
  installmentType: string;
  quotePlan: IInstallmentData;
  firstName: string;
  lastName: string;
  email: string | null;
  coverPeriod: string | null;
  period: string | null;
  source?: string;
  connectedDriver: boolean;
  activityStreams?: ILeadActivityStreams[];

  constructor(Imodel: IQuote) {
    this.id = Imodel.id;
    this.ref = Imodel.ref;
    this.vehicleModel = Imodel.vehicleModel;
    this.numOfPassenger = Imodel.numOfPassenger;
    this.manufacturedYear = Imodel.manufacturedYear;
    this.cc = Imodel.cc;
    this.registrationYear = Imodel.registrationYear;
    this.vehicleUse = Imodel.vehicleUse;
    this.insuredValue = Imodel.insuredValue;
    this.protectionType = getVehicleProtectionFromKey(Imodel.protectionType ?? '');
    this.employer = Imodel.employer;
    this.phone = Imodel.phone;
    this.installmentType = Imodel.installmentType;
    this.quotePlan = Imodel.quotePlan;
    this.firstName = Imodel.firstName;
    this.lastName = Imodel.lastName;
    this.email = Imodel.email ?? '';
    this.coverPeriod = Imodel.coverPeriod;
    this.period = Imodel.period;
    this.source = Imodel?.source;
    this.connectedDriver = Imodel.connectedDriver;
  }
}
