// Auth links
export const SIGN_IN = "/sign-in";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const SET_PASSWORD = "/set-password";
export const SET_PASSWORD_SUCCESS = "/password-success";
export const COMPLETE_ACCOUNT = "/complete-account";
export const INSURER_SIGNUP = "/insurer-signup";


// Dashboard links
export const DASHBOARD_BASE = "/admin";
export const DASHBOARD = DASHBOARD_BASE + "/dashboard";
export const TICKETS = DASHBOARD_BASE + "/tickets";
export const LEADS = DASHBOARD_BASE + "/leads";
export const CUSTOMERS = DASHBOARD_BASE + "/customers";
export const POLICIES = DASHBOARD_BASE + "/policies";
export const VEHICLES = DASHBOARD_BASE + "/vehicles";
export const MESSAGES = DASHBOARD_BASE + "/messages";
export const NOTIFICATION = DASHBOARD + "/notification";
export const QUOTES = DASHBOARD_BASE + "/quotes";
export const QUOTE_DETAILS = DASHBOARD_BASE + "/quotes/:ref";
export const TEAMS = DASHBOARD_BASE + "/teams";
export const DISCOUNT = DASHBOARD_BASE + "/discount";
export const SETTINGS = DASHBOARD_BASE + "/settings";
export const PAYMENTS = DASHBOARD_BASE + "/payments";
export const INSURERS = DASHBOARD_BASE + "/insurers";
export const ADD_INSURERS = INSURERS + "/add-insurer";
export const POLICY_DETAILS = DASHBOARD_BASE + "/policies/:policyId"
export const INSURER_DETAILS = DASHBOARD_BASE + "/insurers/:id"
export const LEAD_DETAILS = DASHBOARD_BASE + "/leads/:id"
export const CREATE_LEADS = LEADS + "/create-leads"
export const VIEW_CUSTOMER = DASHBOARD_BASE + "/customers:id"
export const SINGLE_TEAM = DASHBOARD_BASE + "/teams:id"
export const ROLE_DETAILS = DASHBOARD_BASE + "/settings/roles/role/:id"


// Agent Dashboard links
export const AGENTS_BASE = "/agents";
export const AGENTS_DASHBOARD = AGENTS_BASE + "/dashboard";
export const AGENTS_LEADS = AGENTS_BASE + "/leads";
export const AGENTS_COMMISSIONS = AGENTS_BASE + "/commissions";
export const AGENTS_POLICIES = AGENTS_BASE + "/policies";
export const AGENTS_QUOTES = AGENTS_BASE + "/quotes";
export const AGENTS_PAYMENTS = AGENTS_BASE + "/payments";
export const AGENTS_CREATE_LEADS = AGENTS_LEADS + "/create-leads"
export const AGENTS_SETTINGS = AGENTS_BASE + "/settings";

//Queries
// export const NEW_POLICY = POLICIES + "?new=true";
export const NEW_LEAD = LEADS + "?new=true";


// COMMON 
export const NOT_FOUND = "/404";
