import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { useState } from 'react';
import { ICreateAdminAccountDTO } from '../../../resources/interfaces';
import SelectMenu from '../../../components/form-control/SelectMenu';
import { toast } from 'react-toastify';
import { ACCESS_RIGHTS, GENDER } from '../../../resources/enums';
import { getFormattedPhoneNumber, getInsurerUserTypesList } from '../../../utils/miscFunctions';
import InputSelectMenu from '../../../components/form-control/InputSelectMenu';
import { InsurerAgentRoles } from '../../../resources/constants';
import SelectDropdown from '../../../components/form-control/SelectDropdown';
import { inviteInsurerUser } from '../../../api/requests';
import { PlusIcon } from '@heroicons/react/24/solid';

const InviteInsurerAgent = ({
  onProceed,
  onClose,
  teamID,
  role
}: {
  onProceed: (res: any) => void;
  onClose: () => void;
  teamID?: string;
  role?: ACCESS_RIGHTS;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload: Partial<ICreateAdminAccountDTO> = {
      email: values.email.trim(),
      firstName: values.firstName,
      lastName: values.lastName,
      country: 'Ghana',
      gender: values.gender,
      jobTitle: values.jobTitle,
      phone: getFormattedPhoneNumber(values.phone),
      accessRights: values.userType
    };

    inviteInsurerUser(payload)
      .then((response: any) => {
        if (response) {
          toast.success(response.message ?? 'Insurer user account invite sent successfully', {
            autoClose: 5000
          });
          onProceed(response);
        }
      })
      .catch((error: any) => {
        toast.error(error?.description ?? error?.message);
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <div className="">
      <div className="bg-primary-main flex justify-between">
          <div className="p-[1rem] flex justify-between w-full items-center">
            <div className="flex items-center justify-center gap-5">
              <div className="flex items-center justify-center bg-white p-2 rounded-full">
                <PlusIcon className="h-4 w-4" />
              </div>
              <h2 className="font-[gilroy-bold] text-lg">Invite User</h2>
            </div>
            <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            jobTitle: '',
            gender: '',
            phone: '',
            userType: role
          }}
          validateOnChange={false}
          validateOnBlur
          validationSchema={Yup.object({
            firstName: Yup.string().trim().required('First name is required.'),
            lastName: Yup.string().trim().required('Last name is required.'),
            email: Yup.string()
              .trim()
              .email('Email provided invalid.')
              .required('Email is requied.'),
            jobTitle: Yup.string().required('Job title is required.'),
            gender: Yup.string().trim().required('Gender is required'),
            userType: Yup.string().trim().required('User type is required'),
            phone: Yup.string()
              .trim()
              .required('Phone number is required')
              .min(10, 'Ensure the phone number is up to 10 digits')
              .max(10, 'Ensure the phone number is up to 10 digits')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex gap-x-8">
                <Input
                  name="firstName"
                  label="First name"
                  value={values.firstName}
                  type="text"
                  placeholder="Eg John"
                  wrapperClasses=""
                  required
                  error={errors.firstName}
                  onChange={handleChange}
                  onBlur={handleChange}
                  helperText={errors.firstName}
                />

                <Input
                  name="lastName"
                  label="Last name"
                  value={values.lastName}
                  type="text"
                  placeholder="Eg Doe"
                  required
                  error={errors.lastName}
                  onChange={handleChange}
                  onBlur={handleChange}
                  helperText={errors.lastName}
                />
              </div>

              <div className="flex gap-x-8">
                <Input
                  name="phone"
                  value={values.phone}
                  placeholder={`Eg. 0244444444`}
                  label="Phone"
                  type="tel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone ? errors.phone : undefined}
                  required
                  helperText={touched.phone ? errors.phone : undefined}
                  max={10}
                />

                <SelectMenu
                  label="Gender"
                  name="gender"
                  listSelectedValue={values.gender}
                  list={Object.values(GENDER)}
                  onChange={(val: string | null) => setFieldValue('gender', val)}
                  onBlur={handleBlur}
                  placeholder="Select gender"
                  error={touched.gender ? errors.gender : undefined}
                  helperText={touched.gender ? errors.gender : undefined}
                  required
                  wrapperClasses="w-full"
                />
              </div>

              <div className="">
                {' '}
                <Input
                  name="email"
                  label="Email"
                  value={values.email.trim()}
                  placeholder="Eg: brolly@gmail.com"
                  type="email"
                  wrapperClasses=""
                  required
                  error={errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.email}
                />
              </div>
              <div className="flex gap-x-8">
                <SelectDropdown
                  label="User Type"
                  name="userType"
                  listSelectedValue={values.userType}
                  list={getInsurerUserTypesList(ACCESS_RIGHTS)}
                  onChange={(val: string | null) => setFieldValue('userType', val)}
                  onBlur={handleBlur}
                  placeholder="Select user type"
                  error={touched.userType ? errors.userType : undefined}
                  helperText={touched.userType ? errors.userType : undefined}
                  required
                  wrapperClasses="w-full"
                />

                <InputSelectMenu
                  label="Job Title"
                  name="jobTitle"
                  listSelectedValue={values.jobTitle}
                  onChange={(val: string | null) => {
                    setFieldValue('jobTitle', val);
                  }}
                  onBlur={handleBlur}
                  list={InsurerAgentRoles}
                  placeholder="Select job title"
                  error={touched.jobTitle ? errors.jobTitle : undefined}
                  required
                  sort
                  search
                  emptyListMessage="Select job title to proceed"
                  helperText={touched?.jobTitle ? errors.jobTitle : undefined}
                  wrapperClasses="w-full"
                />
              </div>
              <div className="flex justify-end">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  className="w-fit px-6 text-[14px] rounded"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default InviteInsurerAgent;
