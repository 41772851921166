import { useEffect, useState } from 'react';
import { Animate } from 'react-simple-animate';

interface SideDrawerProps {
  show: boolean;
  children: React.ReactNode;
}

const useLayoutHook = () => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const endStyles = {
    zIndex: 2147483647,
    position: 'absolute' as 'absolute',
    top: 0,
    right: screenWidth > 550 ? 0 : '20px',
    width: screenWidth > 550 ? '550px' : '100vw',
    // minHeight: 'calc(100vh - 105px)',
    maxHeight: 'calc(100vh - 105px)'
  };

  const startStyles = {
    zIndex: 2147483647,
    position: 'absolute' as 'absolute',
    top: 0,
    right: screenWidth > 550 ? '-550px' : '-100vw',
    width: screenWidth > 550 ? '550px' : '100vw'
    // minHeight: 'calc(100vh - 105px)'
  };

  const SideDrawer = ({ show, children }: SideDrawerProps) => (
    <Animate play={show} start={startStyles} end={endStyles}>
      {children}
    </Animate>
  );

  return { screenWidth, SideDrawer };
};

export default useLayoutHook;
