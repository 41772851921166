import { ISizeValue, TSize } from "../resources/types";


export function isDevEnv() {
    return process.env.NODE_ENV === "development";
}

export function stringToEnum<Type>(val: string, enumerator: Record<string, unknown>): Type | null {
    if(typeof enumerator === "object") {
        const objType = enumerator as unknown as Record<string, unknown>;
        return objType[val] as Type;
    }

    return null;
}


export function sizeValues(size?: TSize): ISizeValue {
    switch(size??"xs") {
        case "xs":
            return {
                height: "h-7",
                width: "w-7"
            }
        case "sm":
            return {
                height: "h-12",
                width: "w-12"
            }
        case "md":
            return {
                height: "h-20",
                width: "w-20"
            }
        case "lg":
            return {
                height: "h-32",
                width: "w-32"
            }
        case "xl":
            return {
                height: "h-48",
                width: "w-48"
            }
        case "2xl":
            return {
                height: "h-12",
                width: "w-12"
            }
        case "3xl":
            return {
                height: "h-12",
                width: "h-12"
            }
        case "4xl":
            return {
                height: "h-12",
                width: "h-12"
            }
        default:
            return {
                height: "h-12",
                width: "h-12"
            }
    }
  }