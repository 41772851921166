import { ReactNode, useState } from 'react';
import Button from '../form-control/Button';
import { sentenceCase } from '../../utils/miscFunctions';

interface IDeleteConfirmationProps {
  action: string;
  onClose: () => void;
  onConfirm: () => void;
  resourceName?: string;
  resourceType?: string;
  modalName?: string;
  confirmationMessage?: JSX.Element | Element | ReactNode;
}

const ActionConfirmation = (props: IDeleteConfirmationProps): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  return (
    <div className="py-[43px] px-0 md:px-6 w-full">
      <h3 className="w-full text-center font-headings text-[20px] pb-6">
        Confirm {sentenceCase(props.action)}
      </h3>
      {props.confirmationMessage ? (
        <>{props.confirmationMessage}</>
      ) : (
        <p className="w-full text-center">
          Are you sure you want to {props.action} <b>{props.resourceType}</b>{' '}
          <b>{props.resourceName}</b>?<br />
          {props.modalName!=="Blog" && <span>This action cannot be undone.</span>}
        </p>
      )}

      <div className="flex-col-reverse gap-5 flex md:flex-row  mt-10 px-5 md:px-0">
        <div className="w-full md:w-1/2">
          <Button
            label="Cancel"
            onClick={props.onClose}
            curved
            className=" w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
          />
        </div>

        <div className="w-full md:w-1/2">
          <Button
            label={sentenceCase(props.action)}
            className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-white "
            onClick={() => {
              setSubmitting(true);
              props.onConfirm();
            }}
            curved
            bgColor="primary"
            disabled={submitting}
            loading={submitting}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionConfirmation;
