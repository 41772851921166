import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckIcon } from '@heroicons/react/24/solid';

type ChecklistStrengthProps = {
  title?: string;
  options: string[];
  progressBarColor?: string;
  checkIcon?: JSX.Element;
  completedOptions?: string[]; // Make completedOptions optional
  onOptionChange?: (option: string, remove?: boolean) => void;
  disableClick?: boolean;
  showBar?: boolean;
  onClick?:(task:string)=>void;
};

const ChecklistStrength = ({
  title = 'Checklist',
  options,
  progressBarColor = 'bg-green-500',
  checkIcon = <CheckIcon className="w-4 h-4 font-medium text-white" />,
  completedOptions = [],
  onOptionChange,
  disableClick = true,
  showBar = true,
  onClick
}: ChecklistStrengthProps) => {
  const handleOptionChange = (option: string) => {
    if (onOptionChange) {
      if (completedOptions.includes(option)) {
        onOptionChange(option, true);
      } else {
        onOptionChange(option);
      }
    }
  };

  const progress = (completedOptions.length / options.length) * 100;

  return (
    <div className="w-full">
      <div className="flex flex-col space-y-2">
        <div className="w-full flex items-center justify-between font-semibold">
          <h2>{title}</h2>
          <h4>{`${progress}%`}</h4>
        </div>

        <div className="py-1">
          {showBar && (
            <div className="h-4 bg-gray-200 rounded-md ">
              <div
                className={`h-full rounded-md ${progressBarColor}`}
                style={{ width: `${progress}%` }}></div>
            </div>
          )}
        </div>

        {options.map((option, index) => ( 
          <label key={index} className="flex items-center space-x-2 cursor-pointer" onClick={()=>onClick?.(option)}>
            <input
              type="checkbox"
              className="sr-only"
              checked={completedOptions.includes(option)}
              onChange={() => handleOptionChange(option)}
              disabled={disableClick}
            />
            <span
              className={`text-gray-700 ${
                completedOptions.includes(option) ? 'bg-[#069803] text-white font-bold' : ''
              } size-5 border border-gray-300 rounded-full flex items-center justify-center shrink-0 p-1`}>
              {completedOptions.includes(option) && checkIcon}
            </span>
            <span className="text-gray-700">{option}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default ChecklistStrength;
