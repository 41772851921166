import React, { useEffect, useState } from "react";
import clsx from "clsx";

import ComponentStyles from "../styles/components.module.css";
import { ISizeValue, TSize } from "../resources/types";
import { sizeValues } from "../utilities";

interface ILoaderProps {
    size?: TSize;
    message?: string;
    fullscreen?: boolean;
    displayNewLoader?: boolean;
    brollyLoader?: boolean;
    classes?: string;
    messageClasses?: string;
}


function BrollyLogo() {
    return <svg className="w-8 h-8" viewBox="0 0 67 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2283_3)">
            <path d="M14.0771 4.62778H16.8786V6.13529C17.1832 5.5393 17.5857 5.08855 18.0862 4.77803C18.5866 4.46752 19.1415 4.31726 19.7399 4.31726C20.1641 4.31726 20.6047 4.42244 21.0671 4.62778L20.0499 7.21711C19.6692 7.04183 19.3536 6.95166 19.1034 6.95166C18.6029 6.95166 18.1787 7.23713 17.8305 7.81312C17.4824 8.38405 17.3083 9.5109 17.3083 11.1837L17.3192 11.7697V16.6128H14.0771V4.62778Z" fill="#263238" />
            <path d="M29.776 4.31726C31.0054 4.31726 32.1586 4.59773 33.241 5.16367C34.3236 5.72962 35.1667 6.49589 35.7705 7.46754C36.3743 8.43911 36.6789 9.4859 36.6789 10.6078C36.6789 11.7397 36.3743 12.7964 35.7651 13.783C35.1558 14.7697 34.3236 15.5359 33.2683 16.0919C32.2129 16.6478 31.0543 16.9232 29.7869 16.9232C27.9211 16.9232 26.3273 16.3123 25.0054 15.0902C23.6836 13.8682 23.0254 12.3807 23.0254 10.6328C23.0254 8.75969 23.7706 7.19703 25.2611 5.94998C26.572 4.86317 28.0788 4.31726 29.776 4.31726ZM29.8304 7.14696C28.8132 7.14696 27.9701 7.47252 27.2955 8.12359C26.621 8.77472 26.2837 9.60606 26.2837 10.6177C26.2837 11.6595 26.6156 12.5059 27.2847 13.152C27.9537 13.7981 28.7969 14.1236 29.8195 14.1236C30.8422 14.1236 31.6962 13.7981 32.3708 13.142C33.0507 12.4859 33.3879 11.6495 33.3879 10.6177C33.3879 9.59103 33.0561 8.75464 32.3871 8.11361C31.7234 7.47252 30.8694 7.14696 29.8304 7.14696Z" fill="#263238" />
            <path d="M39.9761 0H43.2453V16.6127H39.9761V0Z" fill="#263238" />
            <path d="M46.8306 0H50.0998V16.6127H46.8306V0Z" fill="#263238" />
            <path d="M52.6025 4.62769H55.9425L59.3259 12.1502L63.0575 4.62769H66.4084L58.2543 21H54.8818L57.558 15.7212L52.6025 4.62769Z" fill="#263238" />
            <path d="M10.2864 9.42569C9.79141 8.72954 9.00808 8.15355 7.94192 7.69279C8.57835 7.25206 9.04072 6.78126 9.32355 6.27542C9.60645 5.77459 9.74786 5.21365 9.74786 4.59262C9.74786 3.64604 9.43781 2.82467 8.81228 2.1235C8.18669 1.42734 7.34897 0.946538 6.29369 0.691112C5.54846 0.500796 4.37893 0.40564 2.76879 0.40564H0V3.34554H3.35083H3.99272H4.21574C4.98273 3.34554 5.55934 3.49579 5.93467 3.79128C6.31001 4.08677 6.5004 4.49245 6.5004 4.99829C6.5004 5.5442 6.30457 5.96991 5.90748 6.28544C5.51038 6.60099 4.90657 6.76125 4.08519 6.76125H3.99272H3.35083H0V16.6127H4.93377C6.35352 16.6127 7.46865 16.4274 8.27372 16.0567C9.07878 15.6861 9.73702 15.1252 10.2483 14.3789C10.7596 13.6277 11.0153 12.8013 11.0153 11.8898C11.0262 10.9382 10.7814 10.1169 10.2864 9.42569ZM7.01173 13.1869C6.5276 13.5125 5.59198 13.6727 4.19942 13.6727H3.36171V9.52085H4.05799C5.37983 9.52085 6.32089 9.72113 6.89206 10.1268C7.45781 10.5325 7.74606 11.0834 7.74606 11.7796C7.73522 12.3956 7.49587 12.8664 7.01173 13.1869Z" fill="#263238" />
        </g>
        <defs>
            <clipPath id="clip0_2283_3">
                <rect width="67" height="21" fill="white" />
            </clipPath>
        </defs>
    </svg>
}

function Loader({
    size = "xs",
    message,
    fullscreen = false,
    displayNewLoader = false,
    brollyLoader = false,
    classes, 
    messageClasses,
}: ILoaderProps): JSX.Element {
    const [sizes, setSizes] = useState<ISizeValue>(sizeValues(size));

    useEffect(() => {
        if (fullscreen) {
            setSizes(sizeValues("md"));
        }
    }, [fullscreen])

    if (brollyLoader) {
        return (
            <div className={clsx("text-center", classes,
                fullscreen && "h-screen flex flex-col justify-center")
            }>
                <div className="flex justify-center">
                    <div className={clsx("border-t-primary-border border-white !border-t-4 rounded-[50%] p-4 mx-2", ComponentStyles.loader)}> 
                        <img
                            src="/img/brolly-black-b.svg"
                            className={clsx(
                                " ",
                                `${sizes.height} ${sizes.width}`,
                            )}
                        />
                    </div>
                    
                </div>
                {message && <div className={clsx("text-sm text-gray-500 mt-2", messageClasses)}>{message}</div>}
            </div>
        )
    }

    if (displayNewLoader)
        return (
            <div className="flex flex-col items-center">
                <div className="rounded-full w-14 h-14 flex justify-center items-center bg-[#FBC02D]">
                    <BrollyLogo />
                </div>
                <div className="bg-[#D9D9D9] h-[6px] w-24 mb-6 mt-0"></div>
                {message && <div className={clsx("text-md text-[#263238]", messageClasses)}>{message}</div>}
            </div>
        );

    return (
        <div className={clsx("text-center",
            fullscreen && "h-screen flex flex-col justify-center")
        }>
            <div className="flex justify-center">
                <div
                    className={clsx(
                        "border-t-primary-border border-2 mx-2 mb-2 rounded-[50%]",
                        `${sizes.height} ${sizes.width}`,
                        ComponentStyles.loader
                    )}
                />
            </div>
            {message && <div className={clsx("text-sm text-gray-500", messageClasses)}>{message}</div>}
        </div>
    );
}

export default Loader;