import { DISCOUNT_STATUS_OPTIONS, DISCOUNT_TYPES, GENDER } from '../../resources/enums';
import { IDiscount } from './IDiscount';

export class Discount {
  id: number;
  ref: string;
  active: DISCOUNT_STATUS_OPTIONS;
  code: string;
  name: string| undefined;
  relation?: string;
  type: DISCOUNT_TYPES;
  value: number;
  createdOn: string;
  expiresOn: string;
  updatedOn: string;

  constructor(Imodel: IDiscount) {
    this.id = Imodel.id;
    this.ref = Imodel.ref ?? '';
    this.active = Imodel.active;
    this.code = Imodel.code;
    this.name = Imodel.name;
    this.relation = Imodel.relation;
    this.type = Imodel.type;
    this.value = Imodel.value;
    this.createdOn = Imodel.createdOn;
    this.expiresOn = Imodel.expiresOn;
    this.updatedOn   = Imodel.updatedOn;
  }

  getStatus = (): string => {
    let status = 'Inactive';

    if (this.active) {
      status = 'Active';
    } else {
      status = 'Ended';
    }

    return status;
  };
  
  getDiscountStatus = (): string => {
    let status = 'Inactive';

    if (this.active) {
      status = 'Active';
    } else {
      status;
    }

    return status;
  };
}
