import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Button from '../../../components/form-control/Button';
import ClipboardCopy from '../../../components/form-control/CopyToClipboard';
import { MdOutlineSecurity } from 'react-icons/md';
import { copyTextToClipboard } from '../../../utils/miscFunctions';
import { toast } from 'react-toastify';
import ShareComponent from '../../../components/share/ShareComponent';
import { LinkIcon } from '@heroicons/react/24/solid';

const PaymentLinkModal = ({
  onProceed,
  onClose,
  link
}: {
  onProceed: () => void;
  onClose: () => void;
  link: string;
}) => {

  
  const handleCopyClick = (value: string) => {
    copyTextToClipboard(value)
      .then(() => {
        toast.success('Copied to clipboard', {
          autoClose: 3000
        });
      })
      .catch((err) => {});
  };
  return (
    <div className="">
      <div className="bg-primary-main flex justify-between">
        <div className="p-5 flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-1">
            <div className="flex items-center justify-center bg-white size-11 rounded-full">
              <LinkIcon className="h-[24px] w-[24px]" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Generated Payment Link</h2>
          </div>
          <XMarkIcon onClick={onProceed} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="p-5 ">
        <div onClick={()=>handleCopyClick(link)} className="text-sm mb-5 cursor-pointer">{link}</div>
        <ShareComponent title={'Payment Link'} text={link} url={link} />
      </div>
    </div>
  );
};

export default PaymentLinkModal;
