import { PencilIcon } from '@heroicons/react/20/solid';
import Input from '../../../components/form-control/Input';
import TextArea from '../../../components/form-control/TextArea';
import { MdPhoto, MdSend } from 'react-icons/md';
import { BsPaperclip } from 'react-icons/bs';
import { HiOutlineMicrophone } from 'react-icons/hi';
import { EnvelopeIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Button from '../../../components/form-control/Button';
import { useState } from 'react';
import { sendCustomerEmail } from '../../../api/requests';
import { toast } from 'react-toastify';

interface NewMessageProps {
  onProceed: () => void;
  onClose: () => void;
  customerMail?: string;
}
interface IEmailCustomerDTO {
  to: string;
  subject: string;
  message: string;
  introduction?: string;
}

const NewMessage = ({ onProceed, onClose, customerMail }: NewMessageProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload: IEmailCustomerDTO = {
      ...values,
      to: values.to,
      subject: values.subject,
      message: values.message
    };

    sendCustomerEmail(payload)
      .then((response: any) => {
        toast.success(response?.message ?? 'Agent account invite sent successfully', {
          autoClose: 5000
        });
        onProceed();
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-5">
            <div className="flex items-center justify-center bg-white p-2 rounded-full">
              <EnvelopeIcon className="h-4 w-4" />
            </div>
            <h2 className="font-bold text-lg">New Message</h2>
          </div>
          <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="p-5 w-full">
        <Formik
          initialValues={{
            to: customerMail ?? '',
            message: '',
            subject: ''
          }}
          validateOnBlur
          validationSchema={Yup.object({
            to: Yup.string().trim().required('Senders address is required.'),
            message: Yup.string().trim().required('Message is required')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex items-center gap-5 ">
                <div className="shrink-0">
                  <span className="text-[#615B5B] ">To :</span>
                </div>

                <Input
                  type="text"
                  name="to"
                  value={values.to}
                  onChange={handleChange}
                  onBlur={handleChange}
                  className="p-2 "
                  placeholder="Eg: support@brolly.africa"
                  error={touched.to ? errors.to : undefined}
                  helperText={touched.to ? errors.to : undefined}
                  required
                />
              </div>

              <div className="flex items-center gap-5 ">
                <div className="shrink-0">
                  <span className="text-[#615B5B] ">Subject :</span>
                </div>

                <Input
                  type="text"
                  name="subject"
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleChange}
                  className="p-2 "
                  placeholder=""
                  error={touched.subject ? errors.subject : undefined}
                  helperText={touched.subject ? errors.subject : undefined}
                />
              </div>

              <TextArea
                placeholder="Write a message..."
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleChange}
                classes="p-5 "
                autoComplete
                error={touched.message ? errors.message : undefined}
                helperText={touched.message ? errors.message : undefined}
                required
              />
              <div className="flex justify-end gap-2">
                {/* <div className="w-10 h-10 bg-[#CFDCE280] cursor-pointer rounded-lg flex items-center justify-center">
                  <HiOutlineMicrophone className="w-5 h-5" />
                </div>
                <div className="w-10 h-10 bg-[#CFDCE280] cursor-pointer rounded-lg flex items-center justify-center">
                  <MdPhoto className="w-5 h-5" />
                </div>
                <div className="w-10 h-10 bg-[#CFDCE280] cursor-pointer rounded-lg flex items-center justify-center">
                  <BsPaperclip className="w-5 h-5" />
                </div> */}
                <div className="w-10 h-10 bg-primary-main cursor-pointer rounded-lg flex items-center justify-center">
                  <MdSend className="w-5 h-5" onClick={() => submitHandler(values)} />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const Icons = (icon: any) => {
  return (
    <div className="w-10 h-10 bg-primary-main cursor-pointer rounded-lg flex items-center justify-center">
      <MdSend className="w-5 h-5" />
    </div>
  );
};

export default NewMessage;
