import { ClockIcon, EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/24/solid';
import {
  TaskEventsProps,
  ThreeStateVerifiedCheckProps,
  VerifiedCheckProps
} from '../../resources/interfaces';
import { ReactComponent as CheckIcon } from '../../resources/icons/check-success.svg';
import { FUEL_SALES_STATUS, TASK_EVENT } from '../../resources/enums';
import PopOnMe from '../elements/poponme';
import clsx from 'clsx';
import { getFormattedSalesStatusFromKey } from '../../utils/miscFunctions';

export const TwoStateVerifiedCheck = ({ verified }: VerifiedCheckProps) => {
  return (
    <>
      {verified === undefined ? (
        <div className="">
          <XMarkIcon className="text-white p-[2px] h-4 w-4 rounded-full bg-red-700 " />
        </div>
      ) : (
        <div className="">
          {verified ? (
            <CheckIcon className="h-4 w-4 rounded-full" />
          ) : (
            <XMarkIcon className="text-white p-[2px] h-4 w-4 rounded-full bg-red-700 " />
          )}
        </div>
      )}
    </>
  );
};

export const ThreeStateVerifiedCheck = ({ verified, clickable }: ThreeStateVerifiedCheckProps) => {
  return (
    <div className={clickable ? 'cursor-pointer' : ''}>
      {verified === 'pending' ? (
        <ClockIcon className="text-yellow-500 p-[2px] h-4 w-4 rounded-full bg-yellow-200" />
      ) : verified === 'Success' ? (
        <CheckIcon className="text-green-700 p-[2px] h-4 w-4 rounded-full bg-green-200" />
      ) : (
        <XMarkIcon className="text-white p-[2px] h-4 w-4 rounded-full bg-red-700" />
      )}
    </div>
  );
};

export const TaskEvents = ({ row, actions }: TaskEventsProps) => {
  let backgroundColor = '';
  let textColor = '';

  switch (row.cell.value) {
    case TASK_EVENT.PAYMENT || FUEL_SALES_STATUS.COMPLETED:
      backgroundColor = 'border border-green-500';
      textColor = 'text-green-500';
      break;
    case TASK_EVENT.POLICY || FUEL_SALES_STATUS.PENDING_CLOSE:
      backgroundColor = 'border border-yellow-400';
      textColor = 'text-yellow-400';
      break;
    case TASK_EVENT.CLAIM:
      backgroundColor = 'border border-red-500';
      textColor = 'text-red-500';
      break;
    case TASK_EVENT.CUSTOMER || FUEL_SALES_STATUS.CLOSED:
      backgroundColor = 'border border-blue-500';
      textColor = 'text-blue-500';
      break;
    case TASK_EVENT.LEAD || FUEL_SALES_STATUS.NEW:
      backgroundColor = 'border border-orange-500';
      textColor = 'text-orange-500';
      break;
    case TASK_EVENT.QUOTE:
      backgroundColor = 'border border-purple-500';
      textColor = 'text-purple-500';
      break;
    case TASK_EVENT.COMMISSION:
      backgroundColor = 'border border-pink-500';
      textColor = 'text-pink-500';
      break;

    default:
      backgroundColor = 'border border-gray-400';
      textColor = 'text-gray-800';
  }

  return (
    <div
      className={clsx(
        `w-full text-xs flex items-center justify-center rounded-3xl p-1`,
        backgroundColor,
        textColor,
        actions && 'cursor-pointer'
      )}>
      {row.cell.value ?? 'N/A'}
      {actions && actions.length > 0 && (
        <div className="cursor-pointer flex justify-center">
          <PopOnMe actions={actions}>
            <EllipsisVerticalIcon className="h-4 w-4" />
          </PopOnMe>
        </div>
      )}
    </div>
  );
};

export const FuelSalesStatus = ({ row }: TaskEventsProps) => {
  console.log('row:', row.value);
  let backgroundColor = '';
  let textColor = '';

  switch (row.value) {
    case FUEL_SALES_STATUS.COMPLETED:
      backgroundColor = 'border border-blue-500';
      textColor = 'text-blue-500';
      break;

    case FUEL_SALES_STATUS.PENDING_CLOSE:
      backgroundColor = 'border border-orange-400';
      textColor = 'text-orange-400';
      break;

    case FUEL_SALES_STATUS.NEW:
      backgroundColor = 'border border-red-500';
      textColor = 'text-red-500';
      break;

    case FUEL_SALES_STATUS.FAILED:
      backgroundColor = 'border border-red-500';
      textColor = 'text-red-500';
      break;

    case FUEL_SALES_STATUS.PENDING_PAYMENT:
      backgroundColor = 'border border-[#FFBF00]';
      textColor = 'text-[#FFBF00]';
      break;

    case FUEL_SALES_STATUS.PAID:
      backgroundColor = 'border border-green-500';
      textColor = 'text-green-500';
      break;

    case FUEL_SALES_STATUS.CLOSED:
      backgroundColor = 'border border-blue-500';
      textColor = 'text-blue-500';
      break;
    
    case FUEL_SALES_STATUS.PENDING_SETTLEMENT:
      backgroundColor = 'border border-[#D4AF37]';
      textColor = 'text-[#D4AF37]';
      break;

    default:
      backgroundColor = 'border border-gray-400';
      textColor = 'text-gray-800';
  }

  return (
    <div
      className={clsx(
        ` text-xs flex items-center justify-center rounded-3xl p-1`,
        backgroundColor,
        textColor
      )}>
      {row.value ? getFormattedSalesStatusFromKey(row.value) : 'N/A'}
    </div>
  );
};
