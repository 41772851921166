import { ACCESS_RIGHTS } from '../resources/enums';
import { IPolicyDocument } from '../resources/interfaces';
import { IAccount, IProfile } from '../resources/types';
import { getPolicyDocumentTitleFromType } from '../utils/miscFunctions';

class UserAccount {
  firstName: string;
  lastName: string;
  sessionId: string;
  userId: string;
  lastSeen?: Date | null;
  isNew: boolean;
  accessRight: ACCESS_RIGHTS | null;
  jobTitle: string;
  isAuthenticated: boolean;
  token: string;
  accountSession: string;
  sessionExpired: boolean;
  documents?: IPolicyDocument[];

  constructor(data?: IUserAccount) {
    if (data) {
      this.firstName = String(data.firstName);
      this.lastName = String(data.lastName);
      this.sessionId = String(data.accountSession);
      this.userId = String(data.userId);
      this.isNew = data.isNew ?? false;
      this.jobTitle = String(data.jobTitle);
      this.accessRight = data.accessRight;
      this.token = String(data.token);
      // this.isAuthenticated = Boolean(this.accessRight && this.userId && this.sessionId);
      this.isAuthenticated = Boolean(this.token);
      this.accountSession = String(data.accountSession);
      this.sessionExpired = !!data.sessionExpired;
      this.documents = data.documents?.map((document: IPolicyDocument) => ({
        id: document.id,
        title: getPolicyDocumentTitleFromType(document.docType),
        docType: document.docType,
        docURL: document.docURL
      }));
    } else {
      this.firstName = '';
      this.lastName = '';
      this.sessionId = '';
      this.userId = '';
      this.isNew = false;
      this.jobTitle = '';
      this.accessRight = null;
      this.isAuthenticated = false;
      this.token = '';
      this.accountSession = '';
      this.sessionExpired = true;
      this.documents = [];
    }
  }
}

export default UserAccount;

export interface IUserAccount {
  firstName?: string;
  lastName?: string;
  profileImage?: string;
  jobTitle?: string;
  accessRight: ACCESS_RIGHTS | null;
  isNew?: boolean;
  accountSession: string;
  userId: string;
  token?: string;
  sessionExpired?: boolean;
  documents?: IPolicyDocument[];
}
