import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  changePolicyManager,
  getAllAdminAccounts
} from '../../../api/requests';
import SelectDropdown, {
  ISelectDropdownItem
} from '../../../components/form-control/SelectDropdown';
import { MdChangeCircle } from 'react-icons/md';
import { getFormattedAccessRight } from '../../../utils/miscFunctions';

export interface IAssignPolicyManager {
  policyId: string;
  managerId: string;
}

const AssignPolicyManager = ({
  onProceed,
  onClose,
  policy
}: {
  onProceed: () => void;
  onClose: () => void;
  policy: any;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [admins, setAdmins] = useState<any>([]);

  useEffect(() => {
    getAllAdmins();
  }, []);

  const getAllAdmins = (page = 0) => {
    setLoading(true);
    getAllAdminAccounts()
      .then((response: any) => {
        const _admins = response?.map((admin: any) => ({
          name: `${admin.firstName} ${admin.lastName} (${getFormattedAccessRight(admin.accessRight)})`,
          value: admin.id
        }));

        setAdmins(_admins);
      })
      .catch((error: Error) => { });
  };
  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload: IAssignPolicyManager = {
      policyId: policy?.ref,
      managerId: values.id
    };

    changePolicyManager(payload)
      .then((response: any) => {
        toast.success(`This policy has been assigned to ${payload?.managerId}`, {
          autoClose: 5000
        });
        onProceed();
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between rounded">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-1">
            <div className="flex items-center justify-center bg-white h-[2.5rem] w-[2.5rem] rounded-full">
              <MdChangeCircle className="h-[1.5rem] w-[1.5rem]" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Assign Policy Manager</h2>
          </div>
          <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="flex items-center justify-center mt-7">
        <span className="text-center px-2">
          You are about to assign this policy to a new admin.
        </span>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            id: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            // email: Yup.string().email('Email provided invalid.').required('Email is requied.')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="">
                <SelectDropdown
                  label="Select new policy manager"
                  name="id"
                  list={admins}
                  listSelectedValue={values.id}
                  onChange={(val: ISelectDropdownItem | null) => {
                    setFieldValue('id', val);
                  }}
                  search
                  searchPlaceHolder='Search by name'
                  onBlur={handleBlur}
                  placeholder="Select Manager"
                  error={touched.id ? errors.id : undefined}
                  helperText={touched?.id ? errors.id : undefined}
                  required
                />
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  className=" px-3 rounded"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AssignPolicyManager;
