import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "./PopOnMe.scss";
import clsx from "clsx";

//children must be a single html element

const TippyContent: React.FC<{ actions: Array<any>; closeTippy: any, tippyWrapperStyles: any, removeButtonHoverEffect: any; popOptionClasses?: string }> = ({
  actions,
  closeTippy,
  tippyWrapperStyles,
  removeButtonHoverEffect,
  popOptionClasses
}) => {
  return (
    <>
      <div className="tippy-actions-wrapper" style={tippyWrapperStyles ? { ...tippyWrapperStyles } : {}}>
        {actions.map((action, index) => {
          if (action.useLink) {
            return (
              <a
                className={clsx(`tippy-action-button hover:!bg-primary-surface ${removeButtonHoverEffect ? 'no-hover-effect' : ''}`, popOptionClasses)}
                onClick={(event: any) => {
                  event.stopPropagation();
                  setTimeout(() => {
                    closeTippy()
                  }, 100);
                  if (action.data) {
                    action.callback(action.data);
                  } else {
                    action.callback();
                  }
                }}
                key={index}
                target="_self"
                href={action.linkHref}
              >
                <p>{action.label}</p>
              </a>
            );
          }
          return (
            <div
              className={clsx(`tippy-action-button hover:!bg-primary-surface ${removeButtonHoverEffect ? 'no-hover-effect' : ''}`, popOptionClasses)}
              onClick={(event: any) => {
                event.stopPropagation();
                setTimeout(() => {
                  closeTippy()
                }, 100);
                if (action.data) {
                  action.callback(action.data);
                } else {
                  action.callback();
                }
              }}
              key={index}
            >
              <p>{action.label}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

const PopOnMe: React.FC<{
  children: any;
  actions: Array<any>;
  onClick?: any;
  onClickOutside?: any;
  tippyWrapperStyles?: any;
  removeButtonHoverEffect?: boolean;
  popOptionClasses?: string;
}> = ({ children, actions, onClick, onClickOutside, tippyWrapperStyles, removeButtonHoverEffect, popOptionClasses }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const onScrollEvent = () => {
      setTimeout(() => {
        setOpen(false);
      }, 100);
    }

    document.addEventListener("scroll", onScrollEvent);

    return (() => document.removeEventListener("scroll", onScrollEvent));
  }, []);

  return (
    <Tippy
      content={
        <TippyContent
          actions={actions}
          closeTippy={() => setOpen(false)}
          tippyWrapperStyles={tippyWrapperStyles}
          removeButtonHoverEffect={removeButtonHoverEffect}
          popOptionClasses={popOptionClasses}
        />
      }
      visible={open}
      interactive
      onClickOutside={() => {
        if (onClickOutside) {
          onClickOutside();
        }
        setOpen(false)
      }}
      placement="bottom-end"
      theme="light-border"
      appendTo={document.body}
    >
      {React.cloneElement(children, {
        onClick: (event: any) => {
          event.stopPropagation();
          if (onClick) {
            onClick(event);
          }
          setOpen(true)
        }
      })}
    </Tippy>
  );
};

export default PopOnMe;
