import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChatLaunchState, LAUNCH_CHAT } from "../../states/slices/liveChatSlice";

function LiveChatWidget(): JSX.Element {
    const LAUNCH_WIDGET = useSelector(getChatLaunchState);
    const dispatch = useDispatch();

    const chatBtnRef = useRef(null);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.rapidbott.cloud/js/widget/ghhjtly5pmzxkcvc/float.js?ref=web1"
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        if (LAUNCH_WIDGET) {
            document.getElementById("launchChat")?.click();
            dispatch(LAUNCH_CHAT(false));
        }
    }, [LAUNCH_WIDGET])

    return (
        <button ref={chatBtnRef} id="launchChat" className="btn bot-trigger--btn max-h-0 h-0 hidden">
            
        </button>
    )
}

export default LiveChatWidget;