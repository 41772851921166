import { toast } from "react-toastify";
import { getAllInitResources } from "../api/requests";

function useResource() {

    const getVehicleMakes = (): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            getAllInitResources()
                .then((response: any) => {
                    resolve(response?.data?.vehicleMake);
                }).catch((error: Error) => {
                    reject(error);
                });
        });
    }

    const shareContennt = async (data: {title?: string, text?: string, url: string}) => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: data.title,
                    text: data.text,
                    url: data.url
                });
            } catch (error) { }
        } else {
            try {
                await navigator.clipboard.writeText(data.url);
                toast.success('URL copied to clipboard');
            } catch (error) {
                // console.error('Clipboard copy failed:', error);
            }
        }
    };

    return { getVehicleMakes, shareContennt };
}

export default useResource;