import React, { useState } from 'react';
import moment from 'moment';
import { NOTIFICATION_TYPE } from '../../resources/enums';
import { ReactComponent as DashboardLeadsIcon } from '../../resources/icons/DashboardLeadsIcon.svg';
import { ReactComponent as DashboardQuotesIcon } from '../../resources/icons/DashboardQuotesIcon.svg';
import { ReactComponent as DashboardPaymentsIcon } from '../../resources/icons/DashboardPaymentsIcon.svg';
import { ReactComponent as DashboardPayoutsIcon } from '../../resources/icons/DashboardPayoutsIcon.svg';
import { ReactComponent as DashboardTeamsIcon } from '../../resources/icons/DashboardTeamsIcon.svg';
import { ReactComponent as DashboardPoliciesIcon } from '../../resources/icons/DashboardPoliciesIcon.svg';
import { ReactComponent as DashboardCommunitiesIcon } from '../../resources/icons/DashboardCommunitiesIcon.svg';
import { ReactComponent as DashboardCustomersIcon } from '../../resources/icons/DashboardCustomersIcon.svg';
import { ReactComponent as DashboardClaimsIcon } from '../../resources/icons/claimsIcon.svg';
import { ReactComponent as DashboardFinancialsIcon } from '../../resources/icons/DashboardFinancialsIcon.svg';
import { INotification } from '../../resources/interfaces';
import { clearMeNotifications } from '../../api/requests';

interface NotificationsProps {
  allNotifications: INotification[];
  meNotifications: INotification[];
  handleOnclick: (notification: INotification) => void;
}

const Notifications: React.FC<NotificationsProps> = ({
  allNotifications,
  meNotifications,
  handleOnclick
}) => {
  const [selectedNotifications, setSelectedNotifications] = useState<string[]>([]);
  const [showAll] = useState<boolean>(false);

  const handleCheckboxClick = (id: string) => {
    if (selectedNotifications.includes(id)) {
      setSelectedNotifications(
        selectedNotifications.filter((notificationId) => notificationId !== id)
      );
    } else {
      setSelectedNotifications([...selectedNotifications, id]);
    }
  };

  const handleClearNotifications = () => {
    clearMeNotifications()
      .then(() => {
        window.location.reload();
      })
      .catch(() => {});
  };

  const notificationsToShow = showAll ? allNotifications : meNotifications;

  return (
    <div className="w-full py-5">
      <div className="flex justify-between mb-4 -sm px-5">
        <div>
          {/* <button
                        className={`mr-5 hover:font-semibold transition duration-300 ease-in-out border-b-2 ${showAll ? 'border-blue-500' : 'border-transparent'
                            }`}
                        onClick={() => setShowAll(true)}>
                        All
                    </button> */}
          <h2
            className={`font-semibold transition duration-300 ease-in-out  text-lg`}
            // onClick={() => setShowAll(false)}
          >
            Inbox
          </h2>
        </div>
      </div>
      <hr />

      {notificationsToShow.length > 0 && (
        <div className="px-5 overflow-y-auto h-full fit md:max-h-[30rem]">
          {notificationsToShow.map((notification: INotification) => (
            <Notification
              key={notification.id}
              notification={notification}
              onCheckboxClick={handleCheckboxClick}
              isChecked={selectedNotifications.includes(notification.id)}
              onClick={() => {
                handleOnclick(notification);
              }}
              viewed={notification.viewed}
            />
          ))}

          {!showAll && notificationsToShow.length > 0 && (
            <div className="pt-5 flex items-center justify-end">
              <button
                className={`px-2 py-1 rounded bg-blue-500 focus:outline-none text-sm text-white hover:text-slate-500 cursor-pointer`}
                onClick={() => handleClearNotifications()}>
                clear
              </button>
            </div>
          )}
        </div>
      )}

      {showAll && allNotifications?.length == 0 && (
        <div className="h-20 flex items-center justify-center font-semibold text-lg">
          <span>No notifications</span>
        </div>
      )}

      {!showAll && meNotifications?.length == 0 && (
        <div className="h-20 flex items-center justify-center font-semibold text-lg">
          <span>No notifications</span>
        </div>
      )}
    </div>
  );
};

interface NotificationProps {
  notification: INotification;
  onCheckboxClick?: (id: string) => void;
  onClick?: () => void;
  isChecked: boolean;
  showCheckBox?: boolean;
  viewed: string;
}

const Notification: React.FC<NotificationProps> = ({
  notification,
  onCheckboxClick,
  onClick,
  isChecked,
  viewed,
  showCheckBox = false
}) => {
  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onCheckboxClick?.(notification.id);
  };

  const getNotificationIconFromType = (type: NOTIFICATION_TYPE) => {
    switch (type) {
      case NOTIFICATION_TYPE.LEAD:
        return <DashboardLeadsIcon />;
      case NOTIFICATION_TYPE.CLAIM:
        return <DashboardClaimsIcon />;
      case NOTIFICATION_TYPE.COMMISSION:
        return <DashboardFinancialsIcon />;
      case NOTIFICATION_TYPE.COMMUNITY:
        return <DashboardCommunitiesIcon />;
      case NOTIFICATION_TYPE.CUSTOMER:
        return <DashboardCustomersIcon />;
      case NOTIFICATION_TYPE.PAYMENT:
        return <DashboardPaymentsIcon />;
      case NOTIFICATION_TYPE.PAYOUT:
        return <DashboardPayoutsIcon />;
      case NOTIFICATION_TYPE.POLICY:
        return <DashboardPoliciesIcon />;
      case NOTIFICATION_TYPE.QUOTE:
        return <DashboardQuotesIcon />;
      case NOTIFICATION_TYPE.STAFF:
        return <DashboardTeamsIcon />;
      case NOTIFICATION_TYPE.TEAM:
        return <DashboardTeamsIcon />;
      default:
        return null;
    }
  };

  return (
    <div className={`flex items-center border-b py-2 gap-5 cursor-pointer`}>
      {showCheckBox && (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxClick}
          className="cursor-pointer"
        />
      )}
      <div className="size-8 rounded-full bg-primary-surface flex items-center justify-center p-2">
        {getNotificationIconFromType(notification?.type)}
      </div>
      <div>
        <p
          onClick={onClick}
          className={`text-sm ${
            viewed === 'true' ? 'text-gray-500' : 'text-black  hover:font-semibold '
          }`}>
          {notification.message}
        </p>
        <p className="text-2xs">{moment(notification.date).format('DD-MM-YY, h:mm:ss a')}</p>
      </div>
    </div>
  );
};

export default Notifications;
