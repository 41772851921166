import moment from 'moment';
import { useRef, useState } from 'react';
import { SelectedDateRange } from './datePicker';
import DashboardDatePickers from "./datePicker"
import SelectDropdown, { ISelectDropdownItem } from '../../form-control/SelectDropdown';
import { getQuarter, getYearsList } from '../../../utils/miscFunctions';
interface DateRangeSelectoProps {
  options: string[];
  onChange: (value: string) => void;
}


const LAST_7_DAYS = "last-7-days";
const TODAY = "today";
const THIS_MONTH = "this-month";
const LAST_MONTH = "last-month";
const DATE_FORMAT = 'YYYY-MM-DD';
const DAILY = "daily";
const LAST_YEAR = "last-year";
const CUSTOM_DATE = "custom";
const YEAR_TO_DATE = "year-to-date";

function getRange(rangeKey: string): SelectedDateRange {
  let from: string; let to: string;
  switch (rangeKey) {
    case TODAY: {
      from = moment(new Date()).format(DATE_FORMAT);
      to = moment(new Date()).format(DATE_FORMAT)
    }
      break;

    case LAST_7_DAYS: {
      from = moment(new Date()).subtract(7, 'day').format(DATE_FORMAT);
      to = moment(new Date()).format(DATE_FORMAT)
    }
      break;

    case THIS_MONTH: {
      from = moment().startOf('month').format(DATE_FORMAT);
      to = moment(new Date()).format(DATE_FORMAT)
    }
      break;

    case LAST_MONTH: {
      from = moment().subtract(1, 'month').format(DATE_FORMAT);
      to = moment(new Date()).format(DATE_FORMAT)
    }
      break;

    case LAST_YEAR: {
      from = moment().subtract(1, 'year').format(DATE_FORMAT);
      to = moment(new Date()).format(DATE_FORMAT)
    }
      break;

    default: {
      from = "";
      to = ""
    }
  }

  return {
    from,
    to
  }
}

const DATE_FILTER_OPTIONS: ISelectDropdownItem[] = [
  {
    name: "Year to Date",
    value: YEAR_TO_DATE
  },
  {
    name: "Today",
    value: TODAY
  },
  {
    name: "Last 7 Days",
    value: LAST_7_DAYS
  },
  {
    name: "This Month",
    value: THIS_MONTH
  },
  {
    name: "Custom",
    value: CUSTOM_DATE
  }
]

const getSize = (windowWidth: number) => {
  return windowWidth <= 1024;
}

function DateRangeSelector(props: any) {
  const [selectedDateRange, setSelectedDateRange] = useState(YEAR_TO_DATE);
  const windowWidth = useRef(window.innerWidth);

  const onChange = (dateRangeKey: string) => {
    setSelectedDateRange(dateRangeKey)
    props.onChange(getRange(dateRangeKey))
  }

  const onDatePickerChange = (dateRange: SelectedDateRange) => props.onChange(dateRange);


  return (
    <div className={(getSize(windowWidth.current) && selectedDateRange === CUSTOM_DATE) ? 'flex-col justify-between' : 'flex justify-between'}>
      <div className='w-full'>
        <SelectDropdown
          listSelectedValue={selectedDateRange}
          onChange={(value: string) => onChange(value)}
          wrapperClasses="pl-0 pr-0 text-sm bg-transparent w-full"
          list={DATE_FILTER_OPTIONS}
          selectInputClassName={"!bg-transparent !text-dark border-[1px] !border-gray-300 !focus:border-dark !outline:border-dark !focus:ring-dark rounded-[3px] pl-0 pr-8 py-0"}
          IconClass={"!text-[#000000]"}
        />
      </div>
      {(selectedDateRange === CUSTOM_DATE) && <DashboardDatePickers onSubmit={onDatePickerChange} />}
    </div>
  );
}

const QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4']


export function YearRangeSelector(props: any) {
  const [selectedDateRange, setSelectedDateRange] = useState<any>(new Date().getFullYear());

  const onChange = (dateRangeKey: string) => {
    setSelectedDateRange(dateRangeKey)
    props.onPoliciesYearChange(dateRangeKey)
  }

  return (
    <SelectDropdown
      listSelectedValue={selectedDateRange}
      onChange={(value: string) => onChange(value)}
      wrapperClasses="pl-0 pr-0 text-sm bg-transparent w-fit"
      list={getYearsList().map((y, i) => {
        return { name: String(y), value: y }
      })}
      selectInputClassName={"!bg-transparent !text-dark border-[1px] !border-gray-300 !focus:border-dark !outline:border-dark !focus:ring-dark rounded-[3px] pl-0 pr-8 py-0"}
      IconClass={"!text-[#000000]"}
    />
  );
}

export function QuarterDateRangeSelector(props: any) {
  const [selectedDateRange, setSelectedDateRange] = useState(`Q${getQuarter(new Date())}`);

  const onChange = (dateRangeKey: string) => {
    setSelectedDateRange(dateRangeKey)
    props.onPoliciesQuarterChange(dateRangeKey)
  }

  return (
    <SelectDropdown
      listSelectedValue={selectedDateRange}
      onChange={(value: string) => onChange(value)}
      wrapperClasses="pl-0 pr-0 text-sm bg-transparent w-fit"
      list={QUARTERS.map((quarter: string) => ({ name: quarter, value: quarter }))}
      selectInputClassName={"!bg-transparent !text-dark border-[1px] !border-gray-300 !focus:border-dark !outline:border-dark !focus:ring-dark rounded-[3px] pl-0 pr-8 py-0"}
      IconClass={"!text-[#000000]"}
    />
  );
}

export default DateRangeSelector;
