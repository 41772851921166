/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
// import GoogleLogin from "react-google-login";
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LockClosedIcon as PasswordIcon, EnvelopeIcon } from '@heroicons/react/20/solid';

import Input from '../../components/form-control/Input';
import Link from '../../components/Link';
import Button from '../../components/form-control/Button';
import { DASHBOARD, FORGOT_PASSWORD } from '../../resources/links';
import { getInsurerProfile, loginWithPassword } from '../../api/requests';
import { ILoginDTO } from '../../resources/interfaces';
import useUserAccount from '../../hooks/userAccountHook';
import useRoute from '../../hooks/routesHook';
import MessagePrompt from '../../components/elements/MessagePrompt';
import { CheckBoxInput } from '../../components/form-control/CheckBoxInput';
import AuthWrapper from './AuthWrapper';
import { User } from '../../models/User';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  REDIRECT_URL,
  SESSION_EXPIRED,
  getRedirectURL,
  getSessionStatus
} from '../../states/slices/globalSlice';
import { getPreviousUserId, USER_ID } from '../../states/slices/userAccountSlice';
import useLayoutHook from '../../hooks/layoutHook';
import { useMutation } from '@tanstack/react-query';

function SignIn() {
  const navigate = useNavigate();

  const { getAccount, setProfile, updateInsurerInState, getProfile } = useUserAccount();
  const [loginError, setLoginError] = useState<string>();
  const { getDashboardBase } = useRoute();
  const sessionExpired = useSelector(getSessionStatus);
  const redirectURL = useSelector(getRedirectURL);
  const userId = useSelector(getPreviousUserId);
  const { setAccount } = useUserAccount();
  const { screenWidth } = useLayoutHook();
  const dispatch = useDispatch();
  const profile = getProfile();

  useEffect(() => {
    if (getAccount().isAuthenticated) {
      navigate(`${getDashboardBase()}/dashboard`, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (sessionExpired) {
      setTimeout(() => {
        dispatch(REDIRECT_URL(redirectURL));
        dispatch(USER_ID(profile?.id));
        dispatch(SESSION_EXPIRED(false));
      }, 20000);
    }
  }, [sessionExpired]);

  const { mutate: handleLogin, isPending: submitting } = useMutation({
    mutationFn: (payload: any) => loginWithPassword(payload),
    onSuccess: (response: any) => {
      setAccount({
        isNew: response?.data?.isNew ?? false,
        accessRight: null,
        accountSession: '',
        userId: '',
        token: response?.data?.token,
        sessionExpired: false
      });
      handleProfileDetails(response?.data?.profile);
    },
    onError: (error: any) => {
      setLoginError(error?.description);
    }
  });

  const getRouteForSignedInUser = (responseData: any) => {
    const isSameUser = responseData?.id === userId;
    if (redirectURL && isSameUser) {
      return redirectURL;
    }
    return DASHBOARD;
  };

  const handleProfileDetails = (data: any) => {
    setProfile(data);

    if (new User(data)?.isInsurer()) {
      getInsurerProfileDetails();
    }

    const signedInRoute = getRouteForSignedInUser(data);
    navigate(signedInRoute, { replace: true });
  };

  // const getProfileDetails = () => {
  //   getAccountProfile()
  //     .then((response: any) => {
  //       setProfile(response.data);

  //       if (new User(response.data)?.isInsurer()) {
  //         getInsurerProfileDetails();
  //       }

  //       const signedInRoute = getRouteForSignedInUser(response.data);
  //       navigate(signedInRoute, { replace: true });
  //     })
  //     .catch((error: any) => {
  //       toast.error(error.message);
  //       signOut();
  //     });
  // };

  const getInsurerProfileDetails = () => {
    getInsurerProfile().then((response: any) => {
      updateInsurerInState(response);
    });
  };

  return (
    <AuthWrapper pageTitle="Sign In" callToActionMsg="Log in, start selling more">
      <div
        className={clsx(
          '  bg-white mx-1 rounded-md py-10 md:mx-14 font-poppins',
          screenWidth < 2100 ? 'w-full' : 'w-1/2'
        )}>
        <div className="text-center font-normal font-poppins">
          {sessionExpired && (
            <MessagePrompt
              type="error"
              message="Account session expired due to inactivity. Kindly log in again"
              showInfoIcon={false}
            />
          )}

          {loginError ? (
            <MessagePrompt type="error" message={loginError} showInfoIcon={false} />
          ) : (
            <p className="text-[#9CA3AF]">Welcome, please enter your credentials to login.</p>
          )}
        </div>

        <Formik
          initialValues={{
            email: '',
            password: '',
            rememberMe: false
          }}
          validateOnChange
          validateOnBlur
          validationSchema={Yup.object({
            email: Yup.string().trim().email('Invalid email').required('Email is required'),
            password: Yup.string().trim().required('Password is required'),
            rememberMe: Yup.boolean()
          })}
          onSubmit={async (values) => {
            const payload: ILoginDTO = {
              email: values.email.trim(),
              password: values.password
            };

            handleLogin(payload);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, touched }) => (
            <form className="px-3 md:px-24 mt-10" onSubmit={handleSubmit}>
              <div className="my-5">
                <Input
                  label="Email"
                  name="email"
                  required
                  wrapperClasses="my-5"
                  value={values.email.toLowerCase()}
                  prepend={<EnvelopeIcon className="w-6 h-6 " />}
                  onChange={handleChange}
                  error={touched.email ? errors.email : undefined}
                  helperText={touched.email ? errors.email : undefined}
                  placeholder="Enter your email address"
                />

                <Input
                  name="password"
                  label="Password"
                  value={values.password}
                  type="password"
                  wrapperClasses="my-5"
                  showPasswordIcon
                  required
                  prepend={<PasswordIcon className="w-6 h-6" />}
                  onChange={handleChange}
                  error={touched.password ? errors.password : undefined}
                  helperText={touched?.password ? errors.password : undefined}
                  placeholder="Enter your password"
                />

                <div className="flex items-center justify-between ">
                  <CheckBoxInput
                    name={'rememberMe'}
                    onChange={(val: boolean) => {
                      setFieldValue('rememberMe', val);
                    }}
                    label={'Remember Me'}
                    checked={values.rememberMe}
                  />

                  <div className="text-sm text-[#004E60]">
                    <Link to={FORGOT_PASSWORD} label={'Forgot password?'} />
                  </div>
                </div>

                <Button
                  type="submit"
                  label="Sign In"
                  className="mt-5 flex justify-center rounded px-6 w-full bg-black text-white"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
        {/* <p>New to Brolly? <Link to={`${getDashboardBase()}/${SiGN}`} label={'Sign up'} /></p> */}
      </div>
    </AuthWrapper>
  );
}

export default SignIn;
