import { Formik } from 'formik';
import * as Yup from 'yup';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import Button from '../form-control/Button';
import SelectMenu from '../form-control/SelectMenu';
import { DOWNLOAD_RESOURCE_TYPES, DOWNLOAD_FILE_TYPES } from '../../resources/enums';
import { IDownloadData } from '../../resources/interfaces';
import { useParams } from 'react-router-dom';
import { generatePoliciesInPayout } from '../../api/requests';
import { toast } from 'react-toastify';

interface IDownloadPromptProps {
  onProceed: (data?: IDownloadData) => void;
  onClose: () => void;
  resourceType: DOWNLOAD_RESOURCE_TYPES;
}

interface IFormData {
  fileType: string;
}

const DownloadPayoutPolicies = (props: IDownloadPromptProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { payoutId } = useParams();

  const submitHandler = (values: IFormData) => {
    setSubmitting(true);

    if (payoutId)
      generatePoliciesInPayout(payoutId, values.fileType)
        .then((response: any) => {
          props.onProceed();       
          window.open(response.documentUrl, '_blank');
        })
        .catch((error: any) => {
          toast.error(error.description ?? 'Failed to export file. Please try again later.', {
            autoClose: 3000
          });
        })
        .finally(() => setSubmitting(false));
  };

  return (
    <>
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <h2>Confirm Export</h2>
          <XMarkIcon
            onClick={props.onClose}
            width={'11px'}
            height={'11px'}
            className="cursor-pointer"
          />
        </div>
      </div>
      <Formik
        initialValues={{
          fileType: DOWNLOAD_FILE_TYPES.CSV
        }}
        // validateOnBlur
        validationSchema={Yup.object().shape({
          fileType: Yup.string().required('File type required')
        })}
        onSubmit={(values) => {
          submitHandler(values);
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="px-4 py-[30px] bg-white space-y-4" onSubmit={handleSubmit}>
            <SelectMenu
              name="fileType"
              label="File Type"
              listSelectedValue={values.fileType}
              onChange={(value: string) => {
                setFieldValue('fileType', value);
              }}
              list={['CSV']}
              // list={Object.values(DOWNLOAD_FILE_TYPES)}
              error={touched.fileType ? errors.fileType : undefined}
              helperText={touched.fileType ? errors.fileType : undefined}
            />

            <div className="flex justify-end w-full">
              <Button
                label="Submit"
                type="submit"
                className="bg-primary-main px-6 rounded border-none"
                loading={submitting}
                disabled={submitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default DownloadPayoutPolicies;
