import React, { ChangeEvent, Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { CheckIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';
import { ScrollToFormikError } from '../elements/ScrollToFormikError';

export interface IInputSelectMenuItem {
  id?: string;
  name: string;
  value: string | number | null;
}

export interface IInputSelectMenuProps {
  selectInputClassName?: string;
  label?: string;
  listSelectedValue?: number | string | null;
  showAvatar?: boolean;
  search?: boolean;
  onChange: Dispatch<SetStateAction<IInputSelectMenuItem | null>> | any;
  onBlur?: (e: React.FocusEvent<any, Element>) => void | null | any;
  list?: Array<string | number>;
  grouped?: boolean;
  groupedList?: Record<string, Array<IInputSelectMenuItem>>;
  placeholder?: string;
  description?: JSX.Element | React.ReactElement | string | number | any;
  name: string;
  error?: string | undefined;
  disabled?: boolean;
  required?: boolean;
  helperText?: string | undefined;
  sort?: boolean;
  emptyListMessage?: string;
  searchPlaceHolder?: string;
  wrapperClasses?: string;
  editable?: boolean;
}

function InputSelectMenu<T>({
  selectInputClassName,
  label,
  listSelectedValue,
  onChange,
  list = [],
  description,
  name,
  error,
  disabled = false,
  required = false,
  helperText,
  onBlur,
  sort = false,
  searchPlaceHolder = 'Search ...',
  wrapperClasses,
  editable = true
}: IInputSelectMenuProps): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const [searchResult, setSearchResult] = useState<Array<string | number>>([]);
  const fieldRef = useRef<HTMLDivElement>(null);
  
  const searchedList =
    _.isEmpty(searchResult) && searchValue.length < 1 ? list : searchResult;

  const onSearchChange = (evt: ChangeEvent<HTMLInputElement> | undefined) => {
    const val = evt?.target.value ?? '';
    onChange(val);

    setSearchValue(val);
    const srchList: Array<string | number> = [];

    list.forEach((listItem) => {
      if (listItem.toString().toLowerCase().includes(val)) srchList.push(listItem);
    });
    setSearchResult(srchList);
  };

  useEffect(() => {
    if (listSelectedValue) {
      setSearchValue(String(listSelectedValue));
    }
  }, [listSelectedValue])

  return (
    <Listbox
      ref={fieldRef}
      value={listSelectedValue}
      onChange={(value: any) => {
        onChange(value);
        setShowDropdown(false);
        setSearchValue("");
      }}
      disabled={disabled}
      as={'div'}
      className={clsx({ ['animate-wiggle']: error && required }, wrapperClasses)}>
      {({ open }) => (
        <>
          {/* Label */}
          {label && (
            <Listbox.Label
              className={clsx('block text-sm font-medium text-gray-700', {
                ['!text-danger-main']: error && required
              })}>
              {label} <span className="!text-danger-main">{required && '*'}</span>
              {description && (
                <p
                  className={clsx('text-xs text-gray-400 italic', {
                    ['!text-danger-border']: error && required
                  })}>
                  {description}
                </p>
              )}
            </Listbox.Label>
          )}

          <div className="mt-1 relative">
            {/* Input Field */}
            <div
              className={clsx(
                'relative w-full bg-white border border-gray-300 shadow-sm  text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-primary-main focus:border-primary-border sm:text-sm rounded',
                {
                  ['bg-red-200 !fixedborder-danger-main focus:ring-danger-main focus:border-danger-border']:
                    error && required
                },
                selectInputClassName
              )}
              onClick={(e: any) => {
                e.preventDefault();
                // if (!open) {
                setShowDropdown(!showDropdown);
                // }
              }}
            >
              <input
                type="text"
                name="search"
                id="search"
                className="border-none focus:ring-0 outline-0 focus:outline-none focus:border-none block w-full text-md sm:text-sm"
                placeholder={searchPlaceHolder}
                value={listSelectedValue??""}
                onChange={(e) => onSearchChange(e)}
                disabled={disabled }
                autoComplete={"off"}
              />

              {!disabled && (
                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 border-none" onClick={() => {
                  setShowDropdown(!showDropdown);
                }}>
                  {showDropdown ? (
                    <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="false" />
                  )}
                </span>
              )}
            </div>

            <Transition
              show={showDropdown}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-40 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" static>
                {/* List */}
                {searchedList && !_.isEmpty(searchedList) && (
                  <>
                    <div className="px-2 pb-2 pt-1">
                      <>
                        {(sort ? searchedList.sort() : searchedList).map((listItem, key) => (
                          <Listbox.Option
                            key={key}
                            className={({ active, selected }) =>
                              clsx(
                                active ? 'bg-primary-surface' : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-0 text-sm',
                                { ['bg-primary-main']: selected }
                              )
                            }
                            value={listItem}>
                            {({ selected }) => (
                              <>
                                <div className="flex items-center">
                                  <span
                                    className={clsx(
                                      selected ? 'font-semibold' : 'font-normal',
                                      'ml-3 block truncate text-sm'
                                    )}>
                                    {listItem}
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={clsx(
                                      selected ? 'text-white' : 'text-gray-900',
                                      'absolute inset-y-0 right-0 flex items-center pr-1'
                                    )}>
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </>
                    </div>
                  </>
                )}
              </Listbox.Options>
            </Transition>
            {/* Helper Text */}
            <div className={clsx('text-xs text-gray-400', { ['!text-danger-main']: error })}>
              {helperText}
            </div>
          </div>
          <ScrollToFormikError
            fieldName={name ?? ""}
            fieldRef={fieldRef}
          />
        </>
      )}
    </Listbox>
  );
}

export default InputSelectMenu;