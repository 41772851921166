import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { numberWithCommas } from '../../../utils/miscFunctions';
import { useEffect, useState, useRef } from 'react';
import { CURRENCIES } from '../../../resources/enums';

ChartJS.register(ArcElement, Tooltip, Legend);
const bgColors=['']
const data = {
  labels: ['Google', 'Web', 'WhatsApp', 'Telegram', 'Facebook', 'Instagram'],
  datasets: [
    {
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: bgColors,
      borderColor: bgColors,
      borderWidth: 1
    }
  ]
};

const DoughnutChart = ({ displayAsCurrency, metricsData, currency = CURRENCIES.GHS, bgColors, labelPosition = "right" }) => {
  const totalRef = useRef(0);
  const [chartData, setChartData] = useState(data);
  const windowWidth = useRef(window.innerWidth);
  const currencyRef = useRef('');

  useEffect(() => {
    currencyRef.current = currency;
  }, [currency]);

  const plugins = [
    {
      beforeDraw: function (chart) {
        const { ctx, data } = chart;
        const xCoor = chart.getDatasetMeta(0)?.data[0]?.x;
        const yCoor = chart.getDatasetMeta(0)?.data[0]?.y;
        ctx.save();

        let { textFontSize } = getDonutSizeAndCutOut(windowWidth);

        ctx.font = `bolder ${textFontSize}px sans-serif`;
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        const total = displayAsCurrency
          ? `${numberWithCommas(totalRef.current)}`
          : totalRef.current;
        const screenSize = windowWidth.current;
        let totalYCoordinate = yCoor + 15;
        let currencyYCoordinate = yCoor - 10;

        if (screenSize <= 375) {
          currencyYCoordinate = yCoor - 10;
          totalYCoordinate = yCoor + 15;
        } else if (screenSize > 375 && screenSize <= 414) {
          currencyYCoordinate = yCoor - 10;
          totalYCoordinate = yCoor + 20;
        } else if (screenSize > 414 && screenSize <= 425) {
          currencyYCoordinate = yCoor - 10;
          totalYCoordinate = yCoor + 12;
        } else if (screenSize > 425 && screenSize <= 640) {
          currencyYCoordinate = yCoor - 10;
          totalYCoordinate = yCoor + 10;
        } else if (screenSize > 640 && screenSize <= 768) {
          currencyYCoordinate = yCoor - 10;
          totalYCoordinate = yCoor + 10;
        } else if (screenSize > 768 && screenSize <= 1024) {
          currencyYCoordinate = yCoor - 5;
          totalYCoordinate = yCoor + 13;
        } else if (screenSize > 1024 && screenSize <= 1440) {
          currencyYCoordinate = yCoor - 5;
          totalYCoordinate = yCoor + 12;
        } else if (screenSize > 1440 && screenSize <= 2560) {
          currencyYCoordinate = yCoor - 6;
          totalYCoordinate = yCoor + 12;
        } else if (screenSize > 2560) {
          totalYCoordinate = yCoor + 25;
        }
        if (!displayAsCurrency) {
          ctx.fillText(total, xCoor, yCoor);
        } else {
          ctx.fillText(displayAsCurrency ? currencyRef.current : '', xCoor, currencyYCoordinate);

          ctx.fillText(total, xCoor, totalYCoordinate);
        }
      }
    }
  ];
  let { donutCutOut } = getDonutSizeAndCutOut(windowWidth);

  const options = {
    rotation: -0.5 * Math.PI,
    responsive: true,
    aspectRatio: 1.5,
    cutout: donutCutOut,
    plugins: {
      legend: {
        position: labelPosition,
        fullSize: true,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderRadius: 20,
          padding:20
        },
        maxWidth: 400
      },
      title: {
        display: false
      }
    }
  };

  useEffect(() => {
    const metricsDataLabels = Object.keys(metricsData);
    const metricsDataList = Object.values(metricsData);
    totalRef.current =  metricsDataList.reduce((acc, curr) => acc + curr, 0);
    currencyRef.current = currency;

    const metricsChartData = {
      labels: metricsDataLabels,
      datasets: [
        {
          data: metricsDataList,
          backgroundColor: bgColors,
          borderColor: bgColors,
          borderWidth: 1
        }
      ],
      options
    };
    
    setChartData(metricsChartData);
  }, [metricsData, currency]);

  return (
    <div className="h-full flex items-center align-middle w-full">
      <Doughnut data={chartData} options={options} plugins={plugins}/>
    </div>
  );
};

DoughnutChart.propTypes = {
  displayAsCurrency: PropTypes.bool,
  chartKey: PropTypes.string,
  metricsData: PropTypes.object,
  currency: PropTypes.string,
  bgColors: PropTypes.array, 
  labelPosition: PropTypes.string,
};

export default DoughnutChart;

function getDonutSizeAndCutOut(windowWidth) {
  let size = {
    donutCutOut: 0,
    textFontSize: 0
  };

  if (windowWidth.current <= 375) {
    size = {
      donutCutOut: 60,
      textFontSize: 12
    };
  } else if (windowWidth.current > 375 && windowWidth.current <= 414) {
    size = {
      donutCutOut: 75,
      textFontSize: 12
    };
  } else if (windowWidth.current > 414 && windowWidth.current <= 425) {
    size = {
      donutCutOut: 69,
      textFontSize: 12
    };
  } else if (windowWidth.current > 425 && windowWidth.current <= 640) {
    size = {
      donutCutOut: 120,
      textFontSize: 13
    };
  } else if (windowWidth.current > 640 && windowWidth.current <= 768) {
    size = {
      donutCutOut: 40,
      textFontSize: 12
    };
  } else if (windowWidth.current > 768 && windowWidth.current <= 1024) {
    size = {
      donutCutOut: 32,
      textFontSize: 12
    };
  } else if (windowWidth.current > 1024 && windowWidth.current <= 1440) {
    size = {
      donutCutOut: 100,
      textFontSize: 15
    };
  } else if (windowWidth.current > 1440 && windowWidth.current <= 1878) {
    size = {
      donutCutOut: 110,
      textFontSize: 16
    };
  } else if (windowWidth.current > 1878 && windowWidth.current <= 2378) {
    size = {
      donutCutOut: 130,
      textFontSize: 14
    };
  } else if (windowWidth.current > 2378 && windowWidth.current <= 2560) {
    size = {
      donutCutOut: 120,
      textFontSize: 14
    };
  } else {
    size = {
      donutCutOut: 190,
      textFontSize: 32
    };
  }

  return size;
}
