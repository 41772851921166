import { useEffect, useState } from 'react';
import { getTeamsPayouts } from '../../../../api/requests';
import { User } from '../../../../models/User';
import useUserAccount from '../../../../hooks/userAccountHook';
import { Payout } from '../../../../models/payout/Payout';
import PayoutsTable from './PayoutsTable';
import { useParams, useSearchParams } from 'react-router-dom';
import { IPayout } from '../../../../models/payout/IPayout';
import Loader from '../../../../components/Loader';

interface IPayoutsProps {
  onPayoutsUpdated?: (payouts: Payout[]) => void;
}

const Payouts = (props: IPayoutsProps) => {
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { getProfile } = useUserAccount();
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get('page')) || 0);
  const user = new User(getProfile());
  const { id } = useParams();

  useEffect(() => {
    fetchPayouts();
  }, [id, currentPage]);

  useEffect(() => {
    props.onPayoutsUpdated?.(payouts);
  }, [payouts, props.onPayoutsUpdated]);

  const fetchPayouts = async () => {
    try {
      const teamID = user.team?.id ?? id;
      if (teamID) {
        setLoading(true);
        const response = await getTeamsPayouts(teamID);
        if (response) {
          setPayouts(response.map((payout: IPayout) => new Payout(payout)));
        }
      }
    } catch (error: any) {
      console.error('Error fetching payouts:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="overflow-x-auto shadow-md mt-8">
        {loading ? (
          <div className="h-screen flex items-center justify-center w-full">
            <Loader message="Loading payouts. Please wait..." brollyLoader />
          </div>
        ) : (
          <PayoutsTable payouts={payouts} loading={loading} />
        )}
      </div>
    </>
  );
};

export default Payouts;
