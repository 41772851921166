import { Formik } from 'formik';
import * as Yup from 'yup';
import { subDays } from 'date-fns';
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  generatePolicyPaymentObjects,
  regeneratePolicyPaymentObjects
} from '../../../api/requests';
import DatePickerInput from '../../../components/form-control/DatePickerInput';
import { INPUT_DATE_FORMAT } from '../../../resources/constants';
import Button from '../../../components/form-control/Button';
import { removeUnderscoresAndCapitalize } from '../../../utils/miscFunctions';
import { IRequestErrorResponse, IRequestResponse } from '../../../resources/interfaces';

interface RegeneratePaymentObjectsProps {
  onProceed: () => void;
  onClose: () => void;
  policy: any;
  action: string;
}

const RegeneratePaymentObjectsModal = (props: RegeneratePaymentObjectsProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const regeneratePaymentObjects = (paymentDate: string) => {
    if (props.policy?.ref) {
      regeneratePolicyPaymentObjects(props.policy?.ref, paymentDate)
        .then((response: IRequestResponse) => {
          toast.success(response?.message ?? 'Payment object generated successfully.');
          props.onProceed();
        })
        .catch((error: any) => {
          toast.error(
            error.desrcription ?? 'Failed to generate payment objects. Please try again later.'
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const generatePaymentObjects = (paymentDate: string) => {
    if (props.policy?.ref) {
      generatePolicyPaymentObjects(props.policy?.ref, paymentDate)
        .then((response: IRequestResponse) => {
          toast.success(response?.message ?? 'Payment object generated successfully.');
          props.onProceed();
        })
        .catch((error: IRequestErrorResponse) => {
          toast.error(
            error.description ?? 'Failed to generate payment objects. Please try again later.'
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const handleAction = (paymentDate: string) => {
    switch (props.action) {
      case 'GENERATE_PAYMENT_OBJECTS':
        generatePaymentObjects(paymentDate);
        break;

      case 'REGENERATE_PAYMENT_OBJECTS':
        regeneratePaymentObjects(paymentDate);
        break;

      default:
        break;
    }
  };

  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-2">
            <div className="flex items-center justify-center bg-white h-10 w-10 rounded-full">
              <ArrowPathIcon className="h-5 w-5" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">
              {removeUnderscoresAndCapitalize(props.action)}
            </h2>
          </div>
          <XMarkIcon
            onClick={props.onClose}
            width={'18px'}
            height={'18px'}
            className="cursor-pointer"
          />
        </div>
      </div>

      <div className="pt-5">
        <span className="text-center px-4">Select policy start date</span>
      </div>

      <Formik
        initialValues={{
          startsOn: new Date()
        }}
        validationSchema={Yup.object().shape({
          startsOn: Yup.date()
            .typeError('Date type error')
            .required('Policy start date is required')
        })}
        onSubmit={(values) => {
          setSubmitting(true);

          const paymentDate = values.startsOn
            ? moment(values.startsOn).format(INPUT_DATE_FORMAT)
            : '';

          handleAction(paymentDate);
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="px-4 py-5 bg-white space-y-4 w-full" onSubmit={handleSubmit}>
            <DatePickerInput
              id={'startsOn'}
              label="Payment start date"
              selected={values.startsOn}
              onChange={(date: Date | null) => {
                setFieldValue('startsOn', date);
              }}
              classes="w-full"
              showYearDropdown
              allowEarlierSelections={false}
              error={
                touched.startsOn && errors.startsOn !== undefined
                  ? String(errors.startsOn)
                  : undefined
              }
              helperText={
                touched.startsOn && errors.startsOn !== undefined
                  ? String(errors.startsOn)
                  : undefined
              }
              dateFormat={'dd/MM/yyyy'}
            />

            <div className="flex justify-end w-full">
              <Button
                label="Submit"
                type="submit"
                className="bg-primary-main px-6 rounded border-none"
                loading={submitting}
                disabled={submitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RegeneratePaymentObjectsModal;
