import * as Yup from 'yup';
import { Bars3BottomRightIcon } from '@heroicons/react/24/solid';
import SelectDropdown, { ISelectDropdownItem } from '../form-control/SelectDropdown';
import Button from '../form-control/Button';
import { Formik } from 'formik';
import moment from 'moment';
import BrollyDatePicker from '../dashboard/datepicker/datepicker';
import clsx from 'clsx';
import { ITableFilterItem } from '../../resources/interfaces';
import Input from '../form-control/Input';
import { useEffect, useState } from 'react';

export interface ITableFilter {
  searchPlaceholder?: string;
  showSearch?: boolean;
  onSearch?: (searchString: string) => void;
  getValues: (formData: any) => void;
  onSubmit?: () => void;
  filters?: ITableFilterItem[];
  onResetFilters?: () => void;
}

const TableFilterForm = ({
  getValues,
  filters, 
  onResetFilters
}: ITableFilter) => {
  const [dirt, setDirty] = useState<boolean>(false);
  const [tableFilters, setTableFilters] = useState<ITableFilterItem[]>(filters ?? []);

  useEffect(() => {
    setTableFilters(filters ?? []);
  }, [filters])

  const handleFormSubmit = async (values: any) => {
    getValues(tableFilters);
  };

  const handleFieldChange = (value: string, index: number, from?: Date, to?: Date) => {
    setDirty(true);
    const _tableFilters: ITableFilterItem[] = [...tableFilters]

    let thisFilter = { ...tableFilters[index] };

    if (from) {
      thisFilter = { ..._tableFilters[index], value: { ...thisFilter?.value, from: moment(from)?.format("YYYY-MM-DD") } }
    } else if (to) {
      thisFilter = { ..._tableFilters[index], value: thisFilter?.value ? { ...thisFilter?.value, to: moment(to)?.format("YYYY-MM-DD") } : { from: "", to: moment(to)?.format("YYYY-MM-DD") } }
    } else {
      thisFilter = { ..._tableFilters[index], value: value };
    }

    _tableFilters[index] = thisFilter;

    setTableFilters([..._tableFilters]);
  }

  return (
    <div className="w-full bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className=" flex items-center gap-3  p-3">
          <div className="flex items-center justify-center bg-white h-[45px] w-[45px] rounded-full">
            <Bars3BottomRightIcon className="h-6 w-6" />
          </div>
          <h2 className="font-[gilroy-bold] text-lg">Filters</h2>
        </div>
      </div>

      <div className="bg-white">
        <Formik
          initialValues={{
            dropDownOneListSelected: '',
            dropDownTwoListSelected: '',
            dropDownThreeListSelected: ''
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={async (values, { resetForm }) => {
            handleFormSubmit(values);
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            dirty,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="p-5 w-full md:min-w-[350px]">
                <div className="w-full grid grid-cols-1 gap-5 my-5">
                  {tableFilters?.map((filter: ITableFilterItem, index: number) => (
                    <>
                      {filter?.type === "select" && (
                        <SelectDropdown
                          label={filter?.label}
                          list={filter?.list}
                          listSelectedValue={filter?.value}
                          onChange={(value: any) => {
                            handleFieldChange(value, index);
                          }}
                          placeholder={filter?.placeholder ?? 'Select filter'}
                          wrapperClasses="min-w-[150px]"
                          search
                        />
                      )}

                      {filter?.type === "input" && (
                        <Input
                          type="text"
                          label={filter?.label}
                          placeholder={filter?.placeholder}
                          className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border"
                          value={`${filter?.value ?? ''}`}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleFieldChange(e?.target?.value, index);
                          }}
                          onBlur={handleBlur}
                        />
                      )}

                      {filter?.type === "dateRange" && (
                        <div className="">
                          {filter?.label && (
                            <div className="my-1">
                              <span className="block text-sm font-medium text-gray-700">
                                {filter?.label}
                              </span>
                            </div>
                          )}

                          <div className="flex items-center gap-1 w-full">
                            <div className="px-0 w-1/2">
                              <BrollyDatePicker
                                selectedDate={filter?.value?.from ? new Date(filter?.value?.from) : null}
                                onChange={(date: Date | null) => {
                                  if (date) {
                                    handleFieldChange("", index, date);
                                  }
                                }}
                                placeholderText="From"
                              />
                            </div>
                            <div className="px-0 w-1/2">
                              <BrollyDatePicker
                                selectedDate={filter?.value?.to ? new Date(filter?.value?.to) : null}
                                onChange={(date: Date | null) => {
                                  if (date) {
                                    handleFieldChange("", index, undefined, date);
                                  }
                                }}
                                placeholderText="To"

                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>

                <div className="flex items-center justify-between">
                  <Button
                    type="button"
                    label="Reset"
                    onClick={() => {
                      setDirty(false);
                      onResetFilters?.();
                    }}
                    className='border border-primary-main hover:bg-primary-main'
                    curved
                  />

                  <Button
                    type="submit"
                    label="Apply"
                    className={clsx(
                      'p-2  ',
                      (dirty || dirt) && 'border border-primary-main hover:bg-primary-main'
                    )}
                    disabled={!dirty && !dirt}
                    curved
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TableFilterForm;
