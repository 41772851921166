import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import TextArea from '../../../components/form-control/TextArea';
import { generateAgencyPayout } from '../../../api/requests';
import { toast } from 'react-toastify';

export interface ISetCommission {
  rate: string;
  type: string;
  teamId: string;
}

const GenerateAgencyPayoutModal = ({
  onProceed,
  onClose,
  teamID
}: {
  onProceed: () => void;
  onClose: () => void;
  teamID: string;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const submitHandler = (values: any) => {
    setSubmitting(true);
    const reason = values.reason;

    generateAgencyPayout(teamID, reason)
      .then((response: any) => {
        toast.success(response, {
          autoClose: 5000
        });
        onProceed();
      })
      .catch((error: any) => {
        toast.error(error.message);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center ">
            <h2 className="font-bold text-lg ">Generate Agency Payout </h2>
          </div>

          <XMarkIcon onClick={onClose} width={'20px'} height={'20px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            reason: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            reason: Yup.string().required('Commission rate is required.')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="">
                <TextArea
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleChange}
                  label="Reason for generating payout"
                  placeholder="Give reason"
                  required
                  error={touched.reason ? errors.reason : undefined}
                  helperText={touched?.reason ? errors.reason : undefined}
                  classes="rounded"
                />
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  className="w-fit px-6 text-[14px]"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GenerateAgencyPayoutModal;
