import { GENDER, INSURANCE_TYPE } from '../../resources/enums';
import { IDocument, ITask } from '../../resources/interfaces';
import { idTypesDTO } from '../../resources/types';
import { IPolicy } from '../policy/IPolicy';
import { ICustomer, ICustomerUpdate } from './ICustomer';

export class Customer {
  firstName: string;
  lastName: string;
  fullName?: string;
  otherNames?: string;
  id: string;
  idType?: idTypesDTO;
  idNumber?: string;
  idExpiryDate?: string;
  gender?: GENDER;
  email: string;
  country?: string;
  regNumber?: string;
  phone: string;
  otherPhone?: string;
  whatsAppPhone?: string;
  insuranceType?: INSURANCE_TYPE;
  startDate?: string;
  expiryDate?: string;
  dateOfBirth?: string;
  city?: string;
  occupation?: string;
  address?: string;
  socialPlatform?: string;
  state?: string;
  image?: string;
  socialPlatformIdentifier?: string;
  employerName?: string;
  employerAddress?: string;
  employerPhone?: string;
  digitalAddress?: string;
  policyList?: IPolicy[];
  educationLevel?: string;
  emailVerified?: boolean;
  phoneVerified?: boolean;
  otherPhoneVerified?: boolean;
  idVerified?: boolean;
  tasks: ITask[];
  customerDocuments: IDocument[] | undefined;
  customerUpdates: ICustomerUpdate[];

  constructor(Imodel: ICustomer) {
    this.fullName = Imodel.firstName + ' ' + Imodel.lastName;
    this.firstName = Imodel.firstName;
    this.lastName = Imodel.lastName;
    this.gender = Imodel.gender;
    this.id = Imodel.id;
    this.idType = Imodel.idType;
    this.idNumber = Imodel.idNumber;
    this.idExpiryDate = Imodel.idExpiry;
    this.gender = Imodel.gender;
    this.email = Imodel.email;
    this.country = Imodel.country;
    this.phone = Imodel.phone;
    this.otherPhone = Imodel.otherPhone;
    this.whatsAppPhone = Imodel.whatsAppPhone;
    this.regNumber = Imodel.regNumber;
    this.insuranceType = Imodel.insuranceType;
    this.startDate = Imodel.startDate;
    this.expiryDate = Imodel.expiryDate;
    this.socialPlatform = Imodel.socialPlatform;
    this.state = Imodel.state;
    this.image = Imodel.image;
    this.socialPlatformIdentifier = Imodel.socialPlatformIdentifier;
    this.employerName = Imodel.employerName;
    this.employerAddress = Imodel.employerAddress;
    this.employerPhone = Imodel.employerPhone;
    this.digitalAddress = Imodel.digitalAddress;
    this.occupation = Imodel.occupation;
    this.policyList = Imodel.policyList;
    this.educationLevel = Imodel.educationLevel;
    this.phoneVerified = Imodel.phoneVerified;
    this.emailVerified = Imodel.emailVerified;
    this.idVerified = Imodel.idVerified;
    this.otherPhoneVerified = Imodel.otherPhoneVerified;
    this.tasks = Imodel.tasks;
    this.customerUpdates = Imodel.customerUpdates ?? [];
  }
}
