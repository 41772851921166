import { XMarkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { BsSend } from 'react-icons/bs';
import * as Yup from 'yup';
import Button from '../../../components/form-control/Button';
import SelectDropdown from '../../../components/form-control/SelectDropdown';
import { Policy } from '../../../models/policy/Policy';
import Input from '../../../components/form-control/Input';
import { Formik } from 'formik';
import {
  getFormattedPhoneNumber,
  getPhoneNumberFromShortCodeFormat,
  removeUnderscoresAndDashes
} from '../../../utils/miscFunctions';
import { sharePolicyAgreementDocumentLink } from '../../../api/requests';
import { toast } from 'react-toastify';
import { POLICY_DOCUMENT_TYPES } from '../../../resources/enums';
import useResource from '../../../hooks/resourcesHook';

type SendAgreementFormModalProps = {
  policy: Policy;
  onClose: () => void;
  onProceed?: (url: string) => void;
};

const documentList = [
  POLICY_DOCUMENT_TYPES.RENEWAL_NOTICE,
  POLICY_DOCUMENT_TYPES.LOAN_AGREEMENT,
  POLICY_DOCUMENT_TYPES.PAYMENT_LOAN_AGREEMENT,
  POLICY_DOCUMENT_TYPES.INSURANCE_CERTIFICATE,
  POLICY_DOCUMENT_TYPES.PAYMENT_AGREEMENT,
  POLICY_DOCUMENT_TYPES.CONTRACT_SUMMARY
].map((doc: POLICY_DOCUMENT_TYPES) => {
  return {
    name: removeUnderscoresAndDashes(doc, true),
    value: doc
  };
});

const SendAgreementFormModal = ({ policy, onClose, onProceed }: SendAgreementFormModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { shareContennt } = useResource();

  return (
    <div className="w-full">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-5">
            <div className="flex items-center justify-center bg-white h-[2.5rem] w-[2.5rem] rounded-full gap-3">
              <BsSend className="h-5 w-5" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Share Document</h2>
          </div>
          <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>

      <div className="p-5">
        <Formik
          initialValues={{
            type: '',
            channel: '',
            email: policy?.applicant?.email,
            phone: getPhoneNumberFromShortCodeFormat(policy?.applicant?.phoneNumber ?? '')
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            channel: Yup.string().trim().required('Channel is required.')
          })}
          onSubmit={async (values) => {
            setLoading(true);
            const phone = getFormattedPhoneNumber(values.phone);
            const identifier =
              values.channel === 'sms' || values.channel === 'whatsapp' ? phone : values.email;
            const type = values.type;

            try {
              if (identifier) {
                const response = await sharePolicyAgreementDocumentLink(
                  policy?.ref,
                  type,
                  values.channel,
                  identifier
                );
                if (response) {
                  console.log('Response:', response);
                  toast.success(response?.message ?? 'Document shared successfully.');
                  shareContennt(response?.data);
                  onProceed?.(response?.data);
                }
              }
            } catch (error: any) {
              toast.error(error?.description ?? 'Failed to share policy document.');
            } finally {
              setLoading(false);
            }
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="" onSubmit={handleSubmit}>
              <div className="">
                <SelectDropdown
                  label="Document name"
                  name="type"
                  listSelectedValue={values.type}
                  list={documentList}
                  onChange={(val: string | null) => setFieldValue('type', val)}
                  onBlur={handleBlur}
                  placeholder="Select document"
                  error={touched.type ? errors.type : undefined}
                  helperText={touched.type ? errors.type : undefined}
                  required
                  wrapperClasses="w-full"
                />
              </div>

              <div className="mt-5">
                <SelectDropdown
                  label="Channel"
                  name="channel"
                  listSelectedValue={values.channel}
                  list={[
                    { name: 'Email', value: 'email' },
                    { name: 'SMS', value: 'sms' },
                    { name: 'WhatsApp', value: 'whatsapp' }
                  ]}
                  onChange={(val: string | null) => setFieldValue('channel', val)}
                  onBlur={handleBlur}
                  placeholder="Select channel of transfer"
                  error={touched.channel ? errors.channel : undefined}
                  helperText={touched.channel ? errors.channel : undefined}
                  required
                  wrapperClasses="w-full"
                />
              </div>

              <div className="mt-5">
                {values.channel == 'email' && (
                  <Input
                    name="email"
                    label="Email"
                    value={values.email}
                    type="text"
                    wrapperClasses=""
                    required
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={errors.email}
                    helperText={errors.email}
                  />
                )}
                {(values.channel == 'sms' || values.channel == 'whatsapp') && (
                  <Input
                    name="phone"
                    label="Phone"
                    value={values.phone}
                    type="tel"
                    wrapperClasses=""
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={errors.phone}
                    helperText={errors.phone}
                    required
                  />
                )}
              </div>

              <div className="flex justify-end mt-5">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  curved
                  className="w-fit px-6 text-[14px]"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SendAgreementFormModal;
