import React, { useState } from 'react';
// import { MOBILE_MONEY_NETWORKS, PAYMENT_OPTIONS } from "../../../../resources/enums";
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { Policy } from '../../../models/policy/Policy';
import {
  getFormattedPhoneNumber,
  getListFromEnum,
  getPhoneNumberFromShortCodeFormat
} from '../../../utils/miscFunctions';
import useUserAccount from '../../../hooks/userAccountHook';
import Button from '../../../components/form-control/Button';
import SelectMenu, { ISelectMenuItemValue } from '../../../components/form-control/SelectMenu';
import Input from '../../../components/form-control/Input';
import { MOBILE_MONEY_NETWORKS } from '../../../resources/enums';
import { HomePolicy } from '../../../models/homePolicy/HomePolicy';

interface IProvideMomoDetailsProps {
  onProceed: (data: { phone: string; network: MOBILE_MONEY_NETWORKS }) => void;
  policy: Policy | HomePolicy | undefined;
}

const NETWORKS = getListFromEnum(MOBILE_MONEY_NETWORKS);

const ProvideMomoDetails = (props: IProvideMomoDetailsProps): JSX.Element => {
  const { getProfile } = useUserAccount();

  return (
    <>
      {/* <div className="pb-12">
        <div className="flex justify-center py-4">
          <img src="/img/logo.svg" />
        </div> */}
      <div className="">
        <div>
          <Formik
            initialValues={{
              phone: getPhoneNumberFromShortCodeFormat(
                props.policy?.applicant?.phoneNumber ?? getProfile().phone ?? ''
              ),
              network: ''
            }}
            validateOnBlur
            validationSchema={Yup.object().shape({
              phone: Yup.string().trim().required('Mobile money wallet number is required.'),
              network: Yup.string().required('Mobile money network is required.')
            })}
            onSubmit={(values) => {
              let _network: MOBILE_MONEY_NETWORKS = MOBILE_MONEY_NETWORKS.MTN;

              switch (values.network?.toLowerCase()) {
                case 'mtn':
                  _network = MOBILE_MONEY_NETWORKS.MTN;
                  break;

                case 'vodafone':
                  _network = MOBILE_MONEY_NETWORKS.VODAFONE;
                  break;
              }

              props.onProceed({
                phone: getFormattedPhoneNumber(values.phone),
                network: _network
              });
            }}>
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleSubmit,
              handleChange,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mt-5 flex flex-col space-y-4">
                  <Input
                    type="tel"
                    id="phone"
                    label="Your mobile money wallet number"
                    placeholder="Eg. 0245000000"
                    className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border px-3"
                    value={values.phone ?? ''}
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={touched?.phone ? errors.phone : undefined}
                    helperText={touched?.phone ? errors.phone : undefined}
                    // countryCode
                  />

                  <SelectMenu
                    label="Mobile Money network"
                    name="network"
                    listSelectedValue={values.network}
                    onChange={(val: ISelectMenuItemValue | null) => {
                      setFieldValue('network', val);
                    }}
                    searchPlaceHolder="Search by network ..."
                    list={NETWORKS}
                    placeholder="Select your mobile money network."
                    error={touched.network ? errors.network : undefined}
                    helperText={touched?.network ? errors.network : undefined}
                  />
                </div>

                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                  label="Proceed"
                  className="w-1/2 mt-6 ml-auto whitespace-nowrap text-base font-medium text-black bg-primary-main py-2 px-4 border-0 shadow-sm flex justify-center items-center cursor-pointer"
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ProvideMomoDetails;
