import React from 'react';
import Button from './form-control/Button';
import { FaAngleLeft } from 'react-icons/fa';

type IBackButton = {
  onClick?: () => void;
};

const BackButton = ({ onClick }: IBackButton) => {
  return (
    <>
      <Button
        label="Back"
        className="rounded !py-1 !px-2 border border-primary-main hover:bg-primary-main"
        icon={<FaAngleLeft className="h-4 w-4  mr-1" />}
        // bgColor='primary'
        curved
        onClick={onClick}
      />
    </>
  );
};

export default BackButton;
