import React from 'react';
import clsx from 'clsx';
import {
  TAG_NEW_COLOR,
  TAG_CONTACTED_COLOR,
  TAG_QUALIFIED_COLOR,
  TAG_UNRESPONSIVE_COLOR,
  TAG_DISQUALIFIED_COLOR,
  TAG_IN_NEGOTIATION_COLOR,
  TAG_NURTURING_COLOR,
  TAG_CONVERTED_COLOR,
  TAG_NA_COLOR
} from '../../../resources/constants';
import {
  LEAD_TAGS
} from '../../../resources/enums';
import { capitalizeSentence } from '../../../utils/miscFunctions';

interface ILeadTagStatusProps {
  status: string | null | boolean;
  classes?: string;
}

const LeadTagStatus: React.FC<ILeadTagStatusProps> = ({
  status,
  classes
}) => {
  const statusClass = clsx(
    'w-[120px] h-[30px] rounded flex items-center justify-center text-sm',
    {
      [TAG_NEW_COLOR]:
        status === LEAD_TAGS.NEW || status === capitalizeSentence(LEAD_TAGS.NEW),
      [TAG_CONTACTED_COLOR]:
        status === LEAD_TAGS.CONTACTED || status === capitalizeSentence(LEAD_TAGS.CONTACTED),
      [TAG_QUALIFIED_COLOR]:
        status === LEAD_TAGS.QUALIFIED || status === capitalizeSentence(LEAD_TAGS.QUALIFIED),
      [TAG_UNRESPONSIVE_COLOR]:
        status === LEAD_TAGS.UNRESPONSIVE || status === capitalizeSentence(LEAD_TAGS.UNRESPONSIVE),
      [TAG_DISQUALIFIED_COLOR]:
        status === LEAD_TAGS.DISQUALIFIED || status === capitalizeSentence(LEAD_TAGS.DISQUALIFIED),
      [TAG_IN_NEGOTIATION_COLOR]:
        status === LEAD_TAGS.IN_NEGOTIATION || status === capitalizeSentence(LEAD_TAGS.IN_NEGOTIATION),
      [TAG_NURTURING_COLOR]:
        status === LEAD_TAGS.NURTURING || status === capitalizeSentence(LEAD_TAGS.NURTURING),
      [TAG_CONVERTED_COLOR]:
        status === LEAD_TAGS.CONVERTED || status === capitalizeSentence(LEAD_TAGS.CONVERTED),
      [TAG_NA_COLOR]:
        status === 'N/A',

    },
    classes
  );

  const displayStatus = status !== null ? status : 'N/A';

  return (
    <div className="w-full flex">
      <div className={statusClass}>{displayStatus}</div>
    </div>
  );
};

export default LeadTagStatus;
