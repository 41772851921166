import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { ToastType } from '../../../resources/enums';
import { blobToFile, getImageExtension, sentenceCase } from '../../../utils/miscFunctions';
import ImageCropper from '../imageCropper';
import { ReactComponent as ImageIcon } from './upload.svg';
import { ScrollToFormikError } from '../ScrollToFormikError';

const FileUpload: FC<{
  title?: string;
  allowSelect?: boolean;
  multiple?: boolean;
  onFileLoad: (images: File[] | ArrayBuffer | null) => void;
  defaultImage?: any;
  type?: 'image' | 'document' | 'audio' | 'video';
  label?: string;
  imageAlt?: string;
  id?: string;
  required?: boolean;
  error?: string;
  description?: string;
  cropImage?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  imageContainerWidth?: string; //Set this in css unit
  imageContainerHeight?: string; //Set this in css unit
  currentImage?: string;
  onFileDeleted?: () => void;
  showFileMax?: boolean;
  onFileSrcChange?: (fileUrl: string) => void;
  profilePhoto?: boolean;
  savedImage?: any;
}> = ({
  allowSelect = true,
  multiple = false,
  onFileLoad,
  defaultImage,
  type,
  title,
  label,
  imageAlt,
  id,
  required = false,
  error,
  description,
  cropImage = true,
  maxWidth = 600,
  maxHeight = 200,
  imageContainerHeight = '177px',
  imageContainerWidth = '100%',
  showFileMax = true,
  onFileSrcChange,
  profilePhoto,
  savedImage
}) => {
  const [currentImage, setCurrentImage] = useState<any>(savedImage);
  const [currentImageFile, setCurrentImageFile] = useState<File | null>();
  const [imagePreviews, setImagePreviews] = useState<{ file: any; name: string; size: string }[]>(
    []
  );
  const [fileType, setFileType] = useState<string>('image/x-png,image/jpeg');
  const [hovered, setHovered] = useState<boolean>(false);
  const [isCropperOpen, setIsCropperOpen] = useState<boolean>(false);

  const fieldRef = useRef<HTMLDivElement>(null);

  maxWidth = profilePhoto ? 300 : maxWidth;
  maxHeight = profilePhoto ? 300 : maxHeight;
  imageContainerHeight = profilePhoto ? '170px' : imageContainerHeight;
  imageContainerWidth = profilePhoto ? '170px' : imageContainerWidth;
  let imageInput: HTMLInputElement | null = null;
  const fileSize = 10485760;

  useEffect(() => {
    if (defaultImage) {
      setImagePreviews([
        {
          file: null,
          name: defaultImage['docURL'],
          size: ''
        }
      ]);
    }
  }, [defaultImage]);

  useEffect(() => {
    onFileSrcChange?.(currentImage);
  }, [currentImage]);

  function onImageUpload(e: any) {
    const files: any = Array.from(e.target.files);
    if (files.length === 0) {
      return;
    }

    if (files[0].size <= fileSize) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setCurrentImage(reader.result);
        setCurrentImageFile(files[0]);

        onFileLoad(files);

        if (cropImage) {
          openImageCropper(reader.result);
        }

        let temp_previews: any = [];

        let file_size =
          files[0].size > 1024
            ? files[0].size > 1048576
              ? `${Math.round(files[0].size / 1048576)}mb`
              : `${Math.round(files[0].size / 1024)}kb`
            : `${files[0].size}b`;

        if (multiple) {
          temp_previews = [...imagePreviews];
          temp_previews.push({
            file: reader.result,
            name: files[0].name,
            size: file_size
          });
          setImagePreviews(temp_previews.reverse());
        } else {
          temp_previews.push({
            file: reader.result,
            name: files[0].name,
            size: file_size
          });
          setImagePreviews(temp_previews.reverse());
        }
        // onFileLoad(temp_previews);
      };

      reader.readAsDataURL(files[0]);
    } else {
      toast.error('File size must not exceed 10mb');
    }
  }

  useEffect(() => {
    if (type) {
      switch (type) {
        case 'image':
          setFileType('image/x-png,image/jpeg');
          break;
        case 'document':
          setFileType('pdf');
          break;
      }
    }
  }, [type]);

  const handleImageCrop = (crop: any) => {
    setCurrentImageFile(crop.file);
    setCurrentImage(crop.url);
    setIsCropperOpen(false);
    onFileLoad([blobToFile(crop.file, currentImageFile)]);
  };

  function openImageCropper(url: any) {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      if (img.height < maxHeight || img.width < maxWidth) {
        setCurrentImage(null);
        setCurrentImageFile(null);
        toast(`Minimum required size for image is ${maxWidth}x${maxHeight}.`, {
          type: ToastType.ERROR,
          autoClose: 5000
        });
      } else {
        setIsCropperOpen(true);
      }
    };
  }

  return (
    <div className={clsx('', profilePhoto ? 'w-[170px]' : ' w-full')} ref={fieldRef}>
      {/* File upload section */}
      <article aria-label="File Upload" className="relative h-full flex flex-col">
        {/* Label */}
        {label && (
          <label
            htmlFor={id}
            className={clsx('block text-sm font-medium text-gray-700', {
              ['text-danger-main']: error && required
            })}>
            {label} <span className="text-danger-main">{required && '*'}</span>
            {/* Description */}
            {description && (
              <div
                className={clsx('text-xs text-gray-400 italic', {
                  ['text-danger-border']: error && required
                })}>
                {description}
              </div>
            )}
          </label>
        )}

        {/* Scroll area */}
        <section
          className={clsx(
            'space-y-8 ',
            profilePhoto ? 'rounded-full ' : 'overflow-auto w-full flex flex-col '
          )}>
          <div
            className="h-72 md:h-44 w-full flex flex-col items-center justify-center space-y-4 md:space-y-2 "
            style={{ width: imageContainerWidth, height: imageContainerHeight }}>
            {/* Uploaded image */}
            {currentImage ? (
              <div
                className={clsx(
                  'border-[1px] border-gray-300 overflow-hidden ',
                  profilePhoto && 'rounded-full'
                )}
                onMouseEnter={() => {
                  setHovered(true);
                }}
                onMouseLeave={() => {
                  setHovered(false);
                }}>
                {/* Trash icon */}
                {hovered && (
                  <div
                    className={clsx(
                      'absolute bottom-1 right-2 z-50 bg-primary-main rounded-full p-1 outline-none size-8 cursor-pointer',
                      profilePhoto ? 'bottom-3 right-5' : '',
                      type == 'document' ? 'right-0' : ''
                    )}
                    onClick={() => setCurrentImage(null)}>
                    <TrashIcon className="w-full text-gray-500 " />
                  </div>
                )}
                {/* Uploaded image */}
                <img
                  style={{ objectFit: 'cover', backgroundPosition: 'center' }}
                  src={currentImage}
                  className={clsx('', profilePhoto ? ' h-[170px] w-[170px]' : 'w-full')}
                  alt={imageAlt}
                />
              </div>
            ) : (
              // Placeholder for image upload
              <>
                {/* File input */}
                <input
                  type="file"
                  id="imageFile"
                  style={{ display: 'none' }}
                  accept={fileType}
                  ref={(input) => {
                    imageInput = input;
                  }}
                  onChange={onImageUpload}
                  className="hidden"
                />
                {/* Image upload container */}
                {allowSelect && (
                  <>
                    <div
                      className={clsx(
                        'w-full h-full  border-2  flex flex-col justify-center items-center cursor-pointer space-y-4 md:space-y-2',
                        profilePhoto
                          ? 'border-black rounded-full'
                          : 'border-[1px] border-dashed border-[#8CEB8C] bg-[#8CEB8C]/[0.1]'
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        imageInput && (imageInput.value = ''); //reset so same image can be picked - no valid justification for this, but it seems useful

                        imageInput?.click();
                      }}>
                      {/* Image icon */}
                      <span className="block rounded-[50%] p-2 bg-[#8CEB8C]">
                        <ImageIcon className="w-[30px] h-[30px]" />
                      </span>
                      {/* Image upload text */}
                      <p className="text-dark text-sm text-center">{title}</p>
                      {/* File max size information */}
                      {showFileMax && (
                        <p className="text-[#8D5241] text-xs md:text-sm text-center">
                          Max 2MB, {sentenceCase(fileType)}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </section>
      </article>
      {/* Image cropper component */}
      <ImageCropper
        aspect={maxWidth / maxHeight}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        horizontal-cover
        sourceImage={currentImage}
        isOpen={isCropperOpen}
        onCropCancel={() => {
          setCurrentImageFile(null);
          setCurrentImage(null);
          setIsCropperOpen(false);
        }}
        onCropFinished={handleImageCrop}
        imageFormat={getImageExtension(currentImageFile)}
      />
      {/* Scroll to Formik error component */}
      <ScrollToFormikError fieldName={id ?? ''} fieldRef={fieldRef} />
    </div>
  );
};

export default FileUpload;
