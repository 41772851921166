import React from 'react';
import TabNavItem, { ITabNavItem } from './TabNavItem';

export interface ITab {
    id: any;
    title: string;
    classes?: string;
    icon?: JSX.Element;
    itemCount?: number;
}

interface TabNavProps {
    tabs: ITab[];
    activeTab: string;
    setActiveTab: (id: string) => void;
    onTabClicked?: (id: string) => void;
    classes?: string;
}

const TabNav: React.FC<TabNavProps> = ({ tabs, activeTab, setActiveTab, onTabClicked, classes }) => {
    return (
        <ul className="flex items-center gap-5">
            {tabs.map((tab: ITab) => (
                <TabNavItem
                    key={tab.id}
                    id={tab.id}
                    title={tab.title}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    onTabClicked={onTabClicked}
                    classes={classes}
                    icon={tab.icon}
                    count={tab.itemCount}
                />
            ))}
        </ul>
    );
};

export default TabNav;
