import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../models/User";
import { AGENTS_BASE, DASHBOARD_BASE } from "../resources/links";
import useUserAccount from "./userAccountHook";

function useRoute() {
    const { getProfile } = useUserAccount();
    const navigate = useNavigate();

    const user = new User(getProfile());

    const getDashboardBase = () => {
        if (!user) {
            navigate("/sign-in", { replace: true });
        }
        // return (user.isAgent() || user.isAgencyAdmin()) ? AGENTS_BASE : DASHBOARD_BASE;
        return DASHBOARD_BASE;
    }

    function unlockBackground() {
        document.body.classList.remove("stop-scrolling");
    }

    function lockBackground() {
        document.body.classList.add("stop-scrolling");
    }

    return { getDashboardBase, lockBackground, unlockBackground };
}

export default useRoute;

import { useState, useEffect } from 'react';

export function useNavigationStack() {

    const navigate = useNavigate();
    const location = useLocation();

    const [pathStack, setPathStack] = useState<string[]>([location.pathname]);

    useEffect(() => {
        setPathStack([...pathStack, location.pathname]);
    }, [location.pathname]);


    function navigateToPrevious() {
        if (pathStack.length > 0) {
            const previousPath = getPreviousPath();

            setPathStack(prevStack => [...prevStack]);

            if (previousPath) {
                navigate(previousPath);
            }
        }
    }

    function getPreviousPath(): string {
        return pathStack[pathStack?.length - 2];
    }

    return { navigate, navigateToPrevious, getPreviousPath, pathStack };
}