import { COMMISSIONS_WITHHOLDING_TAX_PERCENTAGE } from '../../resources/constants';
import { Team } from '../team/Team';
import { ICommission } from './ICommission';

export class Commission {
  id: string;
  commissionAmount: number;
  createdOn: Date | undefined;
  updatedOn: Date | undefined;
  currency: string;
  rate: number;
  dateOfPayment: Date | undefined;
  isPaid: boolean;
  paidAmount: number;
  protectionType: string;
  policyNumber: number;
  policyPaymentRef: string;
  reference: string;
  team: Team | undefined;
  withholdingTax: number;
  netCommission: number;

  constructor(Imodel: ICommission) {
    this.id = Imodel.id;
    this.createdOn = Imodel.createdOn ? new Date(Imodel.createdOn) : new Date();
    this.updatedOn = Imodel.updatedOn ? new Date(Imodel.updatedOn) : undefined;
    this.dateOfPayment = Imodel.dateOfPayment ? new Date(Imodel.dateOfPayment) : undefined;
    this.commissionAmount = Imodel.commissionAmount;
    this.currency = Imodel.currency;
    this.rate = Imodel.rate;
    this.isPaid = Imodel.isPaid;
    this.paidAmount = Imodel.paidAmount;
    this.protectionType = Imodel.protectionType;
    this.policyNumber = Imodel.policyNumber;
    this.policyPaymentRef = Imodel.policyPaymentRef;
    this.reference = Imodel.reference;
    this.team = Imodel.team ? new Team(Imodel.team) : undefined;
    this.withholdingTax = Number(
      Imodel.commissionAmount * (COMMISSIONS_WITHHOLDING_TAX_PERCENTAGE / 100)
    );
    this.netCommission = Number(this.commissionAmount) - Number(this.withholdingTax);
  }
}
