import { PlusIcon } from '@heroicons/react/20/solid';
import Page from '../../../components/PageWrapper';
import { useEffect, useMemo, useState } from 'react';
import { getAllVehicles } from '../../../api/requests';
import Loader from '../../../components/Loader';
import GTable from '../../../components/elements/GTable';
import Button from '../../../components/form-control/Button';
import AddVehicle from './AddVehicle';
import { Vehicle } from '../../../models/vehicle/Vehicle';
import { ITablePaginationData } from '../../../components/elements/GTable/GTable';
import useModal from '../../../hooks/modalHook';

const Vehicles = (): JSX.Element => {
  const [vehicles, setVehicles] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [vehiclesPaginationData, setVehiclesPaginationData] = useState<ITablePaginationData>();
  const [showPerPage, setShowPerPage] = useState<number>(100);
  const { ModalComponent, updateModalStates, closeModal } = useModal();

  // const tableActions = [
  //   {
  //     label: 'Add',
  //     callback: () => {
  //       updateModalStates({
  //         show: true,
  //         size: '2xl',
  //         children: (
  //           <AddVehicle
  //             onProceed={() => {
  //               closeModal();
  //             }}
  //             onClose={closeModal}
  //           />
  //         )
  //       });
  //     }
  //   },
  //   {
  //     label: 'Edit',
  //     callback: () => {
  //       updateModalStates({
  //         show: true,
  //         size: '2xl',
  //         children: (
  //           <EditVehicle
  //             onProceed={() => {
  //               closeModal();
  //             }}
  //             onClose={closeModal}
  //           />
  //         )
  //       });
  //     }
  //   }
  // ];

  const addVehicle = () => {
    updateModalStates({
      show: true,
      size: '2xl',
      children: (
        <AddVehicle
          onProceed={() => {
            closeModal();
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  useEffect(() => {
    setLoading(true);
    getVehicles();
  }, []);

  useEffect(() => {
    getVehicles(0);
  }, [showPerPage]);

  const getVehicles = (page = 0) => {
    getAllVehicles(page, showPerPage)
      .then((response: any) => {
        setLoading(true);
        // const _vehicles = response.map((_vehicle: any) => {
        //   return new Vehicle(_vehicle);
        // });

        setVehicles(response?.data?.data);
        const from = response.pageable.offset + 1;
        const to =
          from + response.size > response.totalElements
            ? response.totalElements
            : from + response.size - 1;

        setVehiclesPaginationData({
          per_page: response.size,
          total: response.totalElements,
          totalPages: response.totalPages,
          from: from,
          to: to
        });
      })

      .catch((error: Error) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useMemo(
    () => [
      // {
      //   id: 'selection',
      //   Header: ({ getToggleAllRowsSelectedProps }: any) => (
      //     <div>
      //       <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
      //     </div>
      //   ),
      //   Cell: ({ row }: any) => (
      //     <div>
      //       <input type="checkbox" {...row.getToggleRowSelectedProps()} />
      //     </div>
      //   )
      // },
      {
        Header: 'M',
        columns: [
          {
            Header: 'Vehicle Make ',
            accessor: 'maker',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },

          {
            Header: 'Model',
            accessor: 'model',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Cubic Capacity',
            accessor: 'cc',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Max Seat',
            accessor: 'maxSeat',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Min. Seat',
            accessor: 'miniSeat',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Latest model year',
            accessor: 'yearIssued',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          }
        ]
      }
    ],
    []
  );

  const handlePageChange = (page: number) => {
    getVehicles(page);
  };

  return (
    <Page title="Vehicles">
      <div className="bg-white p-5 min-h-full">
        <div className="flex items-center justify-between  ">
          <div className="">
            <h2 className="font-semibold ">Vehicles</h2>
            <span className='text-sm'>View a list of all vehicles and associated details </span>
          </div>

          <div className=" flex justify-end items-center">
            <div className=" flex gap-4">
              <div className="">
                <Button
                  icon={<PlusIcon width="20px" height="20px" className="mr-2" />}
                  className="px-[18px] py-[12px] h-[45px] rounded"
                  bgColor="primary"
                  label="Add Vehicle"
                  onClick={addVehicle}
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full">
          {loading ? (
            <div className="h-screen flex items-center justify-center">
              <Loader message="Fetching vehicles. Please wait..." brollyLoader />
            </div>
          ) : (
            <>
              {vehicles.length > 0 ? (
                <>
                  <div className="overflow-x-auto  mt-4">
                    <GTable
                      selectionChanged={(selection: any) => {
                        // setSlcMember(selection);
                      }}
                      columns={columns}
                      // TO DO: Remove this when live search is implemented and made available on the api.
                      data={
                        vehicles &&
                        vehicles?.filter((vehicles: Vehicle) =>
                          vehicles?.model?.toLowerCase().includes(searchString)
                        )
                      }
                      onSearch={(searchString: string) => setSearchString(searchString)}
                      searchPlaceholder={'Search by vehicle make...'}
                      tableType={'vehicles'}
                      // paginationData={vehiclesPaginationData}
                      onPageChange={handlePageChange}
                      showPerPage={showPerPage}
                      onShowPerPageChange={(page: number) => setShowPerPage(page)}
                    // TO DO: Uncomment this when the implementation for filtering tables by policy status is done.
                    // topActions={getTableTopActions()}
                    />
                  </div>
                </>
              ) : (
                <div>No vehicle found.</div>
              )}
            </>
          )}
        </div>
      </div>
      <ModalComponent />
    </Page>
  );
};

export default Vehicles;
