import { Commission } from '../commission/Commission';
import { ICommission } from '../commission/ICommission';
// import { ISales } from '../sales/ISales';
// import { Sales } from '../sales/Sales';
import { Staff } from '../staff/Staff';
import { Team } from '../team/Team';
import { IPayout } from './IPayout';

export class Payout {
  id: string;
  createdOn: Date | undefined;
  updatedOn: Date | undefined;
  currency: string;
  amountPaid: string;
  dateOfPayment: Date | undefined;
  isPaid: boolean;
  reference: string;
  team: Team | undefined;
  totalAmountPayable: number;
  totalCommissionAmount: number;
  commissions: Commission[];
  // sales: Sales[];
  isReviewed: boolean;
  isApproved: boolean;
  firstApprovalBy: Staff | null;
  firstApprovalOn: string;
  secondApprovalBy: Staff | null;
  secondApprovalOn: string;

  constructor(Imodel: IPayout) {
    this.id = Imodel.id;
    this.createdOn = Imodel.createdOn ? new Date(Imodel.createdOn) : new Date();
    this.updatedOn = Imodel.updatedOn ? new Date(Imodel.updatedOn) : undefined;
    this.dateOfPayment = Imodel.dateOfPayment ? new Date(Imodel.dateOfPayment) : undefined;
    this.totalCommissionAmount = Imodel.totalCommissionAmount;
    this.currency = Imodel.currency;
    this.isPaid = Imodel.isPaid;
    this.totalAmountPayable = Imodel.totalAmountPayable;
    this.reference = Imodel.reference;
    this.amountPaid = Imodel.amountPaid;
    this.team = Imodel.team ? new Team(Imodel.team) : undefined;
    this.commissions = Imodel.commissions
      ? Imodel.commissions?.map((commission: ICommission) => new Commission(commission))
      : [];
    // this.sales = Imodel.sales ? Imodel.sales?.map((sale: ISales) => new Sales(sale)) : [];
    this.isReviewed = Imodel.isReviewed;
    this.firstApprovalBy = Imodel.firstApprovalBy ?? null;
    this.firstApprovalOn = Imodel.firstApprovalOn;
    this.secondApprovalBy = Imodel.secondApprovalBy;
    this.secondApprovalOn = Imodel.secondApprovalOn;
    this.isApproved = Imodel.isApproved;
  }
}
