import { useEffect, useState } from 'react';
import useUserAccount from '../../../../hooks/userAccountHook';
import { User } from '../../../../models/User';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/form-control/Input';
import Button from '../../../../components/form-control/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { getARoleByID, getAllPermissions } from '../../../../api/requests';
import { toast } from 'react-toastify';
import moment from 'moment';
import BackButton from '../../../../components/BackButton';
import { ScopeComponent } from './CreateCustomRole';
const ScopeHeaddings = [
  'Dashboard',
  'Leads',
  'Quotes',
  'Policy',
  'Community',
  'Payment',
  'Insurer',
  'Dicount',
  'Vehicle',
  'Team',
  'Claim'
];
const RoleDetails = () => {
  const { getProfile } = useUserAccount();
  const [role, setRole] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const user = new User(getProfile());
  const params = useParams();
  const roleId = params.id;
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    setLoading(true);
    if (roleId) fetchRole(roleId);
    fetchPermissions();
  }, []);

  const fetchRole = (roleId: string) => {
    getARoleByID(roleId)
      .then((response: any) => {
        setRole(response);
      })
      .catch((error: any) => {
        toast.error(error.message);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const fetchPermissions = () => {
    getAllPermissions()
      .then((response: any) => {
        setPermissions(response);
      })
      .catch((error: any) => {
        toast.error(error.message);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const ScopesList = () => {
    const elements: JSX.Element[] = [];
    const _permissions = Object.entries(permissions);
    const transformedArray = _permissions.map(([key, value]) => ({
      key: key,
      value: value
    }));
    const _transformedArray = transformedArray.sort((a, b) => a.key.localeCompare(b.key));

    _transformedArray.forEach((permission, index) => {
      elements.push(
        <div key={index}>
          <ScopeComponent
            label={permission.value}
            scope={permission.key}
            checked={role?.scopes?.includes(permission?.key)}
            onChange={(isChecked) => {}}
            disabled
          />
        </div>
      );
    });

    return <div className="grid grid-cols-1 md:grid-cols-2">{elements}</div>;
  };

  return (
    <div className="">
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader message="Loading. Please wait..." brollyLoader />
        </div>
      ) : (
        <div className="">
          <div className="p-5 flex gap-5 items-center">
            <BackButton onClick={() => navigate('/admin/settings')} />
            <h1 className="font-bold">{role?.name}</h1>
          </div>
          <hr />

          <div className="grid grid-cols-1 md:grid-cols-5  justify-around gap-10 p-10">
            <div className="col-span-2">
              <div className="flex items-center justify-between">
                <h1 className="font-semibold my-2">Role details</h1>
                <span className="text-gray-400 text-xs block">
                  Last modified on
                  {role.updatedOn ? moment(role.updatedOn).format('Do MMMM YYYY, h:mm a') : 'N/A'}
                </span>
              </div>

              <hr />

              <div className="py-5">
                <>
                  <span className="text-sm">Name of role</span>
                  <div className="border p-2 rounded">{role.name}</div>
                </>
                <div className="py-5">
                  <span className="text-sm">Role description</span>
                  <div className="text-sm border py-2 px-3 min-h-[8rem] rounded">
                    {role.description}
                  </div>
                </div>
                <hr />

                <div className="pt-5">
                  <span className="text-sm">Invite a new team member to this role</span>
                  <div className="flex items-center justify-center gap-0">
                    <Input placeholder="email address" className="rounded-r-none" />
                    <div className="">
                      <Button label="Send invite" className="bg-primary-main rounded-r h-11" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border rounded p-5 col-span-3">
              <h1 className="font-semibold my-2">Role permissions</h1>
              <hr />
              <>
                <div className="my-3">
                  <span className="text-sm">
                    See the list of available permissions for this role
                  </span>
                </div>
                <ScopesList />
              </>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleDetails;
