import React, { useRef } from "react";
import clsx from "clsx";
import { ScrollToFormikError } from "../elements/ScrollToFormikError";


interface ITextAreaProps {
    label?: string;
    id?: string;
    description?: string;
    disabled?: boolean;
    readOnly?: boolean;
    name: string;
    placeholder?: string;
    value: string | number;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void | null;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void | null;
    error?: string | undefined;
    required?: boolean;
    helperText?: string;
    autoComplete?: boolean;
    classes?: string;
    resize?: "vertical" | "horizontal" | "none" | "both" | "block" | "inline" | undefined;
    maxHeight?: number | undefined;
    minHeight?: number | undefined;
    maxWidth?: number | undefined;
    minWidth?: number | undefined;
    rows?: number;
    ref?: any;
    max?: number;
}

function TextArea({
    label,
    id,
    description,
    disabled = false,
    name,
    placeholder,
    value,
    readOnly = false,
    onChange,
    onBlur,
    error,
    required = false,
    helperText,
    autoComplete = false,
    resize,
    maxHeight,
    minHeight = 50,
    maxWidth,
    minWidth,
    classes,
    rows,
    ref,
    max
}: ITextAreaProps): JSX.Element {
    const fieldRef = useRef<HTMLDivElement>(null);

    return (
        <div className={clsx("w-full", { ["animate-wiggle"]: error && required })}>
            {/* Label */}
            {label && (
                <label
                    htmlFor={id}
                    className={clsx(
                        "block text-sm font-medium text-gray-700",
                        { ["!text-danger-main"]: error && required }
                    )}
                >
                    {label} <span className="!text-danger-main">{required && "*"}</span>
                    {description && (<p className={clsx("text-xs text-gray-400 italic", { ["!text-danger-border"]: error && required })}>{description}</p>)}
                </label>
            )}

            <div className="mt-1 relative">
                <div className="relative w-full" ref={fieldRef}>
                    <textarea
                        name={name}
                        id={id}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={clsx(
                            "relative w-full border border-gray-300 rounded-md shadow-sm p-2 text-left cursor-default",
                            "focus:outline-none focus:ring-1 focus:ring-primary-main focus:border-primary-border sm:text-sm",
                            "flex items-center",
                            classes,
                            disabled ? "bg-gray-300 cursor-not-allowed" : "bg-white",
                            { ["bg-red-200 !border-danger-main focus:ring-danger-main focus:border-danger-border"]: error && required },
                            readOnly && 'border-none',
                            { ['border-red-200 focus:ring-red-200 focus:border-red-200']: !value && required }
                        )}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        disabled={disabled}
                        value={value}
                        autoComplete={String(autoComplete)}
                        style={{
                            resize: resize,
                            maxHeight: maxHeight,
                            minHeight: minHeight,
                            maxWidth: maxWidth,
                            minWidth: minWidth
                        }}
                        rows={rows}
                        ref={ref}
                        maxLength={max}
                    />
                </div>
            </div>

            {/* Helper Text */}
            <div className={clsx("text-xs text-gray-400", { ["!text-danger-main"]: error })}>{helperText}</div>
            <ScrollToFormikError
                fieldName={name ?? id ?? ""}
                fieldRef={fieldRef}
            />
        </div>
    )
}

export default TextArea;