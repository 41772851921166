import Page from '../../../components/PageWrapper';
import { useEffect, useMemo, useState } from 'react';
import {
  GetRelationshipManagerMetrics,
  getAllTeams,
  getRelationshipManager,
  getRelationshipManagerTeams
} from '../../../api/requests';
import Loader from '../../../components/Loader';
import InviteTeamMember from './InviteTeamMember';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AssignMember from './assignMember';
import useUserAccount from '../../../hooks/userAccountHook';
import { User } from '../../../models/User';
import AssignRelationshipManager from './AssignRelationshipMager';
import SetCommission from './SetCommission';
import useRoute from '../../../hooks/routesHook';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import PopOnMe from '../../../components/elements/poponme';
import { Staff } from '../../../models/staff/Staff';
import DoughnutChart from '../../../components/dashboard/charts/DonutChart';
import DashboardTabs from '../../../components/dashboard/tabs/DashboardTabs';
import MyPopOver from '../../../components/elements/PopOver';
import { RiInformationFill } from 'react-icons/ri';
import RelationshipManagerDetailsModal from './RelationshipManagerDetailsModal';
import { LeadsDonutBgColors, SalesMixDonutsBgColors } from '../../../resources/constants';
import DashboardDatePickers, {
  SelectedDateRange
} from '../../../components/dashboard/date-range-selector/datePicker';
import useModal from '../../../hooks/modalHook';
import BackButton from '../../../components/BackButton';
import {
  IAdminSalesMetrics,
  ITeamLeadMetrics,
  ITeamManagingSalesMetrics
} from '../../../models/generalMetrics/IGeneralMetrics';
import GTable from '../../../components/elements/GTable';
import TabContent from '../../../components/tabs/TabContent';
import TabNav, { ITab } from '../../../components/tabs/TabNav';
import CreateTask from '../leads/CreateTask';
import clsx from 'clsx';
import moment from 'moment';
import { ITableRowAction } from '../../../components/elements/GTable/GTable';
import { ITask } from '../../../resources/interfaces';
import { capitalizeSentence, getFormattedLeadSource, getInstallmentTypeFromKey, getPhoneNumberFromShortCodeFormat, getPolicyReviewStatus, getVehicleProtectionFromKey, numberWithCommas } from '../../../utils/miscFunctions';
import { getStatus } from '../policies/policyDetails';
import { filter } from 'lodash';
import React from 'react';
import PolicyStatus from '../../../components/elements/PolicyStatus';
import { Policy } from '../../../models/policy/Policy';
import { LEAD_STATUS, POLICY_STATUS_OPTIONS } from '../../../resources/enums';

export interface IDisableAdmin {
  id: string;
}

function RelationshipManagerTeam(): JSX.Element {

  //  states
  const [relationshipManager, setRelationshipManager] = useState<Staff>();
  const [loading, setLoading] = useState<boolean>();
  const [teams, setTeams] = useState<any>();
  const [activeTab, setActiveTab] = useState('teams');
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>({
    from: '',
    to: ''
  });
  const [showTeamDetailsModal, setShowTeamDetailsModal] = useState<boolean>(false);
  const [relationshipManagerMetrics, setRelationshipManagerMetrics] = useState<IAdminSalesMetrics>({
    due_later: {
      amount: {
        total: 0,
        third_party: 0,
        comprehensive: 0
      },
      count: {
        total: 0,
        third_party: 0,
        comprehensive: 0
      }
    },
    due_now: {
      amount: {
        total: 0,
        third_party: 0,
        comprehensive: 0
      },
      count: {
        total: 0,
        third_party: 0,
        comprehensive: 0
      }
    },
    lead: {
      channel: {
        whatsapp: 0,
        referral: 0,
        facebook: 0,
        direct: 0,
        telegram: 0,
        google: 0,
        instagram: 0,
        radio: 0
      },
      status: {
        warm: 0,
        paid: 0,
        closed: 0,
        cold: 0
      },

    },
    signups: [],
    policies: [],
    tasks: [],
  });
  const [salesMix, setSalesMix] = useState({
    due_now: {
      amount: {
        Comprehensive: 0,
        'Third Party': 0
      },
      count: {
        Comprehensive: 0,
        'Third Party': 0
      }
    },
    due_later: {
      amount: {
        Comprehensive: 0,
        'Third Party': 0
      },
      count: {
        Comprehensive: 0,
        'Third Party': 0
      }
    }
  });
  const [managedTeamSalesMix, setManagedTeamSalesMix] = useState<ITeamManagingSalesMetrics>({
    due_later: {
      amount: {
        total: 0,
        third_party: 0,
        comprehensive: 0
      },
      count: {
        total: 0,
        third_party: 0,
        comprehensive: 0
      }
    },
    due_now: {
      amount: {
        total: 0,
        third_party: 0,
        comprehensive: 0
      },
      count: {
        total: 0,
        third_party: 0,
        comprehensive: 0
      }
    },
    lead: {
      channel: {
        whatsapp: 0,
        referral: 0,
        facebook: 0,
        direct: 0,
        telegram: 0,
        google: 0,
        instagram: 0,
        radio: 0
      },
      status: {
        warm: 0,
        paid: 0,
        closed: 0,
        cold: 0
      }
    }
  });


  // Hooks
  const { ModalComponent, updateModalStates, closeModal } = useModal();
  const navigate = useNavigate();
  const { getDashboardBase } = useRoute();
  const { getProfile } = useUserAccount();
  const user = new User(getProfile());
  const { managerId } = useParams();
  const id = managerId;



  useEffect(() => {
    if (relationshipManagerMetrics) {
      setSalesMix({
        ...salesMix,
        due_now: {
          amount: {
            Comprehensive: relationshipManagerMetrics.due_now.amount.comprehensive,
            'Third Party': relationshipManagerMetrics.due_now.amount.third_party
          },
          count: {
            Comprehensive: relationshipManagerMetrics.due_now.count.comprehensive,
            'Third Party': relationshipManagerMetrics.due_now.count.third_party
          }
        },
        due_later: {
          amount: {
            Comprehensive: relationshipManagerMetrics.due_later.amount.comprehensive,
            'Third Party': relationshipManagerMetrics.due_later.amount.third_party
          },
          count: {
            Comprehensive: relationshipManagerMetrics.due_later.count.comprehensive,
            'Third Party': relationshipManagerMetrics.due_later.count.third_party
          }
        }
      });
    }
  }, [relationshipManagerMetrics]);

  // useEffect(() => {
  //   if (relationshipManagerMetrics.managed_teams_sales_mix) {
  //     setSalesMix({
  //       ...salesMix,
  //       amount: {
  //         Comprehensive:
  //           relationshipManagerMetrics.managed_teams_sales_mix.amount.comprehensive_100 +
  //           relationshipManagerMetrics.managed_teams_sales_mix.amount.comprehensive_90 +
  //           relationshipManagerMetrics.managed_teams_sales_mix.amount.comprehensive_85,
  //         'Third Party':
  //           relationshipManagerMetrics.managed_teams_sales_mix.amount['third_party'] +
  //           relationshipManagerMetrics.managed_teams_sales_mix.amount['third_party_ft']
  //       },
  //       count: {
  //         Comprehensive:
  //           relationshipManagerMetrics.managed_teams_sales_mix.count.comprehensive_100 +
  //           relationshipManagerMetrics.managed_teams_sales_mix.count.comprehensive_90 +
  //           relationshipManagerMetrics.managed_teams_sales_mix.count.comprehensive_85,
  //         'Third Party':
  //           relationshipManagerMetrics.managed_teams_sales_mix.count['third-party'] +
  //           relationshipManagerMetrics.managed_teams_sales_mix.count['third-party-ft']
  //       }
  //     });
  //   }
  // }, [relationshipManagerMetrics]);

  useEffect(() => {
    setLoading(true);
    if (id) getRelationshipManagerDetails(id);
  }, []);

  useEffect(() => {
    if (id) getTeams(id);
  }, []);

  useEffect(() => {
    if (id) getAdminMetrics(id);
  }, [selectedDateRange]);


  // Actions
  const handleDateChange = (dateRange: { from: string; to: string }) => {
    setSelectedDateRange(dateRange);
  };

  const inviteMember = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <InviteTeamMember
          onProceed={() => {
            closeModal();
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const getSingleTeamActions = (row: any) => {
    const id = row?.id;
    const singleTeamActions = [];

    if (user.isSuperAdmin()) {
      singleTeamActions.push(
        {
          label: 'Set Relationship Manager',
          callback: () => {
            assignRelationshipManager(id);
          }
        },
        {
          label: 'Set commission rate',
          callback: () => {
            SetCommissionPercentage(id);
          }
        },
        {
          label: 'Add member to team',
          callback: () => {
            assignMember(id);
          }
        },
        {
          label: 'Invite member',
          callback: () => {
            inviteMember();
          }
        }
      );
    }

    return singleTeamActions;
  };

  const assignRelationshipManager = (id: string) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <AssignRelationshipManager
          teamID={id}
          onProceed={() => {
            closeModal();
            getAllTeams();
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showCreateTaskModal = (task?: ITask, isEdit = false) => {
    id && updateModalStates({
      show: true,
      size: 'xl',
      children: (
        <CreateTask
          onProceed={() => {
            // id && fetchCustomer(id)
            closeModal();
          }}
          onClose={closeModal}
          id={id}
          isEdit={isEdit}
          task={task}
          type={'CUSTOMER'} />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showUpdateTaskModal = (task?: ITask, isEdit = true) => {
    id && updateModalStates({
      show: true,
      size: 'xl',
      children: (
        <CreateTask
          onProceed={() => {
            // id && fetchCustomer(id)
            closeModal();
          }}
          onClose={closeModal}
          id={id}
          isEdit={isEdit}
          task={task}
          type={'CUSTOMER'} />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const getAdminMetrics = (id: string) => {
    GetRelationshipManagerMetrics(id, selectedDateRange)
      .then((response: any) => {
        setRelationshipManagerMetrics(response);
      })
      .catch((error: Error) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTeams = (id: string) => {
    getRelationshipManagerTeams(id)
      .then((response: any) => {
        setTeams(response?.data);
      })
      .catch((error: Error) => { })
      .finally(() => {
        // setLoading(false);
      });
  };

  const getRelationshipManagerDetails = (id: string) => {
    getRelationshipManager(id)
      .then((response: any) => {
        setRelationshipManager(response);
      })
      .catch((error: Error) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const assignMember = (id: string) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <AssignMember
          teamID={id}
          onProceed={() => {
            closeModal();
            getAllTeams();
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };
  const handleTabClicked = (id: string) => {
    // navigate(`${getDashboardBase()}/approvals?tab=${id}`);
  };

  const getTaskTableRowActions = (data: any): ITableRowAction[] => {
    const actions = [
      {
        label: 'Update',
        callback: (policy: any) => showUpdateTaskModal(),
        data: data
      }
    ];

    return actions;
  };

  const SetCommissionPercentage = (id: string) => {
    updateModalStates({
      show: true,
      size: 'sm',
      children: (
        <SetCommission
          onProceed={() => {
            closeModal();
            getAllTeams();
          }}
          onClose={closeModal}
          teamID={id}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const getAdminTabs = () => {
    let tabs: ITab[] = [
      {
        id: "teams",
        title: "Teams",
        // itemCount: customer && customer?.tasks && customer?.tasks?.length
      },
      {
        id: "tasks",
        title: "Tasks",
        // itemCount: customer && customer?.tasks && customer?.tasks?.length
      },
      {
        id: "signups",
        title: "Signups",
        // itemCount: customer && customer?.policyList && customer?.policyList?.length
      }, {
        id: "policies",
        title: "Policies",
        // itemCount: customer && customer?.policyList && customer?.policyList?.length
      },
    ]

    return tabs
  }
  const getAdminTeamsTabs = () => {
    let tabs: ITab[] = [
      {
        id: "signups",
        title: "Signups",
        // itemCount: customer && customer?.policyList && customer?.policyList?.length
      }, {
        id: "policies",
        title: "Policies",
        // itemCount: customer && customer?.policyList && customer?.policyList?.length
      }, {
        id: "customers",
        title: "Customers",
        // itemCount: customer && customer?.policyList && customer?.policyList?.length
      },
    ]

    return tabs
  }

  const fullName = `${relationshipManager?.firstName} 
  ${relationshipManager?.lastName}
  `;

  // Columns
  const taskColumns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
            Cell: (row: any) => {
              return <span>{row.cell.value ? capitalizeSentence(row.cell.value) : 'N/A'}</span>;
            }
          },

          {
            Header: 'Description',
            accessor: 'description',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Type',
            accessor: 'type',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Assigned To',
            accessor: 'assignedTo.firstName',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? capitalizeSentence(
                      row.row.original.assignedTo.firstName +
                      ' ' +
                      row.row.original.assignedTo.lastName
                    )
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'completed',
            Cell: (row: any) => {
              return getStatus(row.cell.value);
            }
          },
          {
            Header: 'Due date',
            accessor: 'dueOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('MMM Do YY, h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            id: 'Action',
            Cell: (row: any) => {
              return (
                <div className="cursor-pointer flex justify-center">
                  {getTaskTableRowActions(row.row.original).length > 0 && (
                    <PopOnMe actions={getTaskTableRowActions(row.row.original)}>
                      <EllipsisVerticalIcon className="h-4 w-4" />
                    </PopOnMe>
                  )}
                </div>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Type',
            accessor: 'type',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'No of Members',
            accessor: 'numberOfMembers',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Relationship Manager',
            accessor: 'relationshipManager',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? `${row.cell.value.firstName} ${row.cell.value.lastName}`
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Admin',
            accessor: 'teamLead',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            id: 'Action',
            Cell: (row: any) => {
              return (
                <>
                  {getSingleTeamActions.length > 0 && (
                    <div className="cursor-pointer flex justify-center">
                      <PopOnMe actions={getSingleTeamActions(row.row.original)}>
                        <EllipsisVerticalIcon className="h-4 w-4" />
                      </PopOnMe>
                    </div>
                  )}
                </>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const policyolumns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Customer',
            accessor: 'customer',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? `${row.cell.value?.firstName} ${row.cell.value?.lastName}`
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Phone #',
            accessor: 'customer.phone',
            Cell: (row: any) => {
              return <span>{getPhoneNumberFromShortCodeFormat(row.cell.value) ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Policy ID',
            accessor: 'policyNumber',
            Cell: (row: any) => {
              //  to={`${getDashboardBase()}/policies/${
              //   row?.row?.original.ref
              // }?filter=${filter}&page=${currentPage}`}>

              return (
                <Link to={`${getDashboardBase()}/policies/${row.cell.value}`}>
                  <span className="hover:underline text-link-main">{row.cell.value ?? 'N/A'}</span>
                </Link>
              );
            }
          },
          {
            Header: 'Review Stage',
            accessor: 'reviewStage',
            Cell: (row: any) => {
              return (
                (
                  <PolicyStatus
                    status={getPolicyReviewStatus(row.cell.value)}
                    firstSuccessfulPayment={row?.original?.isInitialPaymentReceived()}
                  />
                ) ?? <span>N/A</span>
              );
            }
          },
          {
            Header: 'Protection Type',
            accessor: 'quote.protectionType',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? getVehicleProtectionFromKey(row.cell.value)?.title : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Installment Type',
            accessor: 'installmentType',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? <>{getInstallmentTypeFromKey(row.cell.value)}</> : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Outright Premium',
            accessor: 'quote.quotePlan.outRightPremium',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span>&#8373; {numberWithCommas(row.cell.value)}</span>{' '}
                </div>
              );
            }
          },
          {
            Header: 'Initial Deposit',
            accessor: 'quote.quotePlan.initialDeposit',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span> &#8373; {numberWithCommas(row.cell.value)}</span>
                </div>
              );
            }
          },
          {
            Header: 'Installments',
            accessor: 'quote.quotePlan.monthlyInstallment',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span>
                    {row.cell.value ? (
                      <>
                        <span>&#8373;</span> {numberWithCommas(row.cell.value)}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
              );
            }
          },
          {
            Header: 'Total Payment',
            accessor: 'quote.quotePlan.totalPremium',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span>
                    {' '}
                    {row.cell.value ? (
                      <>
                        <span>&#8373;</span> {numberWithCommas(row.cell.value)}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
              );
            }
          },
          {
            Header: 'Inception Date',
            accessor: 'startsOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('DD/MM/YY h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Insurer',
            accessor: 'insurer',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value?.name : 'N/A'}</span>;
            }
          },
          {
            Header: 'Owner',
            accessor: 'owner',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value?.name : 'N/A'}</span>;
            }
          },
          {
            Header: 'Manager',
            accessor: 'manager',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? `${row.cell.value?.firstName} ${row.cell.value?.lastName}`
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Created On',
            accessor: 'createdOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('DD/MM/YY h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: (row: any) => {
              return (
                <div className="w-full flex  text-white">
                  <span
                    className={clsx(
                      'w-[30px] h-[30px] rounded-full flex items-center justify-center text-[0px] ',
                      {
                        ['bg-status-active']:
                          new Policy(row.row.original).getStatus()?.toLowerCase() ===
                          POLICY_STATUS_OPTIONS.ACTIVE
                      },
                      {
                        ['bg-status-ended']:
                          new Policy(row.row.original).getStatus()?.toLowerCase() ===
                          POLICY_STATUS_OPTIONS.INACTIVE
                      }
                    )}>
                    {new Policy(row.row.original).getStatus()}
                  </span>
                </div>
              );
            }
          },
          // {
          //   id: 'Action',
          //   Cell: (row: any) => {
          //     return (
          //       <div className="cursor-pointer flex justify-center !p-0 w-10">
          //         <PopOnMe actions={getTableRowActions(row.row.original)}>
          //           <EllipsisVerticalIcon className="h-6 w-6" />
          //         </PopOnMe>
          //       </div>
          //     );
          //   }
          // }
        ]
      }
    ],
    []
  );

  const signupColumns = useMemo(
    () => [
      // {
      //   id: 'selection',
      //   Header: ({ getToggleAllRowsSelectedProps }: any) => (
      //     <div>
      //       <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
      //     </div>
      //   ),
      //   Cell: ({ row }: any) => (
      //     <div>
      //       <input type="checkbox" {...row.getToggleRowSelectedProps()} />
      //     </div>
      //   )
      // },
      {
        Header: 'M',
        columns: [
          {
            Header: 'Name',
            accessor: 'firstname',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? (
                    <>
                      {capitalizeSentence(
                        row.row.original.firstname + ' ' + row.row.original.lastname
                      )}
                    </>
                  ) : (
                    'N/A'
                  )}
                </span>
              );
            }
          },

          {
            Header: 'Phone',
            accessor: 'phone',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? getPhoneNumberFromShortCodeFormat(row.cell.value) : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'leadStatus',
            Cell: (row: any) => {
              return (
                <PolicyStatus
                  status={capitalizeSentence(row.cell.value ?? LEAD_STATUS.COLD)}
                  classes="!w-[80px]"
                />
              );
            }
          },
          {
            Header: 'Source',
            accessor: 'leadSource',
            Cell: (row: any) => {
              return <span>{row.cell.value ? getFormattedLeadSource(row.cell.value) : 'N/A'}</span>;
            }
          },
          {
            Header: 'Current Insurer',
            accessor: 'currentInsurer',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Insurance Type',
            accessor: 'insuranceType',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? getVehicleProtectionFromKey(row.cell.value)?.title : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Expiry Date',
            accessor: 'expiryDate',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YYYY') : 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Owner',
            accessor: 'owner.firstName',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? (
                    <>
                      {row.row.original.owner.firstName.charAt(0).toUpperCase() +
                        row.row.original.owner.firstName.slice(1)}{' '}
                      {row.row.original.owner.lastName.charAt(0).toUpperCase() +
                        row.row.original.owner.lastName.slice(1)}
                    </>
                  ) : (
                    'N/A'
                  )}
                </span>
              );
            }
          },
          {
            Header: 'Created On',
            accessor: 'createdOn',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YYYY') : 'N/A'}</span>
              );
            }
          },
          // {
          //   id: 'Action',
          //   Cell: (row: any) => {
          //     return (
          //       <div className="cursor-pointer flex justify-center">
          //         <PopOnMe actions={getTableRowActions(row.row.original)}>
          //           <EllipsisVerticalIcon className="h-4 w-4" />
          //         </PopOnMe>
          //       </div>
          //     );
          //   }
          // }
        ]
      }
    ],
    []
  );

  return (
    <Page title="Admin Teams">
      <div className="bg-white w-full p-5">
        <div className="flex  items-center gap-4 justify-between">
          <div className="flex items-center gap-4">
            <BackButton onClick={() => navigate(-1)} />
            {relationshipManager && (
              <div className="flex gap-x-5 items-center">
                <div className="">
                  <h2 className="font-semibold text-sm md:text-xl">{`${fullName}'s Performance`}</h2>
                </div>

                <div className="shrink-0">
                  {relationshipManager && (
                    <MyPopOver
                      size="sm"
                      trigger={<RiInformationFill className="h-6 w-6 cursor-pointer outline-0" />}
                      show={showTeamDetailsModal}>
                      {<RelationshipManagerDetailsModal />}
                    </MyPopOver>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="p-5">
            <DashboardDatePickers
              onSubmit={(data: SelectedDateRange) => {
                handleDateChange(data);
              }}
            />
          </div>
        </div>

        <div className=" p-2 rounded-[5px]">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6 xl:gap-10 mt-8 mb-8">
            <div className="  rounded-md overflow-hidden  p-4 sm:p-12 md:p-4 lg:p-4 xl:p-5 border border-primary-border">
              <DashboardTabs
                tabGroupTitle="Due Now Sales"
                tab1={{
                  name: 'Amount',
                  children: (
                    <DoughnutChart
                      chartKey={'amount'}
                      displayAsCurrency
                      metricsData={salesMix.due_now.amount}
                      bgColors={SalesMixDonutsBgColors}
                    />
                  )
                }}
                tab2={{
                  name: 'Count',
                  children: (
                    <DoughnutChart
                      chartKey={'count'}
                      metricsData={salesMix.due_now.count}
                      bgColors={SalesMixDonutsBgColors}
                    />
                  )
                }}
              />
            </div>
            <div className="border border-primary-border rounded-md overflow-hidden  p-4 sm:p-12 md:p-4 lg:p-4 xl:p-5 ">
              <DashboardTabs
                tabGroupTitle="Due Later Sales"
                tab1={{
                  name: 'Amount',
                  children: (
                    <DoughnutChart
                      chartKey={'amount'}
                      displayAsCurrency
                      metricsData={salesMix.due_later.amount}
                      bgColors={SalesMixDonutsBgColors}
                    />
                  )
                }}
                tab2={{
                  name: 'Count',
                  children: (
                    <DoughnutChart
                      chartKey={'count'}
                      metricsData={salesMix.due_later.count}
                      bgColors={SalesMixDonutsBgColors}
                    />
                  )
                }}
              />
            </div>
            <div className="  rounded-md overflow-hidden  p-4 sm:p-12 md:p-4 lg:p-4 xl:p-5 border border-primary-border">
              <DashboardTabs
                tabGroupTitle="Leads"
                tab1={{
                  name: 'Channel',
                  children: (
                    <DoughnutChart
                      chartKey={'lead_channel'}
                      metricsData={relationshipManagerMetrics.lead.channel}
                      bgColors={LeadsDonutBgColors}
                    />
                  )
                }}
                tab2={{
                  name: 'Status',
                  children: (
                    <DoughnutChart
                      chartKey={'leads_status'}
                      metricsData={relationshipManagerMetrics.lead.status}
                      bgColors={LeadsDonutBgColors}
                    />
                  )
                }}
              />
            </div>
          </div>

          <div className="mt-10">
            {loading ? (
              <div className=" flex items-center justify-center">
                <Loader message="Loading. Please wait..." brollyLoader />
              </div>
            ) : (
              <div className="overflow-x-auto ">
                <TabNav
                  tabs={getAdminTabs()}
                  activeTab={activeTab}
                  onTabClicked={handleTabClicked}
                  setActiveTab={(id: string) => {
                    setActiveTab(id)
                  }}
                />

                <TabContent id={'teams'} activeTab={activeTab}>
                  {teams && teams.length > 0 ? (
                    <div className=" my-5 ">
                      <GTable
                        columns={columns}
                        data={teams}
                        tableType={'team'}
                        showSearch={false}
                        classes="h-fit"
                      />
                    </div>
                  ) :
                    <div className="h-20 flex items-center justify-center text-lg">No team found </div>
                  }
                </TabContent>


                <TabContent id={'tasks'} activeTab={activeTab}>
                  {relationshipManagerMetrics &&
                    relationshipManagerMetrics.tasks &&
                    relationshipManagerMetrics.tasks.length > 0 ? (
                    <div className=" my-5 ">
                      <GTable
                        columns={taskColumns}
                        data={relationshipManagerMetrics?.tasks}
                        classes="h-fit"
                        showSearch={false}
                        tableType={'task'}
                        stickyHeader
                      />
                    </div>
                  ) :
                    <div className="h-20 flex items-center justify-center text-lg">No task found </div>
                  }
                </TabContent>

                <TabContent id={'signups'} activeTab={activeTab}>
                  {relationshipManagerMetrics &&
                    relationshipManagerMetrics.signups &&
                    relationshipManagerMetrics.signups.length > 0
                    ? (
                      <div className=" my-5 ">
                        <GTable
                          columns={signupColumns}
                          data={relationshipManagerMetrics?.signups}
                          classes="h-fit"
                          showSearch={false}
                          tableType={'signup'}
                          stickyHeader
                        />
                      </div>
                    ) :
                    <div className="h-20 flex items-center justify-center text-lg">No signup found </div>
                  }
                </TabContent>

                <TabContent id={'policies'} activeTab={activeTab}>
                  {relationshipManagerMetrics?.policies && relationshipManagerMetrics?.policies.length > 0 ? (

                    <div className=" my-5 ">
                      <GTable
                        columns={policyolumns}
                        data={relationshipManagerMetrics?.policies}
                        showSearch={false}
                        tableType="policy"
                        onRowClick={(policy: Policy) => {
                          navigate(`${getDashboardBase()}/policies/${policy.ref}`, {
                            replace: true
                          });
                        }}
                        columnClasses={clsx('w-fit')}
                        loading={loading}
                        tableContainerId="tableId"
                        stickyHeader
                      />
                    </div>
                  ) : (
                    <div className="h-40 flex items-center justify-center text-lg">
                      No policy found
                    </div>
                  )}
                </TabContent>
              </div>
            )}
          </div>
          {/* 
          {teams && teams?.length > 0 && (
            <>
              <div className="mt-10">
                <h1 className="text-lg font-semibold">Teams Performance</h1>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6 xl:gap-10 mt-8 mb-8">
                <div className="  rounded-md overflow-hidden  p-4 sm:p-12 md:p-4 lg:p-4 xl:p-5 border border-primary-border">
                  <DashboardTabs
                    tabGroupTitle="Due Now Sales"
                    tab1={{
                      name: 'Amount',
                      children: (
                        <DoughnutChart
                          chartKey={'amount'}
                          displayAsCurrency
                          metricsData={salesMix.due_now.amount}
                          bgColors={SalesMixDonutsBgColors}
                        />
                      )
                    }}
                    tab2={{
                      name: 'Count',
                      children: (
                        <DoughnutChart
                          chartKey={'count'}
                          metricsData={salesMix.due_now.count}
                          bgColors={SalesMixDonutsBgColors}
                        />
                      )
                    }}
                  />
                </div>
                <div className="border border-primary-border rounded-md overflow-hidden  p-4 sm:p-12 md:p-4 lg:p-4 xl:p-5 ">
                  <DashboardTabs
                    tabGroupTitle="Due Later Sales"
                    tab1={{
                      name: 'Amount',
                      children: (
                        <DoughnutChart
                          chartKey={'amount'}
                          displayAsCurrency
                          metricsData={salesMix.due_later.amount}
                          bgColors={SalesMixDonutsBgColors}
                        />
                      )
                    }}
                    tab2={{
                      name: 'Count',
                      children: (
                        <DoughnutChart
                          chartKey={'count'}
                          metricsData={salesMix.due_later.count}
                          bgColors={SalesMixDonutsBgColors}
                        />
                      )
                    }}
                  />
                </div>
                <div className="  rounded-md overflow-hidden  p-4 sm:p-12 md:p-4 lg:p-4 xl:p-5 border border-primary-border">
                  <DashboardTabs
                    tabGroupTitle="Leads"
                    tab1={{
                      name: 'Channel',
                      children: (
                        <DoughnutChart
                          chartKey={'lead_channel'}
                          metricsData={relationshipManagerMetrics.lead.channel}
                          bgColors={LeadsDonutBgColors}
                        />
                      )
                    }}
                    tab2={{
                      name: 'Status',
                      children: (
                        <DoughnutChart
                          chartKey={'leads_status'}
                          metricsData={relationshipManagerMetrics.lead.status}
                          bgColors={LeadsDonutBgColors}
                        />
                      )
                    }}
                  />
                </div>
              </div>

              <div className="">
                <TabNav
                  tabs={getAdminTeamsTabs()}
                  activeTab={activeTab}
                  onTabClicked={handleTabClicked}
                  setActiveTab={(id: string) => {
                    setActiveTab(id)
                  }}
                />
              </div>

              <div className="">
                <TabContent id={'customers'} activeTab={activeTab}>
                  {teams && teams.length > 0 ? (
                    <div className=" my-5 ">
                      <GTable
                        columns={columns}
                        data={teams}
                        tableType={'customer'}
                        showSearch={false}
                        classes="h-fit"
                      />
                    </div>
                  ) :
                    <div className="h-20 flex items-center justify-center text-lg">No customer found </div>
                  }
                </TabContent>

                <TabContent id={'signups'} activeTab={activeTab}>
                  {teams &&
                    teams.tasks &&
                    teams.tasks.length > 0 &&
                    !user.isInsurer() &&
                    !user.isRideSharing() ? (
                    <div className=" my-5 ">
                      <GTable
                        columns={taskColumns}
                        data={teams?.tasks}
                        classes="h-fit"
                        showSearch={false}
                        tableType={'signup'}
                        stickyHeader
                      />
                    </div>
                  ) :
                    <div className="h-20 flex items-center justify-center text-lg">No signup found </div>
                  }
                </TabContent>

                <TabContent id={'policies'} activeTab={activeTab}>
                  {teams?.policyList && (
                    <>
                      <div className=" my-5 ">
                        <GTable
                          columns={columns}
                          data={teams?.policyList}
                          showSearch={false}
                          tableType="policy"
                          onRowClick={(policy: any) => {
                            navigate(`${getDashboardBase()}/policies/${policy.policyNumber}`, {
                              replace: true
                            });
                          }}
                          columnClasses={clsx('w-fit')}
                          loading={loading}
                          tableContainerId="tableId"
                          stickyHeader
                        />
                      </div>

                      {!teams?.policyList && (
                        <div className="h-40 flex items-center justify-center text-lg">
                          No policy found
                        </div>
                      )}
                    </>
                  )}
                </TabContent>
              </div>
            </>
          )} */}
        </div>
      </div>
      <ModalComponent />
    </Page>
  );
}

export default RelationshipManagerTeam;
