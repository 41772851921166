import React from 'react';

interface IDiscountData {
  code: string;
  name: string | undefined;
  value: number;
  type: string;
}

const DiscountInfoModal: React.FC<IDiscountData> = ({ code, name, value, type }) => {
  return (
    <div className={`w-full py-5 bg-white `}>
      <div className="flex flex-col justify-between gap-3 px-5">
        <div>
          <h2 className={`font-semibold transition duration-300 ease-in-out  text-lg`}>
            Discount Info
          </h2>
        </div>
        <hr />
        <div>
          <h2 className={`transition duration-300 ease-in-out  text-sm`}>CODE: {code}</h2>
        </div>
        <hr />
        <div>
          <h2 className={`transition duration-300 ease-in-out  text-sm`}>NAME: {name}</h2>
        </div>
        <hr />
        <div>
          <h2 className={`transition duration-300 ease-in-out  text-sm`}>VALUE: {value}</h2>
        </div>
        <hr />
        <div>
          <h2 className={` transition duration-300 ease-in-out  text-sm`}>TYPE: {type}</h2>
        </div>
      </div>
    </div>
  );
};

export default DiscountInfoModal;
