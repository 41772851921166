import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import '../styles/dashboard.scss';

import Navbar from '../components/navbar';
import Sidebar from '../components/sidebar';
import TopLoaderComponent from '../components/topLoader/TopLoaderComponent';
import LiveChatWidget from '../components/elements/LiveChatWidget';
import Button from '../components/form-control/Button';

import { getTopLoadingState } from '../states/slices/globalSlice';
import useAuth from '../hooks/authHook';
import { LAUNCH_CHAT } from '../states/slices/liveChatSlice';

type DashboardLayoutProps = {
  showSideBar: boolean;
};

const DashboardLayout = ({ showSideBar }: DashboardLayoutProps): JSX.Element => {
  const [showSidebar, setShowSidebar] = useState(false);
  const showTopLoading = useSelector(getTopLoadingState);
  const { isInsurerOnboarding } = useAuth();

  const showDashboardLayout =
    !isInsurerOnboarding() && !window.location.pathname.includes('/store/preview');

  const dispatch = useDispatch();
  const chatRef = useRef(null);

  useEffect(() => {
    if (!showDashboardLayout) {
      setTimeout(() => {
        document
          .querySelectorAll('.bot--bubble-holder')
          .forEach((a: any) => (a.style.display = 'none'));
      }, 1500);
    } else {
      document
        .querySelectorAll('.bot--bubble-holder')
        .forEach((a: any) => (a.style.display = 'initial'));
    }
  }, [showDashboardLayout]);

  const handleMenuClick = () => {
    setShowSidebar((prev) => !prev);
  };

  const handleSidebarMenuClicked = () => {
    setShowSidebar((prev) => !prev);
  };

  return (
    <>
      {showTopLoading && (
        <TopLoaderComponent
          isLoading={showTopLoading}
          pastDelay={false}
          timedOut={false}
          retry={() => {}}
          error={null}
        />
      )}

      <main>
        {!showDashboardLayout ? (
          <>
            {!window.location.pathname.includes('/store/preview') && (
              <Navbar handleMenuClick={handleMenuClick} hideProfileSection isSticky={false} />
            )}
            <Outlet />
            <Button
              className="fixed right-0 bottom-0 rounded-[27px] bg-primary-main px-6 mr-2 mb-2 border-primary-main"
              onClick={() => {
                dispatch(LAUNCH_CHAT(true));
              }}>
              <QuestionMarkCircleIcon className="w-4 mr-1" /> Help
            </Button>
          </>
        ) : (
          <>
            <Navbar handleMenuClick={handleMenuClick} />

            <div className="flex w-full h-full md:h-[calc(100vh_-_80px)] mt-[4rem] md:px-3 bg-[#F4F4F4] gap-5">
              {showSideBar ? (
                <>
                  <Sidebar onMenuItemClicked={handleSidebarMenuClicked} showSidebar={showSidebar} />
                  <div className="w-full overflow-x-hidden overflow-y-auto bg-[#F4F4F4] hideScrollBar min-h-full">
                    {/* <div className="w-full lg:ml-[245px] overflow-x-hidden overflow-y-auto md:p-5 bg-[#F9FAFB]"> */}
                    <Outlet />
                  </div>
                </>
              ) : (
                <div className="w-full overflow-x-hidden overflow-y-auto md:h-[calc(100vh_-_80px)] min-h-full">
                  <Outlet />
                </div>
              )}
            </div>
          </>
        )}
        <LiveChatWidget />
      </main>
    </>
  );
};

export default DashboardLayout;
