import React, { useState } from 'react';
import GTable from '../../../../components/elements/GTable';
import { useNavigate } from 'react-router-dom';
import { Payout } from '../../../../models/payout/Payout';
import moment from 'moment';
import useRoute from '../../../../hooks/routesHook';
import useUserAccount from '../../../../hooks/userAccountHook';
import { User } from '../../../../models/User';
import IconStatus from '../../../../components/elements/IconStatus';

interface IPayoutsTableProps {
  payouts: Payout[];
  loading?: boolean;
  payoutsPaginationData?: any;
}

const PayoutsTable = (props: IPayoutsTableProps) => {
  const [showPerPage, setShowPerPage] = useState<number>(10);
  const { getDashboardBase } = useRoute();
  const navigate = useNavigate();
  const { getProfile } = useUserAccount();
  const user = new User(getProfile());

  const columns = React.useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Reference',
            accessor: 'reference',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Currency',
            accessor: 'currency',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Amount',
            accessor: 'totalAmountPayable',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Date',
            accessor: 'createdOn',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('MM/DD/YY') : 'N/A'}</span>
              );
            }
          },

          {
            Header: 'Reviewed',
            accessor: 'isReviewed',
            Cell: (row: any) => {
              return <IconStatus status={row.cell.value} classes="" />;
            }
          },
          {
            Header: 'Approved',
            accessor: 'isApproved',
            Cell: (row: any) => {
              return <IconStatus status={row.cell.value} />;
            }
          },
          {
            Header: 'Paid',
            accessor: 'isPaid',
            Cell: (row: any) => {
              return <IconStatus status={row.cell.value} />;
            }
          }
        ]
      }
    ],
    []
  );

  const navToPayoutDetails = (payout: Payout) => {
    if (user.isAgent() || user.isAgencyAdmin() || user.isInsurer()) {
      navigate(`${getDashboardBase()}/payouts/${payout.id}`, {
        replace: true
      });
    } else {
      if (payout.team) {
        navigate(`${getDashboardBase()}/teams/${payout.team.id}/payouts/${payout.id}`, {
          replace: true
        });
      }
    }
  };

  return (
    <div className="overflow-x-auto shadow-md">
      <GTable
        columns={columns}
        data={props.payouts}
        showPagination
        searchPlaceholder="Search by reference"
        paginationData={props.payoutsPaginationData}
        tableType={'payouts'}
        showPerPage={showPerPage}
        onShowPerPageChange={(page: number) => setShowPerPage(page)}
        onRowClick={(payout: Payout) => {
          if (payout.id) {
            navToPayoutDetails(payout);
          }
        }}
        loading={props.loading}
        classes={'h-fit'}
      />
    </div>
  );
};

export default PayoutsTable;
