import { INSURER_STATUS_OPTIONS } from '../../resources/enums';
import { Policy } from '../policy/Policy';
import { Team } from '../team/Team';
import { IInsurer, IInsurerReview, IProduct } from './IInsurer';

export class Insurer {
  apiKey?: string;
  contactPerson?: string;
  email?: string;
  id?: string;
  location?: string;
  midPassword?: string;
  midUsername?: string;
  name?: string;
  phoneNumber?: string;
  postalAddress?: string;
  role?: string;
  status?: INSURER_STATUS_OPTIONS;
  website?: string;
  nicRegistration?: string;
  branchCode?: string;
  policies:Policy[];
  country?: string;
  banner?: string;
  bannerMobile?: string;
  benefits: string[];
  description?: string;
  logo?: string;
  products: IProduct[];
  reviews: IInsurerReview[];
  team: Team | undefined;
  tagline: string;
  rating: number;
  themeColour: string;
  fontColour: string;
  slug?: string;
  nicRegistrationNumber?: string;

  constructor(Imodel: IInsurer) {
    this.name = Imodel.name;
    this.email = Imodel.email;
    this.phoneNumber = Imodel.phoneNumber;
    this.contactPerson = Imodel.contactPerson;
    this.website = Imodel.website;
    this.id = Imodel.id;
    this.role = Imodel.role;
    this.status = Imodel.status as INSURER_STATUS_OPTIONS;
    this.location = Imodel.location;
    this.midPassword = Imodel.midPassword;
    this.midUsername = Imodel.midUsername;
    this.apiKey = Imodel.apiKey;
    this.postalAddress = Imodel.postalAddress;
    this.nicRegistration = Imodel.nicRegistration;
    this.branchCode = Imodel.branchCode;
    this.policies = Imodel.policies??[];
    this.country = Imodel.country;
    this.banner = Imodel.banner;
    this.bannerMobile = Imodel.bannerMobile;
    this.benefits = Imodel.benefits??[];
    this.description = Imodel.description;
    this.logo = Imodel.logo;
    this.products = Imodel.products??[];
    this.reviews = Imodel.reviews??[];
    this.team = Imodel.team ? new Team(Imodel.team) :  undefined;
    this.tagline = Imodel.tagline??"";
    this.rating = Imodel.rating??0;
    this.themeColour = Imodel.themeColour??"";
    this.fontColour = Imodel.fontColour??"";
    this.slug = Imodel.slug??"";
    this.apiKey = Imodel.apiKey;
    this.nicRegistrationNumber = Imodel.nicRegistration;
  }

  isActive = (): boolean => {
    return this.status === INSURER_STATUS_OPTIONS.TRUE;
  }
}
