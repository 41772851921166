import { useState } from 'react';
import { Team } from '../../../models/team/Team';

const SingleTeamDetailsModal = ({
  onClose,
  team
}: {
  onClose: () => void;
  team: Team;
}) => {
  const [singleTeam, setSingleTeam] = useState<Team>(team);

  return (
    <>
      {singleTeam && (
        <div>
          <div className="p-[1rem] border-[2px] rounded  border-primary-border">
            <div className=" flex justify-between items-center mb-6">
              <div className="">
                <h2 className="text-lg font-[Gilroy-Medium] font-medium">{singleTeam?.name}</h2>
              </div>
              {/* <div className="">
                <XIcon 
                  className="h-6 w-6 cursor-pointer" 
                  onClick={()=>{
                    onClose()
                  }}
                />
              </div> */}
            </div>
            <div className="mb-3">
              <div className="flex justify-between items-center gap-y-1 ">
                <span className="text-sm">Team Administrator</span>
                <span className="text-sm">{singleTeam?.teamLead}</span>
              </div>

              <div className="flex justify-between items-center  gap-y-1 ">
                <span className="text-sm">Relationship Manager</span>
                {
                  singleTeam?.relationshipManager ? <span className="text-sm">
                    {` ${singleTeam?.relationshipManager && singleTeam?.relationshipManager?.firstName
                      }` +
                      ' ' +
                      `${singleTeam?.relationshipManager && singleTeam?.relationshipManager?.lastName
                      }`}
                  </span> : <>N/A</>
                }
              </div>
            </div>
            <div className="mb-3">
              <h2 className="text-lg font-[Gilroy-Medium] font-medium">Commission Rates</h2>
              <div className="flex justify-between items-center  gap-y-1">
                <span className="text-sm">Comprehensive</span>
                <span className="text-sm">{singleTeam.commissionRateComprehensive}</span>
              </div>
              <div className="flex justify-between items-center  gap-y-1">
                <span className="text-sm">Third Party</span>
                <span className="text-sm">
                  {singleTeam.commissionRateThirdParty ? singleTeam.commissionRateThirdParty : 'N/A'}
                </span>
              </div>
            </div>
            <div className="mb-3">
              <h2 className="text-lg font-[Gilroy-Medium] font-medium">Payments Details</h2>
              <div className="flex justify-between items-center  gap-y-1">
                <span className="text-sm">Account Number</span>
                <span className="text-sm">
                  {singleTeam?.payoutAccount?.accountNumber
                    ? singleTeam?.payoutAccount?.accountNumber
                    : 'N/A'}
                </span>
              </div>
              <div className="flex justify-between items-center  gap-y-1">
                <span className="text-sm">Account Provider</span>
                <span className="text-sm">
                  {singleTeam?.payoutAccount?.accountProvider
                    ? singleTeam?.payoutAccount?.accountProvider
                    : 'N/A'}
                </span>
              </div>
              <div className="flex justify-between items-center  gap-y-1">
                <span className="text-sm">Account Name</span>
                <span className="text-sm">
                  {singleTeam?.payoutAccount?.accountName
                    ? singleTeam?.payoutAccount?.accountName
                    : 'N/A'}
                </span>
              </div>
            </div>

            {/* Meta Data */}
            <div className="mb-3">
              <h2 className="text-lg font-[Gilroy-Medium] font-medium">Meta Data</h2>
              <div className="flex justify-between items-center  gap-y-1">
                <span className="text-sm">Policy Completion Time</span>
                <span className="text-sm">
                  {singleTeam?.getPolicyCompletionTimeInMinutes()}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleTeamDetailsModal;
