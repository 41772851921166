interface ISuccessDialogProps {
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
    description: string;
    confirmBtnLabel?: string;
}

const SuccessConfirmation = ({ title, description, onConfirm, onCancel, confirmBtnLabel = "Continue" }: ISuccessDialogProps) => {
    return <div className="-mx-3 lg:-mx-6 -mt-[4.5rem] rounded-md">
        <div className=" rounded-sm flex flex-col">
            <div onClick={onCancel} className="flex justify-end pt-2 pr-2 bg-[#FBC02D] rounded-t-md">
                <svg className="w-5 h-5 lg:w-7 lg:h-7" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99984 1.66675C5.3915 1.66675 1.6665 5.39175 1.6665 10.0001C1.6665 14.6084 5.3915 18.3334 9.99984 18.3334C14.6082 18.3334 18.3332 14.6084 18.3332 10.0001C18.3332 5.39175 14.6082 1.66675 9.99984 1.66675ZM9.99984 16.6667C6.32484 16.6667 3.33317 13.6751 3.33317 10.0001C3.33317 6.32508 6.32484 3.33341 9.99984 3.33341C13.6748 3.33341 16.6665 6.32508 16.6665 10.0001C16.6665 13.6751 13.6748 16.6667 9.99984 16.6667ZM12.9915 5.83341L9.99984 8.82508L7.00817 5.83341L5.83317 7.00841L8.82484 10.0001L5.83317 12.9917L7.00817 14.1667L9.99984 11.1751L12.9915 14.1667L14.1665 12.9917L11.1748 10.0001L14.1665 7.00841L12.9915 5.83341Z" fill="#FFFCF5" />
                </svg>
            </div>
            <div className="bg-[#FBC02D] py-10">
                <div className="flex justify-center items-center">
                    <svg className="w-16 h-auto lg:w-20" viewBox="0 0 72 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.15">
                            <path d="M50.625 7.5L22.5 35.625L9.375 22.5L0 31.875L22.5 54.375L60 16.875L50.625 7.5Z" fill="black" />
                        </g>
                        <path d="M62.625 9.5L34.5 37.625L21.375 24.5L12 33.875L34.5 56.375L72 18.875L62.625 9.5Z" fill="white" />
                    </svg>
                </div>
            </div>
            <div className="bg-white p-10 px-6">
                <p className="text-center  font-semibold text-3xl text-black">{title}</p>
                <p className="text-center text-lg">{description}</p>
                <div className="mt-6 lg:mt-10 flex justify-center mx-14 mb-3">
                    <button onClick={onConfirm} className="bg-[#FBC02D] px-16 lg:px-24 font-medium text-lg font-robotoCondensed py-2 lg:py-4 text-[#002E2E] rounded-md">
                        {confirmBtnLabel}
                    </button>
                </div>
            </div>

        </div>
    </div>
};

export default SuccessConfirmation;
