import React, { useRef, useState } from "react";
import { StandaloneSearchBox, LoadScript, useJsApiLoader } from "@react-google-maps/api";
import clsx from "clsx";
import { libraries } from "../resources/constants";

export interface IPlacesInput {
    label?: string;
    description?: string | JSX.Element | Element | React.ReactElement;
    disabled?: boolean;
    prepend?: JSX.Element | React.ReactElement | string | number | any;
    append?: JSX.Element | React.ReactElement | string | number | any;
    type?: "text" | "number" | "email" | "tel" | "password";
    name?: string;
    placeholder?: string;
    value: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | null | any;
    onBlur?: (e: React.FocusEvent<any, Element>) => void | null | any;
    error?: string | undefined;
    required?: boolean;
    helperText?: string;
    autoComplete?: boolean;
    classes?: string;
    onLocationChange?: (coordinates: string) => void; // new prop 
    onAddressChange?: (addres: string) => void
}

const PlaceComponent = ({
    label,
    description,
    disabled = false,
    prepend,
    append,
    type = "text",
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    required = false,
    helperText,
    autoComplete = true,
    onLocationChange,
    classes,
    onAddressChange
}: IPlacesInput) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        // googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY ?? "",
        
        googleMapsApiKey: "AIzaSyBO96Ff6UfzfdfT2YisqGY2XdVcVmqGI7M",
        libraries: libraries,
        region: "gh"
    });
    const inputRef = useRef<google.maps.places.SearchBox | null>(null);

    const [coordinates, setCoordinates] = useState("")

    const handlePlaceChanged = (): void => {
        const place = inputRef.current?.getPlaces();

        if (place && place.length > 0) {
            const { formatted_address, geometry, name } = place[0];
            setCoordinates(`${geometry?.location?.lat()} ${geometry?.location?.lng()}`)

            const locality = getLocalityFromPlaceLocation(place[0].address_components);
            const route = getRouteFromPlaceLocation(place[0].address_components);

            onAddressChange?.(`${name ?? ""}${locality ? `, ${locality}` : route ? `, ${route}` : ""}`);

            if (onLocationChange) {
                onLocationChange(`${geometry?.location?.lat()} ${geometry?.location?.lng()}`); // call the onLocationChange prop with the new coordinates
            }
        }
    };

    const getLocalityFromPlaceLocation = (addressComponents: any[] | undefined) => {

        const locality = addressComponents?.find((address: any) => address.types?.includes("locality"));

        if (locality) {
            return locality.long_name;
        }

        return "";
    }

    const getRouteFromPlaceLocation = (addressComponents: any[] | undefined) => {

        const route = addressComponents?.find((address: any) => address.types?.includes("route"));

        if (route) {
            return route.long_name;
        }

        return "";
    }

    const onLoad = (ref: google.maps.places.SearchBox): void => {
        if (ref) {
            inputRef.current = ref;
        }
    };
    return (
        <>
            {isLoaded && (
                <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={handlePlaceChanged}>
                    <div
                        className={clsx("w-full", { ["animate-wiggle"]: error && required })}
                    >
                        {/* Label */}
                        {label && (
                            <label
                                htmlFor={name}
                                className={clsx(
                                    "block text-sm md:text-sm font-medium text-gray-700",
                                    { ["!text-danger-main"]: error && required }
                                )}
                            >
                                {label}
                                <span className="text-danger-main">{required && "*"}</span>
                                {description && (
                                    <div
                                        className={clsx("text-xs text-gray-400 italic", {
                                            ["text-danger-border"]: error && required,
                                        })}
                                    >
                                        <>{description}</>
                                    </div>
                                )}
                            </label>
                        )}

                        <div className="mt-1 relative">
                            <div className="relative w-full">
                                <input
                                    type={type}
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    className={clsx(
                                        "relative w-full border border-gray-300 shadow-sm py-2 text-left cursor-default",
                                        "focus:outline-none focus:ring-1 focus:ring-primary-main focus:border-primary-border text-xs md:text-sm",
                                        "flex items-center",
                                        classes,
                                        prepend ? "pl-12" : "pl-3",
                                        append ? "pr-10" : "pr-3",
                                        disabled ? "bg-gray-300 cursor-not-allowed" : "bg-white",
                                        {
                                            ["bg-red-200 border-danger-main focus:ring-danger-main focus:border-danger-border"]:
                                                error && required,
                                        },
                                        error && "border-danger-main"
                                    )}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                    value={value}
                                    autoComplete={String(autoComplete)}
                                />

                                {prepend && (
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none w-15 h-full">
                                        {prepend}
                                    </div>
                                )}

                                {append && (
                                    <div className="absolute inset-y-0 right-0 pr-0 flex items-center">
                                        {append}
                                    </div>
                                )}

                            </div>
                        </div>

                        {/* Helper Text */}
                        <div
                            className={clsx("text-xs text-gray-400", {
                                ["!text-danger-main"]: error,
                            })}
                        >
                            {helperText}
                        </div>
                    </div>
                </StandaloneSearchBox>
            )}
        </>
    );
};

export default PlaceComponent;
