import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

interface IIconStatus {
  classes?: string;
  status?: boolean;
  onclick?: () => void;
  cursorPointer?: boolean;
}
const IconStatus = ({ classes, status, onclick, cursorPointer = false }: IIconStatus) => {
  return (
    <div className={clsx('', classes)}>
      {status ? (
        <CheckCircleIcon className=" h-5 w-5  text-green-600 " />
      ) : (
        <div className="" onClick={onclick}>
          <XMarkIcon
            className={clsx(
              'text-white p-[2px] h-4 w-4 rounded-full bg-red-700 cursor-default',
              cursorPointer ? 'cursor-pointer':""
            )}
          />
        </div>
      )}
    </div>
  );
};

export default IconStatus;
