import { useState } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Button from '../../../../components/form-control/Button';
import { CheckIcon, WalletIcon } from '@heroicons/react/24/solid';
import { LuFuel, LuWallet } from 'react-icons/lu';
import { FaCheck } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';

interface ICloseFuelSaleProps {
  data: any;
  onClose: () => void;
  onConfirm: () => void;
}

const CloseFuelSaleModal = (props: ICloseFuelSaleProps): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const date = new Date().toLocaleDateString();
  const currentTime = new Date().toLocaleTimeString();
  return (
    <div className="py-8 md:px-6 w-full max-w-md mx-auto bg-white  rounded-lg relative">
      <button onClick={props.onClose} className="absolute top-4 right-4 text-gray-600">
        &#x2715;
      </button>

      <h3 className="text-center text-2xl font-bold mb-2">Confirm Close Sales</h3>

      <p className="text-center text-gray-600 mb-6">
        Are you sure you want to close sales for {date} {currentTime}? <br />
        This action cannot be undone.
      </p>

      <div className="border border-gray-200 bg-gray-50 rounded-lg mb-6 shadow-lg">
        <div className="p-2">
          <div className="text-lg flex items-center gap-5">
            <LuWallet className="size-5" />
            <p className=" font-semibold mb-2">Today&apos; Sales</p>
          </div>
          <div className="px-10">
            <p className=" font-bold text-2xl text-black mb-2">
              GHS {props?.data?.todaySales ?? 0}
            </p>
          </div>

          <div className=" flex items-center gap-5 mt-2">
            <LuFuel className="size-4 text-gray-500" />
            <p className="pl-1">Fuel: {props?.data?.todayConsumption ?? 0} L</p>
          </div>
        </div>

        <div className=" p-2 rounded-lg space-y-5">
          <div className="grid grid-cols-2 items-center justify-between p-2 bg-gray-100 shadow-md rounded">
            <div className="flex items-center space-x-2">
              <div className="flex items-center size-fit p-2 bg-white rounded-full ">
                <FaCheck className="text-green-500 size-4" />
              </div>
              <span className="font-semibold text-blue-950">Mobile Money</span>
            </div>

            <div className="justify-center">
              <p className=" font-semibold">GHS {props?.data?.unclosedMomo ?? 0}</p>
              {/* <p className="text-gray-500 text-sm">Litres: 90 L</p> */}
            </div>
          </div>

          <div className="grid grid-cols-2 items-center justify-between p-2 bg-gray-100 shadow-md rounded">
            <div className="flex items-center space-x-2">
              <div className="flex items-center size-fit p-2 bg-white rounded-full ">
                <GoDotFill className="text-yellow-500" size={24} />
              </div>

              <span className="font-semibold  text-blue-950">Cash</span>
            </div>

            <div className="justify-center">
              <p className="font-semibold">GHS {props?.data?.unclosedCash ?? 0}</p>
              {/* <p className="text-gray-500 text-sm">Litres: 2000 L</p> */}
            </div>
          </div>

          <div className="grid grid-cols-2 items-center justify-between p-2 bg-gray-100 shadow-md rounded">
            <div className="flex items-center space-x-2">
              <div className="flex items-center size-fit p-2 bg-white rounded-full ">
                <GoDotFill className="text-yellow-500" size={24} />
              </div>

              <span className="font-semibold  text-blue-950">Pending</span>
            </div>
            <div className="justify-center">
              <p className="font-semibold">GHS {props?.data?.unclosed ?? 0}</p>
              {/* <p className="text-gray-500 text-sm">Litres: 400 L</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-4 mt-6">
        <Button
          label="No"
          onClick={props.onClose}
          curved
          bgColor="primary"
          className="w-full px-4 py-2 border text-semibold"
        />
        <Button
          label="Yes"
          onClick={() => {
            setSubmitting(true);
            props.onConfirm();
          }}
          curved
          className="w-full px-4 py-2 bg-yellow-500 text-semibold hover:bg-yellow-600 transition duration-300"
          disabled={submitting}
          loading={submitting}
        />
      </div>
    </div>
  );
};

export default CloseFuelSaleModal;
