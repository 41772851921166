import { AxiosError } from 'axios';
import { usePaystackPayment } from 'react-paystack';
import { PaystackProps } from 'react-paystack/dist/types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  InitializeHubtelDirectCharge,
  InitializeHubtelPreapproval,
  generatePolicyPaymentObjects,
  initializeHubtelPayment,
  initializeItConsortiumPayment,
  regeneratePolicyPaymentObjects,
  sendPayNowNotification
} from '../api/requests';
import { HomePolicy } from '../models/homePolicy/HomePolicy';
import { Policy } from '../models/policy/Policy';
import {
  HUBTEL_RESOURCE_PAYMENT_LINKS,
  MOBILE_MONEY_NETWORKS,
  PAYMENT_OPTIONS,
  PAYMENT_PROCESSORS,
  PAYMENT_RESOURCE_TYPE
} from '../resources/enums';
import {
  ICustomerPayments,
  IInitiateHubtelPaymentDTO,
  IPolicyPaymentData,
  IRequestErrorResponse,
  IVerifyMobilePaymentDTO
} from '../resources/interfaces';
import { getPaymentDataFromPolicy } from '../utils/miscFunctions';
import ChoosePaymentModal from '../views/admin/payments/ChoosePaymentModal';
import MakePayment from '../views/admin/payments/MakePayment';
import VerifyPaymentOTP from '../views/admin/payments/VerifyPaymentOTP';
import useModal from './modalHook';
import useUserAccount from './userAccountHook';
import { useEffect, useState } from 'react';
import PhoneVerificationModal from '../views/admin/policies/PhoneVerificationModal';
import HubtelPreapprovalModal from '../views/admin/payments/HubtelPreapprovalModal';
import ProvideMomoDetails from '../views/admin/payments/ProvideMomoDetails';
import VerifyHubtelPreapprovalOTP from '../views/admin/payments/VerifyHubtelPreapprovalOTP';
import RegeneratePaymentObjectsModal from '../views/admin/policies/RegeneratePaymentObjectsModal';

interface usePaymentProps {
  paystackConfig: PaystackProps;
  policy: Policy | HomePolicy | undefined;
}

function usePayment(props: usePaymentProps) {
  const navigate = useNavigate();
  const {} = useUserAccount();

  const { updateModalStates, ModalComponent, closeModal } = useModal();

  const PaymentModalComponent = ModalComponent;

  const [paymentCompleted, setPaymentCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const initializePayment = usePaystackPayment(props.paystackConfig);

  const choosePaymentService = () => {
    if (props.policy) {
      updateModalStates({
        show: true,
        children: (
          <ChoosePaymentModal
            policy={props.policy}
            onProceed={(paymentData?: IPolicyPaymentData, processor?: PAYMENT_PROCESSORS) => {
              if (processor === PAYMENT_PROCESSORS.HUBTEL) {
                if (paymentData) {
                  initializeHubtel({ paymentData: paymentData });
                }
              }

              if (processor === PAYMENT_PROCESSORS.IT_CONSORTIUM) {
                closeModal();
                showPhoneVerificationModal(paymentData?.phone ?? '');
              }
            }}
          />
        ),
        size: 'md'
      });
    }
  };

  const choosePaymentMethod = (
    phone: string,
    policyData: Omit<IPolicyPaymentData, 'accountNumber' | 'network'>,
    showMomoForm?: boolean,
    resourceType?: PAYMENT_RESOURCE_TYPE
  ) => {
    if (phone) {
      updateModalStates({
        show: true,
        size: 'md'
      });
    }
  };

  const showPhoneVerificationModal = (phone: string) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <PhoneVerificationModal
          policy={props.policy}
          close={closeModal}
          onProceed={() => {
            initializeItConsortiumPayment(phone, props.policy?.ref ?? '').then((response: any) => {
              toast.success(
                response?.message ?? 'Payment initiated successfully awaiting user confirmation.',
                {
                  autoClose: 3000
                }
              );
            });
            closeModal();
          }}
          phoneNumber={phone}
          isCustomer={false}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      static: true
    });
  };

  const initializeHubtel = ({
    paymentData,
    resourceType = PAYMENT_RESOURCE_TYPE.AUTO_POLICY
  }: {
    paymentData: IPolicyPaymentData;
    resourceType?: PAYMENT_RESOURCE_TYPE;
  }) => {
    let url = '';
    if (resourceType === PAYMENT_RESOURCE_TYPE.AUTO_POLICY) {
      url = HUBTEL_RESOURCE_PAYMENT_LINKS.AUTO_POLICY;
    }

    if (resourceType === PAYMENT_RESOURCE_TYPE.HAILING_SERVICES) {
      url = HUBTEL_RESOURCE_PAYMENT_LINKS.HAILING_SERVICES;
    }

    if (resourceType === PAYMENT_RESOURCE_TYPE.HOME_POLICY) {
      url = HUBTEL_RESOURCE_PAYMENT_LINKS.HOME_POLICY;
    }

    const payload: IInitiateHubtelPaymentDTO = {
      initialAmount: paymentData?.initialAmount,
      accountNumber: paymentData.accountNumber ?? '',
      network: paymentData.network ?? '',
      policyNumber: paymentData.policyNumber
    };

    initializeHubtelPayment(payload).then((response: any) => {
      const payload: IVerifyMobilePaymentDTO = {
        otpCode: response?.data?.otpPrefix,
        policyNumber: paymentData.policyNumber,
        recurringInvoiceId: response?.data?.recurringInvoiceId,
        requestId: response?.data?.requestId
      };

      updateModalStates({
        show: true,
        children: (
          <VerifyPaymentOTP
            phone={paymentData.phone}
            data={payload}
            onProceed={() => {
              onPaymentSuccessful('hubtel', PAYMENT_OPTIONS.MOBILE_MONEY);
            }}
          />
        )
      });
    });
  };

  const handlePayment = async (
    paymentMethod: PAYMENT_OPTIONS,
    paymentData: IPolicyPaymentData,
    resourceType = PAYMENT_RESOURCE_TYPE.AUTO_POLICY
  ) => {
    try {
      if (paymentData.discountCode) {
        // await applyDiscount(paymentData.policyNumber, paymentData.discountCode);
      }

      switch (paymentMethod) {
        case PAYMENT_OPTIONS.CARD:
          initializePayment(onPaymentSuccessful, closeModal);
          break;

        case PAYMENT_OPTIONS.BANK:
          showBankTransfer();
          break;

        case PAYMENT_OPTIONS.MOBILE_MONEY:
          if (paymentData) {
            if (paymentData?.isFullPayment) {
              initializePayment(onPaymentSuccessful, closeModal);
            } else {
              initializeHubtel({
                paymentData,
                resourceType
              });
            }
          }
          break;
      }
    } catch (error: AxiosError | IRequestErrorResponse | any) {
      toast.error(error?.description, {
        autoClose: 5000
      });
    }
  };

  const onPaymentSuccessful = (paymentService = 'paystack', type = PAYMENT_OPTIONS.CARD) => {
    if (paymentService === 'paystack') {
      toast.success('Payment completed successfully', {
        autoClose: 3000
      });
    } else {
      toast.success(
        'Payment initiated successfully. Ask customer to enter Momo code if prompted to complete payment.',
        {
          autoClose: 5000
        }
      );
    }
    setPaymentCompleted(true);
    // updateModalStates({
    //     show: true,
    //     // children: <PaymentComplete type={paymentService} />,
    //     static: true,
    //     showCloseButton: false,
    //     size: "4xl"
    // })
  };

  function showBankTransfer() {
    updateModalStates({
      show: true,
      // children: <BankTransfer
      //     onPayLater={() => payLater()}
      //     policy={props.policy} />,
      static: true,
      showCloseButton: true,
      size: '4xl'
    });
  }

  const choosePaymentOption = () => {
    if (props.policy) {
      const payment: ICustomerPayments | undefined = props.policy?.getFirstUnpaidPayment();
      updateModalStates({
        show: true,
        children: (
          <MakePayment
            policy={props.policy}
            onProceed={(paymentOption: 'send-email' | 'make-payment') => {
              closeModal();
              if (paymentOption === 'make-payment') {
                if (props.policy) {
                  const paymentData: IPolicyPaymentData = getPaymentDataFromPolicy(props.policy);
                  handlePayment(PAYMENT_OPTIONS.CARD, paymentData);
                }
              }

              if (payment && paymentOption === 'send-email') {
                sendPaymentNotification(payment);
              }
            }}
          />
        )
      });
    }
  };

  const sendPaymentNotification = (payment: ICustomerPayments) => {
    // const payment: ICustomerPayments | undefined = props.policy?.getFirstUnpaidPayment();
    if (payment) {
      sendPayNowNotification(payment?.id)
        .then((response: any) => {
          toast.success('Payment link notification sent successfully');
        })
        .catch((error: any) => {
          toast.error('Failed to send payment link notification.');
        });
    } else {
      toast.error('Failed to send payment link notification. Try again later.', {
        autoClose: 3000
      });
    }
  };

  const InitiateHubtelDirectCharge = (payment: ICustomerPayments) => {
    // const payment: ICustomerPayments | undefined = props.policy?.getFirstUnpaidPayment();
    if (payment) {
      InitializeHubtelDirectCharge(payment?.id)
        .then((response: any) => {
          toast.success(response?.message ?? 'Hubtel direct charge initiated successfully', {
            autoClose: 3000
          });
        })
        .catch((error: any) => {
          toast.error(error?.description ?? 'Failed to initiate Hubtel direct charge.', {
            autoClose: 3000
          });
        });
    } else {
      toast.error('Failed to initiate Hubtel direct charge. Try again later.', {
        autoClose: 3000
      });
    }
  };

  const showHubtelPreapprovalModal = () => {
    const policy: any = props.policy;
    if (policy) {
      updateModalStates({
        show: true,
        children: (
          <HubtelPreapprovalModal
            policy={policy}
            onProceed={(data: { phone: string; network: MOBILE_MONEY_NETWORKS }) => {
              if (policy) {
                const paymentData = getPaymentDataFromPolicy(policy, data.phone, data.network);
                InitializeHubtelPreapproval(
                  paymentData.network,
                  paymentData.phone,
                  paymentData.policyNumber
                )
                  .then((response: any) => {
                    toast.success(response?.message ?? 'Request received! Pending preapproval', {
                      autoClose: 3000
                    });
                    closeModal();

                    if (response && response?.data?.verificationType === 'OTP') {
                      updateModalStates({
                        show: true,
                        children: (
                          <VerifyHubtelPreapprovalOTP
                            phone={paymentData.phone}
                            policyNumber={paymentData.policyNumber}
                            otpPrefix={response?.data?.otpPrefix}
                            onProceed={() => {
                              onPaymentSuccessful('hubtel', PAYMENT_OPTIONS.MOBILE_MONEY);
                              closeModal();
                            }}
                          />
                        )
                      });
                    }
                  })
                  .catch((error: any) => {
                    toast.error(error?.description ?? 'Failed to initiate Hubtel Preapproval.', {
                      autoClose: 3000
                    });
                  });
              } else {
                toast.error('Failed to initiate Hubtel preapproval. Try again later.', {
                  autoClose: 3000
                });
              }
            }}
          />
        ),
        size: 'md'
      });
    }
  };

  const regeneratePaymentObjects = (paymentDate: string) => {
    if (props.policy?.ref) {
      regeneratePolicyPaymentObjects(props.policy?.ref, paymentDate)
        .then((response) => {
          toast.success(response?.message ?? 'Payment object generated successfully.');
        })
        .catch((error: any) => {
          toast.error(
            error.desrcription ?? 'Failed to generate payment objects. Please try again later.'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const generatePaymentObjects = (paymentDate: string) => {
    if (props.policy?.ref) {
      generatePolicyPaymentObjects(props.policy?.ref, paymentDate)
        .then((response) => {
          toast.success(response?.message ?? 'Payment object generated successfully.');
        })
        .catch((error: any) => {
          toast.error(
            error.desrcription ?? 'Failed to generate payment objects. Please try again later.'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const showGeneratePaymentObjectsModal = (action: string, onProceed: () => void) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <RegeneratePaymentObjectsModal
          policy={props.policy}
          onClose={closeModal}
          onProceed={() => {
            onProceed();
            closeModal();
          }}
          action={action}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      static: true
    });
  };

  return {
    handlePayment,
    PaymentModalComponent,
    choosePaymentMethod,
    initializeHubtel,
    choosePaymentService,
    choosePaymentOption,
    sendPaymentNotification,
    InitiateHubtelDirectCharge,
    showHubtelPreapprovalModal,
    paymentCompleted,
    showGeneratePaymentObjectsModal,
    generatePaymentObjects,
    regeneratePaymentObjects
  };
}

export default usePayment;
