import { POLICY_DOCUMENT_TYPES, UPLOADABLE_DOCUMENT_TYPES } from '../../resources/enums';
import { IVehicleType, IVehicleUsages } from '../../resources/interfaces';
import { getVehicleUsageFromKey } from '../../utils/miscFunctions';
import { Driver } from '../driver/Driver';
import SupportedVehicle from '../SupportedVehicle';
import { IVehicle } from './IVehicle';

export class Vehicle implements SupportedVehicle {
  ref: string;
  id: string;
  verified: boolean;
  vehicleAltered: boolean | undefined;
  alterationDetails: string;
  goodRepairState: boolean | undefined;
  repairState: string;
  color: string;
  city: string;
  region: string | undefined;
  driver: Driver;
  make: string | undefined;
  model: string;
  type: IVehicleType;
  yearIssued: number;
  maxSeat: number;
  miniSeat: number;
  avatar: string | undefined;
  cc: number | undefined;
  ownerName: string | undefined;
  ownerAddress: string | undefined;
  ownerPhoneNumber: string;
  usage: IVehicleUsages | null;
  registrationYear: number | undefined;
  insuredValue: number | undefined;
  chassisNumber: string;
  registrationNumber: string | undefined;
  manufacturedYear: number | undefined;
  images: IVehicleImage[] | undefined;
  hasInterestedParties: boolean | undefined;
  interestedParties: string;
  hasInstalledAccessories: boolean | undefined;
  installedAccessoriesDetails?: string;
  installedAccessoriesValue: number;
  natureOfInterest?: string;

  constructor(Imodel: IVehicle) {
    this.ref = Imodel.ref;
    this.id = Imodel.id;
    this.vehicleAltered = Imodel.vehicleAltered ?? undefined;
    this.alterationDetails = Imodel.alterationDetails ?? '';
    this.goodRepairState = Imodel.vehicleInGoodRepairState ?? undefined;
    this.repairState = Imodel.repairState ?? '';
    this.color = Imodel.color ?? '';
    this.city = Imodel.city ?? '';
    this.region = Imodel.region ?? undefined;
    this.driver = new Driver({
      fullName: Imodel.driverName,
      phoneNumber: Imodel.driverPhoneNumber,
      license: Imodel.driverLicense,
      hasDisease: Imodel.driverHasComplications ?? undefined,
      diseaseOrComplications: Imodel.driverComplications
    });
    this.make = Imodel.maker ?? '';
    this.model = Imodel.model ?? '';
    this.type = Imodel.type ?? { name: '', description: '' };
    this.yearIssued = Imodel.yearIssued ?? 0;
    this.maxSeat = Imodel.maxSeat ?? 1;
    this.miniSeat = Imodel.miniSeat ?? 1;
    this.cc = Imodel.cc;
    this.ownerName = Imodel.ownerName;
    this.ownerAddress = Imodel.ownerAddress;
    this.ownerPhoneNumber = Imodel.ownerPhoneNumber ?? '';
    this.usage = getVehicleUsageFromKey(Imodel.vehicleUsage ?? '');
    this.registrationYear = Imodel.registrationYear;
    this.manufacturedYear = Imodel.yearIssued ?? 0;
    this.insuredValue = Imodel.issuedValue;
    this.chassisNumber = Imodel.chassisNumber;
    this.registrationNumber = Imodel.registrationNumber;
    this.images = Imodel.vehicleDocuments;
    this.verified = Imodel.verified;
    this.hasInterestedParties = Imodel.hasInterestedParties ?? undefined;
    this.interestedParties = Imodel.interestedParties ?? '';
    this.hasInstalledAccessories = Imodel.hasInstalledAccessories ?? undefined;
    this.installedAccessoriesDetails = Imodel.installedAccessoriesDetails;
    this.installedAccessoriesValue = Imodel.installedAccessoriesValue ?? 0;
    this.natureOfInterest = Imodel.natureOfInterest ?? undefined;
  }

  getDisplayName(): string {
    return `${this.model} - ${this.type.name}`;
  }

  getDetailedName(): string {
    return `${this.make} ${this.model} - ${this.type.name}`;
  }

  getFrontPhoto(): IVehicleImage | undefined {
    return this.images?.find(
      (image: IVehicleImage) => image.docType === UPLOADABLE_DOCUMENT_TYPES.VEHICLE_PHOTO_FRONT
    );
  }

  getBackPhoto(): IVehicleImage | undefined {
    return this.images?.find(
      (image: IVehicleImage) => image.docType === UPLOADABLE_DOCUMENT_TYPES.VEHICLE_PHOTO_BACK
    );
  }

  getRightPhoto(): IVehicleImage | undefined {
    return this.images?.find(
      (image: IVehicleImage) => image.docType === UPLOADABLE_DOCUMENT_TYPES.VEHICLE_PHOTO_RIGHT
    );
  }

  getLeftPhoto(): IVehicleImage | undefined {
    return this.images?.find(
      (image: IVehicleImage) => image.docType === UPLOADABLE_DOCUMENT_TYPES.VEHICLE_PHOTO_LEFT
    );
  }
}

export interface IVehicleImage {
  docType: UPLOADABLE_DOCUMENT_TYPES;
  docURL: string;
  id: number;
}
