import { useMemo, useState } from 'react';
import GTable, { ITablePaginationData } from '../../../../components/elements/GTable/GTable';
import Loader from '../../../../components/Loader';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Page from '../../../../components/PageWrapper';
import useRoute from '../../../../hooks/routesHook';
import {
  getFormattedPaymentChannel,
  getPhoneNumberFromShortCodeFormat,
  getStatusColor
} from '../../../../utils/miscFunctions';
import useUserAccount from '../../../../hooks/userAccountHook';
import TableFilterForm from '../../../../components/TableFilter/TableFilterForm';
import TableFilter from '../../../../components/TableFilter';
import { ITableFilterItem } from '../../../../resources/interfaces';
import { IUser } from '../../../../models/User';
import useModal from '../../../../hooks/modalHook';
import AddFuelSale from './AddFuelSale';
import { ArrowRightIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import Button from '../../../../components/form-control/Button';
import {
  closeFuelVendorDailySales,
  getAllFuelPurchases,
  getPartnerFuelMetrics,
  getPartnerTeams
} from '../../../../api/requests';
import { FuelSalesStatus } from '../../../../components/statuses';
import CloseFuelSaleModal from './CloseSaleModal';
import { toast } from 'react-toastify';
import useDownload from '../../../../hooks/downloadHook';
import PopOnMe from '../../../../components/elements/poponme';
import SelectDropdown, {
  ISelectDropdownItem
} from '../../../../components/form-control/SelectDropdown';
import { LuFuel } from 'react-icons/lu';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MdPhoneAndroid } from 'react-icons/md';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { TiArrowLeftThick, TiArrowRightThick } from 'react-icons/ti';
import { RiCalendarCloseFill } from 'react-icons/ri';

// const fuelSales: any = [
//   {
//     id: 105487,
//     quantity: 17.86,
//     unit: null,
//     pump: '12345676543',
//     amount: 250,
//     paid: true,
//     transactionNumber: null,
//     pricePerLitre: 14,
//     vehicleRegistration: 'GT 23 -23',
//     status: 'PAID',
//     processor: null,
//     paymentMode: 'CASH',
//     paymentMethod: 'CASH',
//     processorReference: null,
//     fuelSale: null,
//     rebate: {
//       id: 105490,
//       purchaseId: 105487,
//       customer: {
//         email: 'TIMMONSNANCY4242@GMAIL.COM',
//         emailVerified: false,
//         phone: '233555273372',
//         phoneVerified: true,
//         otherPhone: null,
//         otherPhoneVerified: false,
//         firstName: 'FRANCIS',
//         lastName: 'ADJEI',
//         otherNames: null,
//         gender: 'MALE',
//         image: null,
//         dateOfBirth: '2005-11-09T00:00:00.000+00:00',
//         id: 2270,
//         country: 'Ghana',
//         state: '',
//         city: '',
//         address: 'Kpong Lorry Park, Kpong',
//         socialPlatform: '',
//         socialPlatformIdentifier: '',
//         employerName: 'Abu Sadiq',
//         employerAddress: 'brolly@gmail.com',
//         employerPhone: '233244262624',
//         digitalAddress: 'GT-168-1654',
//         policyList: null,
//         customerDocuments: null,
//         idType: 'NATIONAL_ID',
//         educationLevel: null,
//         occupation: 'Self Employed',
//         idNumber: 'GHA-728433801-0',
//         idVerified: false,
//         idExpiry: '2023-11-22',
//         position: '',
//         monthlyIncomeBand: null,
//         account: null,
//         tasks: [],
//         activityStreams: []
//       },
//       amount: 2.5,
//       createdOn: '2024-07-14T18:36:55.048437',
//       settledOn: null,
//       settled: false
//     },
//     customer: {
//       email: 'TIMMONSNANCY4242@GMAIL.COM',
//       emailVerified: false,
//       phone: '233555273372',
//       phoneVerified: true,
//       otherPhone: null,
//       otherPhoneVerified: false,
//       firstName: 'FRANCIS',
//       lastName: 'ADJEI',
//       otherNames: null,
//       gender: 'MALE',
//       image: null,
//       dateOfBirth: '2005-11-09T00:00:00.000+00:00',
//       id: 2270,
//       country: 'Ghana',
//       state: '',
//       city: '',
//       address: 'Kpong Lorry Park, Kpong',
//       socialPlatform: '',
//       socialPlatformIdentifier: '',
//       employerName: 'Abu Sadiq',
//       employerAddress: 'brolly@gmail.com',
//       employerPhone: '233244262624',
//       digitalAddress: 'GT-168-1654',
//       policyList: null,
//       customerDocuments: null,
//       idType: 'NATIONAL_ID',
//       educationLevel: null,
//       occupation: 'Self Employed',
//       idNumber: 'GHA-728433801-0',
//       idVerified: false,
//       idExpiry: '2023-11-22',
//       position: '',
//       monthlyIncomeBand: null,
//       account: null,
//       tasks: [],
//       activityStreams: []
//     },
//     salesPerson: {
//       email: 'francisadjei801+vendor@gmail.com',
//       emailVerified: false,
//       phone: '233577447888',
//       phoneVerified: false,
//       otherPhone: null,
//       otherPhoneVerified: false,
//       firstName: 'Shell',
//       lastName: 'Vendor',
//       otherNames: null,
//       gender: 'MALE',
//       image: null,
//       dateOfBirth: null,
//       id: 99591,
//       country: 'Ghana',
//       state: null,
//       city: null,
//       accessRight: 'FUEL_VENDOR',
//       jobTitle: 'Station Manager',
//       teamName: null,
//       createdOn: '2024-06-24T15:26:05.627+00:00',
//       createdBy: null,
//       updatedOn: null,
//       account: {
//         referenceId: '96870afb-c95c-4057-9d58-512458589fcc',
//         accountIdentifier: 'francisadjei801+vendor@gmail.com',
//         provider: 'PASSWORD',
//         lastSeen: '2024-07-14T18:35:02.913+00:00',
//         verified: true,
//         accessRights: 'FUEL_VENDOR',
//         country: {
//           id: 1,
//           name: 'Ghana',
//           code: '+233',
//           iso: 'GH',
//           language: 'en',
//           currencySymbol: 'GH₵',
//           currencyIso: 'GHS',
//           flag: 'https://img.icons8.com/color/48/000000/ghana.png'
//         },
//         userReference: '99591',
//         accountApproval: null,
//         documents: null,
//         referralCode: 'BEX020R',
//         activated: true,
//         profileImage: null,
//         twoFAEnabled: false
//       },
//       updatedBy: null,
//       role: {
//         id: 99575,
//         name: 'Fuel Partner Vendor',
//         description: 'Fuel partner vendor',
//         type: 'EXTERNAL',
//         accessRight: 'FUEL_VENDOR',
//         refCodePrefix: 'BEX904B',
//         scopes: [],
//         active: null,
//         createdBy: null,
//         createdOn: '2024-06-24T15:22:43.876248',
//         updatedOn: '2024-06-24T15:22:43.876249'
//       },
//       team: {
//         id: 99087,
//         name: 'Airport',
//         type: 'FUEL_PROVIDER',
//         partner: {
//           id: 95416,
//           name: 'Shell',
//           logo: 'https://brolly-user-docs-dev.s3.eu-west-3.amazonaws.com/1716995361817_95416_Shell_LOGO_.jpg',
//           active: true,
//           type: 'FUEL_PROVIDER',
//           representatives: null,
//           teams: null,
//           createdOn: '2024-05-29T15:08:26.734708',
//           updatedOn: '2024-05-29T15:09:22.011838'
//         },
//         createdOn: '2024-06-19',
//         updatedOn: null,
//         rateTypeTP: 'PERCENTAGE',
//         commissionRateTP: null,
//         rateTypeCP: 'PERCENTAGE',
//         commissionRateCP: null,
//         email: null,
//         logo: null,
//         region: null,
//         city: null,
//         banner: null,
//         bannerMobile: null,
//         address: null,
//         corporatePhone: null,
//         mobilePhone: null,
//         themeColour: null,
//         fontColour: null,
//         setupCompleted: false,
//         security: {
//           id: 99086,
//           enabled: false,
//           mode: 'PASSWORD',
//           createdOn: '2024-06-19T10:00:54.206784',
//           updatedOn: '2024-06-19T10:00:54.206786'
//         },
//         slackWorkspace: null,
//         additionalInformation: null
//       },
//       slackId: null,
//       slackWorkspace: null
//     },
//     vendor: {
//       id: 99087,
//       name: 'Airport',
//       type: 'FUEL_PROVIDER',
//       partner: {
//         id: 95416,
//         name: 'Shell',
//         logo: 'https://brolly-user-docs-dev.s3.eu-west-3.amazonaws.com/1716995361817_95416_Shell_LOGO_.jpg',
//         active: true,
//         type: 'FUEL_PROVIDER',
//         representatives: null,
//         teams: null,
//         createdOn: '2024-05-29T15:08:26.734708',
//         updatedOn: '2024-05-29T15:09:22.011838'
//       },
//       createdOn: '2024-06-19',
//       updatedOn: null,
//       rateTypeTP: 'PERCENTAGE',
//       commissionRateTP: null,
//       rateTypeCP: 'PERCENTAGE',
//       commissionRateCP: null,
//       email: null,
//       logo: null,
//       region: null,
//       city: null,
//       banner: null,
//       bannerMobile: null,
//       address: null,
//       corporatePhone: null,
//       mobilePhone: null,
//       themeColour: null,
//       fontColour: null,
//       setupCompleted: false,
//       security: {
//         id: 99086,
//         enabled: false,
//         mode: 'PASSWORD',
//         createdOn: '2024-06-19T10:00:54.206784',
//         updatedOn: '2024-06-19T10:00:54.206786'
//       },
//       slackWorkspace: null,
//       additionalInformation: null
//     },
//     purchaseDate: '2024-07-14T18:36:54.969014'
//   }
// ];

const PartnerFuelSales = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { showExportFuelSalesModal, DownloadHookModal } = useDownload();
  const { user } = useUserAccount();
  const { getDashboardBase } = useRoute();
  const [searchString, setSearchString] = useState<string>();
  const [showPerPage, setShowPerPage] = useState<number>(100);
  const [fuelSales, setFuelSales] = useState<any>([]);
  const [selectedStation, setSelectedStation] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get('page') ? Number(searchParams.get('page')) : 0
  );
  const [salesPaginationData, setSalesPaginationData] = useState<ITablePaginationData>({
    total: 0,
    per_page: 0,
    totalPages: 0,
    initialPage: currentPage ? currentPage : 0,
    from: 0,
    to: 0
  });
  const [tableFilters, setTableFilters] = useState<ITableFilterItem[]>(getDefaultFilters());
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const { updateModalStates, ModalComponent, closeModal } = useModal();
  const [partnerFuelMetrics, setPartnerFuelMetrics] = useState<any>();
  const [partnerTeams, setPartnerTeams] = useState<any>([]);
  const queryClient = useQueryClient();
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const handleIconClick = () => {
    setIsButtonVisible(!isButtonVisible);
  };

  function getDefaultFilters() {
    const filters: ITableFilterItem[] = [
      {
        name: 'create_date',
        value: '',
        type: 'dateRange',
        placeholder: 'Select start date',
        label: 'Date Range'
      }
    ];

    return filters;
  }

  const partnerId = user?.team?.partner?.id;
  const _to = tableFilters?.find((filter: ITableFilterItem) => filter?.name === 'create_date')
    ?.value?.to;
  const _from = tableFilters?.find((filter: ITableFilterItem) => filter?.name === 'create_date')
    ?.value?.from;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: String(page) });
  };

  const getAllPartnerTeams = async () => {
    try {
      if (partnerId && !user.isFuelVendor()) {
        const response = await getPartnerTeams(partnerId);
        setPartnerTeams(response);
      }
    } catch (error: any) {}
  };

  const getFuelDashboardMetrics = async () => {
    try {
      const response = await getPartnerFuelMetrics(_from, _to, selectedStation ?? '');
      setPartnerFuelMetrics(response);
    } catch (error) {}
  };

  const fetchFuelSales = async () => {
    const page = currentPage;

    try {
      const response = await getAllFuelPurchases(page, showPerPage, _from, _to);
      const _sales = response?.content || response;
      setFuelSales(_sales);
      const from = response.pageable?.offset + 1;
      const to =
        from + response.size > response.totalElements
          ? response.totalElements
          : from + response.size - 1;
      setSalesPaginationData({
        ...salesPaginationData,
        ...{
          per_page: response.size,
          total: response.totalElements,
          totalPages: response.totalPages,
          from: from,
          to: to
        }
      });
    } catch (error) {}
  };

  useQuery({ queryKey: ['getAllPartnerTeams'], queryFn: getAllPartnerTeams });
  useQuery({
    queryKey: ['getFuelDashboardMetrics', tableFilters, fuelSales, selectedStation],
    queryFn: getFuelDashboardMetrics
  });
  const { data: fuelSalesData, isLoading: fuelSalesLoading } = useQuery({
    queryKey: ['fetchFuelSales', showPerPage, tableFilters, selectedStation],
    queryFn: fetchFuelSales
  });

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Customer Phone',
        accessor: 'customer.phone',
        Cell: (row: any) => {
          return (
            <span>
              {row.cell.value ? getPhoneNumberFromShortCodeFormat(row.cell.value) : 'N/A'}
            </span>
          );
        }
      },
      {
        Header: 'Amount Bought',
        accessor: 'amount',
        Cell: (row: any) => {
          return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
        }
      },
      {
        Header: 'Fuel (in LTRS)',
        accessor: 'quantity',
        Cell: (row: any) => {
          return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
        }
      },
      {
        Header: 'Pump',
        accessor: 'pump',
        Cell: (row: any) => {
          return <span> {row.cell.value ?? 'N/A'} </span>;
        }
      },
      {
        Header: 'Payment Method',
        accessor: 'paymentMethod',
        Cell: (row: any) => {
          return (
            <span> {row.cell.value ? getFormattedPaymentChannel(row.cell.value) : 'N/A'} </span>
          );
        }
      },
      {
        Header: 'Date',
        accessor: 'purchaseDate',
        Cell: (row: any) => {
          return <span> {row.cell.value ? moment(row.cell.value).format('DD/MM/YY h:mm a') : 'N/A'}</span>;
        }
      }
    ];

    if (user.isFuelManager() || user.isFuelAdmin()) {
      baseColumns.unshift({
        Header: 'Attendant',
        accessor: 'salesPerson',
        Cell: ({ row }: any) => (
          <span>
            {row.original.salesPerson?.firstName} {row.original.salesPerson?.lastName ?? 'N/A'}
          </span>
        )
      });

      baseColumns.push({
        Header: 'Status',
        accessor: 'status',
        Cell: (row: any) => {
          return <FuelSalesStatus row={row} />;
        }
      });
    }

    return [
      {
        Header: 'M',
        columns: baseColumns
      }
    ];
  }, []);

  const recordFuelSale = () => {
    updateModalStates({
      show: true,
      children: (
        <AddFuelSale
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchFuelSales'] });
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'md'
    });
  };

  const closeDailySale = () => {
    updateModalStates({
      show: true,
      showCloseButton: false,
      children: (
        <CloseFuelSaleModal
          data={partnerFuelMetrics}
          onClose={closeModal}
          onConfirm={() => {
            closeFuelVendorDailySales()
              .then((response: any) => {
                closeModal();

                toast.success(response, {
                  autoClose: 3000
                });
              })
              .catch((error: any) => {
                toast.error(error.description, {
                  autoClose: 3000
                });
              });
          }}
        />
      )
    });
  };

  const getOtherActions = () => {
    let actions = [];

    if (user.isFuelManager() || user.isFuelAdmin()) {
      actions.push({
        label: 'Export sales',
        callback: () => {
          showExportFuelSalesModal(partnerTeams);
        }
      });
    }

    return actions;
  };

  return (
    <Page title="Sales">
      {user.isFuelManager() && (
        <div className="bg-white p-5 min-h-full h-full">
          <div className="">
            <h2 className="font-semibold md:text-3xl">Sales Summary</h2>
          </div>

          <div className="mt-5 ">
            <div className="flex items-center justify-end gap-2">
              <h2 className="font-semibold">Consumption by: </h2>
              <SelectDropdown
                name="stationId"
                listSelectedValue={selectedStation}
                placeholder="Select station"
                onChange={(value: string) => {
                  setSelectedStation(value);
                }}
                list={partnerTeams?.map(
                  (station: any) =>
                    ({
                      name: station.name,
                      value: station.id
                    } as ISelectDropdownItem)
                )}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-5">
              <div className="bg-white p-4 rounded shadow-md">
                <p>YTD</p>
                <p className="text-2xl font-bold">{partnerFuelMetrics?.ytdSales ?? 0}</p>
                <p>{partnerFuelMetrics?.ytdConsumption ?? 0} LTRS</p>
              </div>
              <div className="bg-white p-4 rounded shadow-md">
                <p>This month</p>
                <p className="text-2xl font-bold"> {partnerFuelMetrics?.monthSales ?? 0}</p>
                <p>{partnerFuelMetrics?.monthConsumption ?? 0} LTRS</p>
              </div>
              <div className="bg-white p-4 rounded shadow-md">
                <p>This week</p>
                <p className="text-2xl font-bold">{partnerFuelMetrics?.weekSales ?? 0}</p>
                <p>{partnerFuelMetrics?.weekConsumption ?? 0} LTRS</p>
              </div>
              <div className="bg-white p-4 rounded shadow-md">
                <p>Today</p>
                <p className="text-2xl font-bold"> {partnerFuelMetrics?.todaySales ?? 0}</p>
                <p>{partnerFuelMetrics?.todayConsumption ?? 0} LTRS</p>
              </div>
            </div>

            <hr className=" w-full bg-gray-700 my-10" />

            <div className=" mt-10  bg-white p-2">
              <div className="">
                <h2 className="font-semibold text-3xl">Sales Records</h2>
                <span className="text-sm">View a list of all fuel sales.</span>
              </div>

              <GTable
                columns={columns}
                data={fuelSales ?? []}
                classes="h-fit"
                tableType={'sale'}
                onSearch={(searchString: string) => setSearchString(searchString)}
                showSearch={false}
                loading={fuelSalesLoading}
                paginationData={salesPaginationData}
                onPageChange={handlePageChange}
                showPerPage={showPerPage}
                onShowPerPageChange={(page: number) => setShowPerPage(page)}
                actionElement={
                  getOtherActions().length > 0 && (
                    <PopOnMe actions={getOtherActions()}>
                      <div className="cursor-pointer">
                        <EllipsisVerticalIcon className="h-6 w-6 " />
                      </div>
                    </PopOnMe>
                  )
                }
                showFilter={
                  <>
                    <TableFilter
                      openFilter={openFilter}
                      onFilterChange={(filterState: boolean) => setOpenFilter(filterState)}
                      selectedFilters={tableFilters}
                      onRemoveFilter={(filter: ITableFilterItem, index: number) => {
                        const _tableFilters: ITableFilterItem[] = [...tableFilters];

                        let thisFilter = { ...tableFilters[index] };
                        thisFilter = { ..._tableFilters[index], value: '' };

                        _tableFilters[index] = thisFilter;

                        setTableFilters([..._tableFilters]);
                      }}
                      content={
                        <TableFilterForm
                          filters={tableFilters}
                          getValues={(filters: ITableFilterItem[]) => {
                            setTableFilters(filters);
                            setOpenFilter(false);
                          }}
                          onResetFilters={() => setTableFilters(getDefaultFilters())}
                        />
                      }
                    />
                  </>
                }
              />
            </div>
          </div>
        </div>
      )}

      {user.isFuelAdmin() && (
        <div className="bg-white p-5 min-h-full h-full">
          <div className="">
            <h2 className="font-semibold text-3xl">Sales</h2>
            <span className="text-sm">View a list of all fuel sales.</span>
          </div>

          <div className="mt-5 p-2">
            <GTable
              columns={columns}
              data={fuelSales ?? []}
              classes="h-fit"
              tableType={'sale'}
              onSearch={(searchString: string) => setSearchString(searchString)}
              showSearch={false}
              loading={fuelSalesLoading}
              paginationData={salesPaginationData}
              onPageChange={handlePageChange}
              showPerPage={showPerPage}
              onShowPerPageChange={(page: number) => setShowPerPage(page)}
              actionElement={
                !user.isFuelVendor() &&
                getOtherActions().length > 0 && (
                  <PopOnMe actions={getOtherActions()}>
                    <div className="cursor-pointer">
                      <EllipsisVerticalIcon className="h-6 w-6 " />
                    </div>
                  </PopOnMe>
                )
              }
            />
          </div>
        </div>
      )}

      {user.isFuelVendor() && (
        <div className="bg-white md:p-5 min-h-full h-full">
          <div className="w-full relative">
            <div className="bg-[#C3CFD9] p-5 w-full ">
              <span className="text-sm">Today&apos;s Sales</span>
              <h2 className="font-semibold text-3xl">GHS {partnerFuelMetrics?.todaySales ?? 0}</h2>

              <div className="flex items-center justify-between gap-2">
                <div>
                  <span className="text-sm">Total pending sales</span>
                  <h2 className="font-medium text-xl">GHS {partnerFuelMetrics?.unclosed ?? 0}</h2>
                </div>

                <div>
                  <div className="flex items-center gap-1">
                    <MdPhoneAndroid className="size-3" />
                    <h2 className="font-medium">GHS {partnerFuelMetrics?.unclosedMomo ?? 0}</h2>
                  </div>
                  <div className="flex items-center gap-1">
                    <FaRegMoneyBillAlt className="size-3" />
                    <h2 className="font-medium">GHS {partnerFuelMetrics?.unclosedCash ?? 0}</h2>
                  </div>
                </div>
              </div>
            </div>

            {isButtonVisible && (
              <div className="absolute top-2 -right-1 z-20 flex justify-center rounded-full bg-red-300 p-2  gap-2">
                <TiArrowRightThick
                  className="size-6 text-red-600 cursor-pointer"
                  onClick={handleIconClick}
                />
                <span className="cursor-pointer" onClick={closeDailySale}>
                  Close sale
                </span>
              </div>
            )}
            {!isButtonVisible && (
              <div
                className="absolute top-2 -right-4 bg-red-300 rounded-full p-3 z-10 cursor-pointer"
                onClick={handleIconClick}>
                <TiArrowLeftThick className="size-6 text-red-700" />
              </div>
            )}
          </div>

          <div className="h-auto overflow-y-auto hideScrollBar mb-24 mt-5">
            {fuelSalesLoading ? (
              <div className="w-full py-10 flex items-center justify-center h-full">
                <Loader message={`Loading sales. Please wait...`} brollyLoader />
              </div>
            ) : (
              <>
                {fuelSales && fuelSales?.length > 0 ? (
                  <FuelSaleList sales={fuelSales} />
                ) : (
                  <div className="w-full h-40 font-semibold min-h-full flex items-center justify-center">
                    No sale found
                  </div>
                )}
              </>
            )}
          </div>

          <div className="w-full flex h-28 justify-center items-center z-10 fixed bottom-0 left-0 bg-white px-10">
            <Button
              label="Add sale"
              className="bg-brolly-yellow py-3  w-full "
              curved
              onClick={recordFuelSale}
            />
          </div>
        </div>
      )}
      <DownloadHookModal />
      <ModalComponent />
    </Page>
  );
};

interface FuelSale {
  id: string;
  customer: IUser;
  purchaseDate: string;
  amount: string;
  quantity: string;
  paymentMethod: string;
  status: string;
  pump: number;
}
interface FuelSaleListProps {
  sales: FuelSale[];
}

export const FuelSaleList: React.FC<FuelSaleListProps> = ({ sales }: FuelSaleListProps) => {
  return (
    <div className=" w-full  shadow-md">
      {sales.map((sale) => (
        <div key={sale.id} className="flex items-center justify-between p-2 border- ">
          <div className="flex items-center">
            <div className="size-fit p-2 flex items-center justify-center bg-white rounded">
              {sale.paymentMethod === 'CASH' ? (
                <FaRegMoneyBillAlt className="w-6 h-6" />
              ) : (
                <MdPhoneAndroid className="w-6 h-6" />
              )}
            </div>
            <div className="ml-2">
              <div className="text-sm font-semibold">
                {sale.customer.phone
                  ? getPhoneNumberFromShortCodeFormat(sale.customer.phone)
                  : 'N/A'}
              </div>
              <div className="text-xs text-gray-500">
                {moment(sale.purchaseDate).format('DD-MM-YY') ?? 'N/A'} (
                {moment(sale.purchaseDate).format('h:mm')} GMT)
              </div>
            </div>
          </div>
          <div className="text-right pr-3">
            <div className={`font-semibold ${getStatusColor(sale.status)}`}>
              GHC {sale.amount ?? 'N/A'}{' '}
            </div>
            <div className="text-xs text-gray-500 flex items-center gap-3">
              <span>{sale.quantity ?? 'N/A'} L</span>
              <span className="flex items-center">
                <LuFuel className="size-3" /> {sale.pump ?? 'N/A'}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PartnerFuelSales;
