import { useMemo, useState } from 'react';
import GTable from '../../../components/elements/GTable';
import SelectMenu from '../../../components/form-control/SelectMenu';
import useUserAccount from '../../../hooks/userAccountHook';
import DashboardDatePickers from '../../../components/dashboard/date-range-selector/datePicker';
import PartnerFuelSales from '../partners/fuel/PartnerFuelSales';
import { Link } from 'react-router-dom';
import FuelQuotaBar from '../../../components/FuelQuotaBar';
import useRoute from '../../../hooks/routesHook';
import {
  getAllFuelPurchases,
  getPartnerFuelMetrics,
  getPartnerFuelQuota,
  getPartnerTeams
} from '../../../api/requests';
import { IoMdAlarm } from 'react-icons/io';
import moment from 'moment';
import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { FuelSalesStatus } from '../../../components/statuses';
import {
  getFormattedPaymentChannel,
  getPhoneNumberFromShortCodeFormat
} from '../../../utils/miscFunctions';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';

interface FuelDashboardCardProps {
  title: string;
  sales: string;
  consumption: string;
  lastUpdated: string;
  isSelected?: boolean;
  onClick?: () => void;
}

interface StationsTabProps {
  isSelected: boolean;
  station: any;
  onClick: (station: any) => void;
}

const PartnerDashboard = () => {
  const { user } = useUserAccount();
  const { getDashboardBase } = useRoute();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStation, setSelectedStation] = useState<string | null>('');
  const [partnerTeams, setPartnerTeams] = useState<any>([]);
  const [fuelQuotaStats, setFuelQuotaStats] = useState<any>();
  const [fuelSales, setFuelSales] = useState<any>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<any>({
    to: null,
    from: null
  });
  const [partnerFuelMetrics, setPartnerFuelMetrics] = useState<any>();
  const partnerId = user?.team?.partner?.id;
  const [allStationsPeriod, setAllStationsPeriod] = useState('Today');

  const getFuelQuotaStats = async () => {
    if (!partnerId) return;
    try {
      const response = await getPartnerFuelQuota(partnerId);
      setFuelQuotaStats(response);
    } catch (error) {
      console.error(error);
    }
  };

  const { to, from } = selectedDateRange;

  const getFuelDashboardMetrics = async () => {
    if (!partnerId) return;
    try {
      const response = await getPartnerFuelMetrics(from, to, selectedStation ?? '');
      setPartnerFuelMetrics(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllPartnerTeams = async () => {
    if (!partnerId) return;
    try {
      const res = await getPartnerTeams(partnerId);
      const _teams = res?.map((team: any) => ({
        name: team?.name,
        id: team.id
      }));
      setPartnerTeams(_teams);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFuelSales = async () => {
    setLoading(true);
    try {
      const response = await getAllFuelPurchases(0, 5);
      const _sales = response?.content || response;
      setFuelSales(_sales);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useQuery({
    queryKey: ['getFuelDashboardMetrics', selectedDateRange, selectedStation],
    queryFn: getFuelDashboardMetrics
  });
  useQuery({ queryKey: ['fetchFuelSales'], queryFn: fetchFuelSales });
  useQuery({ queryKey: ['getAllPartnerTeams'], queryFn: getAllPartnerTeams });
  useQuery({ queryKey: ['getFuelQuotaStats'], queryFn: getFuelQuotaStats });

  const columns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Attendant',
            accessor: 'salesPerson',
            Cell: ({ row }: any) => (
              <span>
                {row.original.salesPerson?.firstName} {row.original.salesPerson?.lastName ?? 'N/A'}
              </span>
            )
          },
          {
            Header: 'Customer Phone',
            accessor: 'customer.phone',
            Cell: (row: any) => (
              <span>
                {row.cell.value ? getPhoneNumberFromShortCodeFormat(row.cell.value) : 'N/A'}
              </span>
            )
          },
          {
            Header: 'Amount Bought',
            accessor: 'amount',
            Cell: (row: any) => <span>{row.cell.value ? row.cell.value : 'N/A'}</span>
          },
          {
            Header: 'Fuel (in LTRS)',
            accessor: 'quantity',
            Cell: (row: any) => <span>{row.cell.value ? row.cell.value : 'N/A'}</span>
          },
          {
            Header: 'Pump',
            accessor: 'pump',
            Cell: (row: any) => <span> {row.cell.value ?? 'N/A'} </span>
          },
          {
            Header: 'Payment Method',
            accessor: 'paymentMethod',
            Cell: (row: any) => (
              <span> {row.cell.value ? getFormattedPaymentChannel(row.cell.value) : 'N/A'} </span>
            )
          },
          {
            Header: 'Date',
            accessor: 'purchaseDate',
            Cell: (row: any) => (
              <span> {row.cell.value ? moment(row.cell.value).format('DD/MM/YY h:mm a') : 'N/A'}</span>
            )
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: (row: any) => <FuelSalesStatus row={row} />
          }
        ]
      }
    ],
    []
  );

  const getMetricsForAllStationsByDateRange = (range: string) => {
    switch (range) {
      case 'This year':
        return {
          sales: partnerFuelMetrics?.ytdSales ?? 0,
          litres: partnerFuelMetrics?.ytdConsumption ?? 0
        };
      case 'This month':
        return {
          sales: partnerFuelMetrics?.monthSales ?? 0,
          litres: partnerFuelMetrics?.monthConsumption ?? 0
        };
      case 'This week':
        return {
          sales: partnerFuelMetrics?.weekSales ?? 0,
          litres: partnerFuelMetrics?.weekConsumption ?? 0
        };
      case 'Today':
        return {
          sales: partnerFuelMetrics?.todaySales ?? 0,
          litres: partnerFuelMetrics?.todayConsumption ?? 0
        };

      default:
        return {
          sales: 0,
          litres: 0
        };
    }
  };

  return (
    <div className="rounded-md shadow min-h-full ">
      {user.isFuelManager() && (
        <div className="p-5">
          <div className="">
            <div className="flex flex-col lg:flex-row justify-between lg:items-center mb-5">
              <h1 className="md:text-2xl font-bold">Activity Summary</h1>
              <div className="flex flex-col md:flex-row md:items-center my-5 gap-5">
                <DashboardDatePickers
                  onSubmit={(date) => {
                    setSelectedDateRange(date);
                  }}
                />
              </div>
            </div>

            <div className="flex items-center gap-5 overflow-x-auto hideScrollBar w-full py-5 px-1">
              {partnerTeams?.map((station: any, index: number) => {
                return (
                  <StationsTab
                    isSelected={station?.id === selectedStation}
                    station={station}
                    onClick={(station: any) => {
                      setSelectedStation(station?.id);
                    }}
                    key={station?.id}
                  />
                );
              })}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-5">
              <FuelDashboardCard
                title="Today"
                sales={partnerFuelMetrics?.todaySales ?? '000.00'}
                consumption={partnerFuelMetrics?.todayConsumption ?? '000 L'}
                lastUpdated=""
              />
              <FuelDashboardCard
                title="This week"
                sales={partnerFuelMetrics?.weekSales ?? '000.00'}
                consumption={partnerFuelMetrics?.weekConsumption ?? '000 L'}
                lastUpdated=""
              />
              <FuelDashboardCard
                title="This month"
                sales={partnerFuelMetrics?.monthSales ?? '000.00'}
                consumption={partnerFuelMetrics?.monthConsumption ?? '000 L'}
                lastUpdated=""
              />
              <FuelDashboardCard
                title="Year To Date"
                sales={partnerFuelMetrics?.ytdSales ?? '000.00'}
                consumption={partnerFuelMetrics?.ytdConsumption ?? '000 L'}
                lastUpdated=""
              />
              <div className="bg-white p-4 rounded shadow-md border">
                <div className="flex justify-between items-center flex-wrap">
                  <p className="font-medium">All Stations</p>
                  <SelectMenu
                    onChange={(value: string) => setAllStationsPeriod(value)}
                    list={['This year', 'This month', 'This week', 'Today']}
                    listSelectedValue={allStationsPeriod}
                    wrapperClasses="p-0 w-fit"
                  />
                </div>
                <div className="mt-4">
                  <p className="text-3xl font-bold">
                    {getMetricsForAllStationsByDateRange(allStationsPeriod)?.sales ?? '00.00'} GHS
                  </p>
                  <p className="mt-2">
                    {getMetricsForAllStationsByDateRange(allStationsPeriod)?.litres ?? '00 L'}{' '}
                    Litres
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 flex items-end gap-5">
            <h1 className=" md:text-2xl font-bold">Fuel Quota</h1>
            <Link to={`${getDashboardBase()}/quota`} className="flex items-center gap-5">
              <span className=" text-sm">view all</span>
              <ChevronRightIcon className="size-4 text-brolly-ash" />
            </Link>
          </div>

          <div className="w-full mt-2 rounded">
            <FuelQuotaBar
              remainingQuantity={fuelQuotaStats?.currentBalance ?? 0}
              totalQuantity={fuelQuotaStats?.loadBalance ?? 0}
            />
          </div>

          <div className="shadow rounded p-5 mt-5 bg-white">
            <div className="flex items-center gap-3">
              <IoMdAlarm className="size-5 text-[#003A83]" />

              <span className="font-semibold text-lg">Recent transactions(today)</span>
            </div>
            <div className="mt-3">
              <GTable
                columns={columns}
                data={fuelSales ?? []}
                classes="h-fit"
                tableType={'recent transaction'}
                showSearch={false}
                loading={loading}
              />
              <div className="flex items-center justify-center mt-5">
                <Link to={`${getDashboardBase()}/sales`} className="flex items-center gap-2">
                  <span className=" text-sm font-semibold">See all</span>
                  <ChevronRightIcon className="size-4" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {user.isFuelAdmin() && (
        <>
          <div className="p-5">
            <div className="flex flex-col lg:flex-row justify-between lg:items-center">
              <h1 className="md:text-2xl font-bold p-5 md:p-0">Activity Summary</h1>
              {/* <div className="flex flex-col md:flex-row md:items-center my-5 gap-5">
                <DashboardDatePickers
                  onSubmit={(date) => {
                    setSelectedDateRange(date);
                  }}
                />
              </div> */}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
              <FuelDashboardCard
                title="Today"
                sales={partnerFuelMetrics?.todaySales ?? '000.00'}
                consumption={partnerFuelMetrics?.todayConsumption ?? '000 L'}
                lastUpdated=""
              />
              <FuelDashboardCard
                title="This week"
                sales={partnerFuelMetrics?.weekSales ?? '000.00'}
                consumption={partnerFuelMetrics?.weekConsumption ?? '000 L'}
                lastUpdated=""
              />
              <FuelDashboardCard
                title="This month"
                sales={partnerFuelMetrics?.monthSales ?? '000.00'}
                consumption={partnerFuelMetrics?.monthConsumption ?? '000 L'}
                lastUpdated=""
              />
              <FuelDashboardCard
                title="Year To Date"
                sales={partnerFuelMetrics?.ytdSales ?? '000.00'}
                consumption={partnerFuelMetrics?.ytdConsumption ?? '000 L'}
                lastUpdated=""
              />
            </div>
          </div>

          <div className="shadow rounded p-5 mt-5 bg-white">
            <div className="flex items-center gap-3">
              <IoMdAlarm className="size-5 text-[#003A83]" />

              <span className="font-semibold text-lg">Recent transactions(today)</span>
            </div>
            <div className="mt-3">
              <GTable
                columns={columns}
                data={fuelSales ?? []}
                classes="h-fit"
                tableType={'recent transaction'}
                showSearch={false}
                loading={loading}
              />
              <div className="flex items-center justify-center mt-5">
                <Link to={`${getDashboardBase()}/sales`} className="flex items-center gap-2">
                  <span className=" text-sm font-semibold">See all</span>
                  <ChevronRightIcon className="size-4" />
                </Link>
              </div>
            </div>
          </div>
        </>
      )}

      {user.isFuelVendor() && (
        <div className="h-full min-h-full">
          <PartnerFuelSales />
        </div>
      )}
    </div>
  );
};

export const FuelDashboardCard = ({
  title,
  sales,
  consumption,
  lastUpdated,
  isSelected,
  onClick
}: FuelDashboardCardProps) => {
  return (
    <div
      className={`p-4 px-5 rounded shadow-md border  bg-white space-y-1 ${
        isSelected ? 'border-2 border-brolly-yellow' : ''
      }`}
      onClick={onClick}>
      <p className="text-center font-medium">{title}</p>
      <p className="text-left font-medium w-full border-b">Sales :</p>
      <p className="text-3xl font-bold text-left">{sales}</p>
      <p className="text-left font-medium w-full border-b">Fuel dispensed :</p>
      <p className="text-left">{consumption}</p>
      {/* <p className="text-center text-sm text-gray-500">
        Last updated: {moment(lastUpdated).format('DD-MM-YY') ?? 'N/A'}
      </p> */}
    </div>
  );
};

interface StationsTabProps {
  isSelected: boolean;
  station: any; // Replace `any` with the actual type of `station` if available
  onClick: (station: any) => void;
  onClose?: () => void;
}

export const StationsTab = ({ isSelected, station, onClick, onClose }: StationsTabProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      onClick={() => onClick(station)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={clsx(
        'relative w-fit h-fit cursor-pointer px-3 py-1 rounded-lg flex items-center',
        isSelected ? 'bg-brolly-yellow text-black' : 'text-gray-400 bg-white'
      )}>
      <span className="whitespace-nowrap">{station?.name}</span>
      {isHovered && (
        <div className="flex items-center justify-center size-fit absolute -top-2 -left-2 p-1 bg-[#F4F4F4] rounded-full">
          <XMarkIcon className="size-3" onClick={onClose} />
        </div>
      )}
    </button>
  );
};

export default PartnerDashboard;
