import {
  cancelPolicyRequest,
  changePolicyReviewState,
  createPolicyFromQuote,
  getPolicy,
  initializeHubtelPayment,
  initializeItConsortiumPayment,
  updatePolicy,
  updatePolicyStatus
} from '../api/requests';
import { ADMIN_POLICY_STATUS_ACTIONS, MOBILE_MONEY_NETWORKS } from '../resources/enums';
import { IPolicy } from '../models/policy/IPolicy';
import { Policy } from '../models/policy/Policy';
import { policyFormDataToUpdatePolicyDTO } from '../utils/miscFunctions';
import {
  IInitiateHubtelPaymentDTO,
  IReviewPolicyDTO,
  IUpdatePolicyDTO,
  IVerifyMobilePaymentDTO
} from '../resources/interfaces';

function usePolicy() {
  const requestPolicy = (policyRef: string): Promise<Policy> => {
    return new Promise<Policy>((resolve, reject) => {
      getPolicy(policyRef)
        .then((response: any) => {
          resolve(new Policy(response));
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const getAllPolicies = (): Promise<Policy[]> => {
    return new Promise<Policy[]>((resolve, reject) => {
      getAllPolicies()
        .then((response: any) => {
          const policies: Policy[] = [];
          if (response) {
            response?.forEach((policy: IPolicy) => {
              policies.push(new Policy(policy));
            });
          }
          resolve(policies);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const changePolicyStatus = (
    policyRef: string,
    status: ADMIN_POLICY_STATUS_ACTIONS
  ): Promise<Policy> => {
    return new Promise<Policy>((resolve, reject) => {
      updatePolicyStatus(policyRef, status)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const reviewPolicy = (policyRef: string, payload: IReviewPolicyDTO): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      changePolicyReviewState(policyRef, payload)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const cancelPolicy = (policyRef: string, reason: string, terminate: boolean): Promise<Policy> => {
    return new Promise<Policy>((resolve, reject) => {
      cancelPolicyRequest(policyRef, reason, terminate)
        .then((response: any) => {
          resolve(new Policy(response));
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const updatePolicyDetails = (policyId: string, data: any): Promise<void> => {
    const payload = policyFormDataToUpdatePolicyDTO(data);

    return new Promise<void>((resolve, reject) => {
      updatePolicy(policyId, payload)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const generatePolicyFromQuote = (quoteId: string): Promise<Policy> => {
    return new Promise<Policy>((resolve, reject) => {
      createPolicyFromQuote(quoteId)
        .then((response: any) => {
          resolve(new Policy(response?.data));
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  return {
    requestPolicy,
    getAllPolicies,
    changePolicyStatus,
    updatePolicyDetails,
    reviewPolicy,
    cancelPolicy,
    generatePolicyFromQuote
  };
}

export default usePolicy;

export function useHubtelPayment() {
  const initializeHubtel = (data: {
    policy: Policy;
    phone: string;
    network: MOBILE_MONEY_NETWORKS;
  }): Promise<IVerifyMobilePaymentDTO> => {
    const payload: IInitiateHubtelPaymentDTO = {
      initialAmount: data.policy?.discountedAmount ?? 0,
      accountNumber: data.phone,
      network: data.network,
      policyNumber: data.policy?.ref ?? ''
    };

    return new Promise<IVerifyMobilePaymentDTO>((resolve, reject) => {
      initializeHubtelPayment(payload)
        .then((response: any) => {
          const payload: IVerifyMobilePaymentDTO = {
            otpCode: response.data.otpPrefix,
            policyNumber: data.policy?.ref ?? '',
            recurringInvoiceId: response.data.recurringInvoiceId,
            requestId: response.data.requestId
          };
          resolve(payload);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  return {
    initializeHubtel
  };
}

export function useItConsortiumPayment() {
  const initializeItConsortium = (data: {
    policy: Policy;
    phone: string;
    network: MOBILE_MONEY_NETWORKS;
  }): Promise<IVerifyMobilePaymentDTO> => {
    const payload: any = {
      accountNumber: data.phone,
      policyNumber: data.policy?.ref ?? ''
    };

    return new Promise<IVerifyMobilePaymentDTO>((resolve, reject) => {
      initializeItConsortiumPayment(payload.accountNumber, payload.policyNumber)
        .then((response: any) => {
          const payload: IVerifyMobilePaymentDTO = {
            otpCode: response.data.otpPrefix,
            policyNumber: data.policy?.ref ?? '',
            recurringInvoiceId: response.data.recurringInvoiceId,
            requestId: response.data.requestId
          };
          resolve(payload);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  return {
    initializeItConsortium
  };
}
