import React, { useEffect, useState } from 'react';
import { fetchInsurerPayouts } from '../../../../api/requests';
import { User } from '../../../../models/User';
import useUserAccount from '../../../../hooks/userAccountHook';
import { Payout } from '../../../../models/payout/Payout';
import Page from '../../../../components/PageWrapper';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import GTable, { ITableRowAction } from '../../../../components/elements/GTable/GTable';
import useDownload from '../../../../hooks/downloadHook';
import useRoute from '../../../../hooks/routesHook';
import { numberWithCommas } from '../../../../utils/miscFunctions';
import DownloadData from '../../../../components/downloadComponents/DownloadData';
import { DOWNLOAD_ACTION_TYPES, DOWNLOAD_RESOURCE_TYPES } from '../../../../resources/enums';
interface IPayoutsProps {
  onPayoutsUpdated?: (payouts: Payout[]) => void;
}

const InsurerPayouts = (props: IPayoutsProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [InsurerPayouts, setInsurerPayouts] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPerPage, setShowPerPage] = useState<number>(100);
  const [searchString, setSearchString] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get('page') ? Number(searchParams.get('page')) : 0
  );
  const [payoutsPaginationData, setPayoutsPaginationData] = useState({
    total: 0,
    per_page: 0,
    totalPages: 0,
    initialPage: currentPage ? currentPage : 0,
    to: 0,
    from: 0
  });
  const { downloadCSV, downloadExcel } = useDownload();
  const { getDashboardBase } = useRoute();
  const navigate = useNavigate();
  const { getProfile } = useUserAccount();
  const user = new User(getProfile());
  const insurerPayoutColumns = React.useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Created On',
            accessor: 'createdOn',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YY') : 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Gross to Pay',
            accessor: 'grossToPay',
            Cell: (row: any) => {
              return <span> &#8373;{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Net to Pay',
            accessor: 'netToPay',
            Cell: (row: any) => {
              return <span>&#8373; {row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },

          {
            Header: 'Amount Paid',
            accessor: 'amountPaid',
            Cell: (row: any) => {
              return <span>&#8373; {row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Reference',
            accessor: 'reference',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Status',
            accessor: 'isPaid',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? (
                    <span className="w-[120px] h-[30px] rounded flex items-center justify-center text-sm bg-green-600 ">
                      Completed
                    </span>
                  ) : (
                    <span className="w-[120px] h-[30px] rounded flex items-center justify-center text-sm bg-yellow-100">
                      In progress
                    </span>
                  )}
                </span>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const getDownloadOptions: ITableRowAction[] = [
    {
      label: 'As CSV',
      callback: () => {
        downloadCSV(getDownloadData());
      }
    },
    {
      label: 'As Excel',
      callback: () => {
        downloadExcel(getDownloadData());
      }
    },
    {
      label: 'As PDF',
      callback: () => {
        // downloadPDF("tableId");
      }
    }
  ];

  const { id } = useParams();

  useEffect(() => {
    getInsurerPayouts();
  }, []);

  useEffect(() => {
    getInsurerPayouts();
  }, [searchString, showPerPage]);

  const getDownloadData = () => {
    const data = InsurerPayouts?.map((payout: Payout) => ({
      'Create On': moment(payout?.createdOn).format('DD/MM/YYYY'),
      'Paid Date': moment(payout?.dateOfPayment).format('DD/MM/YYYY'),
      Status: payout?.isPaid ? 'Paid' : 'Not Paid',
      'Total Amount': numberWithCommas(payout?.totalAmountPayable)
    }));

    return data;
  };

  const getInsurerPayouts = (page = currentPage) => {
    fetchInsurerPayouts(page, showPerPage, searchString)
      .then((response: any) => {
        setInsurerPayouts(response?.content);

        const from = response.pageable?.offset + 1;
        const to =
          from + response.size > response.totalElements
            ? response.totalElements
            : from + response.size - 1;

        setPayoutsPaginationData({
          ...payoutsPaginationData,
          ...{
            per_page: response.size,
            total: response.totalElements,
            totalPages: response.totalPages,
            from: from,
            to: to
          }
        });
      })

      .catch((error: any) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Page title="Payouts">
      <div className="bg-white min-h-full w-full p-5">
        {/* Payments */}
        <div className="rounded-md  mb-1 justify-between px-2 pb-3 pt-7 align-items-center">
          <div className="">
            <h2 className="font-semibold text-3xl">Payouts</h2>
            <span className="text-sm text-slate-400">
              View a list of all payouts and associated details{' '}
            </span>
          </div>
        </div>

        <div className="overflow-x-auto shadow-md">
          <GTable
            columns={insurerPayoutColumns}
            data={InsurerPayouts}
            showPagination
            searchPlaceholder="Search by reference"
            paginationData={payoutsPaginationData}
            tableType={'payouts'}
            onSearch={(reference: string) => setSearchString(reference)}
            showPerPage={showPerPage}
            onShowPerPageChange={(page: number) => setShowPerPage(page)}
            onRowClick={(payout: Payout) => {
              navigate(`${getDashboardBase()}/payouts/${payout.id}`, {
                replace: true
              });
            }}
            loading={loading}
            classes={'h-fit'}
            actionElement={
              !loading &&
              user.isInsurer() && <DownloadData actionType={DOWNLOAD_ACTION_TYPES.INSURER_DOWNLOAD_PAYOUTS} resourceType={DOWNLOAD_RESOURCE_TYPES.PAYOUT} />
            }
          />
        </div>
      </div>
    </Page>
  );
};

export default InsurerPayouts;
