import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { assignMember } from '../../../api/requests';
import { PiUserSwitchBold } from "react-icons/pi";

export interface IAssignMemberDTO {
  staffEmail: string;
  id: string;
}
export interface AssignMemberProps {
  onProceed: () => void;
  onClose: () => void;
  teamID: string;
}

const AssignMember = ({ onProceed, onClose, teamID }: AssignMemberProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload: IAssignMemberDTO = {
      staffEmail: values.email.trim(),
      id: teamID
    };

    assignMember(payload)
      .then((response: any) => {
        toast.success('Member assigned to team successfully', {
          autoClose: 5000
        });
        onProceed();
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between p-5">
        <div className=" flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-5">
            <div className="flex items-center justify-center bg-white p-2 rounded-full">
              <PiUserSwitchBold className="h-4 w-4" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Move member to team</h2>
          </div>
          <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="flex items-center justify-center mt-[26px]">
        <span>Enter email of admin to send invite link.</span>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            email: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            email: Yup.string().email('Email provided invalid.').required('Email is requied.')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex gap-x-8">
                <Input
                  name="email"
                  label="Email"
                  value={values.email.trim()}
                  type="email"
                  required
                  error={errors.email}
                  onChange={handleChange}
                  helperText={errors.email}
                />
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  className="w-fit px-6 text-[14px]"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AssignMember;
