import Button from '../../../components/form-control/Button';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { IUpdateAdminAccountDTO } from '../../../resources/interfaces';
import { getSlackWorkspaces, updateAdminAccount } from '../../../api/requests';
import { toast } from 'react-toastify';
import {
    getFormattedPhoneNumber,
    getPhoneNumberFromShortCodeFormat
} from '../../../utils/miscFunctions';
import SelectDropdown from '../../../components/form-control/SelectDropdown';
import { MdGroups } from 'react-icons/md';

const AddAdminTonWorkspace = ({ onProceed, onClose, staff }: { onProceed: () => void; onClose: () => void; staff: any; }) => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [workspaces, setWorkspaces] = useState<any[]>();

    useEffect(() => {
        getSlackWorkspaces()
            .then((response: any) => {
                setWorkspaces(response?.data);
            })
            .catch((error: any) => {
                toast.error(error.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }, [])

    const submitHandler = (values: any) => {
        const payload: IUpdateAdminAccountDTO = {
            ...values,
            slackWorkspaceId: values.slackWorkspaceId,
        };
        if (staff) {
            setSubmitting(true);

            updateAdminAccount(staff?.id, payload)
                .then((response: any) => {
                    if (response) {
                        toast.success('Admin account updated successfully', {
                            autoClose: 5000
                        });
                        onProceed();
                    }
                })
                .catch((error: any) => {
                    toast.error(error?.description);
                })
                .finally(() => setSubmitting(false));
        }

    };

    return (
        <div className="w-full">
            <div className="bg-primary-main flex justify-between">
                <div className="p-[1rem] flex justify-between w-full items-center">
                    <div className="flex items-center justify-center gap-5">
                        <div className="flex items-center justify-center bg-white h-[2.5rem] w-[2.5rem] rounded-full gap-3">
                            <MdGroups className="h-5 w-5" />
                        </div>
                        <h2 className="font-[gilroy-bold] text-lg">Add to workspace</h2>
                    </div>
                    <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
                </div>
            </div>

            <div className="p-5">
                <Formik
                    initialValues={{
                        firstName: staff?.firstName,
                        lastName: staff?.lastName,
                        phone: getPhoneNumberFromShortCodeFormat(staff?.phone),
                        email: staff?.email,
                        jobTitle: staff?.jobTitle,
                        gender: staff?.gender,
                        accessRight: staff?.accessRight,
                        slackWorkspaceId: staff?.slackWorkspace,
                    }}
                    validateOnChange={false}
                    validateOnBlur
                    validationSchema={Yup.object({
                        slackWorkspaceId: Yup.string().trim().required('Select a workspace')
                    })}
                    onSubmit={(values) => {
                        const payload = {
                            ...values,
                            accessRight: values.accessRight,
                            phone: getFormattedPhoneNumber(values.phone),
                        };
                        submitHandler(payload);
                    }}>
                    {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <div className="">
                                <SelectDropdown
                                    name="slackWorkspaceId"
                                    label="Slack Workspace"
                                    placeholder="Select workspace "
                                    listSelectedValue={values.slackWorkspaceId}
                                    wrapperClasses=""
                                    required
                                    onChange={(value: any) => {
                                        setFieldValue('slackWorkspaceId', value);
                                    }}
                                    list={workspaces?.map((workspace: any) => ({
                                        name: workspace?.name,
                                        value: workspace?.id
                                    })
                                    )}
                                />
                            </div>

                            <div className="flex justify-end">
                                <Button
                                    type="submit"
                                    label="Submit"
                                    bgColor="primary"
                                    className="w-fit px-6 text-[14px]"
                                    loading={submitting}
                                    disabled={submitting}
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AddAdminTonWorkspace;
