import { useEffect, useState } from 'react';
import { getRelationshipManager } from '../../../api/requests';
import { useParams } from 'react-router-dom';
import { Staff } from '../../../models/staff/Staff';
import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  getFormattedPhoneNumber,
  getPhoneNumberFromShortCodeFormat
} from '../../../utils/miscFunctions';

interface RelationshipManagerDetailsModalPprops {}

const RelationshipManagerDetailsModal = ({}: RelationshipManagerDetailsModalPprops) => {
  const [loading, setLoading] = useState<boolean>();
  const [relationshipManager, setRelationshipManager] = useState<Staff>();

  const { managerId } = useParams();
  const id = managerId;

  useEffect(() => {
    setLoading(true);
    if (id) getRelationshipManagerDetails(id);
  }, []);

  const getRelationshipManagerDetails = (id: string) => {
    getRelationshipManager(id)
      .then((response: any) => {
        setRelationshipManager(response);
      })
      .catch((error: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const fullName = `${relationshipManager?.firstName} 
  ${relationshipManager?.lastName}`;

  return (
    <>
      {relationshipManager && (
        <div>
          <div className=" border-[2px] rounded  border-primary-border">
            <div className="p-[1rem] flex justify-between items-center border border-b-primary-border bg-[#F9F2E2] h-">
              <div className="">
                <h2 className="text-lg  font-bold">Personal Info</h2>
              </div>
              {/* <div className="">
                <XMarkIcon
                  className="h-6 w-6 cursor-pointer"
                  onClick={() => {
                    onClose();
                  }}
                />
              </div> */}
            </div>
            <div className="p-[1rem]">
              <div className="grid grid-cols-2 justify-between items-center gap-4">
                <span className="text-sm">Name</span>
                <span className="text-sm">{fullName}</span>

                <span className="text-sm">Email</span>
                <span className="text-sm">
                  {relationshipManager?.email ? relationshipManager?.email : 'N/A'}
                </span>

                <span className="text-sm">Phone</span>
                <span className="text-sm">
                  {' '}
                  {relationshipManager?.phone
                    ? getPhoneNumberFromShortCodeFormat(relationshipManager?.phone)
                    : 'N/A'}
                </span>

                <span className="text-sm">Address</span>
                <span className="text-sm">
                  {relationshipManager?.address ? relationshipManager?.address : 'N/A'}
                </span>

                <span className="text-sm">ID Number</span>
                <span className="text-sm">
                  {relationshipManager?.idNumber ? relationshipManager?.idNumber : 'N/A'}
                </span>

                <span className="text-sm">ID Type</span>
                <span className="text-sm">
                  {relationshipManager?.idType ? relationshipManager?.idType : 'N/A'}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RelationshipManagerDetailsModal;
