import { useEffect } from 'react';
import useModal from '../../hooks/modalHook';
import useUserAccount from '../../hooks/userAccountHook';
import { User } from '../../models/User';
import { DOWNLOAD_ACTION_TYPES, DOWNLOAD_RESOURCE_TYPES } from '../../resources/enums';
import { IDownloadData } from '../../resources/interfaces';
import DownloadPrompt from './DownloadPrompt';
import SuperInsurerDownload from './SuperInsurerDownload';
import { HiDownload } from 'react-icons/hi';
import DownloadPayoutPolicies from './DownloadPayoutPolicies';

interface IDownloadDataProps {
  resourceType: DOWNLOAD_RESOURCE_TYPES;
  actionType: string;
}

const DownloadData = (props: IDownloadDataProps) => {
  const { ModalComponent, updateModalStates, closeModal } = useModal();
  const { getProfile } = useUserAccount();
  const user = new User(getProfile());

  const confirmDownload = () => {
    let downloadComponent;

    switch (props.actionType) {
      case DOWNLOAD_ACTION_TYPES.INSURER_DOWNLOAD_POLICIES:
      case DOWNLOAD_ACTION_TYPES.INSURER_DOWNLOAD_SALES:
      case DOWNLOAD_ACTION_TYPES.INSURER_DOWNLOAD_PAYOUTS:
        downloadComponent = (
          <DownloadPrompt
            onProceed={(data: IDownloadData) => {
              closeModal();
            }}
            onClose={closeModal}
            resourceType={props.resourceType}
          />
        );
        break;

      case DOWNLOAD_ACTION_TYPES.DOWNLOAD_PAYOUT_POLICIES:
        downloadComponent = (
          <DownloadPayoutPolicies
            onProceed={() => {
              closeModal();
            }}
            onClose={closeModal}
            resourceType={props.resourceType}
          />
        );
        break;

      case DOWNLOAD_ACTION_TYPES.SUPER_DOWNLOAD_INSURER_POLICIES:
        downloadComponent = (
          <SuperInsurerDownload
            onProceed={(data: IDownloadData) => {
              closeModal();
            }}
            onClose={closeModal}
            resourceType={props.resourceType}
          />
        );
        break;

      default:
        break;
    }

    if (downloadComponent) {
      updateModalStates({
        show: true,
        children: downloadComponent,
        showTitleSection: false,
        classes: '!p-0 r-[5px]',
        size: 'md'
      });
    }
  };

  return (
    <div className="cursor-pointer flex justify-center">
      <button
        className="!w-fit px-6 border-[1px] border-primary-main py-2 flex items-center"
        onClick={confirmDownload}>
        <HiDownload className="w-4 h-4 mr-4" />
        Export
      </button>

      <ModalComponent />
    </div>
  );
};

export default DownloadData;
