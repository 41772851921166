import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share';
import Tooltip from '../ToolTip';
import { BsShare } from 'react-icons/bs';
import ToolTip from '../ToolTip';
import { ReactComponent as TwitterXIcon } from '../../../src/resources/icons/x-twitter.svg';
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import clsx from 'clsx';
import { DocumentDuplicateIcon } from '@heroicons/react/20/solid';
import { copyToClipboard } from '../../utils/miscFunctions';

interface IShareComponent {
  title: string;
  text: string;
  url: string;
  bgColor?: string;
  fontColor?: string;
  withShareBtn?: boolean;
}

const ShareComponent = ({
  title,
  text,
  url,
  withShareBtn = false,
  bgColor,
  fontColor
}: IShareComponent) => {
  const [shareExpanded, setShareExpanded] = useState<boolean>(false);

  const windowWidth = useRef(window.innerWidth);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: text,
          url: url
        });
      } catch (error) {}
    } else {
      if (withShareBtn) {
        setShareExpanded(!shareExpanded);
      } else {
        if (withShareBtn) {
          setShareExpanded(!shareExpanded);
        } else {
          try {
            await navigator.clipboard.writeText(url);
            toast.success('URL copied to clipboard');
          } catch (error) {
            // console.error('Clipboard copy failed:', error);
          }
        }
      }
    }
  };

  return (
    <>
      {withShareBtn ? (
        <div
          className={clsx('flex items-center rounded-[18px] p-[0.1rem] pr-2 w-fit', {
            // ['bg-primary-main']: shareExpanded
          })}>
          <svg
            className="w-8 cursor-pointer"
            onClick={() => {
              if (windowWidth.current >= 550) {
                setShareExpanded(!shareExpanded);
              } else {
                handleShare();
              }
            }}
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.5" cy="17.5" r="17.5" fill={bgColor ? `${bgColor}`: '#FBC02D'} />
            <path
              d="M22.2281 8.55556C20.3377 8.55556 18.7895 10.1038 18.7895 11.9942C18.7895 12.2819 18.887 12.5392 18.9545 12.8068L13.0646 15.7532C12.4325 14.9778 11.5102 14.4503 10.4386 14.4503C8.54824 14.4503 7 15.9985 7 17.8889C7 19.7792 8.54824 21.3275 10.4386 21.3275C11.5102 21.3275 12.4325 20.7999 13.0646 20.0246L18.9545 22.971C18.887 23.2386 18.7895 23.4959 18.7895 23.7836C18.7895 25.674 20.3377 27.2222 22.2281 27.2222C24.1184 27.2222 25.6667 25.674 25.6667 23.7836C25.6667 21.8933 24.1184 20.345 22.2281 20.345C21.1565 20.345 20.2341 20.8726 19.6021 21.6479L13.7122 18.7015C13.7797 18.4339 13.8772 18.1766 13.8772 17.8889C13.8772 17.6011 13.7797 17.3439 13.7122 17.0763L19.6021 14.1298C20.2341 14.9052 21.1565 15.4327 22.2281 15.4327C24.1184 15.4327 25.6667 13.8845 25.6667 11.9942C25.6667 10.1038 24.1184 8.55556 22.2281 8.55556ZM22.2281 10.0292C23.322 10.0292 24.193 10.9002 24.193 11.9942C24.193 13.0881 23.322 13.9591 22.2281 13.9591C21.1341 13.9591 20.2632 13.0881 20.2632 11.9942C20.2632 10.9002 21.1341 10.0292 22.2281 10.0292ZM10.4386 15.924C11.5325 15.924 12.4035 16.795 12.4035 17.8889C12.4035 18.9828 11.5325 19.8538 10.4386 19.8538C9.34468 19.8538 8.47368 18.9828 8.47368 17.8889C8.47368 16.795 9.34468 15.924 10.4386 15.924ZM22.2281 21.8187C23.322 21.8187 24.193 22.6897 24.193 23.7836C24.193 24.8775 23.322 25.7485 22.2281 25.7485C21.1341 25.7485 20.2632 24.8775 20.2632 23.7836C20.2632 22.6897 21.1341 21.8187 22.2281 21.8187Z"
              fill={fontColor ? `${fontColor}`: 'white'}
            />
          </svg>
          {shareExpanded && (
            <div className="ml-2 flex items-center gap-x-2 !py-0">
              {/* <WhatsApp className='h-4 w-4' /> */}
              <Tooltip message={'Click to share to whatsApp'}>
                <WhatsappShareButton url={url} title={title}>
                  <WhatsappIcon
                    size={20}
                    round={true}
                    iconFillColor={'black'}
                    bgStyle={{ fillOpacity: 0 }}
                  />
                </WhatsappShareButton>
              </Tooltip>
              <Tooltip message={'Click to share to facebook'}>
                <FacebookShareButton url={url} title={title}>
                  <FacebookIcon
                    size={20}
                    round={true}
                    iconFillColor={'black'}
                    bgStyle={{ fillOpacity: 0 }}
                  />
                </FacebookShareButton>
              </Tooltip>
              <Tooltip message={'Click to share to twitter'}>
                <TwitterShareButton url={url} title={title}>
                  <TwitterXIcon className=" h-3 w-3 text-black" />
                </TwitterShareButton>
              </Tooltip>
              <Tooltip message={'Click to share to Linkedin'}>
                <LinkedinShareButton url={url} title={title}>
                  <LinkedinIcon
                    size={30}
                    round={false}
                    iconFillColor={'black'}
                    bgStyle={{ fillOpacity: 0 }}
                  />
                </LinkedinShareButton>
              </Tooltip>
              <Tooltip message={'Click to copy'}>
                <DocumentDuplicateIcon
                  className={'w-4 text-black'}
                  onClick={() => {
                    copyToClipboard(url);
                    toast.success('Copied to clipboard', {
                      autoClose: 3000
                    });
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      ) : (
        <div className="h-full w-full flex items-center gap-10">
          <Tooltip message={'Click to share to facebook'}>
            <FacebookShareButton url={url}>
              <FacebookIcon
                size={50}
                round={true}
                iconFillColor={'black'}
                bgStyle={{ fillOpacity: 0 }}
              />
            </FacebookShareButton>
          </Tooltip>

          <Tooltip message={'Click to share to twitter'}>
            <TwitterShareButton url={url}>
              <div className="rounded-full h-11 w-11  flex items-center justify-center cursor-pointer">
                <TwitterXIcon className=" h-6 w-6 " />
              </div>
            </TwitterShareButton>
          </Tooltip>

          <Tooltip message={'Click to share to whatsApp'}>
            <WhatsappShareButton url={url}>
              <WhatsappIcon
                size={40}
                round={true}
                iconFillColor={'black'}
                bgStyle={{ fillOpacity: 0 }}
              />
            </WhatsappShareButton>
          </Tooltip>

          <ToolTip message="Click for other options">
            <div
              className="h-10 w-10  rounded-full flex items-center justify-center cursor-pointer"
              onClick={handleShare}>
              <BsShare className=" h-5 w-5 text-black font-bold" />
            </div>
          </ToolTip>
        </div>
      )}
    </>
  );
};

export default ShareComponent;
