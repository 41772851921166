import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/form-control/Input';
import Button from '../../../components/form-control/Button';
import { MdOutlineSecurity } from 'react-icons/md';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { getFormattedPhoneNumber } from '../../../utils/miscFunctions';
import {
  adminPoliciesVerifyPhoneOTP,
  sendRefereeOTP} from '../../../api/requests';
import { toast } from 'react-toastify';
import { IVerifyPolicyRefereeOTP } from './PolicyEdit';

export interface ICustomerPhoneVerificationModal {
  policy: any;
  close: () => void;
  onProceed: () => void;
}

const RefereePhoneVerificationModal = ({
  policy,
  close,
  onProceed
}: ICustomerPhoneVerificationModal) => {
  const sendRefOTP = () => {
    let policyNumber = policy?.ref;
    let phone = getFormattedPhoneNumber(policy?.referee.phone);
    sendRefereeOTP(phone, policyNumber)
      .then((response: any) => {
        toast.success(response?.data?.message, {
          autoClose: 3000
        });
      })
      .catch((error: any) => {
        toast.error(error.message, {
          autoClose: 5000
        });
      });
  };

  const verifyRefereeOTP = (values: any) => {

    const VERIFICATION_CODE_CONSTANT = 'B-';
    const payload: IVerifyPolicyRefereeOTP = {
      verificationCode: VERIFICATION_CODE_CONSTANT + values.code,
      policyNumber: policy?.ref,
      identifier: getFormattedPhoneNumber(policy?.referee?.phone)
    };

    adminPoliciesVerifyPhoneOTP(payload)
      .then((response: any) => {
        if (response) {
          toast.success(response.message, {
            autoClose: 5000
            
          });
          onProceed();
        }
      })
      .catch((error: any) => {
        toast.error(error.message, {
          autoClose: 5000
        });
      });
  };

  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-1">
            <div className="flex items-center justify-center bg-white h-[45px] w-[45px] rounded-full">
              <MdOutlineSecurity className="h-[24px] w-[24px]" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Verify Phone</h2>
          </div>
          <XMarkIcon onClick={close} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className=" mt-[26px]">
        <span className="text-center px-4">
          Enter the verification code provided by the customer
        </span>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            code: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            code: Yup.string()
              .trim()
              .min(6, 'Invalid verification code')
              .max(6, 'Invalid verification code')
              .required('Verification code is required')
          })}
          onSubmit={(values) => {
            // submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-5">
                <div className="flex">
                  <div className="">
                    <Input
                      type="text"
                      name="code"
                      label="Verification code"
                      placeholder=""
                      className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border "
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      prepend={'B-'}
                    />
                  </div>

                  <div className="flex items-end ">
                    <Button
                      label="Verify OTP"
                      title=" "
                      bgColor="primary"
                      className="rounded"
                      onClick={() => verifyRefereeOTP(values)}
                    />
                  </div>
                </div>
                <div className="">
                  <span
                    className="text-blue-700 cursor-pointer hover:underline"
                    onClick={() => sendRefOTP()}>
                    Resend verification code
                  </span>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RefereePhoneVerificationModal;
