import clsx from 'clsx';
import { PAYMENT_STATUS_STATES } from '../resources/enums';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { ITableAction } from './elements/GTable/GTable';
import PopOnMe from './elements/poponme';

interface PaymentStatusProps {
  row: any;
  actions?: any[];
}

const PaymentStatus = ({ row, actions }: PaymentStatusProps) => {
  let backgroundColor = '';
  let statusText = '';
  let textColor = '';

  switch (row.cell.value) {
    case PAYMENT_STATUS_STATES.SUCCESS:
      backgroundColor = 'border border-green-500';
      statusText = 'Success';
      textColor = 'text-green-500';
      break;
    case PAYMENT_STATUS_STATES.PENDING:
      backgroundColor = 'border border-yellow-400';
      statusText = 'Pending';
      textColor = 'text-yellow-400';
      break;
    case PAYMENT_STATUS_STATES.LONG_OVERDUE:
      backgroundColor = 'border border-red-500';
      statusText = 'Long Overdue';
      textColor = 'text-red-500';
      break;
    case PAYMENT_STATUS_STATES.APPROVAL:
      backgroundColor = 'border border-blue-500';
      statusText = 'Approval';
      textColor = 'text-blue-500';
      break;
    case PAYMENT_STATUS_STATES.OVERDUE:
      backgroundColor = 'border border-orange-500';
      statusText = 'Overdue';
      textColor = 'text-orange-500';
      break;
    case PAYMENT_STATUS_STATES.REJECTED:
      backgroundColor = 'border border-red-700';
      statusText = 'Overdue';
      textColor = 'text-red-700';
      break;
    default:
      backgroundColor = 'border border-gray-400';
      statusText = 'Unknown';
      textColor = 'text-gray-800';
  }

  return (
    <div
      className={clsx(
        ` w-full text-sm flex items-center justify-center rounded-3xl p-1`,
        backgroundColor,
        textColor,
        actions && 'cursor-pointer'
      )}>
      {statusText}

      {actions && actions.length > 0 && (
        <div className="cursor-pointer flex justify-center">
          <PopOnMe actions={actions}>
            <EllipsisVerticalIcon className="h-4 w-4" />
          </PopOnMe>
        </div>
      )}
    </div>
  );
};

export default PaymentStatus;
