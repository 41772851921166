import { PencilSquareIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import Button from '../../../components/form-control/Button';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { updateCustomer, updateLead } from '../../../api/requests';
import {
  getFormattedPhoneNumber,
  getPhoneNumberFromShortCodeFormat
} from '../../../utils/miscFunctions';
import { useState } from 'react';
import { Customer } from '../../../models/customer/Customer';

interface EditCustomerProps {
  onProceed: () => void;
  onClose: () => void;
  customer: Customer;
}

const EditCustomer = ({ onProceed, onClose, customer }: EditCustomerProps) => {
  const customerName = `${customer?.firstName} ${customer?.lastName}`;
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <div className="w-full flex justify-between bg-primary-main p-5 items-center">
        <div className="flex gap-3 items-center ">
          <PencilSquareIcon className="size-6" />
          <h1 className="text-lg font-extrabold">{customerName ? customerName : 'N/A'}</h1>
        </div>
        <div className="">
          <XMarkIcon className="h-5 w-5 cursor-pointer" onClick={onClose} />
        </div>
      </div>

      <Formik
        initialValues={{
          phone: customer.phone ? getPhoneNumberFromShortCodeFormat(customer?.phone) : '',
          otherPhone: customer.otherPhone ? getPhoneNumberFromShortCodeFormat(customer?.otherPhone) : '',
          email: customer?.email ?? ''
        }}
        enableReinitialize={true}
        validateOnBlur
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Email provided is invalid.').required('Email is requied.')
        })}
        onSubmit={(values) => {
          const payload: any = {
            ...values,
            phone: getFormattedPhoneNumber(values.phone),
            otherPhone: getFormattedPhoneNumber(values.otherPhone)
          };

          const id = customer.id;

          if (id) setLoading(true);
          updateCustomer(id, payload.email, payload.phone, payload.otherPhone)
            .then((response: any) => {
              toast.success(response.message ?? 'Customer updated successfully.');
              onProceed();
            })
            .catch((error: any) => {
              toast.error(error.description ?? 'Something went wrong');
            })
            .finally(() => setLoading(false));
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="p-5 space-y-5" onSubmit={handleSubmit}>
            <div className="space-y-5 ">
              <Input
                type={'text'}
                id={'email'}
                name="email"
                value={values.email.trim()}
                onChange={handleChange}
                onBlur={handleChange}
                label="Email Address"
                required
                error={touched.email ? errors.email : undefined}
                helperText={touched?.email ? errors.email : undefined}
              />

              <Input
                type={'tel'}
                id={'phone'}
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleChange}
                label="Phone"
                required
                error={touched.phone ? errors.phone : undefined}
                helperText={touched?.phone ? errors.phone : undefined}
              />
              <Input
                type={'tel'}
                id={'otherPhone'}
                name="otherPhone"
                value={values.otherPhone}
                onChange={handleChange}
                onBlur={handleChange}
                label="Other Phone"
                error={touched.otherPhone ? errors.otherPhone : undefined}
                helperText={touched?.otherPhone ? errors.otherPhone : undefined}
              />
            </div>

            <div className="flex-col-reverse gap-5 flex md:flex-row pt-5">
              <div className="w-full md:w-1/2">
                <Button
                  label="Cancel"
                  onClick={onClose}
                  curved
                  className=" w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                />
              </div>

              <div className="w-full md:w-1/2">
                <Button
                  label="Save"
                  className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                  onClick={handleSubmit}
                  disabled={loading}
                  loading={loading}
                  curved
                  bgColor="primary"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditCustomer;
