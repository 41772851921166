import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

interface IInitialState {
  SHOW_TOP_LOADING: boolean;
  SESSION_EXPIRED: boolean;
  REDIRECT_URL: string;
}

const initialState: IInitialState = {
  SHOW_TOP_LOADING: false,
  SESSION_EXPIRED: false,
  REDIRECT_URL: ''
};

const globalSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    SHOW_TOP_LOADING: (state) => {
      state.SHOW_TOP_LOADING = true;
    },
    HIDE_TOP_LOADING: (state) => {
      state.SHOW_TOP_LOADING = false;
    },
    SESSION_EXPIRED: (state, action: PayloadAction<boolean>) => {
      state.SESSION_EXPIRED = action.payload;
    },
    REDIRECT_URL: (state, action: PayloadAction<string>) => {
      state.REDIRECT_URL = action.payload;
    }
  }
});

export const { SHOW_TOP_LOADING, HIDE_TOP_LOADING, SESSION_EXPIRED, REDIRECT_URL } =
  globalSlice.actions;

export const getTopLoadingState = (state: RootState): boolean => state.globals.SHOW_TOP_LOADING;
export const getSessionStatus = (state: RootState): boolean => state.globals.SESSION_EXPIRED;
export const getRedirectURL = (state: RootState): string => state.globals.REDIRECT_URL;

export default globalSlice.reducer;
