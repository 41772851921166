import Button from '../../../components/form-control/Button';
import usePayment from '../../../hooks/paymentHook';
import { Policy } from '../../../models/policy/Policy';
import { HomePolicy } from '../../../models/homePolicy/HomePolicy';
import { PaystackProps } from 'react-paystack/dist/types';
import { getPaystackKey } from '../../../utils/miscFunctions';
import { IVerifyMobilePaymentDTO } from '../../../resources/interfaces';
import useModal from '../../../hooks/modalHook';
import VerifyPaymentOTP from './VerifyPaymentOTP';
import { toast } from 'react-toastify';

interface IMakePaymentProps {
  policy: Policy | HomePolicy;
  onProceed: (paymentOption: "send-email" | "make-payment") => void; 
}
const MakePayment = (props: IMakePaymentProps) => {
  const { updateModalStates, closeModal } = useModal();

  const paystackConfig: PaystackProps = {
    reference: new Date().getTime().toString(),
    email: "support@brolly.africa",
    amount: props.policy
      ? parseInt((props.policy?.quote?.quotePlan?.initialDeposit??0 * 100).toFixed(2))
      : 0,
    currency: "GHS",
    publicKey: getPaystackKey() ?? "",
    metadata: {
      policyNumber: props.policy?.ref,
      custom_fields: [
        {
          display_name: "Policy Number",
          variable_name: "policyNumber",
          value: props.policy?.ref,
        },
      ],
    },
  };

  const { initializeHubtel } = usePayment({ paystackConfig: paystackConfig, policy: props.policy });

  const confirmPaymentOTP = (payload: IVerifyMobilePaymentDTO, phone: string) => {
    updateModalStates({
      show: true,
      children: (
        <VerifyPaymentOTP
          data={payload}
          phone={phone}
          onProceed={() => {
            toast.success('Recurring payment initiated successfully for this policy.', {
              autoClose: 3000
            });
            closeModal();
          }}
        />
      )
    });
  };

  return (
    <>
      <h3 className='text-md font-bold font-poppins'>How will the payment be made?</h3>

      <div className="mt-4 space-y-4">
        <Button 
          label='Send payment link to customer' 
          className='border-[1px] border-primary-main rounded w-full md:w-[70%]' 
          onClick={() => props.onProceed("send-email")}
        />
        <Button 
          label='Enter payment details for customer' 
          className='rounded bg-primary-main w-full md:w-[70%]' 
          onClick={() => props.onProceed("make-payment")}
        />
      </div>
    </>
  );
};

export default MakePayment;

type paymentOptionTabs = "hubtel" | "itConsort"
