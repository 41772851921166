import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { assignMember, getAdmins, setRelationshipManager } from '../../../api/requests';
import SelectDropdown, {
  ISelectDropdownItem
} from '../../../components/form-control/SelectDropdown';

export interface IAssignRelationshipManager {
  teamId: string;
  managerId: string;
}

const AssignRelationshipManager = ({
  onProceed,
  onClose,
  teamID
}: {
  onProceed: () => void;
  onClose: () => void;
  teamID: string;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [admins, setAdmins] = useState<any>([]);

  useEffect(() => {
    getAllAdmins();
  }, []);

  const getAllAdmins = (page = 0) => {
    setLoading(true);
    getAdmins()
      .then((response: any) => {
        const _admins = response?.data?.data;
        setAdmins(_admins);
      })
      .catch((error: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload: IAssignRelationshipManager = {
      teamId: teamID,
      managerId: values.id
    };

    setRelationshipManager(payload)
      .then((response: any) => {
        toast.success('Member assigned to team successfully', {
          autoClose: 5000
        });
        onProceed();
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <h2>Assign Relationship Manager</h2>
          <XMarkIcon onClick={onClose} width={'11px'} height={'11px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="flex items-center justify-center mt-[26px]">
        <span className="text-center px-2">
          You are about to assign a relationship manager to this team.
        </span>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            id: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            // email: Yup.string().email('Email provided invalid.').required('Email is requied.')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="">
                <SelectDropdown
                  label="Select Team Manager"
                  name="id"
                  list={admins?.map((admin: any) => (
                    {
                        name: `${admin.firstName} ${admin.lastName}`,
                        value: admin.id
                    }
                  ))}
                  listSelectedValue={values.id}
                  onChange={(val: ISelectDropdownItem | null) => {
                    setFieldValue('id', val);
                  }}
                  onBlur={handleBlur}
                  placeholder="Select Manager"
                  error={touched.id ? errors.id : undefined}
                  helperText={touched?.id ? errors.id : undefined}
                  required
                />
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  className="w-fit px-6 text-[14px]"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AssignRelationshipManager;
