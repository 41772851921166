/* eslint-disable @typescript-eslint/no-explicit-any */
import Page from '../../components/PageWrapper';
import { ReactComponent as CheckMark } from "./check-mark.svg";
import { ReactComponent as SignupImage } from "./signup-vector.svg";

interface IAuthWrapperProps {
  children: JSX.Element;
  pageTitle?: string;
  callToActionMsg?: string;
}

const valueProps = [
  "Create a customized store in 5mins",
  "Reduce time spent on policy creation by 70%",
  "AI sales team: create policies even when your team is absent",
  "AI claims management to detect fraud etc."
]

function AuthWrapper(props: IAuthWrapperProps) {
  const { callToActionMsg = "Complete your account, start selling more" } = props;

  return (
    <Page title={props.pageTitle}>
      <div className='flex flex-col-reverse md:flex-row justify-evenly px-4 md:px-10 py-10 min-h-screen items-center bg-gradient-to-b from-[#FBC02D]/[0.33] to-[FBC02D]/[0] space-x-8 space-y-8 gap-y-8'>
        {/* children */}
        <div className="w-full pb-5 flex justify-end">
          {props.children}
        </div>

        {/* Welcome */}
        <div className='w-full font-poppins'>
          <h3 className='font-bold text-[36px] leading-[45px] md:text-[60px] md:leading-[70px] text-black font-poppins'>Welcome to <br /> Brolly</h3>
          <p className='text-black py-4'>{callToActionMsg}</p>
          <h3 className='text-black font-bold pb-2 text-[18px] mt-2'>AI solutions to grow your sales</h3>

          {valueProps.map((valueProp: string, index: number) => (
            <div key={index} className="flex items-center space-y-2">
              <CheckMark className='w-[10px] h-[10px] mr-2 md:mr-4' />
              <span className='text-[12px] md:text-[13px text-black h-full mb-2'>{valueProp}</span>
            </div>
          ))}

          <div>
            <SignupImage className='absolute right-0 bottom-[50px]' />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default AuthWrapper;
