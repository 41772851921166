import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { markPolicyCreationAsDone } from '../../../api/requests';
import TextArea from '../../../components/form-control/TextArea';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { Policy } from '../../../models/policy/Policy';

export interface IMarkPolicyAsDone {
    onProceed: () => void;
    onClose: () => void;
    policy?: Policy;
}

export interface IMarkPolicyAsDoneDTO {
    reason: string;
}

const MarkPolicyAsDone = ({ onProceed, onClose, policy }: IMarkPolicyAsDone) => {
    const [submitting, setSubmitting] = useState<boolean>(false);

    const submitHandler = (values: any) => {
        setSubmitting(true);

        const payload: IMarkPolicyAsDoneDTO = {
            ...values,
            reason: values.reason
        };

        if (policy?.ref) {
            markPolicyCreationAsDone(policy.ref, payload)
                .then((response: any) => {
                    onProceed();
                    toast.success(response.message ?? 'Policy marked as complete successfully.', {
                        autoClose: 3000
                    });
                })
                .catch((error: any) => {
                    toast.error(error.description ?? 'Failed to mark policy as complete', {
                        autoClose: 3000
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    return (
        <div className="bg-white">
            <div className="bg-primary-main flex justify-between rounded">
                <div className="p-[1rem] flex justify-between w-full items-center">
                    <div className="flex items-center  gap-1">
                        <div className="flex items-center justify-center bg-white h-[2.5rem] w-[2.5rem] rounded-full">
                            <CheckBadgeIcon className="h-[1.5rem] w-[1.5rem]" />
                        </div>

                        <h2 className="font-bold text-lg text-start">Mark Policy As Done</h2>
                    </div>

                    <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
                </div>
            </div>

            <div className="flex items-center justify-center py-5">
                <h3 className="px-2">You are about to mark this policy as done. </h3>
            </div>

            <div className="p-[1rem] w-full">
                <Formik
                    initialValues={{
                        reason: ''
                    }}
                    validateOnChange={false}
                    validateOnBlur={true}
                    validationSchema={Yup.object({
                        reason: Yup.string().required('Reason is required.')
                    })}
                    onSubmit={(values) => {
                        submitHandler(values);
                    }}>
                    {({ values, errors, handleSubmit, handleChange, handleBlur, touched, isSubmitting }) => (
                        <form className="space-y-5" onSubmit={handleSubmit}>
                            <TextArea
                                label="Reason"
                                name="reason"
                                value={values.reason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Give reason for marking the policy as done"
                                error={touched.reason ? errors.reason : undefined}
                                helperText={touched?.reason ? errors.reason : undefined}
                                required />

                            <div className="flex-col-reverse gap-5 flex md:flex-row py-5">
                                <Button
                                    label="Cancel"
                                    onClick={onClose}
                                    curved
                                    className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main"
                                />

                                <Button
                                    label={'Confirm'}
                                    className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-white"
                                    curved
                                    bgColor="primary"
                                    disabled={submitting}
                                    loading={submitting}
                                    type="submit"
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default MarkPolicyAsDone;
