import { useEffect, useMemo, useState } from 'react';
import { getAgencyCommissions } from '../../../api/requests';
import Loader from '../../../components/Loader';
import GTable from '../../../components/elements/GTable';
import useUserAccount from '../../../hooks/userAccountHook';
import { User } from '../../../models/User';
import { useParams } from 'react-router-dom';
import { BsCheckCircle, BsDashLg } from 'react-icons/bs';
import { ICommission } from '../../../models/commission/ICommission';
import { Commission } from '../../../models/commission/Commission';
import moment from 'moment';
const Commissions = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [commissions, setCommissions] = useState<Commission[]>([]);
  const { getProfile } = useUserAccount();

  const user = new User(getProfile());

  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    if (id) getCommissions(id);
  }, []);

  const getCommissions = (id: string) => {
    getAgencyCommissions(id)
      .then((response: any) => {
        setCommissions(response.map((commission: ICommission) => new Commission(commission)));
      })
      .catch((error: any) => {})
      .then(() => {
        setLoading(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Policy ID',
            accessor: 'policyNumber',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Currency',
            accessor: 'currency',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Payment Received',
            accessor: 'paidAmount',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Com Rate (%)',
            accessor: 'rate',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Gross Com',
            accessor: 'commissionAmount',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value.toFixed(2) : 'N/A'}</span>;
            }
          },

          {
            Header: 'WHT(5%)',
            accessor: 'withholdingTax',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value.toFixed(2) : 'N/A'}</span>;
            }
          },

          {
            Header: 'Net Com',
            accessor: 'netCommission',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value.toFixed(2) : 'N/A'}</span>;
            }
          },
          {
            Header: 'Payment Date',
            accessor: 'dateOfPayment',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YY') : 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'isPaid',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? (
                    <>
                      <span>
                        <BsCheckCircle className="h-5 w-5 text-white bg-green-600 rounded-full" />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="">
                        <BsDashLg className="h-4 w-4" />
                      </span>
                    </>
                  )}
                </span>
              );
            }
          }
        ]
      }
    ],
    []
  );
  return (
    <div>
      {(user.isSuperAdmin() || user.isAdmin() || user.isReviewer()) && (
        <div className="rounded-md h-full mb-1 justify-between px-2 pb-3 pt-7 align-items-center">
          <div className="overflow-x-auto shadow-md ">
            {loading ? (
              <div className="h-screen flex items-center justify-center bg-white">
                <Loader message="Fetching agency commissions. Please wait..." brollyLoader />
              </div>
            ) : (
              <>
                {commissions ? (
                  <>
                    <div className="overflow-x-auto shadow-md ">
                      <GTable
                        selectionChanged={(selection: any) => {
                          // setSlcMember(selection);
                        }}
                        columns={columns}
                        data={commissions}
                        tableType={'commission'}
                        showSearch={false}
                      />
                    </div>
                  </>
                ) : (
                  <div className=" h-40 text-center flex items-center justify-center font-bold">
                    <h2>No commission found.</h2>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Commissions;
