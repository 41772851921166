import { XMarkIcon } from '@heroicons/react/24/outline';
import { LinkIcon } from '@heroicons/react/24/solid';
import { copyTextToClipboard } from '../utils/miscFunctions';
import { toast } from 'react-toastify';
import ShareComponent from './share/ShareComponent';

interface CopyLinkProps {
  onProceed?: () => void;
  onClose: () => void;
  link: string;
  title: string | (() => string);
}
const CopyLink = ({ onProceed, onClose, link, title }: CopyLinkProps) => {
  const handleCopyClick = (value: string) => {
    copyTextToClipboard(value)
      .then(() => {
        toast.success('Copied to clipboard', {
          autoClose: 3000
        });
      })
      .catch((err) => {});
  };

  return (
    <div className="">
      <div className="bg-primary-main flex justify-between">
        <div className="p-5 flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-1">
            <div className="flex items-center justify-center bg-white size-11 rounded-full">
              <LinkIcon className="h-[24px] w-[24px]" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">{`${title}`}</h2>
          </div>
          <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="p-5 ">
        <div onClick={() => handleCopyClick(link)} className="text-sm mb-5 cursor-pointer">
          {link}
        </div>
        <ShareComponent title={'Payment Link'} text={link} url={link} withShareBtn />
      </div>
    </div>
  );
};

export default CopyLink;
