import { useEffect, useState, useRef } from 'react';
import { apiUrl } from '../api/requests';

interface UseSSEOptions<Data> {
  endpoint: string;
  eventType: string;
  onData?: (data: Data) => void; // New prop onData
}

interface SSEData<Data> {
  data: Data | null;
  error: string | null;
}

const useSSE = <Data,>({ endpoint, eventType, onData }: UseSSEOptions<Data>): SSEData<Data> => {
  const [sseData, setSSEData] = useState<SSEData<Data>>({ data: null, error: null });
  const eventSourceRef = useRef<EventSource | null>(null);

  useEffect(() => {
    eventSourceRef.current = new EventSource(`${apiUrl}${endpoint}`);

    const handleEvent = (event: MessageEvent) => {
      const eventData: Data = JSON.parse(event.data);
      setSSEData({ data: eventData, error: null });
      onData?.(eventData);
    };

    const handleError = (errorEvent: Event) => {
      const errorMessage = 'Error occurred';
      console.error('EventSource failed:', errorEvent);
      setSSEData({ data: null, error: errorMessage });
    };

    eventSourceRef.current.addEventListener(eventType, handleEvent);
    eventSourceRef.current.addEventListener('error', handleError);

    return () => {
      eventSourceRef.current?.close();
    };
  }, [endpoint, eventType, onData]);

  return sseData;
};

export default useSSE;
