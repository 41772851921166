import { GENDER, LEAD_SOURCE, LEAD_STATUS, LEAD_TAGS } from "../../resources/enums";
import { IStaff } from "../staff/IStaff";
import { ILead, ILeadActivityStreams, ILeadTask, ILeadUpdate, IUpdateLeadTrail } from "./ILead";


export class Lead {
    id: string;
    firstname: string;
    lastname: string;
    email?: string;
    phone: string;
    currentInsurer?: string
    insuranceType?: string
    registrationNumber?: string
    description?: string
    gender: GENDER
    leadSource?: LEAD_SOURCE
    expiryDate: string
    leadStatus: LEAD_STATUS;
    owner?: IStaff;
    createdOn: string;
    dateOfBirth?: string;
    leadUpdates?: IUpdateLeadTrail[];
    activityStreams?: ILeadActivityStreams[];
    tasks?: ILeadTask[];
    tag?:LEAD_TAGS;

    constructor (Imodel: ILead) {
        this.id = Imodel.id ;
        this.firstname= Imodel.firstname;
        this.lastname= Imodel.lastname;
        this.email= Imodel.email;
        this.phone= Imodel.phone;
        this.description= Imodel.description;
        this.currentInsurer= Imodel.currentInsurer;
        this.registrationNumber= Imodel.registrationNumber;
        this.expiryDate= Imodel.expiryDate;
        this.leadSource= Imodel.leadSource;
        this.insuranceType= Imodel.insuranceType;
        this.gender= Imodel.gender;
        this.phone= Imodel.phone;
        this.leadStatus = Imodel.leadStatus;
        this.createdOn = Imodel.createdOn;
        this.owner = Imodel.owner;
        this.dateOfBirth = Imodel.dateOfBirth;
        this.leadUpdates = Imodel.leadUpdates;
        this.activityStreams = Imodel.activityStreams;
        this.tasks = Imodel.tasks;
        this.tag=Imodel.tag;
    }

}