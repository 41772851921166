import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/form-control/Button";
import usePolicy from "../../../hooks/policyHook";
import { Policy } from "../../../models/policy/Policy";
import { POLICY_REVIEW_STATES } from "../../../resources/enums";
import { IReviewPolicyDTO } from "../../../resources/interfaces";

const SubmitForApproval = ({ policy, onSubmitted, onCancel }: { policy: Policy | undefined; onSubmitted: () => void; onCancel: () => void }) => {
  const { reviewPolicy } = usePolicy();

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const submitPolicy = () => {
    setSubmitting(true);
    
    const payload: IReviewPolicyDTO = {
      reason: "", 
      reviewStage: POLICY_REVIEW_STATES.IN_APPROVAL
    }

    reviewPolicy(policy?.ref ?? "", payload)
      .then((response: any) => {
        toast.success("Good job. Policy submitted for approval successfully.")
        onSubmitted();
      }).catch((error: Error) => {
        toast.error("Failed to submit policy for approval. Please try again later.");
      }).finally(() => setSubmitting(false))
  }
  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <h2>Submit for Approval</h2>
          <XMarkIcon onClick={onCancel} width={"11px"} height={"11px"} className="cursor-pointer" />
        </div>
      </div>

      <div className="p-4 space-y-10">
        <div className="pt-6">
          <p>By submitting this policy, you agree that all of the details
            provided by the policy&apos;s owner is accurate as of this day. Do you
            agree to submit this?
          </p>
        </div>

        <div className="flex justify-end space-x-6">
          <Button
            label="Cancel"
            className="w-fit px-8 h-[45px] rounded-[5px] text-base border-[1px] border-primary-main"
            onClick={onCancel}
          />
          <Button
            label="Submit"
            className="bg-primary-main w-fit px-8 py-2 h-[45px] rounded-[5px] text-base"
            onClick={submitPolicy}
            loading={submitting}
            disabled={submitting}
          />
        </div>
      </div>
    </div>
  );
};

export default SubmitForApproval;