import { Formik } from 'formik';
import * as Yup from 'yup';
import { IAdminDownloadAllPolicies } from '../../resources/interfaces';
import { POLICY_REVIEW_STATES } from '../../resources/enums';
import DatePickerInput from '../form-control/DatePickerInput';
import { subDays } from 'date-fns';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Button from '../form-control/Button';
import moment from 'moment';
import { INPUT_DATE_FORMAT } from '../../resources/constants';
import { downloadPolicies } from '../../api/requests';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { getSelectOptionsFromEnum } from '../../utils/miscFunctions';
import SelectDropdown from '../form-control/SelectDropdown';
import useUserAccount from '../../hooks/userAccountHook';
import { User } from '../../models/User';

interface IDownloadPromptProps {
  onProceed: (data: IAdminDownloadAllPolicies) => void;
  onClose: () => void;
}

interface IFormData {
  active: boolean;
  reviewStage: string;
  from: string;
  to: string;
}

const AdminDownloadAllPolicies = ({ ...props }: IDownloadPromptProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { getProfile } = useUserAccount();
  const user = new User(getProfile());
  const submitHandler = (values: any) => {
    setSubmitting(true);

    const data: IFormData = {
      ...values,
      active: values.active,
      reviewStage: user.isSuperAdmin()
        ? values.reviewStage == 'All'
          ? null
          : values.reviewStage
        : 'APPROVED',
      from: moment(values.from).format(INPUT_DATE_FORMAT),
      to: moment(values.to).format(INPUT_DATE_FORMAT)
    };

    downloadPolicies(data)
      .then((response: any) => {
        window.open(response.documentUrl, '_blank');
        props.onProceed(data);
      })
      .catch(() => {
        toast.error('Failed to export file. Please try again later.', {
          autoClose: 3000
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <h2 className="font-bold text-lg">Confirm Export</h2>
          <XMarkIcon
            onClick={props.onClose}
            width={'18px'}
            height={'18px'}
            className="cursor-pointer"
          />
        </div>
      </div>
      <Formik
        initialValues={{
          active: true,
          reviewStage: '',
          from: subDays(new Date(), 7),
          to: new Date()
        }}
        validateOnBlur
        validationSchema={Yup.object().shape({
          reviewStage: user.isSuperAdmin()
            ? Yup.string().required('Review stage is required')
            : Yup.string(),
          from: Yup.date().typeError('Date type error').required('Date from required')
        })}
        onSubmit={(values) => {
          submitHandler(values);
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="px-4 py-[30px] bg-white space-y-4" onSubmit={handleSubmit}>
            <div className="flex space-x-4 w-full justify-between">
              <DatePickerInput
                id={'from'}
                label="From"
                selected={values.from}
                onChange={(date: Date | null) => {
                  setFieldValue('from', date);
                }}
                classes="w-full"
                showYearDropdown
                allowFutureSelections={false}
                error={touched.from && errors.from !== undefined ? String(errors.from) : undefined}
                helperText={
                  touched.from && errors.from !== undefined ? String(errors.from) : undefined
                }
                dateFormat={'dd/MM/yyyy'}
              />

              <DatePickerInput
                id={'to'}
                selected={values.to}
                onChange={(date: Date | null) => {
                  setFieldValue('to', date);
                }}
                label="To"
                classes="w-full"
                showYearDropdown
                allowFutureSelections={false}
                error={touched.to && errors.to !== undefined ? String(errors.to) : undefined}
                helperText={touched.to && errors.to !== undefined ? String(errors.to) : undefined}
                dateFormat={'dd/MM/yyyy'}
              />
            </div>
            {user.isSuperAdmin() && (
              <SelectDropdown
                name="reviewStage"
                label="Review stage"
                listSelectedValue={values.reviewStage}
                onChange={(value: string) => {
                  setFieldValue('reviewStage', value);
                }}
                list={getSelectOptionsFromEnum(POLICY_REVIEW_STATES)}
                error={touched.reviewStage ? errors.reviewStage : undefined}
                helperText={touched.reviewStage ? errors.reviewStage : undefined}
              />
            )}

            <div className="flex justify-end w-full">
              <Button
                label="Submit"
                type="submit"
                className="bg-primary-main px-6 rounded border-none"
                loading={submitting}
                disabled={submitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AdminDownloadAllPolicies;
