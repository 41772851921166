import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import '../../styles/checkfontSelector.scss';

interface ICheckBoxFontSelectorProps {
  initialColor?: string;
  setFontColour: (color: string) => void;
  className?: string;
}

const CheckBoxFontSelector = ({ initialColor, setFontColour,className
}: ICheckBoxFontSelectorProps) => {
  const [isChecked1, setIsChecked1] = useState(initialColor === '#000000');
  const [isChecked2, setIsChecked2] = useState(initialColor === '#ffffff');

  useEffect(() => {
    setIsChecked1(initialColor?.toLowerCase() === '#000000');
    setIsChecked2(initialColor?.toLowerCase() === '#ffffff');
  }, [initialColor]);

  const handleCheckboxChange = (newFontColour: string) => {
    setIsChecked1(newFontColour === '#000000');
    setIsChecked2(newFontColour === '#ffffff');
    setFontColour(newFontColour);
  };

  const checkboxStyle = {
    backgroundColor: 'black',
    color: 'white',
    outline: 'none'
  };

  return (
    <div className="flex items-center gap-5">
      <label>
        <input
          type="checkbox"
          value="#000000"
          checked={isChecked1}
          onChange={() => handleCheckboxChange('#000000')}
          style={checkboxStyle}
          className={className}
        />
      </label>

      <label className="option">
        <input
          type="checkbox"
          id=""
          value="#ffffff"
          checked={isChecked2}
          onChange={() => handleCheckboxChange('#ffffff')}
          className={clsx('', className)}
        />
        <span className="customcheckbox"></span>
      </label>
    </div>
  );
};

export default CheckBoxFontSelector;
