/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../components/form-control/Input';
import Button from '../../../components/form-control/Button';
import SelectMenu from '../../../components/form-control/SelectMenu';
import { toast } from 'react-toastify';
import { editPolicyPayment } from '../../../api/requests';
import { PAYMENT_CHANNELS, PAYMENT_PROCESSORS } from '../../../resources/enums';
import {
  getFormattedPaymentChannel,
  getValueFromFormattedPaymentChannel
} from '../../../utils/miscFunctions';
import { FaRegEdit } from 'react-icons/fa';
import DatePickerInput from '../../../components/form-control/DatePickerInput';
import moment from 'moment';
import { BANKS_OF_GHANA, INPUT_DATE_FORMAT } from '../../../resources/constants';
import { XMarkIcon } from '@heroicons/react/24/solid';
import TextArea from '../../../components/form-control/TextArea';
import FileUpload from '../../../components/elements/FileUpload/FileUpload';
import useImage from '../../../hooks/imageHook';
import { IRequestErrorResponse, IRequestResponse } from '../../../resources/interfaces';

interface IEditPayment {
  onProceed: () => void;
  onClose: () => void;
  payment: any;
}
export interface IEditPaymentDTO {
  reference: string;
  channel: string;
  processor: string;
  bank?: string;
  narration: string;
  dateOfPayment: string | Date;
  timeOfPayment: string | Date;
  dueDate: string | Date;
}

const ProcessorList: Array<string> = Object.values(PAYMENT_PROCESSORS);
const BankList: Array<string> = Object.values(BANKS_OF_GHANA);
const ChannelList: Array<string> = Object.values(PAYMENT_CHANNELS).map((value) =>
  getFormattedPaymentChannel(value)
);

const PaymentEditModal = ({ onProceed, payment, onClose }: IEditPayment) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentEvidence, setPaymentEvidence] = useState<File | null>(null);
  const { getImageFormData } = useImage();

  return (
    <Formik
      initialValues={{
        reference: payment?.reference,
        channel: payment?.channel,
        processor: payment?.processor,
        bank: payment?.bank,
        narration: payment?.narration,
        dateOfPayment: payment?.dateOfPayment,
        dueDate: payment?.dueDate ? new Date(payment.dueDate) : null
      }}
      validateOnChange
      validateOnBlur
      validationSchema={Yup.object().shape({})}
      onSubmit={(values) => {
        const payload: IEditPaymentDTO = {
          ...values,
          channel: getValueFromFormattedPaymentChannel(values.channel),
          dateOfPayment: values.dateOfPayment
            ? moment(values.dateOfPayment).format(INPUT_DATE_FORMAT)
            : '',
          timeOfPayment: values.dateOfPayment
            ? moment(values.dateOfPayment).format('HH:mm:ss')
            : '',
          dueDate: values.dueDate ? moment(values.dueDate).format(INPUT_DATE_FORMAT) : ''
        };

        let evidenceFile;
        if (paymentEvidence) {
          evidenceFile = getImageFormData(paymentEvidence);
        }

        setLoading(true);
        editPolicyPayment(payment.id, payload, evidenceFile)
          .then((response: IRequestResponse) => {
            if (response) {
              toast.success(response.message ?? 'Payment update request successful');
              onProceed();
            }
          })
          .catch((error: IRequestErrorResponse) => {
            toast.error(error.description);
          })
          .finally(() => {
            setLoading(false);
          });
      }}>
      {({ values, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <div className="bg-primary-main flex justify-between">
              <div className="p-[1rem] flex justify-between w-full items-center">
                <div className="flex items-center justify-center gap-1">
                  <div className="flex items-center justify-center bg-white h-[45px] w-[45px] rounded-full">
                    <FaRegEdit className="h-[24px] w-[24px]" />
                  </div>
                  <h2 className="font-[gilroy-bold] text-lg">Request Edit Approval</h2>
                </div>
                <XMarkIcon onClick={onClose} className="h-6 w-6 cursor-pointer" />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-5 p-5">
              <div className="grid grid-cols-2 gap-5">
                <SelectMenu
                  name="channel"
                  onChange={(value: string) => {
                    setFieldValue('channel', value);
                  }}
                  listSelectedValue={values.channel}
                  list={ChannelList}
                  onBlur={handleBlur}
                  label="Channel"
                  placeholder="Select payment channel"
                />

                <SelectMenu
                  name="processor"
                  list={ProcessorList}
                  listSelectedValue={values.processor}
                  onBlur={handleBlur}
                  onChange={(value: string) => {
                    setFieldValue('processor', value);
                  }}
                  label="Processor"
                  placeholder="Processor"
                />
              </div>

              {values.processor === PAYMENT_PROCESSORS.BANK && (
                <SelectMenu
                  name="bank"
                  list={BankList}
                  listSelectedValue={values.bank}
                  onBlur={handleBlur}
                  onChange={(value: string) => {
                    setFieldValue('bank', value);
                  }}
                  label="Bank Name"
                  placeholder="Bank Name"
                />
              )}

              <div className="grid grid-cols-3 gap-5">
                <div className="col-span-2">
                  <Input
                    type={'text'}
                    name="reference"
                    onChange={handleChange}
                    value={values.reference}
                    onBlur={handleBlur}
                    label="Reference"
                    placeholder="Payment reference"
                  />
                </div>

                <DatePickerInput
                  id={'dateOfPayment'}
                  selected={values.dateOfPayment}
                  onChange={(date: Date | null) => {
                    setFieldValue('dateOfPayment', date);
                  }}
                  label="Date of payment"
                  classes="w-full"
                  showTimeSelect
                  showYearDropdown
                  showMonthDropdown
                  allowFutureSelections={false}
                  dateFormat="dd-MM-yyyy hh:mm a"
                />
              </div>

              <TextArea
                name={'narration'}
                value={values.narration}
                label="Narration"
                placeholder="What's the reason for the edit request."
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete
              />
              <FileUpload
                type="document"
                label="Payment Evidence"
                onFileLoad={(images: any) => {
                  if (images && images[0]) {
                    setPaymentEvidence(images[0]);
                  }
                }}
                showFileMax={false}
                onFileDeleted={() => {
                  setPaymentEvidence(null);
                }}
                cropImage={false}
                maxHeight={90}
                maxWidth={200}
                imageContainerHeight={'200px'}
                imageAlt={'Payment Evidence'}
              />
            </div>
          </div>

          <div className="w-full p-5">
            <Button
              type="submit"
              label="Save"
              className="h-[45px] bg-primary-main rounded  hover:bg-primary-border"
              disabled={loading}
              loading={loading}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default PaymentEditModal;
