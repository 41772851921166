import React, { Fragment, ReactElement, ReactNode, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { XMarkIcon } from '@heroicons/react/24/outline';

// import Back from "../panel/Back";
export interface IModalProps {
  show: boolean;
  children?: JSX.Element | ReactElement | ReactNode | null;
  onClose: () => void | undefined;
  title?: string;
  backdrop?: boolean;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | 'screen-xl'
    | 'screen-2xl'
    | 'fullscreen';
  scrollable?: boolean;
  showCloseButton?: boolean;
  staticMode?: boolean;
  autoCloseAfter?: number; // Param in milliseconds
  showBackButton?: boolean;
  onBack?: () => void;
  classes?: string;
  showTitleSection?: boolean;
}

function Modal({
  show = false,
  onClose,
  title,
  backdrop = true,
  scrollable = false,
  size = 'md',
  children,
  showCloseButton = true,
  staticMode = false,
  autoCloseAfter,
  showBackButton = false,
  onBack,
  classes,
  showTitleSection = true
}: IModalProps): JSX.Element {
  const cancelButtonRef = useRef(null);
  const close = (_value: boolean) => {
    staticMode ? null : onClose();
  };

  useEffect(() => {
    if (autoCloseAfter) {
      setTimeout(() => {
        onClose();
      }, autoCloseAfter);
    }
  }, []);

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as={'div'} className="fixed z-[9999]" onClose={close} initialFocus={cancelButtonRef}>
        {backdrop && (
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Backdrop
              as="div"
              // className="fixed inset-0 bg-gray-500 bg-opacity-25 z-30"
              className="fixed inset-0 bg-black bg-opacity-70 z-30"
            />
          </Transition.Child>
        )}
        <div className="fixed inset-0 overflow-y-auto ">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel
                className={clsx(
                  'w-full transform  bg-white py-6 px-3 md:px-6 text-left align-middle shadow-xl transition-all rounded',
                  { ['overflow-y-auto']: scrollable },
                  { ['max-w-xs']: size == 'xs' },
                  { ['max-w-sm']: size == 'sm' },
                  { ['max-w-md']: size == 'md' },
                  { ['max-w-lg']: size == 'lg' },
                  { ['max-w-xl']: size == 'xl' },
                  { ['max-w-2xl']: size == '2xl' },
                  { ['max-w-3xl']: size == '3xl' },
                  { ['max-w-4xl']: size == '4xl' },
                  { ['max-w-5xl']: size == '5xl' },
                  { ['max-w-6xl']: size == '6xl' },
                  { ['max-w-7xl']: size == '7xl' },
                  { ['max-w-screen-xl']: size == 'screen-xl' },
                  { ['max-w-screen-2xl']: size == 'screen-2xl' },
                  { ['h-screen']: size == 'fullscreen' },
                  classes
                )}>
                {showTitleSection && (
                  <div className="flex items-center">
                    {showBackButton && (
                      <div className="mr-4">
                        {/* <Back onBack={() => onBack?.()} /> */}
                        {/* <div onBack={() => onBack?.()}>Go back</div> */}
                      </div>
                    )}
                    {title && (
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                    )}
                    <span
                      ref={cancelButtonRef}
                      onClick={onClose}
                      className={clsx('ml-auto cursor-pointer', {
                        ['hidden']: !showCloseButton
                      })}>
                      <XMarkIcon className="w-5 h-5" />
                    </span>
                  </div>
                )}
                <div>{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
export default Modal;
