import React, { useState } from 'react';
import Button from '../../../components/form-control/Button';
import clsx from 'clsx';
import ProvideMomoDetails from './ProvideMomoDetails';
import { MOBILE_MONEY_NETWORKS, PAYMENT_PROCESSORS } from '../../../resources/enums';
import usePayment from '../../../hooks/paymentHook';
import { Policy } from '../../../models/policy/Policy';
import { HomePolicy } from '../../../models/homePolicy/HomePolicy';
import { PaystackProps } from 'react-paystack/dist/types';
import { getPaymentDataFromPolicy, getPaystackKey } from '../../../utils/miscFunctions';
import { IPolicyPaymentData, IVerifyMobilePaymentDTO } from '../../../resources/interfaces';
import useModal from '../../../hooks/modalHook';
import VerifyPaymentOTP from './VerifyPaymentOTP';
import { toast } from 'react-toastify';
import ProvideItConsortiumMomoDetails from './ItConsortiumMomoDetails';
import { initializeItConsortiumPayment } from '../../../api/requests';
import CustomerPhoneVerificationModal from '../policies/PhoneVerificationModal';

interface IChoosePaymentModal {
  policy: Policy | HomePolicy;
  onProceed: (paymentData?: IPolicyPaymentData, processor?: PAYMENT_PROCESSORS) => void;
}
const ChoosePaymentModal = ({ policy, onProceed }: IChoosePaymentModal) => {
  const { updateModalStates, closeModal, ModalComponent } = useModal();

  const [activeTab, setActiveTab] = useState<paymentOptionTabs>('hubtel');

  const paystackConfig: PaystackProps = {
    reference: new Date().getTime().toString(),
    email: 'support@brolly.africa',
    amount: policy ? parseInt((policy?.quote?.quotePlan?.initialDeposit ?? 0 * 100).toFixed(2)) : 0,
    currency: 'GHS',
    publicKey: getPaystackKey() ?? '',
    metadata: {
      policyNumber: policy?.ref,
      custom_fields: [
        {
          display_name: 'Policy Number',
          variable_name: 'policyNumber',
          value: policy?.ref
        }
      ]
    }
  };

  return (
    <>
      <div className="flex justify-center py-4">
        <img src="/img/logo.svg" />
      </div>

      <p className="text-[16px] font-[600] py-5 text-center">Provide mobile money details of the customer</p>

      <div className=" w-full flex items-center justify-center">
        <Button
          fullWidth
          id="hubtel"
          onClick={() => setActiveTab('hubtel')}
          label="Hubtel"
          className={clsx(activeTab == 'hubtel' && 'bg-primary-main')}
        />

        <Button
          fullWidth
          id="itConsort"
          onClick={() => {
            setActiveTab('itConsort');
          }}
          label="IT Consortium"
          className={clsx(activeTab == 'itConsort' && 'bg-primary-main')}
        />
      </div>

      <div className="mt-2">
        {activeTab === 'hubtel' && (
          <ProvideMomoDetails
            policy={policy}
            onProceed={(data: { phone: string; network: MOBILE_MONEY_NETWORKS }) => {
              if (policy) {
                onProceed(
                  getPaymentDataFromPolicy(policy, data.phone, data.network),
                  PAYMENT_PROCESSORS.HUBTEL
                );
              }
            }}
          />
        )}

        {activeTab === 'itConsort' && (
          <ProvideItConsortiumMomoDetails
            onProceed={(data: { phone: string; policyNumber: string }) => {
              if (policy) {
                onProceed(
                  getPaymentDataFromPolicy(policy, data.phone, MOBILE_MONEY_NETWORKS.MTN),
                  PAYMENT_PROCESSORS.IT_CONSORTIUM
                );
              }
            }}
            policy={policy}
          />
        )}
      </div>
      <ModalComponent />
    </>
  );
};

export default ChoosePaymentModal;

type paymentOptionTabs = 'hubtel' | 'itConsort';
