import React from 'react';
import SidebarContent from './SidebarContent';
interface sidebarProps {
  showSidebar: boolean;
  onMenuItemClicked: () => void;
}

function index({ showSidebar, onMenuItemClicked }: sidebarProps) {
  const displayClasses = showSidebar
    ? ' mx-auto lg:flex z-10 flex-col w-fit fixed md:static h-full'
    : 'hidden mx-auto z-10 min-[1200px]:flex flex-col w-fit h-full';
  // const displayClasses = props.showSidebar
  //   ? '  lg:flex flex-col z-10 sm:min-w-[245px] fixed h-full'
  //   : 'hidden lg:flex flex-col z-10 min-w-[245px] fixed h-full';

  return (
    <aside className={displayClasses}>
      <SidebarContent onMenuClick={onMenuItemClicked} />
    </aside>
  );
}

export default index;
