import { PARTNER_TYPES, TEAM_TYPES } from '../../resources/enums';
import { ITeamPayoutAccount } from '../../resources/interfaces';
import { ITeam } from '../team/ITeam';
import { Team } from '../team/Team';
import { IUser, User } from '../User';
import { IPartner } from './IPartner';

export class Partner {
  id: string;
  name: string;
  numberOfMembers: number;
  relationshipManager?: User;
  commissionRate?: string;
  summary: any;
  teamLead: string | undefined;
  type: PARTNER_TYPES;
  createdOn: Date;
  updatedOn: Date;
  commissionRateComprehensive: number;
  commissionRateThirdParty: number;
  email: string;
  address: string;
  mobilePhone: string;
  corporatePhone: string;
  setupCompleted: boolean;
  payoutAccount: ITeamPayoutAccount;
  logo: string;
  members: User[];
  policyCreationTime?: number;
  teams: Team[];

  constructor(Imodel: IPartner) {
    this.id = Imodel.id;
    this.name = Imodel.name;
    this.numberOfMembers = Imodel.numberOfMembers;
    this.relationshipManager = Imodel.relationshipManager
      ? new User(Imodel.relationshipManager)
      : undefined;
    this.commissionRate = Imodel.commissionRate;
    this.summary = Imodel.summary;
    this.teamLead = Imodel.teamLead ?? undefined;
    this.type = Imodel.type;
    this.createdOn = new Date(Imodel.createdOn);
    this.updatedOn = new Date(Imodel.updatedOn);
    this.commissionRateComprehensive = Imodel.commissionRateCP;
    this.commissionRateThirdParty = Imodel.commissionRateTP;
    this.email = Imodel.email;
    this.address = Imodel.address;
    this.mobilePhone = Imodel.mobilePhone;
    this.corporatePhone = Imodel.corporatePhone;
    this.setupCompleted = Imodel.setupCompleted;
    this.payoutAccount = Imodel.paymentAccount;
    this.logo = Imodel.logo;
    this.members = Imodel.members ? Imodel.members?.map((member: IUser) => new User(member)) : [];
    this.policyCreationTime = Imodel.policyCreationTime ?? undefined;
    this.teams = Imodel.teams ? Imodel.teams?.map((team: ITeam) => new Team(team)) : [];
  }

  getPolicyCompletionTimeInMinutes(): number | undefined {
    return this.policyCreationTime ? this.policyCreationTime / 1000 : undefined;
  }
}
