import React from 'react';
import BrollyDatePicker from '../datepicker/datepicker';
import { useState } from 'react';
import moment from 'moment';
import Button from '../../form-control/Button';
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

export interface SelectedDateRange {
  from: string;
  to: string;
}
interface Props {
  children?: React.ReactNode;
  onSubmit: (data: SelectedDateRange) => void;
}

function DashboardDatePickers({ onSubmit }: Props) {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const submit = () => {
    const data = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    };
    onSubmit(data);
  };

  return (
    <div className="w-full datepickers flex items-center sm:mt-0">
      <div className="px-0">
        <BrollyDatePicker
          selectedDate={startDate}
          onChange={handleStartDateChange}
          placeholderText="Start Date"
        />
      </div>
      <span className="text-center lg:px-3">to</span>
      <div className="px-0 ">
        <BrollyDatePicker
          selectedDate={endDate}
          onChange={handleEndDateChange}
          placeholderText="End Date"
        />
      </div>
      <Button
        disabled={!startDate || !endDate}
        onClick={submit}
        className="bg-brolly-yellow  font-semibold rounded py-2 px-4 sm:mt-0 disabled:bg-red lg:ml-3 lg:mt-3 xl:mt-0 border-none flex items-center">
        Check
        <ChevronRightIcon className="ml-3 size-4 font-semibold" />
      </Button>
    </div>
  );
}

export default DashboardDatePickers;
