/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { DASHBOARD } from '../../resources/links';
import Avatar from '../Avatar';
import PopOnMe from '../elements/poponme';
import useUserAccount from '../../hooks/userAccountHook';
import useRoute from '../../hooks/routesHook';
import useSSE from '../../hooks/useSSE';
import Notifications from '../notification/Notification';
import MyPopOver from '../elements/PopOver';
import clsx from 'clsx';
import { getFormattedAccessRight } from '../../utils/miscFunctions';
import NotificationIcon from '../notification/NotificationIcon';
import { NOTIFICATION_TYPE } from '../../resources/enums';
import { GoReport } from "react-icons/go";
import ReportFeatureBug from '../report';
import ReportIcon from '../report/ReportIcon';

interface INavbarProps {
  handleMenuClick: (e: any) => void;
  isSticky?: boolean;
  hideProfileSection?: boolean;
  hideMobileMenu?: boolean;
}

function Navbar(props: INavbarProps) {
  const { signOut, user } = useUserAccount();
  const { getDashboardBase } = useRoute();
  const navigate = useNavigate();
  const [allNotifications,] = useState<any[]>([]);
  const [myNotifications, setMyNotifications] = useState<any[]>([]);
  const { isSticky = true } = props;

  const handleOnline = () => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
      return;
    }

    if (Notification.permission === 'granted') {
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(() => {});
    }
  };

  useEffect(() => {
    handleOnline();
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  useSSE<any>({
    endpoint: `/sse/admin/notifications/me/${user.id}`,
    eventType: 'my-notifications',
    onData: (data) => {
      setMyNotifications(data);

      if (data && data.length > myNotifications.length) {
        const newNotification = data[data.length - 1];
        showNotification(newNotification);
      }
    }
  });

  const showNotification = (notification: any) => {
    const { message, data } = notification;
    const notificationTitle = 'New Notification';

    // Display desktop notification...
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        const notification = new Notification(notificationTitle, { body: message });
        setTimeout(() => {
          notification.close();
        }, 10 * 1000);
        notification.addEventListener('click', () => {
          const route = `${getDashboardBase()}/${handleNotificationClick(data)}`;
          navigate(route);
        });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(() => {
          // Handle permission...
        });
      }
    }
  };

  const handleNotificationClick = (notification: any) => {
    let route = '';
    switch (notification.type) {
      case NOTIFICATION_TYPE.POLICY:
        route = `policies/${notification.identifier}`;
        break;
      case NOTIFICATION_TYPE.LEAD:
        route = `leads/${notification.identifier}`;
        break;
      case NOTIFICATION_TYPE.CUSTOMER:
        route = `customers/${notification.identifier}`;
        break;
      case NOTIFICATION_TYPE.QUOTE:
        route = `quotes/${notification.identifier}`;
        break;

      case NOTIFICATION_TYPE.PAYMENT:
        route = `policies/${notification.identifier}`;
        break;

      case NOTIFICATION_TYPE.TASK:
        route = `policies/${notification.identifier}`;
        break;

      default:
        break;
    }

    navigate(`${getDashboardBase()}/${route}`, { replace: true });
  };

  const accountActions = [
    {
      label: 'Logout',
      callback: () => {
        signOut();
      }
    },
    {
      label: 'Settings',
      callback: () => {
        navigate(`${getDashboardBase()}/settings`);
      }
    }
  ];

  let notificationsLength = myNotifications?.length;

  return (
    <div
      className={clsx('py-2 bg-[#F4F4F4] max-w-screen w-full', {
        ['fixed top-0 z-[10]']: isSticky
      })}>
      <div className="flex items-center m-auto mx-5 sm:mx-10">
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center mr-5 sm:mr-20">
            <div className="min-[1200px]:hidden mr-5">
              {!(props.hideMobileMenu || user.isFuelVendor()) && (
                <div onClick={props.handleMenuClick} className="w-8 items-center cursor-pointer">
                  <Bars3Icon className="text-dark" />
                </div>
              )}
            </div>

            {/* NavBrand */}
            <div className="">
              <Link to={DASHBOARD}>
                <div className="rounded-full p-1 w-fit">
                  <img
                    className="max-w-[2.5rem] h-auto truncate"
                    alt={
                      user.isInsurer() || user.isFuelPartner()
                        ? user.team?.partner?.name
                        : user.team?.name
                    }
                    src={user.team?.partner?.logo ?? user.team?.logo ?? '/img/logo.png'}
                    loading="eager"
                  />
                </div>
              </Link>
            </div>
          </div>
         
          {!props.hideProfileSection && (
            <div className="flex items-center gap-4">
               
               <MyPopOver
                  className=" h-fit max-h-[40rem]"
                  trigger={<ReportIcon/>}
                  closeOnOutsideClick={true}>
                <ReportFeatureBug type={''} environment={''} subject={''} description={''} identifier={''} />
                </MyPopOver>

              {(allNotifications || myNotifications) && (
                <MyPopOver
                  className=" h-fit max-h-[40rem]"
                  trigger={<NotificationIcon notifications={notificationsLength} />}
                  closeOnOutsideClick={true}>
                  <Notifications
                    handleOnclick={handleNotificationClick}
                    allNotifications={allNotifications}
                    meNotifications={myNotifications}
                  />
                </MyPopOver>
              )}
        
              <div className="flex items-center gap-2">
                <span className="font-semibold text-sm md:text-base">{user.fullName}</span>
                <span className="text-sm hidden md:block">
                  ( {getFormattedAccessRight(user.accessRight)} )
                </span>
              </div>
              <div className="">
                <PopOnMe actions={accountActions}>
                  <div className="cursor-pointer flex items-center">
                    <Avatar src={user.account?.profileImage} size="sm" />
                  </div>
                </PopOnMe>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
