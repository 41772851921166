import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from '../../../../components/form-control/Button';
import Input from '../../../../components/form-control/Input';
import { addFuelPurchase } from '../../../../api/requests';
import { getFormattedPhoneNumber } from '../../../../utils/miscFunctions';
import cashlogo from '../../../../resources/icons/CashIcon.png';
import mtnLogo from '../../../../resources/icons/MTN-Logo.png';
import telecelLogo from '../../../../resources/icons/Telecel-Logo.png';
import airteltigoLogo from '../../../../resources/icons/ATM-Logo-01.png';
import { FUEL_SALES_PAYMENT_MODES } from '../../../../resources/enums';

interface AddFuelSaleProps {
  onProceed: () => void;
  onClose: () => void;
}

const modes = [
  { label: 'Cash', value: FUEL_SALES_PAYMENT_MODES.CASH, logo: cashlogo },
  { label: 'MTN', value: FUEL_SALES_PAYMENT_MODES.MTN, logo: mtnLogo },
  { label: 'Telecel', value: FUEL_SALES_PAYMENT_MODES.TELECEL, logo: telecelLogo },
  { label: 'AirtelTigo', value: FUEL_SALES_PAYMENT_MODES.AT, logo: airteltigoLogo }
];

const AddFuelSale = ({ onProceed, onClose }: AddFuelSaleProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [pump, setPump] = useState<string>(() => localStorage.getItem('pump') || '');

  useEffect(() => {
    localStorage.setItem('pump', pump);
  }, [pump]);

  return (
    <div className="w-full rounded-md">
      <div className="flex justify-between p-5 items-center text-center">
        <h2 className="text-lg font-semibold mx-auto">Add a Sale</h2>
        <div className="flex justify-center items-center p-1 bg-slate-300 rounded-full">
          <XMarkIcon onClick={onClose} className="cursor-pointer h-5 w-5" />
        </div>
      </div>

      <div className="p-5">
        <Formik
          initialValues={{
            phoneNumber: '',
            amount: '',
            pump,
            vehicleRegistration: '',
            mode: FUEL_SALES_PAYMENT_MODES.CASH // Set default mode to CASH
          }}
          validationSchema={Yup.object({
            phoneNumber: Yup.string()
              .matches(/^[0-9]+$/, 'Must be only digits')
              .length(10, 'Must be exactly 10 digits')
              .required('Phone number of customer is required.'),
            amount: Yup.string().trim().required('Amount is required.'),
            pump: Yup.string().trim().required('Pump number is required.'),
            vehicleRegistration: Yup.string()
              .trim()
              .required('Vehicle registration number is required.'),
            mode: Yup.string().required('Payment mode is required.')
          })}
          onSubmit={(values) => {
            setSubmitting(true);
            const payload = {
              ...values,
              phoneNumber: getFormattedPhoneNumber(values.phoneNumber)
            };

            addFuelPurchase(payload)
              .then((response) => {
                toast.success(response.message ?? 'Sale recorded successfully', {
                  autoClose: 5000
                });
                onProceed();
              })
              .catch((error) => {
                toast.error(error?.description, { autoClose: 5000 });
              })
              .finally(() => setSubmitting(false));
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2">Payment Option</label>
                <div className="flex gap-2 justify-between">
                  {modes.map((mode) => (
                    <button
                      key={mode.value}
                      type="button"
                      onClick={() => setFieldValue('mode', mode.value)}
                      className={`p-1 w-24 border shadow ${
                        values.mode === mode.value ? 'border-brolly-yellow' : 'border-gray-300'
                      } rounded`}>
                      <img
                        src={mode.logo}
                        alt={mode.label}
                        loading="eager"
                        className="h-10 w-full object-center rounded"
                      />
                    </button>
                  ))}
                </div>
                {touched.mode && errors.mode ? (
                  <div className="text-red-500 text-sm mt-1">{errors.mode}</div>
                ) : null}
              </div>

              <Input
                name="pump"
                label="Pump Number"
                value={values.pump}
                placeholder="Enter pump number"
                onChange={(e) => {
                  handleChange(e);
                  setPump(e.target.value);
                }}
                error={touched.pump ? errors.pump : undefined}
                helperText={touched.pump ? errors.pump : undefined}
                required
              />

              <Input
                name="phoneNumber"
                label="Phone"
                value={values.phoneNumber?.trim()}
                placeholder="Phone number"
                type="tel"
                max={10}
                onChange={handleChange}
                error={touched.phoneNumber ? errors.phoneNumber : undefined}
                helperText={touched.phoneNumber ? errors.phoneNumber : undefined}
                required
              />

              <Input
                name="vehicleRegistration"
                label="Vehicle Registration"
                value={values.vehicleRegistration}
                placeholder="Enter vehicle registration number"
                onChange={handleChange}
                error={touched.vehicleRegistration ? errors.vehicleRegistration : undefined}
                helperText={touched.vehicleRegistration ? errors.vehicleRegistration : undefined}
                required
              />

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Select quick amount</label>
                <div className="flex justify-between">
                  {[50, 100, 150, 200, 250].map((amount) => (
                    <button
                      key={amount}
                      type="button"
                      onClick={() => setFieldValue('amount', amount)}
                      className={`p-2 w-14 border ${
                        Number(values.amount) === amount ? 'border-blue-500' : 'border-gray-300'
                      } rounded`}>
                      {amount}
                    </button>
                  ))}
                </div>
              </div>

              <Input
                type="number"
                name="amount"
                value={values.amount}
                onChange={handleChange}
                placeholder="or enter amount"
                error={touched.amount ? errors.amount : undefined}
                helperText={touched.amount ? errors.amount : undefined}
                required
              />

              <div className="flex justify-end mt-3">
                <Button
                  type="submit"
                  label={
                    values.mode === FUEL_SALES_PAYMENT_MODES.CASH
                      ? 'Record Sale'
                      : 'Request Payment'
                  }
                  bgColor="primary"
                  curved
                  className="w-full mx-auto text-sm"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddFuelSale;
