import { useRef } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, FC, useState } from "react";
import { ITextareaProps } from "../../types";
import { ScrollToFormikError } from "../elements/ScrollToFormikError";

export const TextArea: FC<ITextareaProps> = ({
  className,
  name,
  value,
  id,
  label,
  placeholder,
  disabled,
  readOnly,
  passValidation,
  failedValidation,
  editable,
  isRequired,
  rows,
  maxLength,
  showCharCount, 
  onValueChanged,
  onFocusOut
}) => {
  const [editField, setEditField] = useState<boolean>(false);
  const [textareaValue, setTextareaValue] = useState<string>(value || "");

  const fieldRef = useRef<HTMLTextAreaElement>(null);

  return (
    <>
      {editable && !editField ? (
        <div className="flex flex-col box-border w-full p-0">
          <label className="w-full text-gray-900 p-0 mb-1 font-medium text-sm">
            {label} 
          </label>
          <p className="w-full flex flex-row items-center justify-between space-x-4 bg-transparent pl-3 p-1 rounded-md text-gray-700 group border text-xs">
            <span className="w-full truncate">{value}</span>
            <span
              className="invisible group-hover:visible hover:bg-gray-200 rounded-md p-3 cursor-pointer"
              onClick={() => {
                setEditField(true);
              }}
            >
              <PencilIcon className="w-4 h-4 text-priamry-main" />
            </span>
          </p>
        </div>
      ) : (
        <div className="flex flex-col box-border w-full p-0 font-medium text-sm space-y-2">
          {(label || showCharCount) && (
            <div className="w-full text-gray-900 p-0 flex justify-between">
              <label htmlFor={id}>
                {label} {isRequired && "*"}
              </label>
              {maxLength && <span> {textareaValue?.length} / {maxLength}</span>}
              
            </div>
          )}

          <div
            className={`flex flex-row items-center justify-center ${disabled && "bg-gray-300"
              } relative`}
          >
            <textarea
              ref={fieldRef}
              id={id}
              className={`border outline-none border-gray-200 py-3 px-4 ${disabled ? "border-none text-gray-900" : ""
                } text-sm ${failedValidation ? "border-danger-main" : " "
                } w-full ${passValidation ? "border-success-main" : ""
                } focus:ring-2 ${value === "" && !disabled ? "ring-2 ring-primary-border" : ""
                } ${className}`}
              value={value}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              required={isRequired}
              rows={rows}
              maxLength={maxLength}
              name={name}
              onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                onValueChanged(ev);
                setTextareaValue(ev.target.value);
              }}
                onBlur={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                onFocusOut(ev);
                setTextareaValue(ev.target.value);
              }}
            />
          </div>
        </div>
      )}
      <ScrollToFormikError
        fieldName={name ?? id ?? ""}
        fieldRef={fieldRef}
      />
    </>
  );
};

export default TextArea;
