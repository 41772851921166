import clsx from 'clsx';
import { ReactNode, useState } from 'react';

interface IToolTip {
  message: string | JSX.Element;
  bgColor?: string;
  children: ReactNode;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  textColor?:string;
}

export default function Tooltip({ message, children, bgColor, direction = 'top', textColor}: IToolTip) {
  const [isHovered, setIsHovered] = useState(false);

  const getPositionClass = () => {
    switch (direction) {
      case 'top':
        return 'bottom-6 left-1/2 ';
      case 'bottom':
        return 'top-6 left-1/2 ';
      case 'left':
        return 'top-0 right-6  ';
      case 'right':
        return 'top-0 left-8 ';
      default:
        return 'top-0 left-1/2 ';
    }
  };

  return (
    <div className="relative inline-block">
      <div
        className="group relative inline-block cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {children}
      </div>
      {isHovered && (
        <div
          className={clsx(
            `absolute min-w-max text-sm transition-all rounded p-2 text-black ${message =='View discount info' ?'mt-0':'mt-3'}`,
            bgColor ?? 'bg-primary-surface',
            textColor ?? 'text-black',
            getPositionClass()
          )}>
          {message}
        </div>
      )}
    </div>
  );
}
