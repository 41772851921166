import Papa from 'papaparse';
import useModal from './modalHook';
import AdminDownloadAllPolicies from '../components/downloadComponents/AdminDownloadAllPolicies';
import ExportPartnerFuelData from '../components/downloadComponents/ExportFuelData';

function useDownload() {
  const { ModalComponent, closeModal, updateModalStates } = useModal();

  const DownloadHookModal = ModalComponent;
  function convertToCSV(data: any) {
    const csv = Papa?.unparse(data);
    return csv;
  }

  function downloadCSV(data: any) {
    const csvContent = convertToCSV(data);

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    URL.revokeObjectURL(url);
  }

  function downloadExcel(data: any, fileName = 'policies') {
    const csvContent = convertToCSV(data);

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xls`;
    a.click();
    URL.revokeObjectURL(url);
  }

  const fetchFile = (fileUrl: string) => {
    fetch(fileUrl).then((response) => {});
  };

  const showAdminDownloadAllPoliciesModal = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <AdminDownloadAllPolicies
          onProceed={() => {
            closeModal();
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showExportFuelSalesModal = (partnerTeams: any) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <ExportPartnerFuelData
          onProceed={() => {
            closeModal();
          }}
          onClose={closeModal}
          partnerTeams={partnerTeams}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  return {
    convertToCSV,
    downloadCSV,
    downloadExcel,
    fetchFile,
    showAdminDownloadAllPoliciesModal,
    DownloadHookModal,
    ModalComponent,
    showExportFuelSalesModal
  };
}

export default useDownload;
