import React, { ChangeEvent, Dispatch, Fragment, SetStateAction, useRef, useState } from 'react';
import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
  CheckIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/24/solid';
import _ from 'lodash';
import { ScrollToFormikError } from '../elements/ScrollToFormikError';

export interface ISelectMenuItemValue {
  id?: string;
  avatar?: string;
  name: string;
  value: string;
  meta?: Record<string, any>;
}

export interface ISelectMenuProps {
  selectInputClassName?: string;
  label?: string;
  groupedSelectedValue?: ISelectMenuItemValue | null;
  listSelectedValue?: number | string | null;
  showAvatar?: boolean;
  search?: boolean;
  onChange: Dispatch<SetStateAction<ISelectMenuItemValue | null>> | any;
  onBlur?: (e: React.FocusEvent<any, Element>) => void | null | any;
  list?: Array<string | number>;
  grouped?: boolean;
  groupedList?: Record<string, Array<ISelectMenuItemValue>>;
  placeholder?: string;
  description?: JSX.Element | React.ReactElement | string | number | any;
  name?: string;
  error?: string | undefined;
  disabled?: boolean;
  required?: boolean;
  helperText?: string | undefined;
  sort?: boolean;
  emptyListMessage?: string;
  searchPlaceHolder?: string;
  wrapperClasses?: string;
}

function SelectMenu<T>({
  selectInputClassName,
  label,
  groupedSelectedValue,
  listSelectedValue,
  showAvatar = false,
  search = false,
  onChange,
  grouped = false,
  groupedList = {},
  list = [],
  placeholder,
  description,
  name,
  error,
  disabled = false,
  required = false,
  helperText,
  onBlur,
  sort = false,
  emptyListMessage,
  searchPlaceHolder = 'Search ...',
  wrapperClasses
}: ISelectMenuProps): JSX.Element {
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState<Record<string, Array<ISelectMenuItemValue>>>({});
  const [listSearchResult, setListSearchResult] = useState<Array<string | number>>([]);
  const searchedOrGroupedList =
    _.isEmpty(searchResult) && searchValue.length < 1 ? groupedList : searchResult;
  const searchedOrList: Array<string | number> =
    _.isEmpty(listSearchResult) && searchValue.length < 1 ? list : listSearchResult;

  const fieldRef = useRef<HTMLDivElement>(null);

  const onSearchChange = (evt: ChangeEvent<HTMLInputElement> | undefined) => {
    const val = evt?.target.value ?? '';
    setSearchValue(val);
    const srchList: any = [];
    let srchGrpList: Record<string, ISelectMenuItemValue[]> = {};

    if (grouped) {
      _.forEach(groupedList, (listItem, key) => {
        listItem.forEach((item) => {
          if (
            item.name.toLowerCase().includes(val.toLowerCase()) ||
            key.toLowerCase().includes(val.toLowerCase())
          ) {
            if (srchGrpList?.[key]) srchGrpList[key].push(item);
            else
              srchGrpList = {
                ...srchGrpList,
                [key]: [item]
              };
          }
        });
      });

      setSearchResult(srchGrpList);
    } else {
      list.forEach((listItem: string | number) => {
        if (listItem.toString().toLowerCase().includes(val)) srchList.push(listItem);
      });
      setListSearchResult(srchList);
    }
  };

  return (
    <Listbox
      ref={fieldRef}
      value={
        grouped
          ? groupedSelectedValue
          : list.includes(listSelectedValue ?? '')
          ? listSelectedValue
          : null
      }
      onChange={onChange}
      disabled={disabled}
      as={'div'}
      className={clsx({ ['animate-wiggle']: error && required }, wrapperClasses)}>
      {({ open }) => (
        <>
          {/* Label */}
          {label && (
            <Listbox.Label
              className={clsx('block text-sm font-medium text-gray-700', {
                ['!text-danger-main']: error && required
              })}>
              {label} <span className="!text-danger-main">{required && '*'}</span>
              {description && (
                <p
                  className={clsx('text-xs text-gray-400 italic', {
                    ['!text-danger-border']: error && required
                  })}>
                  {description}
                </p>
              )}
            </Listbox.Label>
          )}

          <div className="mt-1 relative">
            {/* Input Field */}
            <div>
              <Listbox.Button
                name={name}
                className={clsx(
                  'relative w-full bg-white border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-primary-main focus:border-primary-border sm:text-sm rounded',
                  {
                    ['bg-red-200 border-danger-main focus:ring-danger-main focus:border-danger-border']:
                      error && required
                  },
                  selectInputClassName
                )}
                onBlur={!open ? onBlur : (e: any) => null}>
                {(grouped ? groupedSelectedValue : listSelectedValue) ? (
                  <span className="flex items-center">
                    {showAvatar && grouped && (
                      <img
                        src={groupedSelectedValue?.avatar}
                        alt=""
                        className={clsx(
                          'flex-shrink-0 h-8 w-8 rounded-full border-primary-main border-2',
                          { ['!border-danger-main']: error && required }
                        )}
                      />
                    )}
                    <span className={clsx('ml-3 block truncate', { ['ml-0']: disabled })}>
                      {grouped ? groupedSelectedValue?.value : listSelectedValue}
                    </span>
                  </span>
                ) : (
                  <span className="text-gray-400 text-sm">
                    {placeholder ? placeholder : 'Select Option'}
                  </span>
                )}

                {!disabled && (
                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none border-none">
                    {open ? (
                      <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="false" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    )}
                  </span>
                )}
              </Listbox.Button>

              {/* Helper Text */}
              <div className={clsx('text-xs text-gray-400', { ['!text-danger-main']: error })}>
                {helperText}
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-40 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {/* List */}
                {(!grouped && _.isEmpty(list)) || (grouped && _.isEmpty(groupedList)) ? (
                  // List Empty
                  <div className="w-full flex justify-center items-center h-20 flex-col">
                    <h4 className="font-medium text-red-400">No Option&#40;s&#41; Available!</h4>
                    <small className="text-xs text-gray-500">
                      {emptyListMessage ? emptyListMessage : 'Refresh to try again'}
                    </small>
                  </div>
                ) : (
                  <>
                    {/* Search */}
                    {search && (
                      <div className="px-2 py-4 relative">
                        <div className="py-1 border-b-2 border-gray-200">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="sm:text-sm">
                              <MagnifyingGlassIcon className="h-5 w-5 stroke-gray-400" />
                            </span>
                          </div>

                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="focus:ring-primary-main outline-0 focus:border-primary-main block w-full pl-10 text-md sm:text-sm"
                            placeholder={searchPlaceHolder}
                            value={searchValue}
                            onChange={(e) => onSearchChange(e)}
                          />
                        </div>
                      </div>
                    )}

                    <div className="px-2 pb-2 pt-1">
                      {grouped ? (
                        <>
                          {/* Groupings */}
                          {(grouped && _.isEmpty(searchedOrGroupedList)) ||
                          (!grouped && _.isEmpty(searchedOrList)) ? (
                            <div className="w-full flex justify-center items-center h-14 flex-col">
                              <h3 className="font-normal !text-red-400">
                                No Search Result&#40;s&#41;
                              </h3>
                              {/* <Button 
                                                                children = {<div>
                                                                    Add {searchValue} as a vehicle model
                                                                </div>}
                                                            /> */}
                            </div>
                          ) : (
                            <>
                              {_.map(searchedOrGroupedList, (groupItem, key) => (
                                <div key={key} className="px-2">
                                  <h3 className="text-sm text-gray-400">{key.toUpperCase()}</h3>
                                  <hr className="border-gray-200" />

                                  <div className="mt-1 mb-2">
                                    {_.map(groupItem, (listItem, index) => (
                                      <Listbox.Option
                                        key={index}
                                        className={({ active, selected }) =>
                                          clsx(
                                            active ? 'bg-primary-surface' : 'text-gray-900',
                                            'cursor-pointer select-none relative py-1 pl-3 pr-0 text-sm',
                                            { ['bg-primary-main']: selected }
                                          )
                                        }
                                        value={listItem}>
                                        {({ selected }) => (
                                          <>
                                            <div className="flex items-center">
                                              {/* Avatar */}
                                              {showAvatar && (
                                                <img
                                                  src={listItem.avatar}
                                                  alt=""
                                                  className={clsx(
                                                    'flex-shrink-0 h-6 w-6 rounded-full',
                                                    { ['border-primary-main']: selected }
                                                  )}
                                                />
                                              )}

                                              <span
                                                className={clsx(
                                                  selected ? 'font-semibold' : 'font-normal',
                                                  'ml-3 block truncate text-sm'
                                                )}>
                                                {listItem.name}
                                              </span>
                                            </div>

                                            {selected ? (
                                              <span
                                                className={clsx(
                                                  selected ? 'text-white' : 'text-gray-900',
                                                  'absolute inset-y-0 right-0 flex items-center pr-0'
                                                )}>
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {(sort ? searchedOrList.sort() : searchedOrList).map((listItem, key) => (
                            <Listbox.Option
                              key={key}
                              className={({ active, selected }) =>
                                clsx(
                                  active ? 'bg-primary-surface' : 'text-gray-900',
                                  'cursor-pointer select-none relative py-2 pl-3 pr-0 text-sm',
                                  { ['bg-primary-main']: selected }
                                )
                              }
                              value={listItem}>
                              {({ selected }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={clsx(
                                        selected ? 'font-semibold' : 'font-normal',
                                        'ml-3 block truncate text-sm'
                                      )}>
                                      {listItem}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={clsx(
                                        selected ? 'text-white' : 'text-gray-900',
                                        'absolute inset-y-0 right-0 flex items-center pr-1'
                                      )}>
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                )}
              </Listbox.Options>
            </Transition>
          </div>
          <ScrollToFormikError fieldName={name ?? ''} fieldRef={fieldRef} />
        </>
      )}
    </Listbox>
  );
}

export default SelectMenu;

export type TMiniSelectMenuType = string | number | readonly string[] | undefined;

interface IMiniSelectMenuProps {
  name?: string;
  options: Array<TMiniSelectMenuType>;
  value?: TMiniSelectMenuType;
  onChange?:
    | Dispatch<SetStateAction<TMiniSelectMenuType>>
    | React.ChangeEventHandler<HTMLSelectElement>
    | any;
}

export function MiniSelectMenu({
  options,
  value,
  onChange,
  name
}: IMiniSelectMenuProps): JSX.Element {
  return (
    <select className={clsx('border-0 outline-none')} value={value} onChange={onChange} name={name}>
      {options.map((option, key) => (
        <option key={key} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
