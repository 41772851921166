import clsx from 'clsx';
import { REDACT_INFO_TYPES } from '../../resources/enums';
import { useState } from 'react';
import Tooltip from '../ToolTip';
import { showProtectedData } from '../../api/requests';
import { toast } from 'react-toastify';

const RedactInfo = (props: IRedactInfoProps): JSX.Element => {
  const { totalToRedact = 6, clickable = true } = props;

  const [showInfo, setShowInfo] = useState<boolean>(false);

  const showRedactedInfo = () => {
    showProtectedData(props.dataId, props.dataType)
      .then((response: any) => {
        setShowInfo(true);
      })
      .catch(() => {
        toast.error('Sorry, cannot show protected info at this time. Please try again later', {
          autoClose: 5000
        });
      });
  };

  const getRedactedInfo = (info: string): string => {
    let redactedInfo = '';

    if (props.infoType === REDACT_INFO_TYPES.PHONE) {
      redactedInfo = info
        .split('')
        ?.map((char: string, index: number) => {
          if (isLastFour(info, index)) {
            return char;
          } else {
            return '*';
          }
        })
        ?.join('');
    } else if (props.infoType === REDACT_INFO_TYPES.EMAIL) {
    } else {
      let countRedacted = 0;

      redactedInfo = info
        .split('')
        ?.map((char: string, index: number) => {
          if (countRedacted >= totalToRedact) {
            countRedacted++;
            return char;
          } else {
            countRedacted++;
            return '*';
          }
        })
        ?.join('');
    }
    return redactedInfo;
  };

  const isLastFour = (number: string, valueIndex: number): boolean => {
    return number.length - valueIndex <= 4;
  };

  return (
    <Tooltip message={!showInfo ? 'Click to show info' : 'Click to hide info'}>
      <div className={clsx('', props.classes)}>
        <span
          className={clsx('flex items-center cursor-default', clickable && 'cursor-pointer')}
          onClick={() => {
            if (clickable) {
              if (showInfo) {
                setShowInfo(false);
              } else {
                showRedactedInfo();
              }
            }
          }}>
          {showInfo ? props.info : getRedactedInfo(props.info)}
        </span>
      </div>
    </Tooltip>
  );
};

export default RedactInfo;

export interface IRedactInfoProps {
  infoType?: REDACT_INFO_TYPES;
  info: string;
  classes?: string;
  totalToRedact?: number;
  clickable?: boolean;
  dataId: string;
  dataType: string;
}
