/* eslint-disable @typescript-eslint/no-explicit-any */


import { Formik } from 'formik';
import * as Yup from 'yup';
import { EnvelopeIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';
import Input from '../../components/form-control/Input';
import Link from '../../components/Link';
import Button from '../../components/form-control/Button';
import { SIGN_IN } from '../../resources/links';
import Page from '../../components/PageWrapper';
import { resetAccountPAssword } from '../../api/requests';
import { toast } from 'react-toastify';
import MessagePrompt from '../../components/elements/MessagePrompt';
import { useState } from 'react';

function ForgotPassword() {
  const [response, setResponse] = useState<any>();
  return (
    <Page title="Forgot Password">
      <div className="w-full bg-white mx-1 rounded-md py-10 md:mx-14 font-poppins">
        <p className="text-sm sm:text-base py-2 text-center  text-[#9CA3AF]">
          Request for account password reset through mail
        </p>
        <Formik
          initialValues={{
            email: '',
            admin: true
          }}
          validateOnChange={false}
          validateOnBlur
          validationSchema={Yup.object({
            email: Yup.string().trim().email('Invalid email').required('Email is required')
          })}
          onSubmit={async (values) => {
            const payload = {
              ...values,
              email: values.email.toLowerCase()
            };

            try {
              const res = await resetAccountPAssword(payload.admin, payload.email);
              setResponse(res);

              toast.success(
                response.message ?? 'A link to reset account credentials has been sent to email',
                {
                  autoClose: 3000
                }
              );
            } catch (error: any) {
              toast.error(error?.description ?? `Sorry, No account exist with ${values.email}`, {
                autoClose: 3000
              });
            }
          }}>
          {({ values, errors, isSubmitting, handleSubmit, handleChange }) => (
            <form className="px-3 md:px-24 my-7" onSubmit={handleSubmit}>
              {response && (
                <MessagePrompt
                  type={'success'}
                  message={'A link to reset account credentials has been sent to email.'}
                  classes="w-full"
                />
              )}

              <div className="my-5">
                <Input
                  type="email"
                  label="Email"
                  name="email"
                  required
                  wrapperClasses="my-5"
                  value={values.email}
                  prepend={<EnvelopeIcon className="w-6 h-6 text-gray-500" />}
                  error={errors.email}
                  onChange={handleChange}
                  helperText={errors.email}
                />

                <Button
                  type="submit"
                  label="Submit"
                  className="mt-5 flex justify-center rounded px-6 w-full bg-black text-white"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />

                <div className="text-center mt-10">
                  <div className="text-sm flex gap-4 items-center justify-center py-1 sm:py-3">
                    <ArrowLeftIcon className="h-6 w-6 text-gray-600" />
                    <Link to={SIGN_IN} label="Back to Sign in" />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Page>
  );
}

export default ForgotPassword;
