import { Formik } from 'formik';
import { startCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Button from '../../../form-control/Button';
import Input from '../../../form-control/Input';
import { AGENCY_PAYMENT_ACCOUNT_TYPES, INSURER_STATUS_OPTIONS, TEAM_TYPES } from '../../../../resources/enums';
import * as Yup from 'yup';
import { INSURERS } from '../../../../resources/links';
import PlaceComponent from '../../../PlaceComponent';
import useUserAccount from '../../../../hooks/userAccountHook';
import { User } from '../../../../models/User';
import InputSelectMenu from '../../../form-control/InputSelectMenu';
import { BANKS_OF_GHANA } from '../../../../resources/constants';
import { IAddTeamPayoutDTO, ITeamPayoutAccount, IUpdateTeamDTO } from '../../../../resources/interfaces';
import { setAgencyPaymentAccount, updateTeam } from '../../../../api/requests';
import { toast } from 'react-toastify';
import { getFormattedPhoneNumber, getPhoneNumberFromShortCodeFormat } from '../../../../utils/miscFunctions';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { ITeam } from '../../../../models/team/ITeam';

const InsurerStatusList: Array<string> = Object.values(INSURER_STATUS_OPTIONS).map((value) =>
  startCase(value)
);

interface IInsurerDetailsProps {
  proceed: () => void;
}

const InsurerDetails = (props: IInsurerDetailsProps) => {
  const navigate = useNavigate();
  const { updateProfile } = useUserAccount();

  const onBack = () => {
    navigate(INSURERS);
  };

  const { getProfile } = useUserAccount();
  const user = new User(getProfile());

  return (
    <Formik
      initialValues={{
        contactPersonFirstName: '',
        contactPersonLastName: '',
        email: user.team?.email ?? "",
        address: user.team?.address ?? '',
        name: user.team?.name ?? "",
        mobilePhone: getPhoneNumberFromShortCodeFormat(user.team?.mobilePhone ?? ""),
        corporatePhone: getPhoneNumberFromShortCodeFormat(user.team?.corporatePhone ?? ""),
        bankName: user.team?.payoutAccount?.accountProvider ?? "",
        accountName: user.team?.payoutAccount?.accountName ?? "",
        accountNumber: user.team?.payoutAccount?.accountNumber ?? "", 
        accountBranch: user.team?.payoutAccount?.accountBranch??""
      }}
      validateOnBlur
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Insurer name is required.'),
        email: Yup.string()
          .email('Invalid email')
          .trim()
          .required("Insurer's email address is required."),
        bankName: Yup.string().required("Bank name is required"),
        accountName: Yup.string().required("Account name is required"),
        accountNumber: Yup.string().required("Account number is required"),
        mobilePhone: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, "Must be exactly 10 digits")
          .max(10, "Must be exactly 10 digits")
          .trim()
          .required("Phone number is required."),
        corporatePhone: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, "Must be exactly 10 digits")
          .max(10, "Must be exactly 10 digits")
          .trim()
          .required("Phone number is required.")
      })}
      onSubmit={(values) => {
        const payload: IUpdateTeamDTO = {
          ...values,
          type: TEAM_TYPES.INSURER,
          mobilePhone: getFormattedPhoneNumber(values.mobilePhone ?? ""),
          corporatePhone: getFormattedPhoneNumber(values.corporatePhone)
        };

        if (user.team) {
          const payoutPayload: IAddTeamPayoutDTO = {
            accountName: values.accountName,
            accountNumber: values.accountNumber,
            accountProvider: values.bankName,
            accountType: AGENCY_PAYMENT_ACCOUNT_TYPES.BANK, 
            accountBranch: values.accountBranch
          }
          const requests: any[] = [];

          requests.push(
            updateTeam(user.team.id, payload),
            setAgencyPaymentAccount(payoutPayload)
          );

          Promise.all(requests)
            .then((responses: any) => {
              const iuser = getProfile();

              // @ts-ignore
              const team: ITeam = { ...getProfile().team, ...payload, payoutAccount: payoutPayload as ITeamPayoutAccount }

              if (iuser?.team?.id) {
                updateProfile({ ...iuser, team: team })
              }
              props.proceed();
            }).catch((error) => {
              toast.error("Failed to update details. Please try again later. ", {
                autoClose: 5000
              })
            })
        }

      }}>
      {({
        values,
        errors,
        isSubmitting,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        touched
      }) => (
        <form className="px-5 md:px-[45px] pb-[30px] bg-white text-dark" onSubmit={handleSubmit}>
          <div className="flex items-center gap-2 mb-[36px] px-2 md:px-0 flex-col">
            <p className='text-[30px] text-dark text-center'>
              Hi {user.firstName}, I&apos;m Verb, and I&apos;m here to help you set up your insurer store on Brolly. Let&apos;s start by confirming the information below:
            </p>
          </div>

          <div className="px-[8rem]">
            <h3 className="text-xl font-semibold col-span-1 md:col-span-2 mt-10">Company Information</h3>
            <div className="grid grid-cols-1 items-center gap-5 md:gap-7 mt-5">
              <div className="flex flex-col md:flex-row space-x-4 justify-between w-full">
                <Input
                  type={'text'}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Company Name"
                  placeholder="Enter insurance name"
                  required
                  error={touched.name ? errors.name : undefined}
                  helperText={touched?.name ? errors.name : undefined}
                  className="rounded w-full "
                />

                <div className="w-full">
                  <Input
                    type={'email'}
                    name="email"
                    value={values.email.trim()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Email"
                    placeholder="Eg: support@insurance.com"
                    required
                    error={touched.email ? errors.email : undefined}
                    helperText={touched?.email ? errors.email : undefined}
                    className="w-full"
                    append={<CheckCircleIcon className="w-7 text-[#00c07b] mr-2" />}
                    disabled
                  />
                </div>
              </div>

              <div className="">
                <PlaceComponent
                  type={'text'}
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onAddressChange={(val: string) => {
                    setFieldValue('address', val);
                  }}
                  label="Address"
                  placeholder="Eg: 40 Awudu Street, Accra"
                  error={touched.address ? errors.address : undefined}
                  helperText={touched?.address ? errors.address : undefined}
                  classes="rounded"
                  required
                />
              </div>

              <div className="flex flex-col md:flex-row space-x-4 justify-between w-full">
                <Input
                  name='corporatePhone'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.corporatePhone}
                  error={touched.corporatePhone ? errors.corporatePhone : undefined}
                  label="Corporate Phone"
                  placeholder="Eg: xxxxxxxxxx"
                  wrapperClasses="w-full"
                />

                <Input
                  name='mobilePhone'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobilePhone}
                  error={errors.mobilePhone}
                  label={"Mobile Phone"}
                  wrapperClasses="w-full"
                  helperText={touched?.mobilePhone ? errors.mobilePhone : undefined}
                  placeholder="Eg: xxxxxxxxxx"
                />

                {/* <IntlPhoneInput
                  onBlur={handleBlur}
                  onChange={(phone: any) => {
                    setFieldValue("mobilePhone", phone);
                  }}
                  value={values.mobilePhone}
                  error={errors.mobilePhone}
                  label={"Mobile Phone"}
                  wrapperClasses="w-full"
                /> */}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <h3 className="text-xl font-semibold col-span-1 md:col-span-2 mt-10">Settlement Bank Account Details</h3>
                <InputSelectMenu
                  name="bankName"
                  listSelectedValue={values.bankName}
                  onChange={(value: string) => {
                    setFieldValue("bankName", value);
                  }}
                  // onBlur={handleBlur}
                  list={BANKS_OF_GHANA}
                  label="Bank Name"
                  placeholder=""
                  error={touched.bankName ? errors.bankName : undefined}
                  helperText={touched?.bankName ? errors.bankName : undefined}
                />

                <Input
                  type={'text'}
                  name="accountName"
                  value={values.accountName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showPasswordIcon
                  label="Account Name"
                  placeholder=""
                  error={touched.accountName ? errors.accountName : undefined}
                  helperText={touched?.accountName ? errors.accountName : undefined}
                />

                <Input
                  type={'text'}
                  name="accountNumber"
                  value={values.accountNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showPasswordIcon
                  label="Account Number"
                  placeholder=""
                  error={touched.accountNumber ? errors.accountNumber : undefined}
                  helperText={touched?.accountNumber ? errors.accountNumber : undefined}
                />

                <Input
                  type={'text'}
                  name="accountBranch"
                  value={values.accountBranch}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  showPasswordIcon
                  label="Account Branch"
                  placeholder=""
                  error={touched.accountBranch ? errors.accountBranch : undefined}
                  helperText={touched?.accountBranch ? errors.accountBranch : undefined}
                />
              </div>
            </div>

            <div className="flex-row  items-center md:flex md:justify-center md:mt-[56px] mt-8 ">
              <div className="">
                <Button
                  loading={isSubmitting}
                  label="Confirm"
                  type="submit"
                  className="w-full md:w-[150px]  bg-primary-main rounded h-[52px]"
                // bgColor="bg-primary-main"
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default InsurerDetails;
