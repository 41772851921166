import { Formik } from 'formik';
import { startCase } from 'lodash';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createNewInsurer } from '../../../api/requests';
import Input from '../../../components/form-control/Input';
import { ICreateInsurer } from '../../../models/insurer/IInsurer';
import { INSURER_STATUS_OPTIONS } from '../../../resources/enums';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import SelectMenu from '../../../components/form-control/SelectMenu';
import { INSURERS } from '../../../resources/links';
import FileUpload from '../../../components/elements/FileUpload/FileUpload';
import PlaceComponent from '../../../components/PlaceComponent';
import InputSelectMenu from '../../../components/form-control/InputSelectMenu';
import { InsurerAgentRoles } from '../../../resources/constants';
import { AxiosError } from 'axios';
import { IRequestErrorResponse } from '../../../resources/interfaces';
import { getFormattedPhoneNumber } from '../../../utils/miscFunctions';
import BackButton from '../../../components/BackButton';
import Button from '../../../components/form-control/Button';

const InsurerStatusList: Array<string> = Object.values(INSURER_STATUS_OPTIONS).map((value) =>
  startCase(value)
);

const AddInsurer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [insurerLogo, setInsurerLogo] = useState();
  const navigate = useNavigate();

  return (
    <div className=" px-0 md:px-6 w-full bg-white rounded">
      <div className="flex items-center p-4 gap-5">
        <Link to={INSURERS}>
          <BackButton />
        </Link>

        <h3 className="text-[#455A64] text-[24px] font-heading font-bold leading-[30px]">
          Create Insurer
        </h3>
      </div>

      <div className="w-full rounded border-[1px] border-gray-300 p-4">
        <Formik
          initialValues={{
            apiKey: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            contactPersonEmail: '',
            contactPersonPhone: '',
            email: '',
            location: '',
            midPassword: '',
            midUsername: '',
            name: '',
            phoneNumber: '',
            postalAddress: '',
            role: '',
            status: '',
            website: '',
            nicRegistration: '',
            branchCode: ''
          }}
          enableReinitialize={true}
          validateOnBlur
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Insurer name is required.'),
            phoneNumber: Yup.string()
              .trim()
              .required('Phone number is required')
              .length(10, 'Ensure the phone number is exactly 10 digits'),
            email: Yup.string()
              .email('Invalid email')
              .trim()
              .required("Insurer's email address is required."),
            contactPersonFirstName: Yup.string().required(
              "Contact person's first name is required"
            ),
            contactPersonLastName: Yup.string().required("Contact person's last name is required"),
            contactPersonPhone: Yup.string().required("Contact person's phone number is required"),
            contactPersonEmail: Yup.string().required("Contact person's email is required"),
            // location: Yup.string().required("Insurer's location is required"),
            midUsername: Yup.string().required("Insurer's MID username is required"),
            status: Yup.string().required("Insurer's status is required.")
          })}
          onSubmit={(values) => {
            const payload: ICreateInsurer = {
              ...values,
              country: 'Ghana',
              phoneNumber: getFormattedPhoneNumber(values.phoneNumber),
              contactPersonPhone: getFormattedPhoneNumber(values.phoneNumber)
            };

            setLoading(true);
            createNewInsurer(payload)
              .then((response: any) => {
                toast.success(response.message ?? 'Insurer created successfully');
                if (response) {
                  navigate(INSURERS);
                } else {
                }
              })
              .catch((error: any) => {
                toast.error(error.description ?? 'Failed to create insurer', {
                  autoClose: 3000
                });
              })
              .finally(() => {
                setLoading(false);
              });
          }}>
          {({
            values,
            errors,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            touched
          }) => (
            <form className="" onSubmit={handleSubmit} method="post">
              <div className="w-full grid grid-cols-1 md:flex  gap-5">
                <div className="w-full md:w-[65%] rounded border-[1px] border-gray-300 p-4">
                  <div className="flex flex-col space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                      <Input
                        type={'text'}
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Insurer Name"
                        placeholder="Eg: Enterprise Insurance"
                        required
                        error={touched.name ? errors.name : undefined}
                        helperText={touched?.name ? errors.name : undefined}
                      />
                      <Input
                        type={'text'}
                        name="midUsername"
                        value={values.midUsername}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="MID Username"
                        placeholder="Eg:  "
                        required
                        error={touched.midUsername ? errors.midUsername : undefined}
                        helperText={touched?.midUsername ? errors.midUsername : undefined}
                      />
                      <Input
                        type={'email'}
                        name="email"
                        value={values.email.trim()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Email"
                        placeholder="Eg: brolly@gnail.com"
                        required
                        error={touched.email ? errors.email : undefined}
                        helperText={touched?.email ? errors.email : undefined}
                      />
                      <Input
                        type={'tel'}
                        id={'phoneNumber'}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Phone Number"
                        placeholder="Eg: xxxxxxxxxx"
                        required
                        error={touched.phoneNumber ? errors.phoneNumber : undefined}
                        helperText={touched?.phoneNumber ? errors.phoneNumber : undefined}
                      />

                      <Input
                        type={'password'}
                        name="midPassword"
                        value={values.midPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="MID Password"
                        showPasswordIcon
                        placeholder=""
                        error={touched.midPassword ? errors.midPassword : undefined}
                        helperText={touched?.midPassword ? errors.midPassword : undefined}
                      />
                      <Input
                        type={'password'}
                        name="apiKey"
                        value={values.apiKey}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        showPasswordIcon
                        label="MID API Key"
                        placeholder=""
                        error={touched.apiKey ? errors.apiKey : undefined}
                        helperText={touched?.apiKey ? errors.apiKey : undefined}
                      />

                      <Input
                        type={'text'}
                        id={'website'}
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Website"
                        placeholder="Eg: brolly.africa"
                        error={touched.website ? errors.website : undefined}
                        helperText={touched?.website ? errors.website : undefined}
                      />

                      <Input
                        type={'text'}
                        id={'postalAddress'}
                        value={values.postalAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Postal Address"
                        placeholder="Eg: GA-591-135"
                        required
                        error={touched.postalAddress ? errors.postalAddress : undefined}
                        helperText={touched?.postalAddress ? errors.postalAddress : undefined}
                      />
                    </div>

                    <PlaceComponent
                      type={'text'}
                      name="location"
                      value={values.location}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onAddressChange={(val: string) => {
                        setFieldValue('location', val);
                      }}
                      label="Location"
                      placeholder="Eg: 40 Awudu Street, Accra"
                      error={touched.location ? errors.location : undefined}
                      helperText={touched?.location ? errors.location : undefined}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                      <Input
                        type="text"
                        name="nicRegistration"
                        value={values.nicRegistration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="NIC Registration Number"
                        placeholder=""
                        error={touched.nicRegistration ? errors.nicRegistration : undefined}
                        helperText={touched?.nicRegistration ? errors.nicRegistration : undefined}
                      />
                      <Input
                        type="text"
                        name="branchCode"
                        value={values.branchCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Branch Code"
                        placeholder=""
                        error={touched.branchCode ? errors.branchCode : undefined}
                        helperText={touched?.branchCode ? errors.branchCode : undefined}
                      />
                    </div>
                    <SelectMenu
                      placeholder="Select Insurer Status"
                      list={InsurerStatusList}
                      listSelectedValue={values.status}
                      name="status"
                      label="Insurer Status"
                      onChange={(option: string | null) => setFieldValue('status', option)}
                      onBlur={handleBlur}
                      required
                      error={touched.status ? errors.status : undefined}
                      helperText={touched?.status ? errors.status : undefined}
                    />
                  </div>
                </div>

                <div className="w-full md:w-[35%] rounded border-[1px] border-gray-300 p-4">
                  <h2 className="text-xl font-semibold pb-5">Contact Person</h2>
                  <div className="grid gap-5">
                    <Input
                      type={'text'}
                      name="contactPersonFirstName"
                      value={values.contactPersonFirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="First Name"
                      placeholder="Eg: John"
                      required
                      error={
                        touched.contactPersonFirstName ? errors.contactPersonFirstName : undefined
                      }
                      helperText={
                        touched?.contactPersonFirstName ? errors.contactPersonFirstName : undefined
                      }
                    />
                    <Input
                      type={'text'}
                      name="contactPersonLastName"
                      value={values.contactPersonLastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Last Name"
                      placeholder="Eg: Doe"
                      required
                      error={
                        touched.contactPersonLastName ? errors.contactPersonLastName : undefined
                      }
                      helperText={
                        touched?.contactPersonLastName ? errors.contactPersonLastName : undefined
                      }
                    />
                    <Input
                      type={'tel'}
                      name="contactPersonPhone"
                      value={values.contactPersonPhone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Phone"
                      placeholder="Eg: 02********"
                      required
                      error={touched.contactPersonPhone ? errors.contactPersonPhone : undefined}
                      helperText={
                        touched?.contactPersonPhone ? errors.contactPersonPhone : undefined
                      }
                    />
                    <Input
                      type={'email'}
                      name="contactPersonEmail"
                      value={values.contactPersonEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Email"
                      placeholder="Eg: example@gmail.com"
                      required
                      error={touched.contactPersonEmail ? errors.contactPersonEmail : undefined}
                      helperText={
                        touched?.contactPersonEmail ? errors.contactPersonEmail : undefined
                      }
                    />
                    <InputSelectMenu
                      label="Role"
                      name="role"
                      listSelectedValue={values.role}
                      onChange={(val: string | null) => {
                        setFieldValue('role', val);
                      }}
                      onBlur={handleBlur}
                      list={InsurerAgentRoles}
                      placeholder="Select or type role"
                      searchPlaceHolder="Select or type new role"
                      error={touched.role ? errors.role : undefined}
                      required
                      sort
                      // search
                      emptyListMessage="Select role to proceed"
                      helperText={touched?.role ? errors.role : undefined}
                    />
                  </div>

                  <div className="w-full py-5 ">
                    <Button
                      label="Save"
                      type="submit"
                      id="submitButton"
                      className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                      curved
                      bgColor="primary"
                      loading={loading}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddInsurer;
