import Cropper from "react-easy-crop";
import React, { useCallback, useEffect, useState } from "react";
import "./ImageCropper.scss";
import "./image-cropper-responsive.scss";
import InputSlider from "react-input-slider";

const ImageCropper: React.FC<any> = props => {
  const { isOpen, sourceImage, aspect, onCropCancel, onCropFinished, imageFormat } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isVisible, setVisibility] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    setVisibility(isOpen);
  }, [isOpen]);

  function handleCropCancel() {
    if (onCropCancel) {
      onCropCancel();
    }
    setVisibility(false);
  }

  const cropImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        sourceImage,
        croppedAreaPixels
      );
      if (onCropFinished) {
        onCropFinished(croppedImage);
        setVisibility(false);
      }
    } catch (e) {
      console.error("Crop Image:", e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels]);

  const createImage = (url: any) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", error => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue: any) {
    return (degreeValue * Math.PI) / 180;
  }

  /**
   * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
   * @param {File} image - Image File url
   * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
   * @param {number} rotation - optional rotation parameter
   */
  async function getCroppedImg(imageSrc: any, pixelCrop: any, rotation = 0) {
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise(resolve => {
      canvas.toBlob((blob: any) => {
        blob.name = "croppedImage";
        const fileUrl = window.URL.createObjectURL(blob);
        resolve({ file: blob, url: fileUrl });
      }, "image/" + (imageFormat ? imageFormat : "jpeg"));
    });
  }

  if (isVisible) {
    return (
      <div className="cropper-container absolute w-full h-full z-[1000]" >
        <div className={"ignore flex flex-row justify-center items-center fixed bg-dark w-full h-full top-0 left-0 z-[1000]"}>
          <div className="image-cropper relative w-[calc(100vh - 250px] h-[calc(100vh - 250px] z-[999999]">
            <Cropper
              image={sourceImage}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <div className="cropper-actions first:w-full first:block first:mr-[20px] first:border-[#ffffff] first:rounded first:shadow">
              <InputSlider
                xstep={0.1}
                x={zoom}
                onChange={values => setZoom(values.x)}
                xmin={1}
                xmax={3}
              />
              <button
                type="button"
                className="confirm"
                onClick={() => {
                  cropImage();
                }}
              >
                Crop
              </button>
              <button className="cancel" onClick={handleCropCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default ImageCropper;
