import { useDispatch, useSelector } from 'react-redux';
import { replace, upperCase } from 'lodash';

// import { REFRESH_INTERVALS, SERVER_DATE_FORMAT } from "../../resources/constants";
import UserAccount, { IUserAccount } from '../models/UserAccount';
import {
  getInsurerFromState,
  getUserAccount,
  getUserProfile,
  isAuthenticated,
  SIGN_OUT,
  UPDATE_ACCOUNT,
  UPDATE_INSURER,
  UPDATE_PROFILE,
  USER_ID
} from '../states/slices/userAccountSlice';
import { INSURERS, SIGN_IN } from '../resources/links';
import { IUser, User } from '../models/User';
import { useNavigate } from 'react-router-dom';
import { Insurer } from '../models/insurer/Insurer';
import { IInsurer } from '../models/insurer/IInsurer';
import { REDIRECT_URL } from '../states/slices/globalSlice';
import { getPathAndParamsStringFromUrl } from '../utils/miscFunctions';

function useUserAccount() {
  const dispatch = useDispatch();
  const PROFILE = useSelector(getUserProfile);
  const ACCOUNT = useSelector(getUserAccount);
  const IS_AUTHENTICATED = useSelector(isAuthenticated);
  const INSURER = useSelector(getInsurerFromState);
  const nameInitials = upperCase(
    PROFILE?.firstName?.charAt(0) + PROFILE?.lastName?.charAt(0)
  ).trim();

  const navigate = useNavigate();

  const setProfile = (data: IUser): IUser => {
    dispatch(UPDATE_PROFILE(data));
    return PROFILE;
  };

  const updateProfile = (data: Partial<IUser>): IUser => {
    dispatch(UPDATE_PROFILE(data));
    return PROFILE;
  };

  const getProfile = (): IUser => {
    return PROFILE;
  };

  const getUser = (): User => {
    return new User({
      ...PROFILE
    });
  };

  const setAccount = (data: IUserAccount): IUserAccount => {
    dispatch(UPDATE_ACCOUNT(data));
    return ACCOUNT;
  };

  const updateAccount = (data: Partial<IUserAccount>): IUserAccount => {
    dispatch(UPDATE_ACCOUNT(data));
    return ACCOUNT;
  };

  const updateInsurerInState = (data: Partial<IInsurer>): IInsurer => {
    dispatch(UPDATE_INSURER(data));
    return INSURER;
  };

  const getAccount = () => {
    return new UserAccount(ACCOUNT);
  };

  const getInsurer = (): Insurer => {
    return new Insurer({
      ...INSURER
    });
  };

  const signOut = async (redirect = true) => {
    const redirectUrl = getPathAndParamsStringFromUrl(window.location.toString()) ?? '';
    dispatch(SIGN_OUT());
    dispatch(REDIRECT_URL(redirectUrl));
    dispatch(USER_ID(PROFILE.id));

    if (redirect) {
      navigate(SIGN_IN, { replace: true });
    }
  };

  const user = new User(getProfile());

  return {
    setProfile,
    getProfile,
    getUser,
    updateProfile,
    signOut,
    getAccount,
    updateAccount,
    setAccount,
    IS_AUTHENTICATED,
    nameInitials,
    updateInsurerInState,
    getInsurer,
    user
  };
}

export default useUserAccount;
