import React, { useEffect, useState } from 'react';
import { useTable, useRowSelect } from 'react-table';
import clsx from 'clsx';
import ReactPaginate from 'react-paginate';
import { FcPrevious, FcNext } from 'react-icons/fc';
import { MdSearch } from 'react-icons/md';
import SelectMenu, { ISelectMenuItemValue } from '../../form-control/SelectMenu';
import Loader from '../../Loader';
import Input from '../../form-control/Input';
import NotFoundComponent from '../../NotFoundComponent';
import { Link } from 'react-router-dom';
import { ITableFilterItem } from '../../../resources/interfaces';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface ITableProps {
  columns: any;
  data: any[];
  selectionChanged?: (selectedObjects: any, selectedIndexes: number[]) => void;
  classes?: string;
  stickyHeader?: boolean;
  showSearch?: boolean;
  searchPlaceholder?: string;
  onSearch?: (searchString: string) => void;
  topActions?: ITableTopActionItem[] | JSX.Element | React.ReactNode;
  otherTopActions?: JSX.Element | React.ReactNode;
  tableType?: string;
  showPagination?: boolean;
  paginationData?: ITablePaginationData;
  onPageChange?: (page: number) => void;
  showPerPage?: number;
  onShowPerPageChange?: (page: number) => void;
  onRowClick?: (data: any) => void;
  loading?: boolean;
  columnClasses?: string;
  propBasedColumnClasses?: (data: any) => string;
  actionElement?: JSX.Element | React.ReactNode;
  showDateRangeSelector?: boolean;
  onDateChange?: (data: any) => void;
  tableId?: string;
  tableContainerId?: string;
  showHeaders?: boolean;
  showCheckboxes?: boolean;
  showFilter?: JSX.Element | Element;
  selectedFilters?: ITableFilterItem[];
  onRemoveFilter?: (filter: ITableFilterItem, index: number) => void;
}

export interface ITableTopActionItem {
  id: string;
  label: string;
  callback: () => void;
  active: boolean;
}
export interface ITableRowAction {
  label: string;
  callback: (data?: any) => void;
  data?: any;
}

export interface ITableAction {
  label: string;
  callback: (data?: any) => void;
}
export interface ITablePaginationData {
  total: number;
  per_page: number;
  totalPages: number;
  from?: number;
  to?: number;
  initialPage?: number;
}

const GTable: React.FC<ITableProps> = ({
  columns,
  data,
  selectionChanged,
  classes,
  stickyHeader = true,
  showSearch = true,
  searchPlaceholder = 'Search',
  onSearch,
  topActions = [],
  tableType,
  showPagination = true,
  paginationData,
  onPageChange,
  showPerPage,
  onShowPerPageChange,
  onRowClick,
  loading,
  columnClasses,
  propBasedColumnClasses,
  actionElement,
  showDateRangeSelector = false,
  onDateChange,
  otherTopActions,
  tableId,
  tableContainerId,
  showHeaders = true,
  showFilter,
  selectedFilters,
  onRemoveFilter
}) => {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    //@ts-ignore
    state: { selectedRowIds }
  } = useTable({ columns, data }, useRowSelect);

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const selectedTableIndexes: number[] = [];
    const selectedObjects: any[] = [];
    Object.keys(selectedRowIds)?.forEach((key: string) => {
      selectedTableIndexes.push(Number(key));
      selectedObjects.push(data[Number(key)]);
    });
    selectionChanged?.(selectedObjects, selectedTableIndexes);
  }, [selectedRowIds]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch?.(searchValue);
    }
  };

  const handleSubmitSearch = () => {
    if (searchValue) onSearch?.(searchValue);
  };


  return (
    <>
      {loading ? (
        <div className="w-full text-center py-10 flex items-center justify-center h-full ">
          <Loader message={`Loading ${tableType ?? 'data'}. Please wait...`} brollyLoader />
        </div>
      ) : (
        <div>
          <div className="flex flex-col md:flex-row justify-between gap-5 my-5">
            <div className="flex flex-col md:flex-row gap-5">
              {showFilter && <>{showFilter}</>}

              {showSearch && (
                <div className="w-full md:min-w-[350px] flex items-center">
                  <Input
                    type="search"
                    id={'search'}
                    placeholder={searchPlaceholder}
                    onChange={handleSearchInputChange}
                    onKeyDown={handleKeyPress}
                    className="rounded-r-none h-[45px] border w-full"
                    scrollToFormik={false}
                  />
                  <MdSearch
                    className="text-[#828282] size-5 p-[5px] border border-gray-300 border-l-0 h-[45px] rounded-r w-[40px] cursor-pointer shadow-sm"
                    onClick={handleSubmitSearch}
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col md:flex-row gap-5 md:flex-end items-center">
              {otherTopActions && (
                <div className="flex items-center gap-5">
                  {Array.isArray(otherTopActions) ? (
                    <ul className="flex gap-4 items-center">
                      {otherTopActions?.map((action: ITableTopActionItem, index: number) => (
                        <li
                          key={index}
                          className={clsx(
                            'hover:underline underline-offset-8 decoration-2 decoration-primary-main cursor-pointer',
                            { underline: action.active }
                          )}
                          onClick={action.callback}>
                          {action.label}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    otherTopActions
                  )}
                </div>
              )}
              {showPagination && paginationData && (
                <div className="flex flex-row items-center space-x-4 w-full shrink-0">
                  <span className="block">
                    {paginationData.from} to {paginationData.to} of {paginationData.total} items
                  </span>

                  <div className="space-x-2 flex items-center">
                    <span>Show</span>
                    <SelectMenu
                      name=""
                      listSelectedValue={String(showPerPage)}
                      onChange={(val: ISelectMenuItemValue) => {
                        onShowPerPageChange?.(Number(val ?? 25));
                      }}
                      list={[25, 50, 75, 100]}
                    />
                  </div>
                  {actionElement && <>{actionElement}</>}
                </div>
              )}
            </div>
          </div>

          {selectedFilters && (
            <div className="flex items-center flex-wrap space-y-1 pb-2">
              {selectedFilters?.map((filter: ITableFilterItem, index: number) => (
                <>
                  {(filter?.value || filter?.value?.to || filter?.value?.from) && (
                    <div
                      className="whitespace-nowrap bg-teal-600 text-white px-2 py-1 mr-2 rounded flex text-xs items-center gap-1"
                      key={index}>
                      <span>{filter.label} :</span>
                      <>
                        {typeof filter.value === 'string' || typeof filter.value === 'number' ? (
                          filter.value
                        ) : (
                          <span>
                            {filter?.value?.from}{' '}
                            {filter?.value?.to ? `to ${filter?.value?.to}` : ''}
                          </span>
                        )}
                      </>
                      <XMarkIcon
                        className="w-[16px] ml-2 cursor-pointer"
                        onClick={() => onRemoveFilter?.(filter, index)}
                      />
                    </div>
                  )}
                </>
              ))}
            </div>
          )}

          <div id={tableContainerId} className={clsx('overflow-x-auto shadow-md text-sm', classes)}>
            <table {...getTableProps()} className="w-full px-[0.5rem] relative" id={tableId}>
              {showHeaders && (
                <thead>
                  {headerGroups.map((headerGroup: any, i: any) => (
                    <tr
                      key={i}
                      {...headerGroup.getHeaderGroupProps()}
                      className="first:hidden py-[0.5rem] text-left border-[1px] border-gray-300 rounded">
                      {headerGroup.headers.map((column: any, i: number) => {
                        if (!column.restricted) {
                          return (
                            <th
                              key={i}
                              {...column.getHeaderProps()}
                              className={clsx(
                                'px-[0.6rem] top-0 bg-[#FFFFFF] py-2 whitespace-nowrap',
                                { sticky: stickyHeader }
                              )}>
                              {column.render('Header')}
                            </th>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  ))}
                </thead>
              )}

              <tbody>
                {rows.map((row: any, i: number) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={i}
                      {...row.getRowProps()}
                      className={clsx('border-b border-b-gray-200', {
                        'cursor-pointer': onRowClick
                      })}>
                      {row.cells.map((cell: any, index: number) => {
                        if (!cell.column.restricted) {
                          return (
                            <td
                              key={i}
                              {...cell.getCellProps()}
                              className={clsx(
                                'text-left py-[0.6rem] px-[0.6rem] text-base whitespace-nowrap text-ellipsis',
                                columnClasses,
                                propBasedColumnClasses?.(row.original)
                              )}
                              onClick={() => {
                                if (cell?.column?.id !== 'selection') {
                                  onRowClick?.(row.original);
                                }
                              }}>
                              {cell.render('Cell')}
                            </td>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {showPagination && paginationData && paginationData.totalPages > 1 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={<FcNext />}
                onPageChange={(event) => {
                  onPageChange?.(event.selected);
                }}
                pageRangeDisplayed={5}
                pageCount={
                  paginationData.totalPages
                    ? paginationData.totalPages
                    : paginationData.total / paginationData.per_page
                }
                previousLabel={<FcPrevious />}
                containerClassName={'flex justify-center items-center p-4 space-x-2'}
                pageClassName={
                  'w-[36px] h-[36px] text-[#8A8383] border-[1px] border-[#D8E5EB] rounded-[3px] flex justify-center items-center text-base'
                }
                activeClassName={'bg-primary-main'}
                activeLinkClassName={'text-dark border-primary-main'}
                initialPage={paginationData.initialPage}
              />
            )}
          </div>

          {!loading && data && data.length === 0 && (
            <div className="w-full h-full text-center py-10">
              <NotFoundComponent text={`No ${tableType} found`} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GTable;
