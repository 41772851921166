import NotFound from '../resources/icons/NoResults.jpg';

interface INotFoundComponent {
  text: string;
}
const NotFoundComponent = ({text}:INotFoundComponent) => {
  return (
    <div className="h-full flex items-center justify-center">
      {/* <img src={NotFound} alt="" className="h-[12rem] w-[12rem]" /> */}
      <h1 className="text-3xl font-bold">{text}</h1>
    </div>
  );
};

export default NotFoundComponent;
