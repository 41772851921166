import { AxiosError } from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { applyDiscount, verifyPhoneOTP } from '../../../api/requests';
import Input from '../../../components/form-control/Input';
import ToastContent from '../../../components/ToastContent';
import { IRequestErrorResponse } from '../../../resources/interfaces';
import * as Yup from 'yup';
import Button from '../../../components/form-control/Button';
import { MdOutlineDiscount } from 'react-icons/md';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Policy } from '../../../models/policy/Policy';

type ApplyDiscountProps = {
  closeModal: () => void;
  onProceed: () => void;
  policy?: Policy;
};

const ApplyDiscount = ({ closeModal, onProceed, policy }: ApplyDiscountProps) => {
  return (
    <div className="">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-5">
            <div className="flex items-center justify-center bg-white h-[2.5rem] w-[2.5rem] rounded-full gap-3">
              <MdOutlineDiscount className="h-5 w-5" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Apply Discount</h2>
          </div>
          <XMarkIcon
            onClick={closeModal}
            width={'18px'}
            height={'18px'}
            className="cursor-pointer"
          />
        </div>
      </div>

      <div className="p-[1.5rem]">
        <Formik
          initialValues={{ discountCode: '' }}
          validateOnBlur
          validationSchema={Yup.object().shape({
            discountCode: Yup.string().trim().required('Discount code is required')
          })}
          onSubmit={async (values) => {
            try {
              const { discountCode } = values;
              const policyNumber = policy?.ref;
              if (policyNumber) {
                const response = await applyDiscount(policyNumber, discountCode);

                if (response) {
                  toast.success(
                    <ToastContent
                      description={response?.message ?? 'Discount applied successfully'}
                      title={''}
                    />
                  );
                  closeModal();
                  onProceed();
                }
              }
            } catch (error: IRequestErrorResponse | AxiosError | any) {
              toast.error(<ToastContent title={error?.message} description={error?.description} />);
            }
          }}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="mt-[1rem]">
                <Input
                  label="Discount code"
                  type="text"
                  id="discountCode"
                  value={values.discountCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="discountCode"
                  error={touched.discountCode ? errors.discountCode : undefined}
                  helperText={touched.discountCode ? errors.discountCode : undefined}
                  required
                  // prepend="B-"
                />
              </div>
              <div className="w-full flex justify-end">
                <Button
                  type="submit"
                  label="Apply"
                  curved
                  className="w-full md:w-1/2 mt-[2rem] py-[1rem] bg-primary-main"
                  loading={isSubmitting}
                  disabled={isSubmitting}>
                  Submit
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ApplyDiscount;
