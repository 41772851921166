// import React, { Component } from "react";
// import { Navigate } from "react-router-dom";
// import useUserAccount from "../hooks/userAccountHook";
// import { User } from "../models/User";
// import { IRoute } from "./IRoute";

// export class PrivateRoute extends Component<IRoute, any> {
//   const = { getProfile } = useUserAccount();

//   const user = new User(getProfile());

//   return user ? element : <Navigate to="/login" />
// }

// export default PrivateRoute;

import React from "react";
import { Navigate } from "react-router-dom";
import { IRoute } from "./IRoute";
import { SIGN_IN } from "../resources/links";
import useUserAccount from "../hooks/userAccountHook";

export const PrivateRoute = (props: IRoute): JSX.Element => {
  const { getAccount } = useUserAccount();
  const authed = getAccount().isAuthenticated;

  const { children } = props;

  return (
    <>
      {authed ? (
        <>
          {children}
        </>
      ) : (
        <Navigate to={SIGN_IN} />
      )}
    </>
  );
}

export default PrivateRoute;


