import React, { Component } from 'react';

import clsx from 'clsx';

import '../../styles/components.module.css';
import '../../styles/top_loader.scss';
import { ITopLoaderComponentProps } from './ITopLoaderComponentProps';
import { ITopLoaderComponentState } from './ITopLoaderComponentState';

export default class TopLoaderComponent extends Component<
  ITopLoaderComponentProps,
  ITopLoaderComponentState
> {
  public loadProgress() {
    return (
      <div className={clsx('main__loading')}>
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
    );
  }

  public render() {
    return <div>{this.loadProgress()}</div>;
  }
}
