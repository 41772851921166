import { useNavigate } from 'react-router-dom';
import Button from '../../../form-control/Button';
import { ACCESS_RIGHTS } from '../../../../resources/enums';
import useUserAccount from '../../../../hooks/userAccountHook';
import { User } from '../../../../models/User';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import useModal from '../../../../hooks/modalHook';
import InviteInsurerAgent from '../../../../views/admin/partners/InviteInsurerAgentModal';
import { Team } from '../../../../models/team/Team';
import { RiAddCircleLine } from 'react-icons/ri';

interface IInviteInsurerUsersProps {
  proceed: () => void;
  onBack: () => void;
  team?: Team;
}

const InviteInsurerUsers = (props: IInviteInsurerUsersProps) => {
  const { getUser } = useUserAccount();
  const { updateModalStates, ModalComponent, closeModal } = useModal();

  const navigate = useNavigate();
  const { updateProfile } = useUserAccount();
  const [invitedUserFields, setInvitedUserFields] = useState<IInvitedUserField[]>([
    {
      label: "Finance Manager",
      user: null,
      userType: ACCESS_RIGHTS.INSURER_FINANCE_ADMIN
    },
    {
      label: "Underwriting Manager",
      user: null,
      userType: ACCESS_RIGHTS.INSURER_POLICY_ADMIN
    },
    {
      label: "Claims Manager",
      user: null,
      userType: ACCESS_RIGHTS.INSURER_CLAIM_ADMIN
    }
  ]);

  const { getProfile } = useUserAccount();
  const user = new User(getProfile());

  useEffect(() => {
    const accountsManager = props.team?.members?.find((member: User) => member.accessRight === ACCESS_RIGHTS.INSURER_FINANCE_ADMIN);

    const policyManager = props.team?.members?.find((member: User) => member.accessRight === ACCESS_RIGHTS.INSURER_POLICY_ADMIN);

    const claimsManager = props.team?.members?.find((member: User) => member.accessRight === ACCESS_RIGHTS.INSURER_CLAIM_ADMIN);

    const _invitedFields = [...invitedUserFields];

    if (accountsManager) _invitedFields[0] = { ..._invitedFields[0], user: accountsManager };

    if (policyManager) _invitedFields[1] = { ..._invitedFields[1], user: policyManager }

    if (claimsManager) _invitedFields[2] = { ..._invitedFields[2], user: claimsManager }

    setInvitedUserFields([..._invitedFields])
  }, [props.team])

  const inviteUser = (userType: ACCESS_RIGHTS) => {
    updateModalStates({
      show: true,
      children: <InviteInsurerAgent
        onProceed={(res: any) => {
          const userFields = [...invitedUserFields];

          const addedUserIndex = invitedUserFields.findIndex((userField: IInvitedUserField) => userField.userType === userType);

          userFields[addedUserIndex].user = res.data.staff;

          setInvitedUserFields(userFields);

          closeModal();
        }}
        onClose={() => {
          closeModal()
        }}
        role={userType}
      />,
      size: "lg",
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    })
  }

  const submitHandler = () => {
    // const uninvitedUser = invitedUserFields.find((userField: IInvitedUserField) => userField.user === null);

    // if (uninvitedUser) {
    //   toast.error(`Invite a ${uninvitedUser.label} to proceed`, {
    //     autoClose: 3000
    //   });
    //   return;
    // }

    props.proceed();
  }

  return (
    <div className='px-4'>
      <h2 className="text-xl font-semibold w-full text-center">Final step {getUser().firstName}, add the following users to complete your setup.</h2>

      <div className='m-6 md:px-[9rem] space-y-4'>
        {invitedUserFields.map((userField: IInvitedUserField, index: number) => (
          <div key={index} className="flex items-center justify-start">
            <div className='flex items-center gap-x-4 md:w-1/2 h-full'>
              {userField.user ? (
                <CheckCircleIcon className="w-4 text-[#00c07b]" />
              ) : (
                <RiAddCircleLine className='w-4' />
              )}

              <span>{userField.label}</span>
            </div>
            <div className='flex items-center m-0'>
              {userField.user ? (
                <span className='h-full'>{userField.user?.firstName} {userField.user?.lastName}</span>
              ) : (
                <Button
                  label='Invite User'
                  className='rounded-[18px] h-full'
                  onClick={() => inviteUser(userField.userType)}
                />
              )}
            </div>
          </div>
        ))}
        <div className='w-full flex justify-center mt-6'>
          <span onClick={submitHandler} className='text-gray-400  cursor-pointer rounded-[18px] border-[1px] px-6 py-1'>Skip</span>
        </div>

      </div>

      <div className="flex-row items-center md:flex justify-center md:mt-[56px] mt-8 md:space-x-4">
        <div className="">
          <Button
            label="Back"
            className="w-full mb-5 md:mb-0  md:w-[150px] h-[52px] rounded !border border-black hover:bg-white hover:border-black py-0 bg-black text-white !font-bold"
            onClick={props.onBack}
          />
        </div>
        <Button
          // loading={isSubmitting}
          label="Next"
          // type="submit"
          className="w-full md:w-[150px] bg-primary-main rounded h-[52px] font-bold"
          onClick={submitHandler}
        />
      </div>
      <ModalComponent />
    </div>
  );
};

export default InviteInsurerUsers;

interface IInvitedUserField {
  label: string;
  user: User | null,
  userType: ACCESS_RIGHTS
}
