import React, { useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import { ITableFilterItem } from '../../resources/interfaces';
import XMarkIcon from '@heroicons/react/20/solid/XMarkIcon';

interface ITableFilter {
  content: JSX.Element | React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onClickOutside?: () => void;
  openFilter?: boolean;
  onFilterChange?: (filterState: boolean) => void;
  selectedFilters?: ITableFilterItem[];
  onRemoveFilter?: (filter: ITableFilterItem, index: number) => void;
}

const TableFilter = ({ content, onClick, onClickOutside, openFilter = false, onFilterChange, selectedFilters, onRemoveFilter }: ITableFilter) => {
  const [open, setOpen] = React.useState<boolean>(openFilter);

  useEffect(() => {
    setOpen(openFilter);
  }, [openFilter])

  return (
    <div className="w-full flex">
      <Tippy
        content={<div className="w-full">{content}</div>}
        visible={open}
        interactive
        onClickOutside={() => {
          if (onClickOutside) {
            onClickOutside();
          }
          setOpen(false);
          onFilterChange?.(false);
        }}
        placement="bottom-start"
        className="w-full"
        theme="light-border"
        appendTo={'parent'}>
        <button
          type="submit"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            if (onClick) {
              onClick(event);
            }

            if (onFilterChange) {
              onFilterChange?.(!open);
            } else {
              setOpen(!open);
            }

          }}
          className="border border-primary-main hover:bg-primary-main w-fit rounded px-4 p-2">
          Filters
        </button>
      </Tippy>

      {/* Selected filters */}
      {/* <div className='pl-2 flex items-center flex-wrap space-y-1 py-1'>
        {selectedFilters?.map((filter: ITableFilterItem, index: number) => (
          <>
            {(filter?.value || filter?.value?.to || filter?.value?.from) && (
              <span className='whitespace-nowrap bg-teal-600 text-white px-2 py-1 mr-2 rounded flex text-xs' key={index}>
                <>{(typeof (filter.value) === "string" || typeof (filter.value) === "number") ? filter.value : (
                  <span>{filter?.value?.from} {filter?.value?.to ? `to ${filter?.value?.to}` : ""}</span>
                )}</>
                <XMarkIcon
                  className='w-[16px] ml-2 cursor-pointer'
                  onClick={() => onRemoveFilter?.(filter, index)}
                />
              </span>
            )}
          </>
        ))}
      </div> */}
    </div>
  );
};

export default TableFilter;
