import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  placeholderText: string;
  selectedDate: Date | null;
  onChange: (data: Date | null) => void;
}

const BrollyDatePicker: React.FC<Props> = ({ onChange, placeholderText, selectedDate }) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      className="rounded cursor-pointer border-none shadow w-fit max-w-[8rem] bg-white text-brolly-ash"
      placeholderText={placeholderText}
      // showMonthYearDropdown
      showMonthDropdown
      showYearDropdown
    />
  );
};

export default BrollyDatePicker;
