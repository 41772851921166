import { Formik } from 'formik';
import TextArea from '../../../components/form-control/TextArea';
import * as Yup from 'yup';
import { LEAD_STATUS } from '../../../resources/enums';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/form-control/Button';
import { getSingleLead, updateLeadTrail } from '../../../api/requests';
import { toast } from 'react-toastify';
import useRoute from '../../../hooks/routesHook';
import { Animate } from 'react-simple-animate';
import clsx from 'clsx';
import { XMarkIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { Lead } from '../../../models/lead/Lead';
import { useParams } from 'react-router-dom';
import SelectMenu from '../../../components/form-control/SelectMenu';
export interface IUpdateLeadTrail {
  description?: string;
  leadStatus?: string;
  leadOwner?: string;
  id?: string;
  date?: Date;
}

interface ILeadUpdates {
  onProceed: () => void;
  onClose: () => void;
  show: boolean;
  updates: IUpdateLeadTrail[];
  leadID: string;
  onLeadUpdated: (lead: Lead) => void;
}

const AddUpdate = ({
  onProceed,
  onClose,
  leadID,
  show = false,
  updates,
  onLeadUpdated
}: ILeadUpdates) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [lead, setLead] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [screenWidth, setScreenWidth] = React.useState<number>(
    document.documentElement.clientWidth
  );
  const { lockBackground, unlockBackground } = useRoute();

  React.useEffect(() => {
    const resizeCallback = () => {
      setScreenWidth(document.documentElement.clientWidth);
    };
    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  React.useEffect(() => {
    if (show) {
      lockBackground();
    } else {
      unlockBackground();
    }
  }, [show]);

  const endStyles = {
    zIndex: 2147483647,
    position: 'absolute' as 'absolute',
    top: 100,
    right: screenWidth > 550 ? 0 : '20px',
    width: screenWidth > 550 ? '681px' : '100vw',
    minHeight: 'calc(100vh - 105px)'
  };

  const startStyles = {
    zIndex: 2147483647,
    position: 'absolute' as 'absolute',
    top: 100,
    right: screenWidth > 550 ? '-681px' : '-100vw',
    width: screenWidth > 550 ? '681px' : '100vw',
    minHeight: 'calc(100vh - 105px)'
  };

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      fetchLead(id);
    }
  }, [id]);

  const fetchLead = (id: string) => {
    getSingleLead(id)
      .then((response) => {
        setLead(new Lead(response.data));
      })
      .catch((error: Error) => {})
      .finally(() => {});
  };

  return (
    <Animate play={show} start={startStyles} end={endStyles}>
      <div className="bg-white border border-primary-main px-14 pl-18 sm:pl-14 py-4 shadow-full overflow-y-auto h-[calc(100vh-100px)]">
        <div className=" flex justify-end pl-[1rem] items-center">
          <XMarkIcon onClick={onClose} className="cursor-pointer size-5" />
        </div>

        <h3 className="text-[24px] font-semibold py-2">
          {updates.length > 0 ? 'These are updates on this policy' : 'Add an update'}
        </h3>

        {updates.map((update: IUpdateLeadTrail, index: number) => (
          <>
            <div
              key={index}
              className="min-h-[121px] border border-primary-main rounded p-6 space-y-3">
              <div className="">
                <h3 className="">{update.description}</h3>
              </div>

              <div className="flex items-center text-[10px] space-x-8 text-[#616161] justify-end">
                <span>{update.leadOwner}</span>
                <span>{`${moment(update.date)?.format('ll')} | ${moment(update.date)?.format(
                  'LT'
                )}`}</span>
              </div>
            </div>
            <div className="px-8">
              <div
                className={clsx('border-l-[1px] border-primary-main', {
                  ['min-h-[1.5rem]']: index + 1 !== updates.length
                })}></div>
            </div>
          </>
        ))}

        <Formik
          initialValues={{
            description: '',
            leadStatus: ''
          }}
          validateOnChange
          validationSchema={Yup.object().shape({
            leadStatus: Yup.string().required('Lead status is required.'),
            description: Yup.string().required('Add an update.')
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            leadID;
            const payload: IUpdateLeadTrail = {
              ...values
            };
            setSubmitting(true);

            if (leadID) {
              updateLeadTrail(leadID, payload)
                .then((response: any) => {
                  if (response) {
                    onLeadUpdated(new Lead(response));
                    toast.success(response.message ?? 'Update added successfully', {
                      autoClose: 3000
                    });
                    resetForm();
                  }
                })
                .catch((error: any) => {
                  toast.error(error.message ?? 'Failed to add update', {
                    autoClose: 3000
                  });
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }
          }}>
          {({
            values,
            errors,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            setFieldValue
          }) => (
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="">
                <div className="">
                  <SelectMenu
                    label="Lead status"
                    name="leadStatus"
                    listSelectedValue={values.leadStatus}
                    list={Object.values(LEAD_STATUS)}
                    onChange={(val: string | null) => setFieldValue('leadStatus', val)}
                    onBlur={handleBlur}
                    placeholder="Select lead status"
                    required
                    wrapperClasses="w-full"
                    error={touched.leadStatus ? errors.leadStatus : undefined}
                    helperText={touched?.leadStatus ? errors.leadStatus : undefined}
                  />
                </div>
                <div className="mt-7">
                  <TextArea
                    name={'description'}
                    value={values.description}
                    onChange={handleChange}
                    placeholder="Write an update..."
                    rows={3}
                    required
                    error={touched.description ? errors.description : undefined}
                    helperText={touched?.description ? errors.description : undefined}
                  />
                </div>
              </div>
              <div className=" mt-7">
                <Button
                  label="Add update"
                  className="bg-primary-main w-fit px-8 py-2 h-[45px] rounded-md"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Animate>
  );
};

export default AddUpdate;
