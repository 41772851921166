import { CreditCardIcon, DevicePhoneMobileIcon, StarIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getInsurerProfile } from '../../../../api/requests';
import useRoute from '../../../../hooks/routesHook';
import useUserAccount from '../../../../hooks/userAccountHook';
import { IInsurerReview } from '../../../../models/insurer/IInsurer';
import { Insurer } from '../../../../models/insurer/Insurer';
import Avatar from '../../../Avatar';
import { ParagraphWithBoldWord, Testimonial } from '../../../elements/Slider';
import { toast } from 'react-toastify';
import Button from '../../../form-control/Button';
import ShareComponent from '../../../share/ShareComponent';
import { getClientWebsiteUrl, getNumberOfStringCharacters } from '../../../../utils/miscFunctions';
import DefaultHeroImage from './insurers-default-hero.png';
import DefaultHeroImageMobile from './insurers-default-hero.png';
import { WalletIcon } from '@heroicons/react/24/solid';

const InsurerStore = () => {
  const [insurer, setInsurer] = useState<Insurer>();

  useEffect(() => {
    getInsurerProfile().then((response: any) => {
      setInsurer(new Insurer(response));
    });
  }, []);

  return (
    <>
      {insurer && (
        <>
          <Nav insurer={insurer} />

          <Header insurer={insurer} />

          <div className="flex flex-col lg:flex-row lg:px-24 lg:justify-between lg:my-6 lg:gap-10 w-full">
            <div className="w-full lg:w-1/2">
              <AboutStore insurer={insurer} />
            </div>

            <div className="w-full lg:w-1/2">
              {insurer && insurer?.benefits.length > 0 && <Benefits insurer={insurer} />}{' '}
            </div>
          </div>
          <hr className="hidden lg:block lg:my-8" />

          <Reviews insurer={insurer} />
        </>
      )}
    </>
  );
};

function Nav(props: { insurer: Insurer }) {
  const navigate = useNavigate();
  const { getDashboardBase } = useRoute();

  const gotoStoreSetupPage = () => {
    navigate(`${getDashboardBase()}/store`);
  };

  return (
    <div className="w-full bg-[#f8f8f8] py-5 px-14">
      <div
        className={clsx('flex justify-between items-center', {
          ['!justify-end']: props.insurer?.logo
        })}>
        {!props.insurer.logo && (
          <button
            className="bg-[#c4c4c4] text-lg px-12 py-3 font-poppins"
            onClick={gotoStoreSetupPage}>
            Insert Logo
          </button>
        )}
        <p
          className="text-lg font-medium text-right text-black font-poppins cursor-pointer"
          onClick={gotoStoreSetupPage}>
          Dashboard
        </p>
        {/* )} */}
      </div>
    </div>
  );
}

function Header({ insurer }: { insurer: Insurer }) {
  const rating = insurer?.rating ?? 0;
  const handleRatingClick = (value: number) => {};

  const [url, setUrl] = useState(window.location.href);
  const [copySuccess, setCopySuccess] = useState(false);

  const navigate = useNavigate();
  const { getDashboardBase } = useRoute();

  const windowWidth = useRef(window.innerWidth);

  const handleNativeShareNotAvailable = async () => {
    try {
      await navigator.clipboard.writeText(url);
      toast.success('Url copied. Go ahead and paste it to share');
    } catch (err) {
      console.error('Failed to copy to clipboard: ', err);
    }
  };
  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopySuccess(true);

      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy to clipboard: ', err);
    }
  };
  const shareUrl = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: window.location.href
        })
        .then(() => {})
        .catch((error) => {});
    } else {
      handleNativeShareNotAvailable();
    }
  };

  const gotoStoreSetupPage = () => {
    navigate(`${getDashboardBase()}/store`);
  };

  return (
    <div className="w-full">
      <div className="h-[calc(100vh-320px)] relative">
        {/* mobile */}
        <div
          className={clsx(
            'md:hidden h-full bg-no-repeat w-full bg-cover flex justify-center items-center'
          )}
          style={{
            objectFit: 'contain',
            backgroundImage: `url(${
              insurer?.bannerMobile
                ? insurer?.bannerMobile
                : windowWidth.current > 550
                ? DefaultHeroImage
                : DefaultHeroImageMobile
            })`
          }}>
          {!insurer.banner && (
            <Button
              label="Get started"
              className="bg-primary-main rounded-[10px] border-primary-main w-[200px] h-[42px] !font-bold absolute"
              onClick={gotoStoreSetupPage}
            />
          )}
        </div>

        {/* desktop */}
        <div
          className={clsx(
            'hidden h-full bg-no-repeat w-full bg-cover md:flex justify-center items-center'
          )}
          style={{
            objectFit: 'contain',
            backgroundImage: `url(${
              insurer?.banner
                ? insurer?.banner
                : windowWidth.current > 550
                ? DefaultHeroImage
                : DefaultHeroImageMobile
            })`
          }}>
          {!insurer.banner && (
            <Button
              label="Get started"
              className="bg-primary-main rounded-[10px] border-primary-main w-[200px] h-[42px] !font-bold absolute"
              onClick={gotoStoreSetupPage}
            />
          )}
        </div>

        {/* mobile */}
        <div className="lg:hidden z-10 h-20 w-20 absolute -bottom-9 left-1/2 transform -translate-x-1/2 border-4 shadow-md border-white ">
          <img src={insurer.logo} className="h-full w-full object-cover p-1 lg:p-2 bg-white" />
        </div>

        {/* desktop */}
        <div className="hidden lg:block z-10 h-16 w-16 lg:h-48 lg:w-48 border-4 shadow-md border-white lg:mx-24 absolute lg:left-24 -bottom-9 lg:-bottom-16">
          <img src={insurer.logo} className="h-full w-full object-cover p-1 lg:p-2 bg-white" />
        </div>
      </div>

      <div className="bg-[#FBC02D0D] pt-6 pb-8 lg:py-16">
        <div className="flex flex-col md:flex-row justify-between px-6 pt-4 lg:px-24">
          <div className="">
            {/* insurer name and share icon - mobile */}
            <div className="flex items-center justify-center lg:hidden">
              <div className="flex flex-col mb-2 mt-2 lg:mt-0">
                <div className="relative w-screen">
                  <div className="absolute right-3 top-1">
                    <svg
                      onClick={shareUrl}
                      className={clsx('w-8 cursor-pointer')}
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle
                        cx="17.5"
                        cy="17.5"
                        r="17.5"
                        fill={insurer.themeColour ?? '#FBC02D'}
                      />
                      <path
                        d="M22.2281 8.55556C20.3377 8.55556 18.7895 10.1038 18.7895 11.9942C18.7895 12.2819 18.887 12.5392 18.9545 12.8068L13.0646 15.7532C12.4325 14.9778 11.5102 14.4503 10.4386 14.4503C8.54824 14.4503 7 15.9985 7 17.8889C7 19.7792 8.54824 21.3275 10.4386 21.3275C11.5102 21.3275 12.4325 20.7999 13.0646 20.0246L18.9545 22.971C18.887 23.2386 18.7895 23.4959 18.7895 23.7836C18.7895 25.674 20.3377 27.2222 22.2281 27.2222C24.1184 27.2222 25.6667 25.674 25.6667 23.7836C25.6667 21.8933 24.1184 20.345 22.2281 20.345C21.1565 20.345 20.2341 20.8726 19.6021 21.6479L13.7122 18.7015C13.7797 18.4339 13.8772 18.1766 13.8772 17.8889C13.8772 17.6011 13.7797 17.3439 13.7122 17.0763L19.6021 14.1298C20.2341 14.9052 21.1565 15.4327 22.2281 15.4327C24.1184 15.4327 25.6667 13.8845 25.6667 11.9942C25.6667 10.1038 24.1184 8.55556 22.2281 8.55556ZM22.2281 10.0292C23.322 10.0292 24.193 10.9002 24.193 11.9942C24.193 13.0881 23.322 13.9591 22.2281 13.9591C21.1341 13.9591 20.2632 13.0881 20.2632 11.9942C20.2632 10.9002 21.1341 10.0292 22.2281 10.0292ZM10.4386 15.924C11.5325 15.924 12.4035 16.795 12.4035 17.8889C12.4035 18.9828 11.5325 19.8538 10.4386 19.8538C9.34468 19.8538 8.47368 18.9828 8.47368 17.8889C8.47368 16.795 9.34468 15.924 10.4386 15.924ZM22.2281 21.8187C23.322 21.8187 24.193 22.6897 24.193 23.7836C24.193 24.8775 23.322 25.7485 22.2281 25.7485C21.1341 25.7485 20.2632 24.8775 20.2632 23.7836C20.2632 22.6897 21.1341 21.8187 22.2281 21.8187Z"
                        fill={insurer.fontColour ?? 'black'}
                      />
                    </svg>
                  </div>
                </div>
                <div className="flex justify-center lg:justify-start lg:pr-10 items-center">
                  <p className="lg:block mb-2 lg:mr-8 leading-tight text-2xl lg:text-[2.5em] font-poppins font-bold lg:text-left lg:pl-0 lg:translate-x-0">
                    {insurer?.name ?? 'NA'}
                  </p>
                </div>
                <h1 className="text-lg text-[#121330] text-center lg:text-left leading-tight font-poppins px-6 lg:px-0">
                  {insurer?.tagline}
                </h1>
              </div>
            </div>
            {/* insurer name and share icon - desktop */}
            <div className="hidden lg:block">
              <div className="flex items-center">
                <div className="flex flex-col mb-2 mt-2 lg:mt-0">
                  <div className="flex justify-between lg:pr-10">
                    <p className="lg:block mb-2 mr-3 lg:mr-8 leading-tight text-2xl lg:text-[2.5em] font-poppins font-bold text-center lg:text-left pl-4 translate-x-1/2 lg:pl-0 lg:translate-x-0">
                      {insurer?.name ?? 'NA'}
                    </p>

                    <ShareComponent
                      title={`Pay small-small for car insurance with ${insurer.name}`}
                      text={''}
                      url={`${getClientWebsiteUrl()}/${insurer?.slug}`}
                      withShareBtn
                      bgColor={insurer?.themeColour}
                      fontColor={insurer?.fontColour}
                    />
                    {copySuccess && (
                      <div className=" bg-green-500 text-white text-xs px-2 py-1 rounded-md mt-4 mr-2">
                        Copied to clipboard
                      </div>
                    )}
                  </div>
                  <h1 className="text-lg text-[#121330] leading-tight font-poppins">
                    {insurer?.tagline}
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full lg:w-1/4">
              <div className="flex w-fit">
                {[1, 2, 3, 4, 5].map((value) => (
                  <StarIcon
                    stroke={value <= insurer.rating ? '#FBC02D' : '#FBC02D'}
                    fill={value <= insurer.rating ? '#FBC02D' : 'white'}
                    key={value}
                    color="black"
                    className={`h-5 w-5 mr-1 lg:mr-2 cursor-pointer`}
                    onClick={() => handleRatingClick(value)}
                  />
                ))}
              </div>

              <p className="block  mr-4 lg:mr-auto w-1/2 underline text-[#5C5C5C] font-poppins whitespace-nowrap">
                {insurer?.reviews?.length} {insurer?.reviews?.length === 1 ? 'Review' : 'Reviews'}
              </p>
            </div>
          </div>

          <div className="mt-5 md:mt-0">
            <button
              className="bg-[#FBC02D] text-black px-4 lg:px-10 py-[6px] lg:py-3 rounded-md text-xs lg:text-base w-full"
              style={{
                backgroundColor: insurer.themeColour ?? '#FBC02D',
                color: insurer.fontColour ?? '#000000'
              }}>
              Get a Quote
            </button>
          </div>
        </div>
      </div>

      <HowItWorks insurer={insurer} />
    </div>
  );
}

function CheckIcon() {
  return (
    <svg className="w-4" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9869 4.19316C17.8137 4.19858 17.6493 4.27458 17.5285 4.40507L7.33323 15.1102L3.13791 10.7051C3.07648 10.6379 3.0029 10.5843 2.92149 10.5473C2.84007 10.5104 2.75244 10.4908 2.66375 10.4899C2.57506 10.4889 2.48707 10.5066 2.40496 10.5418C2.32284 10.577 2.24824 10.629 2.18552 10.6949C2.1228 10.7608 2.07322 10.8391 2.0397 10.9253C2.00617 11.0115 1.98937 11.1039 1.99027 11.197C1.99117 11.2902 2.00976 11.3822 2.04495 11.4677C2.08014 11.5532 2.13122 11.6304 2.19521 11.6949L6.86187 16.5949C6.9869 16.7261 7.15645 16.7999 7.33323 16.7999C7.51001 16.7999 7.67955 16.7261 7.80458 16.5949L18.4712 5.39492C18.5676 5.29659 18.6334 5.17001 18.66 5.03174C18.6867 4.89348 18.6729 4.74999 18.6205 4.62008C18.5682 4.49017 18.4797 4.3799 18.3666 4.30372C18.2535 4.22754 18.1212 4.189 17.9869 4.19316Z"
        fill="black"
      />
    </svg>
  );
}

function HowItWorks(props: { insurer: Insurer }) {
  return (
    <div className="px-6 bg-white">
      <Steps insurer={props.insurer} />
    </div>
  );
}

function AboutStore(props: { insurer: Insurer }) {
  return (
    <div className="px-6 lg:pl-0 lg:pr-4 my-8 bg-white">
      <h3 className="text-lg lg:text-3xl mb-6 lg:mb-3 font-poppins">About Us</h3>
      <p className="font-poppins text-justify">
        {props.insurer?.description
          ? `${getNumberOfStringCharacters(props.insurer?.description, 0, 500)}...`
          : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop"}
      </p>
    </div>
  );
}

function Benefits(props: { insurer: Insurer }) {
  return (
    <div className="px-6 my-8 lg:py-12 bg-[#ebeced] bg-[rgba(209, 212, 217, 0.16)] py-8">
      <h3 className="text-lg lg:mb-4 lg:text-3xl font-poppins">Highlights</h3>
      <ul className="mt-3">
        {props.insurer?.benefits?.length > 0 ? (
          props.insurer?.benefits?.map((benefit: string, index: number) => (
            <li className="flex lg:mb-4" key={index}>
              <CheckIcon />
              <span className="ml-3 font-poppins">{benefit}</span>
            </li>
          ))
        ) : (
          <>
            <li className="flex">
              <CheckIcon />
              <span className="ml-3 font-poppins">Flexiable Payment</span>
            </li>
            <li className="flex">
              <CheckIcon />
              <span className="ml-3 font-poppins">Same day Instant Coverage</span>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

function Reviews(props: { insurer: Insurer }) {
  const testimonialData: Testimonial[] = [
    {
      rating: 5,
      name: 'Dellah Lukutor',
      text: 'It was quite a smooth experience with them, the technology works seamlessly and the people are pleasant.',
      avatar: (
        <img
          className="h-12 lg:h-[4rem]"
          src="https://lh3.googleusercontent.com/a-/ACB-R5TOC5_MVjyr_rgyp6tHN3AH4ZiOevdV5Zu83zh0bg=w40-h40-p-rp-mo-br100"
          alt="Dellah Lukutor's avatar"
        />
      )
    },
    {
      name: 'Abraham Obeng Asiedu',
      text: 'Seamless automated system with high efficiency. Within 15 minutes my Insurance renewal got sorted.I will highly recommend them to everyone.',
      rating: 5,
      avatar: (
        <img
          className="h-12 lg:h-[4rem]"
          src="https://lh3.googleusercontent.com/a/AGNmyxY4g7WKVCDEkPneUVE_Slma5B1skm4E3hU7pa3r=w40-h40-p-rp-mo-br100"
          alt="Abraham Obeng Asiedu's avatar"
        />
      )
    },
    {
      name: 'Don Papa Inno',
      text: 'I think its cool dealing with Brolly when it comes to your vehicle insurance needs. Look no further if you are considering to have your vehicle insured but on flexible payment terms. Brolly does the magic with no extra charges. Just knock on their door once and there you are!',
      rating: 4,
      avatar: (
        <img
          className="h-12 lg:h-[4rem]"
          src="https://lh3.googleusercontent.com/a/AGNmyxZs9_bTIa0x26tZxWnpJ_wBQD951aYYpBo7OyVdfXo=w40-h40-p-rp-mo-br100"
          alt="Don Papa Inno's avatar"
        />
      )
    }
  ];

  return (
    <div className="px-6 lg:px-28 lg:w-4/5">
      <h3 className="text-lg mb-6 lg:text-3xl">Recent Reviews</h3>
      {props.insurer?.reviews.map((review: IInsurerReview, index) => (
        <div className="pb-8" key={index}>
          <div className="rounded-lg flex flex-col lg:pt-8 lg:h-44">
            <div className="flex min-w-min items-center lg:items-start mb-2">
              <Avatar src={review.customer?.image} />
              <div className="flex flex-col ml-4">
                <div className="text-base lg:text-lg font-poppins">
                  {`${review.customer?.firstName ?? ''} ${review.customer?.lastName ?? ''}`}
                </div>
                <div className="flex mt-1">
                  <div className="flex justify-center">
                    {[...Array(review.rating)].map((_, index) => (
                      <img
                        className="w-4 px-[1.5px] lg:w-6"
                        key={index}
                        src={require('./rating-star.png')}
                        alt="star rating"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 text-xs text-[#5C5C5C] lg:ml-[5rem] font-poppins">
              {<ParagraphWithBoldWord text={review.feedback ?? ''} />}
            </div>
          </div>
          {index + 1 != testimonialData.length && <hr className="mt-6" />}
        </div>
      ))}

      {props.insurer?.reviews?.length === 0 && (
        <h3 className="w-full text-center font-bold font-poppins text-md">No Reviews</h3>
      )}
    </div>
  );
}

function Steps({ insurer }: { insurer: Insurer }) {
  return (
    <div className="my-8 lg:my-12">
      <h3 className="text-lg lg:text-3xl lg:mb-3 lg:text-center font-poppins">
        Pay small- small in 3 easy steps
      </h3>
      <div className="flex flex-col py-6 lg:flex-row lg:justify-between lg:mt-8">
        <div className="flex justify-start gap-6 items-start mb-3 md:flex-col md:justify-start lg:pr-6 flex-1 lg:items-center">
          <div className="flex-col justify-center">
            <span
              className={clsx('bg-[#8CEB8C] rounded-[50%] p-4 w-12 md:w-8 lg:w-16 block')}
              style={{ backgroundColor: insurer.themeColour ?? '#8CEB8C' }}>
              <DevicePhoneMobileIcon stroke="1" fill={insurer.fontColour ?? '#000000'} />
            </span>

            <div className="w-full mt-3 flex justify-center lg:hidden">
              <svg
                className="w-[2px] h-auto"
                viewBox="0 0 2 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.05 3.99266L0.28 3.99266L0.28 0.0026567L1.05 0.00265673L1.05 3.99266ZM1.05 12.6005L0.279999 12.6005L0.28 8.61047L1.05 8.61047L1.05 12.6005ZM1.05 21.2083L0.279999 21.2083L0.279999 17.2183L1.05 17.2183L1.05 21.2083ZM1.05 29.8161L0.279999 29.8161L0.279999 25.8261L1.05 25.8261L1.05 29.8161ZM1.05 38.4239L0.279998 38.4239L0.279999 34.4339L1.05 34.4339L1.05 38.4239ZM1.05 47.0317L0.279998 47.0317L0.279998 43.0417L1.05 43.0417L1.05 47.0317ZM1.05 55.6395L0.279998 55.6395L0.279998 51.6495L1.05 51.6495L1.05 55.6395Z"
                  fill="#5C5C5C"
                />
              </svg>
            </div>
          </div>
          <div>
            <h3 className="text-base lg:text-2xl mb-1 md:text-center font-poppins">Step 1</h3>
            <p className="lg:hidden text-sm  md:text-center lg:text-base font-poppins">
              Get a new quote or <br /> begin with your renewal premium
            </p>
            <p className="hidden lg:block text-sm md:text-center lg:text-base font-poppins">
              Get a new quote or
              <br /> begin with your renewal premium
            </p>
          </div>
        </div>
        <div className="hidden lg:block w-1/6 mt-6">
          <svg
            className="w-full"
            viewBox="0 0 272 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.895 0.95V1.72H0.904997V0.95H4.895ZM13.5028 0.95V1.72H9.51281V0.95H13.5028ZM22.1106 0.95V1.72H18.1206V0.95H22.1106ZM30.7184 0.95V1.72H26.7284V0.95H30.7184ZM39.3262 0.95V1.72H35.3362V0.95H39.3262ZM47.9341 0.95V1.72H43.9441V0.95H47.9341ZM56.5419 0.95V1.72H52.5519V0.95H56.5419ZM65.1497 0.95V1.72H61.1597V0.95H65.1497ZM73.7575 0.95V1.72H69.7675V0.95H73.7575ZM82.3653 0.95V1.72H78.3753V0.95H82.3653ZM90.9731 0.95V1.72H86.9831V0.95H90.9731ZM99.5809 0.95V1.72H95.5909V0.95H99.5809ZM108.189 0.95V1.72H104.199V0.95H108.189ZM116.797 0.95V1.72H112.807V0.95H116.797ZM125.404 0.95V1.72H121.414V0.95H125.404ZM134.012 0.95V1.72H130.022V0.95H134.012ZM142.62 0.95V1.72H138.63V0.95H142.62ZM151.228 0.95V1.72H147.238V0.95H151.228ZM159.836 0.95V1.72H155.846V0.95H159.836ZM168.443 0.95V1.72H164.453V0.95H168.443ZM177.051 0.95V1.72H173.061V0.95H177.051ZM185.659 0.95V1.72H181.669V0.95H185.659ZM194.267 0.95V1.72H190.277V0.95H194.267ZM202.875 0.95V1.72H198.885V0.95H202.875ZM211.482 0.95V1.72H207.492V0.95H211.482ZM220.09 0.95V1.72H216.1V0.95H220.09ZM228.698 0.95V1.72H224.708V0.95H228.698ZM237.306 0.95V1.72H233.316V0.95H237.306ZM245.914 0.95V1.72H241.924V0.95H245.914ZM254.522 0.95V1.72H250.532V0.95H254.522ZM263.129 0.95V1.72H259.139V0.95H263.129ZM271.737 0.95V1.72H267.747V0.95H271.737Z"
              fill="#5C5C5C"
            />
          </svg>
        </div>
        <div className="flex justify-start gap-6 items-start mb-3 md:flex-col md:justify-start lg:px-6  flex-1 lg:items-center">
          <div className="flex-col justify-center">
            <span
              className="bg-[#8CEB8C] rounded-[50%] p-4 w-12 md:w-8 lg:w-16 block"
              style={{ backgroundColor: insurer.themeColour ?? '#8CEB8C' }}>
              <CreditCardIcon stroke="1" fill={insurer.fontColour ?? '#000000'} />
            </span>

            <div className="w-full mt-3 flex justify-center lg:hidden">
              <svg
                className="w-[2px] h-auto"
                viewBox="0 0 2 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.05 3.99266L0.28 3.99266L0.28 0.0026567L1.05 0.00265673L1.05 3.99266ZM1.05 12.6005L0.279999 12.6005L0.28 8.61047L1.05 8.61047L1.05 12.6005ZM1.05 21.2083L0.279999 21.2083L0.279999 17.2183L1.05 17.2183L1.05 21.2083ZM1.05 29.8161L0.279999 29.8161L0.279999 25.8261L1.05 25.8261L1.05 29.8161ZM1.05 38.4239L0.279998 38.4239L0.279999 34.4339L1.05 34.4339L1.05 38.4239ZM1.05 47.0317L0.279998 47.0317L0.279998 43.0417L1.05 43.0417L1.05 47.0317ZM1.05 55.6395L0.279998 55.6395L0.279998 51.6495L1.05 51.6495L1.05 55.6395Z"
                  fill="#5C5C5C"
                />
              </svg>
            </div>
          </div>
          <div>
            <h3 className="text-base lg:text-2xl mb-1 md:text-center font-poppins">Step 2</h3>
            <p className="text-sm md:text-center lg:text-base font-poppins">
              Provide your personal details and vehicle information
            </p>
          </div>
        </div>
        <div className="hidden lg:block w-1/6 mt-6">
          <svg
            className="w-full"
            viewBox="0 0 272 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.895 0.95V1.72H0.904997V0.95H4.895ZM13.5028 0.95V1.72H9.51281V0.95H13.5028ZM22.1106 0.95V1.72H18.1206V0.95H22.1106ZM30.7184 0.95V1.72H26.7284V0.95H30.7184ZM39.3262 0.95V1.72H35.3362V0.95H39.3262ZM47.9341 0.95V1.72H43.9441V0.95H47.9341ZM56.5419 0.95V1.72H52.5519V0.95H56.5419ZM65.1497 0.95V1.72H61.1597V0.95H65.1497ZM73.7575 0.95V1.72H69.7675V0.95H73.7575ZM82.3653 0.95V1.72H78.3753V0.95H82.3653ZM90.9731 0.95V1.72H86.9831V0.95H90.9731ZM99.5809 0.95V1.72H95.5909V0.95H99.5809ZM108.189 0.95V1.72H104.199V0.95H108.189ZM116.797 0.95V1.72H112.807V0.95H116.797ZM125.404 0.95V1.72H121.414V0.95H125.404ZM134.012 0.95V1.72H130.022V0.95H134.012ZM142.62 0.95V1.72H138.63V0.95H142.62ZM151.228 0.95V1.72H147.238V0.95H151.228ZM159.836 0.95V1.72H155.846V0.95H159.836ZM168.443 0.95V1.72H164.453V0.95H168.443ZM177.051 0.95V1.72H173.061V0.95H177.051ZM185.659 0.95V1.72H181.669V0.95H185.659ZM194.267 0.95V1.72H190.277V0.95H194.267ZM202.875 0.95V1.72H198.885V0.95H202.875ZM211.482 0.95V1.72H207.492V0.95H211.482ZM220.09 0.95V1.72H216.1V0.95H220.09ZM228.698 0.95V1.72H224.708V0.95H228.698ZM237.306 0.95V1.72H233.316V0.95H237.306ZM245.914 0.95V1.72H241.924V0.95H245.914ZM254.522 0.95V1.72H250.532V0.95H254.522ZM263.129 0.95V1.72H259.139V0.95H263.129ZM271.737 0.95V1.72H267.747V0.95H271.737Z"
              fill="#5C5C5C"
            />
          </svg>
        </div>
        <div className="flex justify-start gap-6 items-start md:flex-col md:justify-start lg:pl-6  flex-1 lg:items-center">
          <span
            className="bg-[#8CEB8C] rounded-[50%] p-4 w-12 md:w-8 lg:w-16 block"
            style={{ backgroundColor: insurer.themeColour ?? '#8CEB8C' }}>
            <WalletIcon stroke="1" fill={insurer.fontColour ?? '#000000'} />
          </span>

          <div>
            <h3 className="text-base lg:text-2xl mb-1 md:text-center font-poppins">Step 3</h3>
            <p className="lg:hidden text-sm md:text-center lg:text-base font-poppins">
              Make initial deposit. <br /> If you need help along the line, WhatsApp or call on{' '}
              <span>
                <a href="tel:+233201819581">0201 819 581</a>
              </span>
            </p>
            <p className="hidden lg:block text-sm md:text-center lg:text-base font-poppins">
              Make initial deposit.
              <br /> If you need help along the line,
              <br /> WhatsApp or call on{' '}
              <span>
                <a href="tel:+233201819581">0201 819 581</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsurerStore;
