import { POLICY_REVIEW_STATES } from "../../resources/enums";
import { Address } from "../address/Address";
import { IPayment } from "../payment/IPayment";
import { IPolicy } from "../policy/IPolicy";
import { Policy } from "../policy/Policy";
import { ICommunity, IContactPerson } from "./ICommunity";

export class Community{
    name: string;
    description?: string;
    type: string ;
    id:string;
    reviewStage: POLICY_REVIEW_STATES | undefined;
    address?: Address | undefined;
    contactPersons: IContactPerson[];
    active: boolean
    policies: Policy[];
    payments: any[];

    constructor(Imodel: ICommunity){
        this.name= Imodel?.name;
        this.description= Imodel?.description;
        this.type= Imodel?.type;
        this.id = Imodel?.id;
        this.reviewStage = Imodel?.reviewStage as POLICY_REVIEW_STATES;
        this.address = Imodel?.address ? new Address(Imodel?.address) : undefined;
        this.contactPersons = Imodel?.contactPersons??[];
        this.active = Imodel.active ?? false;
        this.policies = Imodel.policies ? Imodel.policies?.map((policy: IPolicy) => new Policy(policy)) : [];
        this.payments = Imodel.payments ? Imodel.payments?.map((payment: IPayment) => payment) : [];
    }

    getPrimaryContactPerson = (): IContactPerson => {
        return this.contactPersons[0];
    };
}