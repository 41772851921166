import { ReactNode, lazy, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import PageLoader from '../components/PageLoader';
import useUserAccount from '../hooks/userAccountHook';
import AuthLayout from '../layouts/AuthLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import ErrorsLayout from '../layouts/ErrorsLayout';
import {
  DASHBOARD,
  DASHBOARD_BASE,
  FORGOT_PASSWORD,
  NOT_FOUND,
  RESET_PASSWORD,
  SIGN_IN,
  COMPLETE_ACCOUNT
} from '../resources/links';
import CreateLeads from '../views/admin/leads/CreateLead';
import ForgotPassword from '../views/auth/ForgotPassword';
import ResetPassword from '../views/auth/ResetPassword';
import SignIn from '../views/auth/SignIn';
import NotFound from '../views/errors/NotFound';
import LeadDetailsView from '../views/admin/leads/LeadDetailsView';
import ViewCustomer from '../views/admin/customers/ViewCustomer';
import RoleDetailsView from '../views/admin/settings/RolesAndPermisions/RoleDetails';
import CreateRoleView from '../views/admin/settings/RolesAndPermisions/CreateCustomRole';
import CompleteAccount from '../views/auth/CompleteAccount';
import SingleTeam from '../views/admin/partners/SingleTeam';
import Vehicles from '../views/admin/vehicles';
import AddInsurer from '../views/admin/insurers/AddInsurer';
import { User } from '../models/User';
import useRoute, { useNavigationStack } from '../hooks/routesHook';
import RelationshipManagerTeam from '../views/admin/partners/RelationshipManagerTeam';
import PrivateRoute from './PrivateRoute';
import InsurerPayouts from '../views/admin/financials/payouts/InsurerPayouts';
import InsurerOnboarding from '../components/dashboard/insurers/onboarding/InsurerOnboarding';
import useAuth from '../hooks/authHook';
import SetupInsurerStore from '../components/dashboard/insurers/store/InsurerStoreSetup';
import InsurerStorePreview from '../components/dashboard/insurers/store/InsurerStorePreview';
import PartnerDashboard from '../views/admin/dashboard/PartnerDashboard';

const DashboardView = lazy(() => import('../views/admin/dashboard'));
const Approvals = lazy(() => import('../views/admin/approvals'));
const InsurerDashboardView = lazy(() => import('../views/admin/dashboard/InsurerDashboard'));
const CustomersView = lazy(() => import('../views/admin/customers'));
const PaymentsView = lazy(() => import('../views/admin/payments'));
const InsurerSales = lazy(() => import('../views/admin/payments/InsurerSales'));
const PartnerFuelSales = lazy(() => import('../views/admin/partners/fuel/PartnerFuelSales'));
const FuelVendorSalesHistory = lazy(
  () => import('../views/admin/partners/fuel/FuelVendorSalesHistory')
);
const FuelSalesAuditview = lazy(() => import('../views/admin/partners/fuel/FuelSalesAuditview'));
const PartnerFuelQuota = lazy(() => import('../views/admin/partners/fuel/FuelQuota'));
const LeadsView = lazy(() => import('../views/admin/leads'));
const MessagesView = lazy(() => import('../views/admin/messages'));
const NotificationsView = lazy(() => import('../views/admin/notifications'));
const InsurersView = lazy(() => import('../views/admin/insurers'));
const InsurerDetails = lazy(() => import('../views/admin/insurers/insurerDetails'));
const InsurerEdit = lazy(() => import('../views/admin/insurers/EditInsurer'));
const PoliciesView = lazy(() => import('../views/admin/policies'));
const PolicyDetailsView = lazy(() => import('../views/admin/policies/policyDetails'));
const PolicyEdit = lazy(() => import('../views/admin/policies/PolicyEdit'));
const FuelView = lazy(() => import('../views/admin/fuel'));
const SettingsView = lazy(() => import('../views/admin/settings'));
const QuotesView = lazy(() => import('../views/admin/quotes'));
const QuotesDetails = lazy(() => import('../views/admin/quotes/ViewQuote'));
const TeamView = lazy(() => import('../views/admin/partners'));
const TicketsView = lazy(() => import('../views/admin/tickets'));
const Financials = lazy(() => import('../views/admin/financials'));
const AgentTeam = lazy(() => import('../views/agent/team'));
const PayoutDetails = lazy(() => import('../views/admin/financials/payouts/PayoutDetails'));
const RideSharingPayout = lazy(() => import('../views/admin/financials/payouts/RideSharingPayout'));
const CommunitiesView = lazy(() => import('../views/admin/communities'));
const SingleCommunityView = lazy(() => import('../views/admin/communities/ViewCommunity'));
const ClaimsView = lazy(() => import('../views/admin/claims'));
const EditClaim = lazy(() => import('../views/admin/claims/EditClaim'));
const ClaimsDetailsView = lazy(() => import('../views/admin/claims/ClaimDetails'));
const InsurerResourcesView = lazy(() => import('../views/admin/resources'));
const AuditLogs = lazy(() => import('../views/admin/auditLogs'));
const TasksView = lazy(() => import('../views/admin/Tasks'));
const InsurerReviewsView = lazy(() => import('../views/admin/insurers/InsurerReviews'));
const CreatePolicyView = lazy(() => import('../views/admin/policies/AdminCreatePolicy'));
const BlogsView = lazy(() => import('../views/admin/blogs'));
const CreateBlogView = lazy(() => import('../views/admin/blogs/CreateBlog'));

//AGENTS
const AgentDashboardView = lazy(() => import('../views/admin/dashboard/AgentsDashboard'));

function Router() {
  const { getAccount, getProfile } = useUserAccount();
  const { getDashboardBase } = useRoute();
  const { isInsurerOnboarding } = useAuth();
  const user = new User(getProfile());
  const { getPreviousPath } = useNavigationStack();
  const navigate = useNavigate();

  useEffect(() => {
    if (isInsurerOnboarding()) {
      navigate(`${getDashboardBase()}/onboarding`);
    }
  }, [user]);

  const getDashboardlayout = (user: User): ReactNode => {
    if (user.isFuelVendor()) {
      return <DashboardLayout showSideBar={false} />;
    } else {
      return <DashboardLayout showSideBar />;
    }
  };

  const getDashboardView = (user: User): ReactNode => {
    let access;

    if (user.isAgency()) {
      access = 'agency';
    } else if (user.isInsurer()) {
      access = 'insurer';
    } else if (user.isRideSharing()) {
      access = 'rideSharing';
    } else if (user.isPartner()) {
      access = 'partner';
    } else {
      access = 'general';
    }

    switch (access) {
      case 'agency':
        return <AgentDashboardView />;
      case 'insurer':
        return <InsurerDashboardView />;
      case 'rideSharing':
        return <InsurerDashboardView />;
      case 'partner':
        return <PartnerDashboard />;
      default:
        return <DashboardView />;
    }
  };

  return (
    <div className="font-body">
      <Routes>
        {/* -------------------- AUTH ROUTES ----------------------------------- */}
        <Route path="/" element={<AuthLayout />} caseSensitive>
          <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} caseSensitive />
          <Route path={RESET_PASSWORD} element={<ResetPassword />} caseSensitive />
          <Route path={NOT_FOUND} element={<NotFound />} />
          <Route path="/" element={<Navigate replace to={SIGN_IN} />} caseSensitive />
          {/* <Route path="*" element={<Navigate replace to={NOT_FOUND} />} caseSensitive /> */}
        </Route>

        <Route path={SIGN_IN} element={<SignIn />} index caseSensitive />
        <Route path={COMPLETE_ACCOUNT} element={<CompleteAccount />} caseSensitive />

        {/* -------------------- DASHBOARD ROUTES ----------------------------------- */}
        {getAccount().isAuthenticated ? (
          <Route path={DASHBOARD_BASE} element={getDashboardlayout(user)} caseSensitive>
            <Route
              path={`${getDashboardBase()}/dashboard`}
              element={
                <PrivateRoute>
                  <PageLoader>{getDashboardView(user)}</PageLoader>
                </PrivateRoute>
              }
              index
              caseSensitive
            />

            {user.isSuperAdmin() && (
              <>
                <Route
                  path={`${getDashboardBase()}/approvals`}
                  element={
                    <PageLoader>
                      <Approvals />
                    </PageLoader>
                  }
                  caseSensitive
                  index
                />

                <Route
                  path={`${getDashboardBase()}/quota/:partnerId`}
                  element={
                    <PageLoader>
                      <PartnerFuelQuota />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />
              </>
            )}

            {(user.isReviewer() || user.isSuperAdmin()) && (
              <>
                <Route
                  path={`${getDashboardBase()}/tickets`}
                  element={
                    <PrivateRoute>
                      <PageLoader>
                        <TicketsView />
                      </PageLoader>
                    </PrivateRoute>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/customers`}
                  element={
                    <PrivateRoute>
                      <PageLoader>
                        <CustomersView />
                      </PageLoader>
                    </PrivateRoute>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/customers/:id`}
                  element={
                    <PageLoader>
                      <ViewCustomer previousPath={getPreviousPath()} />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/vehicles`}
                  element={
                    <PageLoader>
                      <Vehicles />
                    </PageLoader>
                  }
                  caseSensitive
                  index
                />

                <Route
                  path={`${getDashboardBase}/messages`}
                  element={
                    <PageLoader>
                      <MessagesView />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/notifications`}
                  element={
                    <PageLoader>
                      <NotificationsView />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/teams`}
                  element={
                    <PageLoader>
                      <TeamView />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/teams/:id`}
                  element={
                    <PageLoader>
                      <SingleTeam />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/teams/staff/:managerId`}
                  element={
                    <PageLoader>
                      <RelationshipManagerTeam />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/insurers`}
                  element={
                    <PageLoader>
                      <InsurersView />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/insurers/:id`}
                  element={
                    <PageLoader>
                      <InsurerDetails />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/insurers/:id/edit`}
                  element={
                    <PageLoader>
                      <InsurerEdit />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/insurers/add-insurer`}
                  element={
                    <PageLoader>
                      <AddInsurer />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/audit`}
                  element={
                    <PageLoader>
                      <AuditLogs />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={DASHBOARD_BASE}
                  element={<Navigate to={DASHBOARD} replace />}
                  caseSensitive
                />
              </>
            )}

            {user.isAdmin() && (
              <>
                <Route
                  path={`${getDashboardBase()}/teams/:id`}
                  element={
                    <PageLoader>
                      <SingleTeam />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/customers/:id`}
                  element={
                    <PageLoader>
                      <ViewCustomer previousPath={getPreviousPath()} />
                    </PageLoader>
                  }
                  index
                  caseSensitive
                />

                <Route
                  path={`${getDashboardBase()}/customers`}
                  element={
                    <PrivateRoute>
                      <PageLoader>
                        <CustomersView />
                      </PageLoader>
                    </PrivateRoute>
                  }
                  index
                  caseSensitive
                />
              </>
            )}

            {(user.isInsurerAdmin() ||
              user.isInsurerTechAdmin() ||
              user.isInsurerMarketingAdmin()) && (
              <>
                <Route
                  path={`${getDashboardBase()}/payouts`}
                  element={
                    <PageLoader>
                      <InsurerPayouts />
                    </PageLoader>
                  }
                  caseSensitive
                  index
                />

                <Route
                  path={`${getDashboardBase()}/onboarding`}
                  element={
                    <PageLoader>
                      <InsurerOnboarding />
                    </PageLoader>
                  }
                  caseSensitive
                  index
                />

                <Route
                  path={`${getDashboardBase()}/store`}
                  element={
                    <PageLoader>
                      <SetupInsurerStore />
                    </PageLoader>
                  }
                  caseSensitive
                  index
                />

                <Route
                  path={`${getDashboardBase()}/store/preview`}
                  element={
                    <PageLoader>
                      <InsurerStorePreview />
                    </PageLoader>
                  }
                  caseSensitive
                  index
                />

                <Route
                  path={`${getDashboardBase()}/resources`}
                  element={
                    <PageLoader>
                      <InsurerResourcesView />
                    </PageLoader>
                  }
                  caseSensitive
                  index
                />
              </>
            )}

            <Route
              path={`${getDashboardBase()}/blogs`}
              element={
                <PageLoader>
                  <BlogsView />
                </PageLoader>
              }
              caseSensitive
              index
            />
            <Route
              path={`${getDashboardBase()}/blogs/create-blog`}
              element={
                <PageLoader>
                  <CreateBlogView />
                </PageLoader>
              }
              caseSensitive
              index
            />
            <Route
              path={`${getDashboardBase()}/blogs/:blogId/edit`}
              element={
                <PageLoader>
                  <CreateBlogView />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/reviews`}
              element={
                <PageLoader>
                  <InsurerReviewsView />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/communities`}
              element={
                <PrivateRoute>
                  <PageLoader>
                    <CommunitiesView />
                  </PageLoader>
                </PrivateRoute>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/communities/:communityId`}
              element={
                <PrivateRoute>
                  <PageLoader>
                    <SingleCommunityView />
                  </PageLoader>
                </PrivateRoute>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/leads`}
              element={
                <PageLoader>
                  <LeadsView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/policies/create-policy`}
              element={
                <PageLoader>
                  <CreatePolicyView />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/signups`}
              element={
                <PageLoader>
                  <LeadsView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/policies`}
              element={
                <PageLoader>
                  <PoliciesView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/policies/:policyId`}
              element={
                <PageLoader>
                  <PolicyDetailsView previousPath={getPreviousPath()} />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/quotes`}
              element={
                <PageLoader>
                  <QuotesView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/fuel`}
              element={
                <PageLoader>
                  <FuelView />
                </PageLoader>
              }
              index
              caseSensitive
            />
            <Route
              path={`${getDashboardBase()}/payments`}
              element={
                <PageLoader>
                  <PaymentsView />
                </PageLoader>
              }
              caseSensitive
              index
            />

            {(user.isFuelAdmin() || user.isFuelManager()) && (
              <Route
                path={`${getDashboardBase()}/audit`}
                element={
                  <PageLoader>
                    <FuelSalesAuditview />
                  </PageLoader>
                }
                index
                caseSensitive
              />
            )}

            {user.isFuelManager() && (
              <Route
                path={`${getDashboardBase()}/quota`}
                element={
                  <PageLoader>
                    <PartnerFuelQuota />
                  </PageLoader>
                }
                index
                caseSensitive
              />
            )}

            {user.isFuelVendor() && (
              <Route
                path={`${getDashboardBase()}/sales`}
                element={
                  <PageLoader>
                    <FuelVendorSalesHistory />
                  </PageLoader>
                }
                index
                caseSensitive
              />
            )}

            <Route
              path={`${getDashboardBase()}/sales`}
              element={
                <PageLoader>
                  <PartnerFuelSales />
                </PageLoader>
              }
              index
              caseSensitive
            />

            {user.isInsurer() && (
              <Route
                path={`${getDashboardBase()}/sales`}
                element={
                  <PageLoader>
                    <InsurerSales />
                  </PageLoader>
                }
                caseSensitive
                index
              />
            )}

            <Route
              path={`${getDashboardBase()}/financials`}
              element={
                <PageLoader>
                  <Financials />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/payouts/:payoutId`}
              element={
                <PageLoader>
                  <PayoutDetails />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/teams/:teamId/payouts/:payoutId`}
              element={
                <PageLoader>
                  <PayoutDetails />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/team`}
              element={
                <PageLoader>
                  <AgentTeam />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/policies/:policyId/edit`}
              element={
                <PageLoader>
                  <PolicyEdit />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/quotes/:ref`}
              element={
                <PageLoader>
                  <QuotesDetails />
                </PageLoader>
              }
              caseSensitive
              index
            />

            <Route
              path={`${getDashboardBase()}/tasks`}
              element={
                <PageLoader>
                  <TasksView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/settings`}
              element={
                <PageLoader>
                  <SettingsView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/settings/roles/role/:id`}
              element={
                <PageLoader>
                  <RoleDetailsView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/settings/roles/create-role`}
              element={
                <PageLoader>
                  <CreateRoleView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/claims`}
              element={
                <PageLoader>
                  <ClaimsView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/claims/:claimNumber`}
              element={
                <PageLoader>
                  <ClaimsDetailsView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/claims/:claimNumber/edit`}
              element={
                <PageLoader>
                  <EditClaim />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/signups/create-leads`}
              element={
                <PageLoader>
                  <CreateLeads />
                </PageLoader>
              }
              index
              caseSensitive
            />

            <Route
              path={`${getDashboardBase()}/signups/:id`}
              element={
                <PageLoader>
                  <LeadDetailsView />
                </PageLoader>
              }
              index
              caseSensitive
            />

            {user.isRideSharing() && (
              <Route
                path={`${getDashboardBase()}/payouts`}
                element={
                  <PageLoader>
                    <RideSharingPayout />
                  </PageLoader>
                }
                index
                caseSensitive
              />
            )}
          </Route>
        ) : (
          <Route path={window.location.pathname} element={<Navigate to={SIGN_IN} />} />
        )}

        {/* -------------------- ERROR ROUTES ----------------------------------- */}
        <Route element={<ErrorsLayout />} caseSensitive>
          <Route path={NOT_FOUND} element={<NotFound />} caseSensitive />
        </Route>
      </Routes>
    </div>
  );
}

export default Router;
