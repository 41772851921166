import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import Input from '../../../components/form-control/Input';
import Button from '../../../components/form-control/Button';
import { MdOutlineSecurity } from 'react-icons/md';
import { XMarkIcon, ShieldExclamationIcon } from '@heroicons/react/24/solid';
import { Policy } from '../../../models/policy/Policy';
import { getFormattedPhoneNumber } from '../../../utils/miscFunctions';
import { IVerifyCustomerDTO } from '../customers/ViewCustomer';
import { sendCustomerOTP, sendOTPToPhone, verifyNonCustomerPhoneOTP, verifyPhoneOTP } from '../../../api/requests';
import { toast } from 'react-toastify';
import { HomePolicy } from '../../../models/homePolicy/HomePolicy';
import Loader from '../../../components/Loader';

export interface ICustomerPhoneVerificationModal {
  policy: Policy | HomePolicy | undefined;
  close: () => void;
  onProceed: () => void;
  phoneNumber?: string;
  isCustomer?: boolean;
}

const PhoneVerificationModal = ({ policy, close, onProceed, phoneNumber, isCustomer = true }: ICustomerPhoneVerificationModal) => {
  const [phone, setPhone] = useState<string>(phoneNumber ? getFormattedPhoneNumber(phoneNumber) : isCustomer ? getFormattedPhoneNumber(policy?.applicant?.phoneNumber ?? "") : "");
  const [sending, setSending] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const otpSentRef = useRef<boolean>(false);

  useEffect(() => {
    if (phone && !otpSentRef.current) {
      otpSentRef.current = true;
      resendOTP();
    }
  }, [phone])

  const resendOTP = () => {
    if (phone) {
      setSending(true);

      if (isCustomer) {
        sendCustomerOTP(phone)
          .then((response: any) => {
            if (response) {
              toast.success(response.message, {
                autoClose: 5000
              });
              setOtpSent(true);
            }
          })
          .catch((error: any) => {
            toast.error(error.description, {
              autoClose: 5000
            });
            setOtpSent(false);
          }).finally(() => setSending(false));
      } else {
        sendOTPToPhone(phone)
          .then((response: any) => {
            if (response) {
              toast.success(response.message, {
                autoClose: 5000
              });
              setOtpSent(true);
            }
          })
          .catch((error: any) => {
            toast.error(error.description, {
              autoClose: 5000
            });
            setOtpSent(false);
          }).finally(() => setSending(false));
      }
    }
  };

  const verifyOTP = (values: any) => {
    if (phone) {
      const VERIFICATION_CODE_CONSTANT = 'B-';
      const payload: IVerifyCustomerDTO = {
        verificationCode: VERIFICATION_CODE_CONSTANT + values.code,
        customerId: policy?.applicant?.customerId,
        accountProviders: 'PHONE',
        identifier: phone
      };

      if (isCustomer) {
        verifyPhoneOTP(payload)
          .then((response: any) => {
            if (response) toast.success(response, {
              autoClose: 5000
            });
            onProceed();
          })
          .catch((error: any) => {

            toast.error(error.description, {
              autoClose: 5000
            });
          });
      } else {
        verifyNonCustomerPhoneOTP(payload)
          .then((response: any) => {
            if (response) toast.success(response, {
              autoClose: 5000
            });
            onProceed();
          })
          .catch((error: any) => {

            toast.error(error.description, {
              autoClose: 5000
            });
          });
      }

    }
  };

  return (
    <div className="bg-white">
      {/* Modal Header */}
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-1">
            <div className="flex items-center justify-center bg-white h-[45px] w-[45px] rounded-full">
              <MdOutlineSecurity className="h-[24px] w-[24px]" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Verify Phone</h2>
          </div>
          <XMarkIcon onClick={close} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>

      {/* Modal Body */}
      {sending ? (
        <div className='my-12'>
          <Loader message='Sending otp...' />
        </div>

      ) : (
        <>
          <div className="p-[1rem] w-full">
            {otpSent ? (
              <>
                <div className=" mt-[26px]">
                  <span className="text-center">
                    Enter the verification code provided by the customer
                  </span>
                </div >
                <Formik
                  initialValues={{
                    code: ''
                  }}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={Yup.object({
                    code: Yup.string()
                      .trim()
                      .min(6, 'Invalid verification code')
                      .max(6, 'Invalid verification code')
                      .required('Verification code is required')
                  })}
                  onSubmit={(values) => {
                    // submitHandler(values);
                  }}>
                  {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
                    <form className="space-y-4 py-[1rem]" onSubmit={handleSubmit}>
                      <div className="flex flex-col gap-5">
                        <div className="flex">
                          <div className="">
                            <Input
                              type="text"
                              name="code"
                              label="Verification code"
                              placeholder=""
                              className="rounded-[0px] placeholder-[#848484] focus:ring-primary-border "
                              value={values.code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              prepend={'B-'}
                            />
                          </div>

                          <div className="flex items-end ">
                            <Button
                              label="Verify OTP"
                              title=" "
                              bgColor="primary"
                              className="rounded-r-md py-[0.63rem] ml-0"
                              onClick={() => verifyOTP(values)}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </>
            ) : (
              <div className='flex items-center my-2'>
                <ShieldExclamationIcon className='w-6 mr-2 text-danger-pressed' /> <span>Failed to send otp.</span>
              </div>
            )}

            <div className="">
              <span
                className="text-blue-700 cursor-pointer hover:underline text-sm"
                onClick={() => resendOTP()}>
                Resend verification code
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PhoneVerificationModal;
