import { ReactElement, ReactNode } from 'react';

interface ITabContent {
  id: string;
  activeTab: string | null;
  children: ReactElement | ReactNode | JSX.Element; 
}
const TabContent = ({ id, activeTab, children }: ITabContent) => {
  return activeTab === id ? <div className="TabContent">{children}</div> : null;
};

export default TabContent;
