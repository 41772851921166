import React, { ReactElement, ReactNode } from "react";
import clsx from "clsx";

import Loader from "../Loader";

export interface IButtonProps {
    type?: "button" | "submit" | "reset";
    label?: string;
    loading?: boolean;
    disabled?: boolean;
    bgColor?: "primary" | "bg-background" | "secondary" | "bg-primary-border"
    onClick?: () => void | undefined;
    className?: string,
    children?: string | JSX.Element | ReactElement | ReactNode | null;
    icon?: string | JSX.Element | ReactElement | ReactNode | null;
    title?: string;
    fullWidth?: boolean;
    curved?: boolean;
    size?: "lg" | "md" | "sm",
    id?: string;
}

function Button({
    type = "button",
    label,
    loading = false,
    disabled = false,
    onClick,
    bgColor,
    className,
    children,
    icon,
    title,
    fullWidth = false,
    curved = false,
    size = "sm",
    id
}: IButtonProps): JSX.Element {
    const buttonLabel = children ? children : label;
    return (
        <button
            className={clsx(
                "text-base font-medium border shadow-sm",
                "flex items-center focus:ring-primary-border w-fit px-4",
                "hover:text-gray-700",
                { "border border-primary-main hover:bg-primary-main": bgColor == "primary" },
                { "bg-secondary-main": bgColor == "secondary" },
                { "w-full": fullWidth },
                { "rounded-md": curved },
                { "p-3": size == "md" },
                { "p-2 text-sm": size == "sm" },
                { ["bg-gray-300"]: disabled },
                className
            )}
            type={type}
            disabled={disabled}
            onClick={onClick}
            title={title}
            id={id}
        >
            {icon ? (<span className="flex-none">{icon}</span>) : null}
            <span className="flex justify-center whitespace-nowrap w-full items-center">
                {loading ? (<Loader size="xs" />) : buttonLabel}
            </span>
        </button>
    )
}

export default Button;