import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/form-control/Button';
import SelectDropdown from '../../../components/form-control/SelectDropdown';
import TextArea from '../../../components/form-control/TextArea';
import usePolicy from '../../../hooks/policyHook';
import { Policy } from '../../../models/policy/Policy';
import { POLICY_REVIEW_STATES } from '../../../resources/enums';
import { IReviewPolicyDTO } from '../../../resources/interfaces';
import { getBooleanSelectDropdownList } from '../../../utils/miscFunctions';
import { CheckBoxInput } from '../../../components/form-control/CheckBoxInput';

interface IReviewPolicy {
  policy: Policy | undefined;
  onProceed: () => void;
  onCancel: () => void;
  type: POLICY_REVIEW_STATES;
  title: string;
  titleIcon: React.ReactNode;
  request?: boolean;
}

const ReviewPolicy = ({ policy, onProceed, onCancel, type, titleIcon, title, request }: IReviewPolicy) => {
  const { reviewPolicy, cancelPolicy } = usePolicy();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [terminatePolicy, setTerminatePolicy] = useState<boolean>(false);
  const [reviewReason, setReviewReason] = useState<string>('');
  const [allInfoAccurate, setAllInfoAccurate] = useState<string | undefined>(undefined);

  const getReviewEndpoint = (type: POLICY_REVIEW_STATES) => {
    const payload: IReviewPolicyDTO = {
      reason: reviewReason,
      reviewStage: type,
    };

    setSubmitting(true);

    let promise;

    switch (type) {
      case POLICY_REVIEW_STATES.CANCELLED:
        promise = request ? reviewPolicy(policy?.ref ?? '', payload) : cancelPolicy(policy?.ref ?? '', reviewReason, terminatePolicy);
        break;
      case POLICY_REVIEW_STATES.DUE_LATER:
      case POLICY_REVIEW_STATES.ON_HOLD:
      case POLICY_REVIEW_STATES.IN_REVIEW:
      default:
        promise = reviewPolicy(policy?.ref ?? '', payload);
        break;
    }

    promise
      .then((response: any) => {
        toast.success(response.message, {
          autoClose: 3000,
        });
        onProceed();
      })
      .catch((error: Error) => {
        toast.error('Request failed. Please try again later.');
      })
      .finally(() => setSubmitting(false));
  };

  const handleSubmit = () => {
    if (type === POLICY_REVIEW_STATES.IN_REVIEW && allInfoAccurate !== 'yes') {
      toast.error('You need to confirm all information provided is accurate to proceed.', {
        autoClose: 5000,
      });
      return;
    }

    if (type !== POLICY_REVIEW_STATES.IN_REVIEW && !reviewReason) {
      toast.error('You need to provide a reason to proceed.', {
        autoClose: 5000,
      });
      return;
    }

    getReviewEndpoint(type);
  };

  const getDescription = (): string => {
    if (request) {
      return 'You are submitting this policy for cancellation, provide reasons or concerns.';
    }

    switch (type) {
      case POLICY_REVIEW_STATES.DUE_LATER:
        return 'If you want to set this policy to due later, please provide reasons or concerns about the policy.';
      case POLICY_REVIEW_STATES.CANCELLED:
        return 'By cancelling this policy, you agree to have this policy cancelled by the National Commission/Motor Insurance Department. Provide reasons or concerns for the cancellation of this policy.';
      case POLICY_REVIEW_STATES.ON_HOLD:
        return 'If you want to put this policy on hold, please provide reasons or concerns about the policy.';
      case POLICY_REVIEW_STATES.IN_REVIEW:
        return `By submitting this policy for review, you agree that all of the details provided by the policy's owner are accurate as of this day. Do you agree to submit this?`;
      default:
        return '';
    }
  };

  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between h-[60px] px-[1rem] items-center">
        <div className="flex items-center">
          <div className="mr-2 rounded-[50%] w-[45px] h-[45px] bg-white flex items-center justify-center">
            {titleIcon}
          </div>
          <h2 className="font-semibold text-lg">{title}</h2>
        </div>
        <XMarkIcon onClick={onCancel} width={'11px'} height={'11px'} className="cursor-pointer" />
      </div>

      <div className="p-4">
        <div className="py-2 space-y-4">
          <p>{getDescription()}</p>

          {type === POLICY_REVIEW_STATES.IN_REVIEW && (
            <SelectDropdown
              searchPlaceHolder="Click to select"
              name="goodRepairState"
              listSelectedValue={allInfoAccurate}
              onChange={(val: string | undefined) => setAllInfoAccurate(val)}
              list={getBooleanSelectDropdownList()}
              placeholder=""
            />
          )}

          {type === POLICY_REVIEW_STATES.CANCELLED && (
            <div className="space-y-5">
              <TextArea
                name={'reason'}
                value={reviewReason}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setReviewReason(event.target.value)}
                placeholder="Write your reasons here."
                rows={5}
              />
              {
                !request && <CheckBoxInput
                  label={'Terminate'}
                  name={'terminatePolicy'}
                  checked={terminatePolicy}
                  onChange={(val: boolean) => setTerminatePolicy(val)}
                />
              }

            </div>
          )}

          {!(type === POLICY_REVIEW_STATES.CANCELLED || type === POLICY_REVIEW_STATES.IN_REVIEW) && (
            <TextArea
              name={'reason'}
              value={reviewReason}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setReviewReason(event.target.value)}
              placeholder="Write your reasons here."
              rows={5}
            />
          )}
        </div>

        <div className="flex justify-end space-x-6 mt-10">
          <Button
            label="Cancel"
            className="w-fit px-8 h-[45px] rounded-[5px] text-base border-[1px] border-primary-main"
            onClick={onCancel}
          />
          <Button
            label="Confirm"
            className="bg-primary-main w-fit px-8 py-2 h-[45px] rounded-[5px] text-base"
            onClick={handleSubmit}
            loading={submitting}
            disabled={submitting}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewPolicy;
