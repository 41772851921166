import imageCompression from 'browser-image-compression';
import {
  deleteUploadedImage,
  uploadBlogMedia,
  uploadDocument,
  uploadDocumentByPut,
  uploadPartnerLogo,
  uploadProductIcon
} from '../api/requests';
import {
  DELETE_IMAGE_ENDPOINTS,
  UPLOADABLE_DOCUMENT_TYPES,
  UPLOAD_IMAGE_ENDPOINTS
} from '../resources/enums';
import { blobToFile } from '../utils/miscFunctions';

const compressOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1920,
  useWebWorker: true
};

function useImage() {
  const uploadImage = (image: File, url: string, requestMethod = 'post', fileName = 'file') => {
    return new Promise<void>((resolve, reject) => {
      const imageData = getImageFormData(image, fileName);

      if (requestMethod === 'post') {
        uploadDocument(`${url}`, imageData)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: Error) => {
            reject(error);
          });
      }

      if (requestMethod === 'put') {
        uploadDocumentByPut(`${url}`, imageData)
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: Error) => {
            reject(error);
          });
      }
    });
  };

  const uploadStaffImage = (
    image: File,
    documentType: UPLOADABLE_DOCUMENT_TYPES,
    url: UPLOAD_IMAGE_ENDPOINTS
  ) => {
    return new Promise<void>((resolve, reject) => {
      const imageData = getImageFormData(image);

      uploadDocument(`${url}?documentType=${documentType}`, imageData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const uploadVehicleImage = (
    image: File | '',
    documentType: UPLOADABLE_DOCUMENT_TYPES,
    url: UPLOAD_IMAGE_ENDPOINTS,
    vehicleId: string
  ) => {
    return new Promise<void>((resolve, reject) => {
      let imageData = new FormData();
      if (image) {
        imageData = getImageFormData(image);
      }

      imageData.append('documentType', documentType);
      imageData.append('vehicleId', vehicleId);

      uploadDocument(`${url}`, imageData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const uploadPolicyOwnerDocument = (
    file: File | '',
    documentType: UPLOADABLE_DOCUMENT_TYPES,
    url: UPLOAD_IMAGE_ENDPOINTS,
    customerId: string
  ) => {
    return new Promise<void>((resolve, reject) => {
      let fileData = new FormData();
      if (file) {
        fileData = getImageFormData(file);
      }

      fileData.append('documentType', documentType);
      fileData.append('customerId', customerId);

      uploadDocument(`${url}`, fileData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const uploadPolicyDocument = (
    file: File | '',
    documentType: UPLOADABLE_DOCUMENT_TYPES,
    url: UPLOAD_IMAGE_ENDPOINTS,
    policyNumber: string
  ) => {
    return new Promise<void>((resolve, reject) => {
      let fileData = new FormData();
      if (file) {
        fileData = getImageFormData(file);
      }

      fileData.append('documentType', documentType);
      fileData.append('policyNumber', policyNumber);

      uploadDocument(`${url}`, fileData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const getImageFormData = (image: File, imageFieldName = 'file', compress = false) => {
    const data = new FormData();

    if (compress) {
      const compressedFile = getCompressedImage(image);
      data.append(imageFieldName, blobToFile(compressedFile, image));
    } else {
      data.append(imageFieldName, image);
    }

    return data;
  };

  const getCompressedImage = async (image: File) => {
    const compressedFile = await imageCompression(image, compressOptions);

    return compressedFile;
  };

  const removeImage = (url: DELETE_IMAGE_ENDPOINTS) => {
    return new Promise<void>((resolve, reject) => {
      deleteUploadedImage(`${url}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const productIconUpload = (productId: string, file: File) => {
    return new Promise<void>((resolve, reject) => {
      const imageData = getImageFormData(file);
      uploadProductIcon(productId, imageData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const partnerLogoUpload = (partnerId: string, file: File) => {
    return new Promise<void>((resolve, reject) => {
      const imageData = getImageFormData(file);
      uploadPartnerLogo(partnerId, imageData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  const uploadBlogPhoto = (blogId: string, file: File, isBanner:boolean) => {
    return new Promise<void>((resolve, reject) => {
      const imageData = getImageFormData(file);
      uploadBlogMedia(blogId, imageData, isBanner)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  return {
    getCompressedImage,
    getImageFormData,
    uploadImage,
    uploadVehicleImage,
    uploadPolicyDocument,
    uploadPolicyOwnerDocument,
    uploadStaffImage,
    removeImage,
    productIconUpload,
    partnerLogoUpload,
    uploadBlogPhoto
  };
}

export default useImage;
