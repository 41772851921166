import React from 'react';
import clsx from 'clsx';

interface FuelQuotaBarProps {
  totalQuantity: number;
  remainingQuantity: number;
  className?: string;
  style?: string; // Tailwind CSS classes
}

const FuelQuotaBar: React.FC<FuelQuotaBarProps> = ({
  totalQuantity,
  remainingQuantity,
  className = '',
  style = ''
}) => {
  // Ensure totalQuantity is not zero to prevent division by zero
  const validTotalQuantity = totalQuantity > 0 ? totalQuantity : 1;

  const progress = (remainingQuantity / validTotalQuantity) * 100;
  const safeProgress = isNaN(progress) || remainingQuantity <= 0 ? 0 : progress;
  const safeRemainingQuantity = isNaN(remainingQuantity) ? 0 : remainingQuantity;

  const remainingText = `${safeRemainingQuantity.toFixed(2)} L (${safeProgress.toFixed(2)}%)`;
  const usedQuantity = validTotalQuantity - safeRemainingQuantity;
  const safeUsedQuantity = isNaN(usedQuantity) ? 0 : usedQuantity;
  const usedText = `${safeUsedQuantity.toFixed(2)} L (${(100 - safeProgress).toFixed(2)}%)`;

  return (
    <div className={clsx('flex items-center w-full ', className)}>
      <div className={clsx('relative flex-1 h-8 bg-gray-200 rounded', style)}>
        <div
          className="h-full bg-yellow-500 rounded transition-all duration-300 ease-in-out"
          style={{ width: `${safeProgress}%` }}
        />
        {/* <div
          className="absolute left-0 top-0 h-full flex items-center pl-2 text-xs font-bold text-black"
          style={{ width: `${100 - safeProgress}%` }}>
          {safeProgress <= 60 && <div className="w-full text-center">{usedText}</div>}
        </div> */}
        <div
          className="absolute right-0 top-0 h-full flex items-center pr-2 text-xs font-bold text-gray-500"
          style={{ width: `${safeProgress}%` }}>
          {/* <div className="w-full text-center">{remainingText}</div> */}
          {/* {safeProgress >= 40 && <div className="w-full text-center">{remainingText}</div>} */}
        </div>
      </div>
    </div>
  );
};

export default FuelQuotaBar;
