import clsx from "clsx";
import React, { useEffect } from "react";
import { FC, useState } from "react";

export const CheckBoxInput = ({ label, id, name, onChange, value, checked, error, helperText, classes,disabled }: ICheckBoxInput): JSX.Element => {
  const [checkedValue, setCheckedValue] = useState<boolean>(checked);

  return (
    <div className="space-x-6">
      <label className={clsx("inline-flex items-center cursor-pointer")}>
        <input
          type="checkbox"
          className={clsx(
            `cursor-pointer border border-primary-main checked:outline-white focus:outline-white outline-none active:outline-white text-primary-main`, 
            classes
          )}
          name={name}
          id={id}
          value={value}
          checked={checkedValue}
          onChange={() => {
            setCheckedValue(!checkedValue);
            onChange(!checkedValue);
          }}
          disabled={disabled}
        />
        <span className="ml-2 text-sm font-medium text-gray-900">{label}</span>
      </label>
      <div className={clsx("text-xs text-gray-400", { ["text-danger-main"]: error })}>{helperText}</div>
    </div>
  );
};

interface ICheckBoxInput {
  label: string;
  id?: string;
  name: string;
  value?: string;
  selectedValue?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (data: boolean) => void;
  error?: string;
  helperText?: string;
  classes?: string;
}
