import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  editTeam,
  getSlackWorkspaces
} from '../../../api/requests';
import SelectDropdown, {
  ISelectDropdownItem
} from '../../../components/form-control/SelectDropdown';
import { TEAM_TYPES } from '../../../resources/enums';
import SelectMenu from '../../../components/form-control/SelectMenu';
import { MdOutlineGroups } from 'react-icons/md';
import { CheckBoxInput } from '../../../components/form-control/CheckBoxInput';
import { GHANA_REGIONS } from '../../../resources/constants';


export interface EditTeamProps {
  team: any;
  onProceed: (data?: any) => void;
  onClose: () => void;
}

const EditTeam = ({ onProceed, onClose, team }: EditTeamProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [addMembers, setAddMembers] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getSlackWorkspaces()
      .then((response: any) => {
        setWorkspaces(response?.data);
      })
      .catch((error: any) => {
        toast.error(error.message);
      })

  }, [])


  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload = {
      ...values,
      name: values.name,
      type: values.type,
      slackWorkspaceId: values.slackWorkspaceId,
      addMembers: values.addMembers,
    };

    const teamID = team.id

    editTeam(teamID, payload)
      .then((response: any) => {
        toast.success(response?.message ?? 'Team details updtaed successfully', {
          autoClose: 5000
        });
        onProceed();
      })
      .catch((error: any) => {
        toast.error(`${error.description}`);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between p-5">
        <div className=" flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-5">
            <div className="flex items-center justify-center bg-white p-2 rounded-full">
              <MdOutlineGroups className="h-4 w-4" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Edit Team</h2>
          </div>
          <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            name: team.name,
            type: team.type,
            region: team.region,
            city: team.city,
            slackWorkspaceId: team.slackWorkspaceId,
            addMembers: team.addMembers,
          }}
          validateOnBlur
          validationSchema={Yup.object({
            name: Yup.string().required('Team name is requied.'),
            type: Yup.string().required('Please select team type')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="space-y-5">
                <Input
                  label="Team Name"
                  placeholder="Enter team name"
                  name="name"
                  value={values.name}
                  type="text"
                  wrapperClasses=""
                  required
                  // error={errors.name}
                  onChange={handleChange}
                  onBlur={handleChange}
                // helperText={errors.name}
                />

                <SelectMenu
                  label="Select Team Type"
                  name="type"
                  list={Object.values(TEAM_TYPES)}
                  listSelectedValue={values.type}
                  onChange={(val: ISelectDropdownItem | null) => {
                    setFieldValue('type', val);
                  }}
                  onBlur={handleBlur}
                  placeholder="Select Manager"
                  required
                />
                <SelectDropdown
                  label="Region"
                  name="region"
                  listSelectedValue={values.region}
                  list={GHANA_REGIONS?.map((region: string, id: number) => ({
                    name: (region),
                    value: region
                  }))}
                  onChange={(val: string | null) => setFieldValue('region', val)}
                  onBlur={handleBlur}
                  placeholder="Select team region"
                  required

                />

                {
                  values.region &&
                  <Input
                    label="City"
                    placeholder="Enter city"
                    name="city"
                    value={values.city}
                    type="text"
                    wrapperClasses=""
                    required
                    // error={errors.city}
                    onChange={handleChange}
                    onBlur={handleChange}
                  // helperText={errors.name}
                  />
                }

                <SelectDropdown
                  name="slackWorkspaceId"
                  label="Slack Workspace"
                  placeholder="Select workspace "
                  listSelectedValue={values.slackWorkspaceId}
                  wrapperClasses=""
                  required
                  onChange={(value: any) => {
                    setFieldValue('slackWorkspaceId', value);
                  }}
                  list={workspaces?.map((workspace: any) => ({
                    name: workspace?.name,
                    value: workspace?.id
                  })
                  )}
                />

                {values.slackWorkspaceId &&
                  <CheckBoxInput
                    label={'Add members to workspace'}
                    name={'addMembers'}
                    checked={values?.addMembers}
                    onChange={(value: boolean) => {
                      setFieldValue("addMembers", value)
                    }}
                  />
                }
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  className="w-fit px-6 text-[14px]"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditTeam;
