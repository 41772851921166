import clsx from 'clsx';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { IoLogOut as LogoutIcon } from 'react-icons/io5';
import { RIDE_SHARING_SIDEBAR_NAVS, SIDE_BAR_NAVS } from '../../resources/constants';
import Button from '../form-control/Button';
import useUserAccount from '../../hooks/userAccountHook';
import { User } from '../../models/User';
import { ISideBarItem, ISideBarSubItem } from '../../resources/interfaces';
import { ACCESS_RIGHTS, POLICY_TYPES, POLICY_TYPES_PATH } from '../../resources/enums';
import useRoute from '../../hooks/routesHook';
import { useEffect, useState } from 'react';
import { Bars3Icon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { getInsurerProfile } from '../../api/requests';
import { Insurer } from '../../models/insurer/Insurer';
import { IProduct } from '../../models/insurer/IInsurer';
import { ReactComponent as DashboardPoliciesIcon } from '../../resources/icons/DashboardPoliciesIcon.svg';
import { FaCar, FaHome } from 'react-icons/fa';
import useLayoutHook from '../../hooks/layoutHook';
import { Animate } from 'react-simple-animate';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { DASHBOARD } from '../../resources/links';
import Tooltip from '../ToolTip';

interface SidebarContentProps {
  onMenuClick: () => void;
}

function SidebarContent({ onMenuClick }: SidebarContentProps) {
  const { signOut, getProfile, nameInitials, user } = useUserAccount();
  const { getDashboardBase } = useRoute();
  const { screenWidth } = useLayoutHook();
  const [showSubNav, setShowSubNav] = useState<boolean>(false);
  const [toggleFullNav, setToggleFullNav] = useState<boolean>(false);
  const [insurer, setInsurer] = useState<Insurer>();
  const navigate = useNavigate();

  useEffect(() => {
    user.isInsurer() &&
      getInsurerProfile().then((response: any) => {
        setInsurer(response);
      });
  }, []);

  const logout = () => {
    signOut();
  };

  const userHasAccess = (sideBarItem: ISideBarItem): boolean => {
    return user.accessRight ? sideBarItem.access.includes(user.accessRight) : false;
  };

  const isActiveSubNav = (navPath: string): boolean => {
    const fullPath = `${window.location?.pathname}${window.location?.search}`;
    return fullPath?.includes(navPath?.toLowerCase());
  };

  const isActiveNav = (navPath: string): boolean => {
    const path = `${window.location?.pathname}`;
    const indexPath = window.location?.pathname?.split('/')[2];
    return indexPath?.includes(navPath?.toLowerCase().replace('/', '')?.trim());
  };

  const toggleSubNav = (navPath: string, firstSubNavPath: string) => {
    if (!isActiveNav(navPath)) {
      navigate(`${getDashboardBase()}${firstSubNavPath}`);
    }
    setShowSubNav(!showSubNav);
  };

  const getSidebarNavs = () => {
    if (user.isInsurer()) {
      // TO DO: Remove the filter when the other products are enabled.
      const products: IProduct[] =
        insurer?.products?.filter(
          (product: IProduct) => product.name?.split(' ')[0]?.toLowerCase() === POLICY_TYPES.AUTO
        ) ?? [];

      if (products && products.length > 0) {
        const policiesIndex = SIDE_BAR_NAVS.middle.findIndex(
          (item: any) => item.title?.toLowerCase() === 'policies'
        );

        const policiesNav = {
          title: 'Policies',
          path: '/policies',
          icon: DashboardPoliciesIcon,
          access: [
            ACCESS_RIGHTS.SUPER_ADMINISTRATOR,
            ACCESS_RIGHTS.REVIEWER,
            ACCESS_RIGHTS.ADMINISTRATOR,
            ACCESS_RIGHTS.AGENCY_ADMIN,
            ACCESS_RIGHTS.AGENT,
            ...getInsurerAccessRights()
          ],
          key: 'policies',
          hasChildren: true,
          // children: products.sort((a: any, b: any) => (a.position > b.position ? 1 : -1)).map((product: IProduct) => {
          children: products
            .sort((a: any, b: any) =>
              getPolicyTypePosition(a.name) > getPolicyTypePosition(b.name) ? 1 : -1
            )
            .map((product: IProduct) => {
              return {
                title: product.name?.split(' ')[0],
                path: getPolicyTypePath(product.name),
                icon: getPolicyTypeIcon(product.name)
              };
            })
        };

        const _sidebarNavs = { ...SIDE_BAR_NAVS };
        _sidebarNavs.middle[policiesIndex] = policiesNav;
        return {
          ...SIDE_BAR_NAVS,
          middle: [...SIDE_BAR_NAVS.middle]
        };
      }
    }

    return SIDE_BAR_NAVS;
  };

  const getInsurerAccessRights = (): ACCESS_RIGHTS[] => {
    return [
      ACCESS_RIGHTS.INSURER_ADMIN,
      ACCESS_RIGHTS.INSURER_AGENT,
      ACCESS_RIGHTS.INSURER_CLAIM_ADMIN,
      ACCESS_RIGHTS.INSURER_POLICY_ADMIN,
      ACCESS_RIGHTS.INSURER_TECH_ADMIN,
      ACCESS_RIGHTS.INSURER_FINANCE_ADMIN
    ];
  };

  const getPolicyTypePath = (policyType: string): string => {
    const _policyType: POLICY_TYPES = policyType.split(' ')[0]?.toLowerCase() as POLICY_TYPES;

    switch (_policyType) {
      case POLICY_TYPES.AUTO:
        return POLICY_TYPES_PATH.AUTO;
      case POLICY_TYPES.HOME:
        return POLICY_TYPES_PATH.HOME;
    }

    return '/policies';
  };

  const getPolicyTypeIcon = (policyType: string) => {
    const _policyType: POLICY_TYPES = policyType.split(' ')[0]?.toLowerCase() as POLICY_TYPES;

    switch (_policyType) {
      case POLICY_TYPES.AUTO:
        return FaCar;
      case POLICY_TYPES.HOME:
        return FaHome;
    }

    return FaCar;
  };

  const getPolicyTypePosition = (policyType: string): number => {
    const _policyType: POLICY_TYPES = policyType.split(' ')[0]?.toLowerCase() as POLICY_TYPES;

    switch (_policyType) {
      case POLICY_TYPES.AUTO:
        return 1;
      case POLICY_TYPES.HOME:
        return 2;
    }

    return 1;
  };

  const handleToggleFullNav = (): any => {
    setToggleFullNav(!toggleFullNav);
  };

  const handleToggleMobileNav = (): any => {
    setToggleFullNav(false);
  };

  const endStyles = {
    zIndex: 2147483647,
    position: 'absolute' as 'absolute',
    top: 0,
    left: screenWidth > 550 ? 0 : '230px',
    width: screenWidth > 550 ? '230px' : '100vw'
  };

  const startStyles = {
    zIndex: 2147483647,
    position: 'absolute' as 'absolute',
    top: 0,
    left: screenWidth > 550 ? '-230px' : '-100vw',
    width: screenWidth > 550 ? '230px' : '100vw'
  };

  return (
    <>
      <div className=" bg-white  py-2 px-3 rounded-md md:flex flex-col gap-5 min-h-full w-full min-w-[200px]">
        <div className="pt-5 ">
          <h2 className="py-3 text-gray-500">Dashboard</h2>
          {getSidebarNavs()?.top.map((nav: ISideBarItem, index: number) => (
            <>
              {userHasAccess(nav) && (
                <div className="flex items-center justify-center w-full">
                  {/* <Tooltip message={nav.title} direction="right"> */}
                  <NavLink
                    onClick={onMenuClick}
                    key={index}
                    to={`${getDashboardBase()}${nav.path}`}
                    className={clsx(
                      'cursor-pointer w-full px-3 py-2 flex items-center rounded-md',
                      { [' bg-brolly-yellow']: isActiveNav(nav.path) },
                      { ['hover:bg-primary-surface']: !isActiveNav(nav.path) }
                    )}>
                    {nav.icon && (
                      <span className="mr-3">
                        <nav.icon className="w-6 h-6 " />
                      </span>
                    )}
                    <h2 className="w-full">{nav.title}</h2>
                  </NavLink>
                  {/* </Tooltip> */}
                </div>
              )}
            </>
          ))}
        </div>

        <div className="mt-3">
          <h2 className="pb-3 text-gray-500">Manage</h2>

          {getSidebarNavs()?.middle.map((nav: ISideBarItem, index: number) => (
            <>
              {userHasAccess(nav) && (
                <div className="flex items-center justify-center w-full">
                  {/* <Tooltip message={nav.title} direction="right"> */}
                  <NavLink
                    onClick={onMenuClick}
                    key={index}
                    to={`${getDashboardBase()}${nav.path}`}
                    className={clsx(
                      'cursor-pointer w-full px-3 py-2 flex items-center rounded-md',
                      { [' bg-brolly-yellow']: isActiveNav(nav.path) },
                      { ['hover:bg-primary-surface']: !isActiveNav(nav.path) }
                    )}>
                    {nav.icon && (
                      <span className="mr-3">
                        <nav.icon className="w-6 h-6 " />
                      </span>
                    )}
                    <h2 className="w-full">{nav.title}</h2>
                  </NavLink>
                  {/* </Tooltip> */}
                </div>
              )}
            </>
          ))}
        </div>
      </div>

      <Animate play={toggleFullNav} start={startStyles} end={endStyles} duration={0.2}>
        <div className="mx-auto overflow-y-auto bg-white  min-h-screen  p-5 shadow ">
          <div className="w-full py-3 px-2 flex items-center justify-between gap-5">
            {user.team && (
              <h1 className={clsx('text-black font-bold truncate')}>
                {user?.team.name ?? user?.firstName}
              </h1>
            )}

            <div className="flex items-center justify-center p-1 size-fit bg-[#F4F4F4] rounded-full">
              <XMarkIcon
                className="size-4 cursor-pointer hover:font-bold"
                onClick={handleToggleFullNav}
              />
            </div>
          </div>

          {/* NavBrand */}
          <div className="pb-3 pl-8">
            <Link to={DASHBOARD}>
              <div className="border border-[#AAAAAA] p-1 w-fit">
                <img
                  className="max-w-[9rem] md:max-w-[4rem] w-[4rem] sm:w-[9rem] md:w-[9rem] h-auto "
                  alt={
                    user.isInsurer() || user.isFuelPartner()
                      ? user.team?.partner?.name
                      : user.team?.name
                  }
                  src={user.team?.partner?.logo ?? user.team?.logo ?? '/img/logo.png'}
                  loading="eager"
                />
              </div>
            </Link>
          </div>

          <div className="flex flex-col items-center justify-between pt-5 min-h-[calc(100vh_-_20%)]">
            <div>
              <h2 className="pb-3 text-gray-500">Dashboard</h2>
              {getSidebarNavs()?.top.map((nav: ISideBarItem, index: number) => (
                <>
                  {userHasAccess(nav) && (
                    <>
                      {nav.hasChildren && nav.children?.length && nav.children?.length > 0 ? (
                        <>
                          <div
                            onClick={() => {
                              onMenuClick();
                              toggleSubNav(nav.path, nav?.children?.[0]?.path ?? nav.path);
                              handleToggleFullNav();
                            }}
                            key={index}
                            className={clsx(
                              'cursor-pointer w-full justify-center p-2 flex items-center rounded-md',
                              { ['hover:bg-primary-surface']: isActiveNav(nav.path) }
                            )}>
                            {nav.icon && (
                              <span className="mr-3">
                                <nav.icon className="w-6 h-6 " />
                              </span>
                            )}
                            <h2 className="w-full">{nav.title}</h2>
                            {isActiveNav(nav.path) && showSubNav ? (
                              <ChevronUpIcon
                                className="h-7 w-7 text-gray-400 cursor-pointer"
                                aria-hidden="true"
                                onClick={() => {}}
                              />
                            ) : (
                              <ChevronDownIcon
                                className="h-7 w-7 text-gray-400 cursor-pointer"
                                aria-hidden="true"
                                onClick={() => {}}
                              />
                            )}
                          </div>
                          {isActiveNav(nav.path) && showSubNav && (
                            <div className="pl-[2.3rem]">
                              {nav.children?.map((navSub: ISideBarSubItem, idx: number) => (
                                <NavLink
                                  onClick={() => {
                                    onMenuClick();
                                    handleToggleFullNav();
                                  }}
                                  key={idx}
                                  to={`${getDashboardBase()}${navSub.path}`}
                                  className={({ isActive }) =>
                                    clsx(
                                      'cursor-pointer w-full px-4 py-1 flex items-center rounded-md',
                                      {
                                        ['bg-brolly-yellow font-base']: isActiveSubNav(navSub.path)
                                      },
                                      { ['hover:bg-primary-surface']: !isActiveSubNav(navSub.path) }
                                    )
                                  }>
                                  {navSub.icon && (
                                    <span className="mr-3">
                                      <navSub.icon className="w-4 h-4" />
                                    </span>
                                  )}
                                  <h2>{navSub.title}</h2>
                                </NavLink>
                              ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <NavLink
                          onClick={() => {
                            onMenuClick();
                            handleToggleFullNav();
                          }}
                          key={index}
                          to={`${getDashboardBase()}${nav.path}`}
                          className={({ isActive }) =>
                            clsx(
                              'cursor-pointer w-full p-2 flex items-center  rounded-md',
                              {
                                ['font-semibold bg-brolly-yellow font-base']: isActiveNav(nav.path)
                              },
                              { ['hover:bg-primary-surface']: !isActiveNav(nav.path) }
                            )
                          }>
                          {nav.icon && (
                            <span className="mr-3 text-[#5c5c5c]">
                              <nav.icon className="w-6 h-6" />
                            </span>
                          )}
                          <h2>{nav.title}</h2>
                        </NavLink>
                      )}
                    </>
                  )}
                </>
              ))}
            </div>

            <div>
              <h2 className="pb-3 text-gray-500">Manage</h2>
              {getSidebarNavs()?.middle.map((nav: ISideBarItem, index: number) => (
                <>
                  {userHasAccess(nav) && (
                    <>
                      {nav.hasChildren && nav.children?.length && nav.children?.length > 0 ? (
                        <>
                          <div
                            onClick={() => {
                              onMenuClick();
                              toggleSubNav(nav.path, nav?.children?.[0]?.path ?? nav.path);
                              handleToggleFullNav();
                            }}
                            key={index}
                            className={clsx(
                              'cursor-pointer w-full justify-center p-2 flex items-center rounded-md',
                              { ['hover:bg-primary-surface']: isActiveNav(nav.path) }
                            )}>
                            {nav.icon && (
                              <span className="mr-3">
                                <nav.icon className="w-6 h-6 " />
                              </span>
                            )}
                            <h2 className="w-full">{nav.title}</h2>
                            {isActiveNav(nav.path) && showSubNav ? (
                              <ChevronUpIcon
                                className="h-7 w-7 text-gray-400 cursor-pointer"
                                aria-hidden="true"
                                onClick={() => {}}
                              />
                            ) : (
                              <ChevronDownIcon
                                className="h-7 w-7 text-gray-400 cursor-pointer"
                                aria-hidden="true"
                                onClick={() => {}}
                              />
                            )}
                          </div>
                          {isActiveNav(nav.path) && showSubNav && (
                            <div className="pl-[2.3rem]">
                              {nav.children?.map((navSub: ISideBarSubItem, idx: number) => (
                                <NavLink
                                  onClick={() => {
                                    onMenuClick();
                                    handleToggleFullNav();
                                  }}
                                  key={idx}
                                  to={`${getDashboardBase()}${navSub.path}`}
                                  className={({ isActive }) =>
                                    clsx(
                                      'cursor-pointer w-full px-4 py-1 flex items-center rounded-md',
                                      {
                                        ['bg-brolly-yellow font-base']: isActiveSubNav(navSub.path)
                                      },
                                      { ['hover:bg-primary-surface']: !isActiveSubNav(navSub.path) }
                                    )
                                  }>
                                  {navSub.icon && (
                                    <span className="mr-3">
                                      <navSub.icon className="w-4 h-4" />
                                    </span>
                                  )}
                                  <h2>{navSub.title}</h2>
                                </NavLink>
                              ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <NavLink
                          onClick={() => {
                            onMenuClick();
                            handleToggleFullNav();
                          }}
                          key={index}
                          to={`${getDashboardBase()}${nav.path}`}
                          className={({ isActive }) =>
                            clsx(
                              'cursor-pointer w-full p-2 flex items-center  rounded-md',
                              {
                                ['font-semibold bg-brolly-yellow font-base']: isActiveNav(nav.path)
                              },
                              { ['hover:bg-primary-surface']: !isActiveNav(nav.path) }
                            )
                          }>
                          {nav.icon && (
                            <span className="mr-3 text-[#5c5c5c]">
                              <nav.icon className="w-6 h-6" />
                            </span>
                          )}
                          <h2>{nav.title}</h2>
                        </NavLink>
                      )}
                    </>
                  )}
                </>
              ))}
            </div>

            <div className="mt-[1rem]">
              <Button
                onClick={logout}
                label="Log out"
                icon={<LogoutIcon className="h-6 w-6  text-red-600" />}
                className="cursor-pointer px-4 py-2 flex items-center  gap-3text-red-600 border-none hover:bg-primary-surface md:hidden"
              />
              {(user.isInsurer() || user.isAgency()) && (
                <div className="flex mt-4 items-center">
                  <h3 className="text-[14px] mr-4">Powered by:</h3>{' '}
                  <img
                    className="max-w-none w-[2rem] sm:w-[3rem] md:w-[3rem] h-auto"
                    alt={'Brolly Logo'}
                    src={'/img/logo.png'}
                    loading="eager"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Animate>
    </>
  );
}

export default SidebarContent;
