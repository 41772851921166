import Page from '../../../components/PageWrapper';
import Button from '../../../components/form-control/Button';
import NewMessage from './NewMessage';
import useModal from '../../../hooks/modalHook';
import { useEffect, useMemo, useState } from 'react';
import {
  GetDashboardAgencyMetrics,
  deleteAdminAccount,
  disableAdminAccount,
  getASingleTeam
} from '../../../api/requests';
import Loader from '../../../components/Loader';
import InviteTeamMember from './InviteTeamMember';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RiInformationFill } from 'react-icons/ri';
import AssignMember from './assignMember';
import PopOnMe from '../../../components/elements/poponme/PopOnMe';
import useUserAccount from '../../../hooks/userAccountHook';
import { User } from '../../../models/User';
import { toast } from 'react-toastify';
import { SelectedDateRange } from '../../../components/dashboard/date-range-selector/datePicker';
import TabContent from '../../../components/tabs/TabContent';
import Commission from './Commission';
import Members from './Members';
import Performance from './Performance';
import clsx from 'clsx';
import AssignRelationshipManager from './AssignRelationshipMager';
import SetCommission from './SetCommission';
import useRoute from '../../../hooks/routesHook';
import SetTeamLead from './setTeamLead';
import EditTeam from './EditTeam';
import TeamName from './TeamName';
import SingleTeamDetailsModal from './SingleTeamDetailsModal';
import MyPopOver from '../../../components/elements/PopOver';
import Payouts from '../financials/payouts/Payouts';
import SalesSummaryOld from '../../../components/dashboard/sales-summary/SalesSummaryOld';
import GenerateAgencyPayoutModal from './GenerateAgencyPayoutModal';
import ActionConfirmation from '../../../components/elements/ActionConfirmation';
import BackButton from '../../../components/BackButton';
import { Team } from '../../../models/team/Team';
import AddTeamToWorkspace from './AddTeamToWorkspace';
import TabNav, { ITab } from '../../../components/tabs/TabNav';
import moment from 'moment';
import PolicyStatus from '../../../components/elements/PolicyStatus';
import { Policy } from '../../../models/policy/Policy';
import { POLICY_STATUS_OPTIONS } from '../../../resources/enums';
import {
  getPhoneNumberFromShortCodeFormat,
  getPolicyReviewStatus,
  getInstallmentTypeFromKey,
  numberWithCommas,
  getVehicleProtectionFromKey
} from '../../../utils/miscFunctions';
import GTable from '../../../components/elements/GTable';

export interface IDisableAdmin {
  id: string;
}

const tabs: ITab[] = [
  {
    id: 'members',
    title: 'Members'
  },
  {
    id: 'performance',
    title: 'Performance'
  },
  {
    id: 'policies',
    title: 'Policies'
  },
  {
    id: 'commissions',
    title: 'Commissions'
  },
  {
    id: 'payouts',
    title: 'Payouts'
  }
];

function SingleTeam(): JSX.Element {
  const [singleTeam, setSingleTeam] = useState<Team>();
  const [singleTeamMetrics, setSingleTeamMetrics] = useState<any>({
    leads: 0,
    policy: 0,
    policies: [],
    sales: 0,
    customers: 0,
    sales_mix: {
      amount: {
        comprehensive_90: 0,
        comprehensive_85: 0,
        comprehensive_100: 0,
        'third-party-ft': 0,
        'third-party': 0
      },
      count: {
        comprehensive_90: 0,
        comprehensive_85: 0,
        comprehensive_100: 0,
        'third-party-ft': 0,
        'third-party': 0
      }
    }
  });

  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>({
    from: '',
    to: ''
  });

  const [salesMix, setSalesMix] = useState({
    amount: {
      Comprehensive: 0,
      'Third Party': 0
    },
    count: {
      Comprehensive: 0,
      'Third Party': 0
    }
  });

  const [selectedAdmin, setSelectedAdmin] = useState<any>();
  const [loading, setLoading] = useState<boolean>();
  const { ModalComponent, updateModalStates, closeModal } = useModal();
  const [activeTab, setActiveTab] = useState('members');
  const [showTeamDetailsModal, setShowTeamDetailsModal] = useState<boolean>(false);
  const { getProfile } = useUserAccount();
  const user = new User(getProfile());
  const { getDashboardBase } = useRoute();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getSingleTeam(id);
    }
  }, [id]);

  useEffect(() => {
    if (id) getData(id);
  }, [selectedDateRange]);

  useEffect(() => {
    if (singleTeamMetrics?.sales_mix) {
      setSalesMix({
        ...salesMix,
        amount: {
          Comprehensive:
            singleTeamMetrics?.sales_mix.amount.comprehensive_100 +
            singleTeamMetrics?.sales_mix.amount.comprehensive_90 +
            singleTeamMetrics?.sales_mix.amount.comprehensive_85,
          'Third Party':
            singleTeamMetrics?.sales_mix.amount['third-party'] +
            singleTeamMetrics?.sales_mix.amount['third-party-ft']
        },
        count: {
          Comprehensive:
            singleTeamMetrics?.sales_mix.count.comprehensive_100 +
            singleTeamMetrics?.sales_mix.count.comprehensive_90 +
            singleTeamMetrics?.sales_mix.count.comprehensive_85,
          'Third Party':
            singleTeamMetrics?.sales_mix.count['third-party'] +
            singleTeamMetrics?.sales_mix.count['third-party-ft']
        }
      });
    }
  }, [singleTeamMetrics]);

  const onChange = (dateRange: { from: string; to: string }) => {
    setSelectedDateRange(dateRange);
  };

  const getData = (teamId: string) => {
    GetDashboardAgencyMetrics(teamId, selectedDateRange)
      .then((response: any) => {
        setSingleTeamMetrics({
          leads: response?.leads,
          policy: response?.policy,
          sales: response?.sales,
          customers: response?.customers,
          policies: response?.policies ?? []
        });
      })
      .catch((error: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteAdmin = (adminId: string) => {
    updateModalStates({
      show: true,
      showCloseButton: false,
      children: (
        <ActionConfirmation
          action="delete"
          resourceType="community"
          resourceName={''}
          onClose={closeModal}
          onConfirm={() => {
            setLoading(true);
            deleteAdminAccount(adminId)
              .then((response: any) => {
                closeModal();
                toast.success(response.message, {
                  autoClose: 3000
                });
              })
              .catch((error: Error) => {});
          }}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const disableAdmin = (id: string, status: boolean) => {
    setLoading(true);
    disableAdminAccount(id, status)
      .then((response: any) => {})
      .catch((error: any) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSingleTeam = (id: string) => {
    setLoading(true);
    getASingleTeam(id)
      .then((response: any) => {
        if (response) {
          setSingleTeam(new Team(response?.data));
        }
      })
      .catch((error: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const addNewMessage = () => {
    updateModalStates({
      show: true,
      size: '2xl',
      children: (
        <NewMessage
          onProceed={() => {
            closeModal();
          }}
          onClose={closeModal}
        />
      )
    });
  };

  const assignTeamLead = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <SetTeamLead
          teamID={singleTeam?.id ?? ''}
          onProceed={() => {
            closeModal();
            if (id) getSingleTeam(id);
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const editTeam = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <EditTeam
          team={singleTeam}
          onProceed={() => {
            if (id) getSingleTeam(id);
            closeModal();
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const assignRelationshipManager = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <AssignRelationshipManager
          teamID={singleTeam?.id ?? ''}
          onProceed={() => {
            closeModal();
            if (id) getSingleTeam(id);
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const assignMember = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <AssignMember
          teamID={singleTeam?.id ?? ''}
          onProceed={() => {
            closeModal();
            if (id) getSingleTeam(id);
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const inviteMember = () => {
    updateModalStates({
      show: true,
      size: 'lg',
      children: (
        <InviteTeamMember
          onProceed={() => {
            closeModal();
            if (id) getSingleTeam(id);
          }}
          teamID={singleTeam?.id}
          team={singleTeam}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const addTeamToSlackWorkspace = () => {
    updateModalStates({
      show: true,
      size: 'lg',
      children: (
        <AddTeamToWorkspace
          onProceed={() => {
            closeModal();
            if (id) getSingleTeam(id);
          }}
          team={singleTeam}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const SetCommissionPercentage = () => {
    updateModalStates({
      show: true,
      size: '2xl',
      children: (
        <SetCommission
          onProceed={() => {
            closeModal();
            if (id) getSingleTeam(id);
          }}
          onClose={closeModal}
          teamID={singleTeam?.id ?? ''}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const generateAnAgencyPayout = async () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <GenerateAgencyPayoutModal
          onProceed={() => {
            closeModal();
            if (id) getSingleTeam(id);
          }}
          onClose={closeModal}
          teamID={singleTeam?.id ?? ''}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const getSingleTeamActions = () => {
    const singleTeamActions = [];

    if (user.isSuperAdmin()) {
      singleTeamActions.push(
        {
          label: 'Set relationship manager',
          callback: () => {
            assignRelationshipManager();
          }
        },
        {
          label: 'Assign team admin',
          callback: () => {
            assignTeamLead();
          }
        },
        {
          label: 'Add to workspace',
          callback: () => {
            addTeamToSlackWorkspace();
          }
        },
        {
          label: 'Move member to team',
          callback: () => {
            assignMember();
          }
        },
        {
          label: 'Set commission rate',
          callback: () => {
            SetCommissionPercentage();
          }
        },
        {
          label: 'Edit team information',
          callback: () => {
            editTeam();
          }
        },

        {
          label: 'Invite new member',
          callback: () => {
            inviteMember();
          }
        },
        {
          label: 'Generate payout',
          callback: () => {
            generateAnAgencyPayout();
          }
        }
      );
    }

    return singleTeamActions;
  };

  const singleAdminActions = () => {
    const actions = [];
    if (user.isSuperAdmin()) {
      actions.push(
        {
          label: 'Disable Account',
          callback: () => {
            let adminId = selectedAdmin?.id;
            if (selectedAdmin && adminId) disableAdmin(adminId, !selectedAdmin?.activate);
          }
        },
        {
          label: 'Delete Account',
          callback: () => {
            let adminId = selectedAdmin?.id;
            if (selectedAdmin && adminId) deleteAdmin(adminId);
          }
        }
      );
    }

    return actions;
  };

  const policyolumns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Customer',
            accessor: 'customer',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? `${row.cell.value?.firstName} ${row.cell.value?.lastName}`
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Phone #',
            accessor: 'customer.phone',
            Cell: (row: any) => {
              return <span>{getPhoneNumberFromShortCodeFormat(row.cell.value) ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Policy ID',
            accessor: 'policyNumber',
            Cell: (row: any) => {
              return (
                <Link to={`${getDashboardBase()}/policies/${row.cell.value}`}>
                  <span className="hover:underline text-link-main">{row.cell.value ?? 'N/A'}</span>
                </Link>
              );
            }
          },
          {
            Header: 'Review Stage',
            accessor: 'reviewStage',
            Cell: (row: any) => {
              return (
                <>
                  {row.cell.value ? (
                    <PolicyStatus
                      status={getPolicyReviewStatus(row.cell.value)}
                      firstSuccessfulPayment={row?.original?.isInitialPaymentReceived()}
                    />
                  ) : (
                    <span>N/A</span>
                  )}
                </>
              );
            }
          },
          {
            Header: 'Protection Type',
            accessor: 'quote.protectionType',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? getVehicleProtectionFromKey(row.cell.value)?.title : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Installment Type',
            accessor: 'installmentType',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? <>{getInstallmentTypeFromKey(row.cell.value)}</> : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Outright Premium',
            accessor: 'quote.quotePlan.outRightPremium',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span>&#8373; {numberWithCommas(row.cell.value)}</span>{' '}
                </div>
              );
            }
          },
          {
            Header: 'Initial Deposit',
            accessor: 'quote.quotePlan.initialDeposit',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span> &#8373; {numberWithCommas(row.cell.value)}</span>
                </div>
              );
            }
          },
          {
            Header: 'Installments',
            accessor: 'quote.quotePlan.monthlyInstallment',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span>
                    {row.cell.value ? (
                      <>
                        <span>&#8373;</span> {numberWithCommas(row.cell.value)}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
              );
            }
          },
          {
            Header: 'Total Payment',
            accessor: 'quote.quotePlan.totalPremium',
            Cell: (row: any) => {
              return (
                <div className="min-w-[100px]">
                  <span>
                    {' '}
                    {row.cell.value ? (
                      <>
                        <span>&#8373;</span> {numberWithCommas(row.cell.value)}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </span>
                </div>
              );
            }
          },
          {
            Header: 'Inception Date',
            accessor: 'startsOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('DD/MM/YY h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Insurer',
            accessor: 'insurer',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value?.name : 'N/A'}</span>;
            }
          },
          {
            Header: 'Owner',
            accessor: 'owner',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value?.name : 'N/A'}</span>;
            }
          },
          {
            Header: 'Manager',
            accessor: 'manager',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? `${row.cell.value?.firstName} ${row.cell.value?.lastName}`
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Created On',
            accessor: 'createdOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('DD/MM/YY h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: (row: any) => {
              return (
                <div className="w-full flex  text-white">
                  <span
                    className={clsx(
                      'w-[30px] h-[30px] rounded-full flex items-center justify-center text-[0px] ',
                      {
                        ['bg-status-active']:
                          new Policy(row.row.original).getStatus()?.toLowerCase() ===
                          POLICY_STATUS_OPTIONS.ACTIVE
                      },
                      {
                        ['bg-status-ended']:
                          new Policy(row.row.original).getStatus()?.toLowerCase() ===
                          POLICY_STATUS_OPTIONS.INACTIVE
                      }
                    )}>
                    {new Policy(row.row.original).getStatus()}
                  </span>
                </div>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const policiesTable = () => {
    return (
      <div className="overflow-x-auto shadow-md mt-4">
        <GTable
          columns={policyolumns}
          data={singleTeamMetrics?.policies}
          tableType={'policy'}
          showSearch={false}
          // onPageChange={handlePageChange}
          // showPerPage={showPerPage}
          onRowClick={(policy: Policy) => {
            navigate(`${getDashboardBase()}/policies/${policy.ref}`, { replace: true });
          }}
          // showPagination
          // paginationData={policiesPaginationData}
          // onShowPerPageChange={(page: number) => setShowPerPage(page)}
          classes={'h-fit'}
          loading={loading}
        />
      </div>
    );
  };

  return (
    <Page title="Team">
      <div className="bg-white w-full p-5 min-h-full">
        <div className="flex flex-col md:flex-row justify-between items-center gap-5">
          <div className="flex items-center gap-4 w-full">
            <BackButton
              onClick={() => {
                if (user.isSuperAdmin() || user.isReviewer()) {
                  navigate(`${getDashboardBase()}/teams`);
                } else {
                  navigate(`${getDashboardBase()}/team`);
                }
              }}
            />

            <div className="w-full">
              {singleTeam && (
                <TeamName
                  name={singleTeam?.name ?? ''}
                  teamId={singleTeam.id}
                  onNameChange={(_name: string) => {
                    if (_name) {
                      const _team = singleTeam;
                      _team.name = _name;

                      setSingleTeam(_team);
                    }
                  }}
                />
              )}
            </div>
          </div>

          <div className="flex items-center gap-2">
            <div className="">
              {singleTeam && (
                <MyPopOver
                  trigger={<RiInformationFill className="h-6 w-6 cursor-pointer outline-0" />}
                  show={showTeamDetailsModal}>
                  <SingleTeamDetailsModal
                    team={singleTeam}
                    onClose={() => {
                      setShowTeamDetailsModal(false);
                    }}
                  />
                </MyPopOver>
              )}
            </div>

            <>
              {getSingleTeamActions()?.length > 0 && (
                <div className="cursor-pointer border border-primary-main rounded py-1 px-3">
                  <PopOnMe actions={getSingleTeamActions()}>
                    <span className="">Actions</span>
                  </PopOnMe>
                </div>
              )}
            </>
          </div>
        </div>

        <br />

        <>
          {loading ? (
            <div className="h-screen flex items-center justify-center">
              <Loader message="Fetching single team. Please wait..." brollyLoader />
            </div>
          ) : (
            <>
              {singleTeamMetrics ? (
                <>
                  <SalesSummaryOld
                    generalMetrics={singleTeamMetrics}
                    onDateRangeChange={onChange}
                  />

                  {/* Team Sales Breakdown*/}
                  {(user.isSuperAdmin() || user.isReviewer()) && (
                    <div className="mt-5">
                      <h2 className="font-medium text-xl text-center mb-2">Sales Breakdown</h2>

                      {singleTeamMetrics && (
                        <>
                          {/* Sales*/}
                          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5 md:my-5  p-5">
                            <div className="grid grid-cols-1 gap-2 border rounded border-primary-border ">
                              <div className="border-b border-primary-border bg-primary-surface p-2 w-full">
                                <h2 className="font-bold text-xl">Amount</h2>
                              </div>
                              <div className="grid grid-cols-1 gap-5  p-5 items-center justify-start">
                                <div className="flex items-center justify-between gap-5">
                                  <span>Comprehensive</span>
                                  <h2 className="font-bold text-2xl">
                                    {salesMix ? salesMix?.amount.Comprehensive : 'N/A'}
                                  </h2>
                                </div>
                                <div className="flex items-center justify-between gap-5">
                                  <span>Third Party</span>
                                  <h2 className="font-bold text-2xl">
                                    {salesMix ? salesMix?.amount['Third Party'] : 'N/A'}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="grid grid-cols-1 gap-2 border rounded border-primary-border ">
                              <div className="border-b border-primary-border bg-primary-surface p-2 w-full">
                                <h2 className="font-bold text-xl">Count</h2>
                              </div>
                              <div className="grid grid-cols-1 gap-5  p-5 items-center justify-start">
                                <div className="flex items-center justify-between gap-5">
                                  <span>Comprehensive</span>
                                  <h2 className="font-bold text-2xl">
                                    {salesMix ? salesMix?.count.Comprehensive : 'N/A'}
                                  </h2>
                                </div>
                                <div className="flex items-center justify-between gap-5">
                                  <span>Third Party</span>
                                  <h2 className="font-bold text-2xl">
                                    {salesMix ? salesMix?.count['Third Party'] : 'N/A'}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  <div className="mt-[56px]">
                    <TabNav
                      tabs={tabs}
                      activeTab={activeTab}
                      setActiveTab={(id: string) => {
                        setActiveTab(id);
                      }}
                    />
                  </div>

                  <div className="">
                    <TabContent id={'members'} activeTab={activeTab}>
                      <Members />
                    </TabContent>
                    <TabContent id={'policies'} activeTab={activeTab}>
                      {policiesTable()}
                    </TabContent>
                    <TabContent id={'performance'} activeTab={activeTab}>
                      <Performance />
                    </TabContent>
                    <TabContent id={'commissions'} activeTab={activeTab}>
                      <Commission />
                    </TabContent>
                    <TabContent id={'payouts'} activeTab={activeTab}>
                      <Payouts />
                    </TabContent>
                  </div>
                </>
              ) : (
                <div className="h-[500px] flex items-center justify-center">
                  <div className="grid grid-rows-2 ">
                    <h2 className="ml-6 mb-0"> No team member added.</h2>
                    <div className="">
                      <Button
                        label="Invite a team member"
                        className="py-1 px-4 rounded"
                        bgColor="primary"
                        onClick={inviteMember}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </div>
      <ModalComponent />
    </Page>
  );
}

export default SingleTeam;
export interface Payout {
  currency: string;
  reference: string;
  purpose: string;
  amount: number;
  channel: string;
  date: string;
  status: string;
  paidTo: string;
}
