import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';

interface IScrollToErrorProps {
  fieldName: string;
  fieldRef: any;
}

export function ScrollToFormikError(props: IScrollToErrorProps) {
  const formikBag = useFormikContext();

  useEffect(() => {
    if (formikBag) {
      const firstError = Object.keys(formikBag?.errors)[0];

      if (formikBag?.isSubmitting && firstError === props.fieldName) {
        props.fieldRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [formikBag?.isSubmitting, props.fieldName, formikBag?.errors]);
  return null;
}
