import React from "react";
import {Link} from "react-router-dom";


interface ICustomLink {
    to: string;
    label: string;
}

function CustomLink({
    to,
    label,
}: ICustomLink) {

    return (
        <Link to={to} className="no-underline">
            <span className="font-medium text-gray-700 hover:text-info-hover">{label}</span>
        </Link>
    )
}

export default CustomLink;
