import clsx from 'clsx';
import {
  ARCHIVED_POLICY_STATUS_COLOR,
  IN_APPROVAL_PAYMENT_STATUS_COLOR,
  IN_REVIEW_PAYMENT_STATUS_COLOR,
  NEW,
  SUCCESS_PAYMENT_STATUS_COLOR
} from '../../resources/constants';

interface IClaimStagesProps {
  status: string;
  classes?: string;
}

const ClaimStages = (props: IClaimStagesProps): JSX.Element => {
  return (
    <div className="w-full flex ">
      <span
        className={clsx(
          'w-[120px] h-[30px] rounded flex items-center justify-center text-[10px]  ',
          {
            [NEW]: props.status === 'NEW'
          },
          {
            [ARCHIVED_POLICY_STATUS_COLOR]: props.status === 'ACKNOWLEDGEMENT'
          },
          {
            [IN_REVIEW_PAYMENT_STATUS_COLOR]: props.status === 'ASSESSMENT'
          },
          {
            [IN_APPROVAL_PAYMENT_STATUS_COLOR]: props.status === 'SETTLEMENT'
          },
          {
            [SUCCESS_PAYMENT_STATUS_COLOR]: props.status == 'PAYMENT'
          },

          props.classes
        )}>
        {props.status}
      </span>
    </div>
  );
};

export default ClaimStages;
