import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Button from './form-control/Button';
import { superApprovePaymentEdit } from '../api/requests';
import { toast } from 'react-toastify';
import { BsPersonAdd } from 'react-icons/bs';
import { PiStampLight } from 'react-icons/pi';

interface ApproveModalProps {
  data: any;
  title: string;
  onSubmitted: () => void;
  onCancel: () => void;
}
const ApproveModal = ({ data, onSubmitted, onCancel, title }: ApproveModalProps) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleApprove = () => {
    setSubmitting(true);

    superApprovePaymentEdit(data?.id)
      .then((response: any) => {
        toast.success(response?.message ?? 'Payment object edit approved successfully.');
        onSubmitted();
      })
      .catch((error: any) => {
        toast.error(
          error?.description ?? 'Failed to submit policy for approval. Please try again later.'
        );
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-5">
            <div className="flex items-center justify-center bg-white h-[2.5rem] w-[2.5rem] rounded-full gap-3">
              <PiStampLight className="h-5 w-5" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">{title}</h2>
          </div>
          <XMarkIcon onClick={onCancel} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className="p-4 space-y-10">
        <div className="">
          <span className="">Evidence:</span>
          <img src={data?.evidenceUrl} alt="evidence image" className="h-full w-full rounded object-contain " />
        </div>

        <div className="flex items-center gap-2">
          <span className="">Description:</span>
          <p>{data?.narration}</p>
        </div>

        <div className="flex justify-end space-x-6">
          <Button
            label="Cancel"
            className="w-fit px-8 h-[45px] rounded-[5px] text-base border-[1px] border-primary-main"
            onClick={onCancel}
          />
          <Button
            label="Approve"
            className="bg-primary-main w-fit px-8 py-2 h-[45px] rounded-[5px] text-base"
            onClick={handleApprove}
            loading={submitting}
            disabled={submitting}
          />
        </div>
      </div>
    </div>
  );
};

export default ApproveModal;
