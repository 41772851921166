import { User } from "../models/User";
import useUserAccount from "./userAccountHook";

function useAuth() {
    const { getAccount, getProfile } = useUserAccount();
    const user = new User(getProfile());

    const signInWithEmail = async () => {
        
        return;
    }

    const signInWithGoogle = async () => {
        
        return;
    }

    const signUserOut = async () => {
        
        return;
    }

    const isInsurerOnboarding = (): boolean => {
        return getAccount().isAuthenticated && user.isInsurer() && !getProfile().team?.setupCompleted;
    }
    
    return {
        signInWithEmail,
        signInWithGoogle,
        signUserOut, 
        isInsurerOnboarding
    }
}

export default useAuth;