import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/form-control/Button';
import usePolicy from '../../../hooks/policyHook';
import { Policy } from '../../../models/policy/Policy';
import { ADMIN_POLICY_STATUS_ACTIONS } from '../../../resources/enums';

const PolicyApproval = ({
  policy,
  onApproved,
  onCancel
}: {
  policy: Policy | undefined;
  onApproved: () => void;
  onCancel: () => void;
}) => {
  const { changePolicyStatus } = usePolicy();

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const approvePolicy = () => {
    setSubmitting(true);

    changePolicyStatus(policy?.ref ?? '', ADMIN_POLICY_STATUS_ACTIONS.APPROVE)
      .then((response: any) => {
        toast.success(response.message);
        onApproved();
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <h2>Approve Policy</h2>
          <XMarkIcon onClick={onCancel} width={'11px'} height={'11px'} className="cursor-pointer" />
        </div>
      </div>

      <div className="p-4">
        <div className="py-10">
          <p>
            By approving this policy, you agree that all of the details provided by the
            policy&apos;s owner is accurate as of this day. Do you agree to approve this ?
          </p>
        </div>

        <div className="flex justify-end space-x-6">
          <Button
            label="Cancel"
            className="w-fit px-8 h-[45px] rounded-[5px] text-base border-[1px] border-primary-main"
            onClick={onCancel}
          />
          <Button
            label="Approve"
            className="bg-primary-main w-fit px-8 py-2 h-[45px] rounded-[5px] text-base"
            onClick={approvePolicy}
            loading={submitting}
            disabled={submitting}
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyApproval;
