/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PersistConfig, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/lib/persistReducer";
import userAccountReducer from "./slices/userAccountSlice"
import globalReducer from "./slices/globalSlice";
import liveChatReducer from "./slices/liveChatSlice";
import { PERSISTENT_STORAGE_KEY } from "../resources/constants";


const persistConfig: PersistConfig<any, any, any, any> = {
    key: PERSISTENT_STORAGE_KEY,
    version: 1,
    storage,
    throttle: 1000,
    serialize: true
}

const rootReducer = combineReducers({
  userAccount: userAccountReducer,
  globals: globalReducer,
  livechat: liveChatReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});


export default store;
export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;