import React from 'react';
import clsx from 'clsx';
import {
  ARCHIVED_POLICY_STATUS_COLOR,
  CANCELLED_PAYMENT_STATUS_COLOR,
  DECLINED_PAYMENT_STATUS_COLOR,
  DUE_LATER_POLICY_STATUS_COLOR,
  EXPIRED_POLICY_STATUS_COLOR,
  IN_APPROVAL_PAYMENT_STATUS_COLOR,
  IN_REVIEW_PAYMENT_STATUS_COLOR,
  NEW,
  ON_HOLD_PAYMENT_STATUS_COLOR,
  OVERDUE_STATUS_COLOR,
  SUCCESS_PAYMENT_STATUS_COLOR
} from '../../resources/constants';
import { capitalizeSentence } from '../../utils/miscFunctions';
import {
  FUEL_SALES_STATUS,
  LEAD_STATUS,
  POLICY_REVIEW_STATES,
  TASK_EVENT,
  TASK_STATUSES
} from '../../resources/enums';

interface IPolicyStatusProps {
  status: string | null | boolean;
  classes?: string;
  firstSuccessfulPayment?: boolean;
}

const PolicyStatus: React.FC<IPolicyStatusProps> = ({
  status,
  classes,
  firstSuccessfulPayment
}) => {
  const statusClass = clsx(
    'w-[120px] h-[30px] rounded flex items-center justify-center text-sm',
    {
      [SUCCESS_PAYMENT_STATUS_COLOR]:
        status === POLICY_REVIEW_STATES.APPROVED ||
        status === 'Approved' ||
        status === TASK_EVENT.POLICY ||
        status === TASK_STATUSES.DONE ||
        status === 'active' ||
        status === capitalizeSentence(LEAD_STATUS.PAID) ||
        status === TASK_EVENT.QUOTE ||
        status === FUEL_SALES_STATUS.COMPLETED ||
        status === true,
      [NEW]:
        status === 'New' ||
        status === POLICY_REVIEW_STATES.NEW ||
        status === TASK_EVENT.LEAD ||
        status === TASK_STATUSES.NEW,

      [IN_APPROVAL_PAYMENT_STATUS_COLOR]:
        status === 'In Approval' ||
        status === POLICY_REVIEW_STATES.IN_APPROVAL ||
        status === capitalizeSentence(LEAD_STATUS.WARM) ||
        status === TASK_EVENT.CUSTOMER ||
        status === TASK_STATUSES.INAPPROVAL ||
        status === FUEL_SALES_STATUS.PENDING_CLOSE ||
        status === 'PENDING_CLOSE',

      [OVERDUE_STATUS_COLOR]:
      status === TASK_STATUSES.OVERDUE,

      [IN_REVIEW_PAYMENT_STATUS_COLOR]:
        status === POLICY_REVIEW_STATES.IN_REVIEW ||
        status === 'In Review' ||
        status === capitalizeSentence(LEAD_STATUS.CLOSED) ||
        status === TASK_EVENT.COMMUNITY ||
        status === TASK_STATUSES.IN_PROGRESS ||
        (firstSuccessfulPayment && (status === 'Due Later' || status === TASK_EVENT.PAYMENT)),

      [ON_HOLD_PAYMENT_STATUS_COLOR]:
        status === POLICY_REVIEW_STATES.ON_HOLD || status === 'On Hold',

      [DECLINED_PAYMENT_STATUS_COLOR]:
        status === POLICY_REVIEW_STATES.DECLINED || status === 'Declined',

      [CANCELLED_PAYMENT_STATUS_COLOR]:
        status === POLICY_REVIEW_STATES.CANCELLED ||
        status === 'Cancelled' ||
        status === capitalizeSentence(LEAD_STATUS.COLD),

      [ARCHIVED_POLICY_STATUS_COLOR]:
        status === POLICY_REVIEW_STATES.ARCHIVED || status === 'Archived',

      [DUE_LATER_POLICY_STATUS_COLOR]:
        status === POLICY_REVIEW_STATES.DUE_LATER || status === 'Due Later',

      [EXPIRED_POLICY_STATUS_COLOR]:
        status === POLICY_REVIEW_STATES.EXPIRED || status === 'Expired' || status === false,
      ['bg-status-inactive']: status === 'inactive' || status === null
    },
    classes
  );

  const displayStatus = status !== null ? status : 'N/A';

  return (
    <div className="w-full flex">
      <div className={statusClass}>{displayStatus}</div>
    </div>
  );
};

export default PolicyStatus;
