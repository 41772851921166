import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/form-control/Button';

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex items-center justify-center  flex-col bg-white">
      <div className="mb-[41px]">
        <img src="/img/404 Page Illustration.svg" alt="" />
      </div>
      <div className="mb-7">
        <h1 className=" font-[Gilroy-Bold] text-[48px] leading-[56px]">Not found</h1>
      </div>
      <div className="">
        <span className="font-[Gilroy-Medium] text-[16px] leading-[19px]">
          We couldn&apos;t find what you were looking for.
        </span>
      </div>
      <div className="">
        <span className="font-[Gilroy-Medium] text-[16px] leading-[19px]">
          Try again.
        </span>
        <Button
          label='Log in'
          className='bg-primary-main px-6 rounded'
          onClick={() => {
            navigate("/", { replace: true })
          }}
        />
      </div>
    </div>
  );
}

export default NotFound;
