import { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ProfileAvatar } from '../resources/icons/avatar-icon.svg';
import { TSize } from '../resources/types';
import { sizeValues } from '../utilities';
import { upperCase } from 'lodash';
import useUserAccount from '../hooks/userAccountHook';
import { User } from '../models/User';
import { getNameInitials } from '../utils/miscFunctions';

interface IBaseProps {
  size?: TSize;
}

interface IAvatarProps extends IBaseProps {
  src?: string;
  nameInitials?: string;
  avatarColour?: string;
  initialsColour?: string;
}

function Avatar({
  size = 'md',
  src,
  nameInitials,
  avatarColour,
  initialsColour
}: IAvatarProps): JSX.Element {
  const [loaded, setLoaded] = useState(true);
  const sizes = sizeValues(size);
  const { getProfile, getInsurer } = useUserAccount();
  const user = new User(getProfile());
  const insurerProfile = getInsurer();
  const onErrorHandler = () => {
    setLoaded(false);
  };

  return (
    <>
      {src && loaded ? (
        <div className="text-center">
          <img
            style={{ objectFit: 'cover', backgroundPosition: 'center' }}
            src={src}
            alt={upperCase(nameInitials)}
            className={clsx('rounded-full text-center border-2', sizes.height, sizes.width)}
            onError={onErrorHandler}
          />
        </div>
      ) : (
        <div
          className={clsx('text-center rounded-full text-gray-700 bg-green-300')}
          style={{
            backgroundColor: user.isInsurer() && avatarColour ? insurerProfile.themeColour : ''
          }}>
          {nameInitials ? (
            <div className={clsx(' flex items-center justify-center', sizes.height, sizes.width)}>
              <h3 className={`text-xs font-bold `} style={{ color: `${initialsColour}` }}>
                {getNameInitials(nameInitials)}
              </h3>
            </div>
          ) : (
            <ProfileAvatar className={clsx(sizes.height, sizes.width, 'text-gray-700')} />
          )}
        </div>
      )}
    </>
  );
}

export default Avatar;
