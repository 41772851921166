import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { getTeamProfile, deleteAgent, getSingleTeamPerformance,  } from '../../../api/requests';
import Loader from '../../../components/Loader';
import GTable from '../../../components/elements/GTable';
import { ITableRowAction } from '../../../components/elements/GTable/GTable';
import useUserAccount from '../../../hooks/userAccountHook';
import { User } from '../../../models/User';
import { useParams } from 'react-router-dom';

const Performance = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [teamPerformance, setTeamPerformance] = useState<any>([]);
  const { getProfile } = useUserAccount();
  const user = new User(getProfile());

  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    if (id) getTeamPerformance(id);
  }, []);

  const getTeamPerformance = (teamId: string) => {
    getSingleTeamPerformance(teamId)
      .then((response: any) => {
        setTeamPerformance(response);
      })
      .catch((error: any) => {})
      .then(() => {
        setLoading(false);
      });
  };

  const getTableRowActions = (data: any): ITableRowAction[] => {
    const actions = [
      {
        label: 'Remove member',
        callback: () => {
          const agentId = data?.id;
          deleteAgent(agentId)
            .then((response: any) => {
              setLoading(true);
              toast.success('Agent account deleted');
              getTeamProfile();
            })
            .catch((error: any) => {
              toast.error(`${error.description}`);
            })
            .finally(() => {
              setLoading(false);
            });
        },
        data: data
      }
    ];
    {
      actions.push();
    }

    return actions;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
            Cell: (row: any) => {
              return <span>{row.cell.value}</span>;
            }
          },
          {
            Header: 'No. of Policies',
            accessor: 'numberOfPolicies',
            Cell: (row: any) => {
              return <span>{row.cell.value}</span>;
            }
          },
          {
            Header: 'No. of Leads',
            accessor: 'numberOfLeads',
            Cell: (row: any) => {
              return <span>{row.cell.value}</span>;
            }
          },
          {
            Header: 'No. of Customers ',
            accessor: 'numberOfCustomers',
            Cell: (row: any) => {
              return <span>{row.cell.value}</span>;
            }
          },
          // {
          //   id: 'Action',
          //   Cell: (row: any) => {
          //     return (
          //       <div className="cursor-pointer flex justify-center">
          //         <PopOnMe actions={getTableRowActions(row.row.original)}>
          //           <DotsVerticalIcon className="h-4 w-4" />
          //         </PopOnMe>
          //       </div>
          //     );
          //   }
          // }
        ]
      }
    ],
    []
  );

  // border border-[#FDEDC4]
  return (
    <div>
      {(user.isSuperAdmin() || user.isAdmin() || user.isReviewer()) && (
        <div className="rounded-md mb-1 justify-between px-2 pb-3 pt-7 align-items-center">
          <div className="overflow-x-auto shadow-md  ">
            {loading ? (
              <div className="h-screen flex items-center justify-center bg-white">
                <Loader message="Fetching team performance. Please wait..." brollyLoader/>
              </div>
            ) : (
              <>
                {teamPerformance? (
                  <>
                    <div className="overflow-x-auto shadow-md">
                      <GTable
                        selectionChanged={(selection: any) => {
                          // setSlcMember(selection);
                        }}
                        columns={columns}
                        data={teamPerformance && teamPerformance}
                        tableType={'Team Performance'}
                        showSearch={false}
                        classes='h-fit'
                      />
                    </div>
                  </>
                ) : (
                  <div className=" h-40 text-center flex items-center justify-center font-bold">
                    <h2>No performance data found.</h2>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Performance;
