import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { editTeam, getSlackWorkspaces } from '../../../api/requests';
import { toast } from 'react-toastify';
import SelectDropdown from '../../../components/form-control/SelectDropdown';
import { MdGroups } from 'react-icons/md';
import { CheckBoxInput } from '../../../components/form-control/CheckBoxInput';

interface AddTeamToWorkspaceProps {
    onProceed: () => void;
    onClose: () => void;
    team: any;
}

const AddTeamToWorkspace = ({ onProceed, onClose, team }: AddTeamToWorkspaceProps) => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [workspaces, setWorkspaces] = useState<any[]>();

    useEffect(() => {
        getSlackWorkspaces()
            .then((response: any) => {
                setWorkspaces(response?.data);
            })
            .catch((error: any) => {
                toast.error(error.message);
            })

    }, [])


    return (
        <div className="w-full">
            <div className="bg-primary-main flex justify-between">
                <div className="p-[1rem] flex justify-between w-full items-center">
                    <div className="flex items-center justify-center gap-5">
                        <div className="flex items-center justify-center bg-white h-[2.5rem] w-[2.5rem] rounded-full gap-3">
                            <MdGroups className="h-5 w-5" />
                        </div>
                        <h2 className="font-[gilroy-bold] text-lg">Add to workspace</h2>
                    </div>
                    <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
                </div>
            </div>

            <div className="p-5">
                <Formik
                    initialValues={{
                        address: team?.address,
                        corporatePhone: team?.corporatePhone,
                        email: team?.email,
                        mobilePhone: team?.mobilePhone,
                        name: team?.name,
                        type: team?.type,
                        slackWorkspaceId: team?.slackWorkspace,
                        addMembers: team?.addMembers ?? true,
                    }}
                    validateOnChange={false}
                    validateOnBlur
                    validationSchema={Yup.object({
                        slackWorkspaceId: Yup.string().trim().required('Select a workspace')
                    })}
                    onSubmit={(values) => {
                        const teamID = team.id
                        setSubmitting(true);

                        const payload = {
                            ...values,
                            slackWorkspaceId: values.slackWorkspaceId,
                            addMembers: values.addMembers,
                        };

                        // console.log(payload)
                        editTeam(teamID, payload)
                            .then((response: any) => {
                                toast.success(response?.message ?? 'Team added to slack workspace successfully', {
                                    autoClose: 5000
                                });
                                onProceed();
                            })
                            .catch((error: any) => {
                                toast.error(`${error.description}`);
                            })
                            .finally(() => setSubmitting(false));
                    }}>
                    {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <div className="space-y-5">
                                <SelectDropdown
                                    name="slackWorkspaceId"
                                    label="Slack Workspace"
                                    placeholder="Select workspace "
                                    listSelectedValue={values.slackWorkspaceId}
                                    wrapperClasses=""
                                    required
                                    onChange={(value: any) => {
                                        setFieldValue('slackWorkspaceId', value);
                                    }}
                                    list={workspaces?.map((workspace: any) => ({
                                        name: workspace?.name,
                                        value: workspace?.id
                                    })
                                    )}
                                />

                                {values.slackWorkspaceId &&
                                    <CheckBoxInput
                                        label={'Add members to workspace'}
                                        name={'addMembers'}
                                        checked={values?.addMembers}
                                        onChange={(value: boolean) => {
                                            setFieldValue("addMembers", value)
                                        }}
                                    />
                                }

                            </div>

                            <div className="flex justify-end">
                                <Button
                                    type="submit"
                                    label="Submit"
                                    bgColor="primary"
                                    className="w-fit px-6 text-[14px]"
                                    loading={submitting}
                                    disabled={submitting}
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AddTeamToWorkspace;
