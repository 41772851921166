import React, { useCallback, useMemo, useState } from 'react';
import { Policy } from '../../../../models/policy/Policy';
import moment from 'moment';
import {
  PAYMENT_STATUS_STATES,
  POLICY_DOCUMENT_TYPES,
  POLICY_REVIEW_STATES,
  REDACT_INFO_TYPES
} from '../../../../resources/enums';
import { toast } from 'react-toastify';
import { IPolicyDocument, ITask } from '../../../../resources/interfaces';
import {
  capitalizeSentence,
  getClientWebsiteUrl,
  getFormattedLeadSource,
  getFormattedPaymentChannel,
  getInstallmentPeriodFromKey,
  getInstallmentTypeFromKey,
  getNumOfInstallmentsFromInstallmentPeriod,
  getPaystackConfig,
  getPhoneNumberFromShortCodeFormat,
  getVehicleProtectionFromKey,
  numberWithCommas,
  removeUnderscoresAndDashes,
  sentenceCase
} from '../../../../utils/miscFunctions';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as CheckIcon } from './imgs/check-success.svg';
import { ReactComponent as CancelPolicy } from './imgs/cancel-policy.svg';
import { ReactComponent as OnHoldIcon } from './imgs/on-hold.svg';
import { ReactComponent as PdfIcon } from './imgs/pdf.svg';
import PolicyApproval from '../PolicyApproval';
import {
  createClaimFromPolicy,
  downloadPolicyDetails,
  downloadPolicyDocument,
  getPaymentLink,
  getPolicy,
  getPolicyClaims,
  makeBulkPaymentForPolicy,
  makeSelectedPaymentsForAPolicy,
  requestPolicyVehiclePhotos,
  verifyCustomerID,
  verifyVehicle
} from '../../../../api/requests';
import useModal from '../../../../hooks/modalHook';
import ToastContent from '../../../../components/ToastContent';
import useUserAccount from '../../../../hooks/userAccountHook';
import SubmitForApproval from '../SubmitForApproval';
import MessagePrompt from '../../../../components/elements/MessagePrompt';
import { MdChangeCircle, MdDateRange } from 'react-icons/md';
import clsx from 'clsx';
import ReviewPolicy from '../ReviewPolicy';
import { EllipsisVerticalIcon, LinkIcon, XMarkIcon } from '@heroicons/react/24/solid';
import PopOnMe from '../../../../components/elements/poponme';
import PaymentLinkModal from '../paymentLinkModal';
import useRoute from '../../../../hooks/routesHook';
import GTable, { ITableAction } from '../../../../components/elements/GTable/GTable';
import { ChevronDownIcon, EyeIcon } from '@heroicons/react/24/outline';
import fileDownload from 'js-file-download';
import { IVehicleImage } from '../../../../models/vehicle/Vehicle';
import ImageViewer from 'react-simple-image-viewer';
import ToolTip from '../../../../components/ToolTip';
import PolicyUpdates from '../PolicyUpdates';
import AssignPolicyManager from '../AssignPolicyManger';
import { IPolicyUpdate } from '../../../../models/policy/IPolicy';
import CustomerPhoneVerificationModal from '../PhoneVerificationModal';
import CustomerEmailVerificationModal from '../CustomerEmailVerificationModal';
import IconStatus from '../../../../components/elements/IconStatus';
import RefereePhoneVerificationModal from '../RefereePhoneVerification';
import PaymentAgreementIcon from './imgs/contract (1).png';
import ContractSummary from './imgs/contract.png';
import PolicyStickerIcon from './imgs/shield.png';
import RenewalNoticeIcon from './imgs/calendar.png';
import DriverLicenseIcon from './imgs/id-card.png';
import InsuranceCertificateIcon from './imgs/file-protection.png';
import RoadWorthyIcon from './imgs/car-insurance.png';
import IdCard from './imgs/OWNER_ID.png';
import OWNER_PHOTO from './imgs/OWNER_PHOTO.png';
import RedactInfo from '../../../../components/elements/RedactInfo';
import BackButton from '../../../../components/BackButton';
import PaymentEditModal from '../../payments/PaymentEditModal';
import usePayment from '../../../../hooks/paymentHook';
import { PaystackProps } from 'react-paystack/dist/types';
import { HiDownload } from 'react-icons/hi';
import Loader from '../../../../components/Loader';
import NotFoundComponent from '../../../../components/NotFoundComponent';
import useResource from '../../../../hooks/resourcesHook';
import QuoteIframe from '../../../../components/dashboard/quoteWidget/QuoteWidget';
import { GrDocumentTime } from 'react-icons/gr';
import ActivityStepper from '../../../../components/ActivityStepper';
import PolicyStatus from '../../../../components/elements/PolicyStatus';
import ActionConfirmation from '../../../../components/elements/ActionConfirmation';
import { IoDocumentText } from 'react-icons/io5';
import PaymentStatus from '../../../../components/PaymentStatus';
import ApproveModal from '../../../../components/ApproveModal';
import TabContent from '../../../../components/tabs/TabContent';
import TabNavItem from '../../../../components/tabs/TabNavItem';
import CreateTask from '../../leads/CreateTask';
import { SUCCESS_PAYMENT_STATUS_COLOR } from '../../../../resources/constants';
import MarkPolicyAsDone from '../MarkPolicyAsDone';
import { Claim } from '../../../../models/claim/Claim';
import ClaimStages from '../../../../components/elements/ClaimStages';
import { TwoStateVerifiedCheck } from '../../../../components/statuses';
import useLayoutHook from '../../../../hooks/layoutHook';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import SendAgreementFormModal from '../SendAgreementFormModal';
import ApplyDiscount from '../ApplyDiscount';
import MyPopOver from '../../../../components/elements/PopOver';
import Tooltip from '../../../../components/ToolTip';
import DiscountInfoModal from '../DiscountInfoModal';
import { FaEye } from 'react-icons/fa';
import CopyLink from '../../../../components/CopyLink';

const policyDetailsContentStyles =
  'flex flex-col gap-2 border border-gray-300 p-2 rounded-lg w-full';
interface IPolicyDetail {
  title: string;
  icon: string | JSX.Element;
  children: JSX.Element;
  id: POLICY_DETAIL_SECTIONS;
}

interface IPolicyDetailsProps {
  previousPath: string;
}

enum POLICY_DETAIL_SECTIONS {
  ABOUT_YOU = 'about_you',
  ABOUT_VEHICLE = 'about_vehicle',
  ABOUT_POLICY = 'about_policy',
  OTHER_INFORMATION = 'other_information',
  REFEREE_DETAILS = 'referee_details',
  COMMUNITY_DETAILS = 'community_details'
}

const PolicyDetails = (props: IPolicyDetailsProps): JSX.Element => {
  const [policy, setPolicy] = React.useState<Policy>();
  const [claims, setClaims] = React.useState<Claim[]>();
  const [policyPayments, setPolicyPayments] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [aboutYouCheck, setAboutYouCheck] = useState<boolean>(false);
  const [aboutVehicleCheck, setAboutVehicleCheck] = useState<boolean>(false);
  const [aboutPolicyCheck, setAboutPolicyCheck] = useState<boolean>(false);
  const [otherInfoCheck, setOtherInfoCheck] = useState<boolean>(false);
  const [refereeCheck, setRefereeDetailsCheck] = useState<boolean>(false);
  const [communityCheck, setCommunityCheck] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [showUpdates, setShowUpdates] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { ModalComponent, updateModalStates, closeModal } = useModal();
  const [selectedPayments, setSelectedPayments] = useState<any>([]);
  const [selectedPaymentIndexes, setSelectedPaymentIndexes] = useState<any>([]);
  const [activeTab, setActiveTab] = useState('payments');
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useUserAccount();
  const { getDashboardBase } = useRoute();
  const { screenWidth } = useLayoutHook();
  const { shareContennt } = useResource();
  const paystackConfig: PaystackProps = getPaystackConfig(policy);
  const policyId = params.policyId;
  const queryClient = useQueryClient();
  const {
    choosePaymentService,
    PaymentModalComponent,
    choosePaymentOption,
    InitiateHubtelDirectCharge,
    showHubtelPreapprovalModal,
    sendPaymentNotification,
    showGeneratePaymentObjectsModal,
    paymentCompleted
  } = usePayment({
    paystackConfig: paystackConfig,
    policy: policy
  });

  const fetchPolicy = async () => {
    try {
      if (policyId) {
        const response = await getPolicy(policyId);
        setPolicy(new Policy(response.data));
        setPolicyPayments(policy?.payments);
      }
    } catch (error) {
      toast.error('Failed to fetch policy details. Please try again later.');
    }
  };

  const fetchPolicyClaims = async () => {
    try {
      if (policyId) {
        const response: any = await getPolicyClaims(policyId);
        setClaims(response);
        setPolicyPayments(policy?.payments);
      }
    } catch (error) {
      toast.error('Failed to fetch policy details. Please try again later.');
    }
  };

  const { isLoading } = useQuery({
    queryKey: ['fetchPolicy', params, refresh],
    queryFn: fetchPolicy,
    enabled: !!policyId
  });

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
  };

  const { isLoading: claimsLoading } = useQuery({
    queryKey: ['fetchPolicyClaims', activeTab == 'claims'],
    queryFn: fetchPolicyClaims,
    enabled: !!policyId
  });

  const assignPolicyManager = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <AssignPolicyManager
          policy={policy}
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showPaymentLinkModal = (paymentLink: string) => {
    updateModalStates({
      show: true,
      size: 'lg',
      children: (
        <PaymentLinkModal
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
          onClose={closeModal}
          link={paymentLink}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const exportPolicyDetails = async () => {
    try {
      const response: any = await downloadPolicyDetails(policy?.ref);
      window.open(response?.data?.docURL, '_blank');
      closeModal();
      toast.success(response.message ?? 'Successful request to download policy data.', {
        autoClose: 3000
      });
    } catch (error: any) {
      closeModal();
      toast.error(error.description ?? 'Failed to download policy data.', {
        autoClose: 3000
      });
    }
  };

  const showMarkPolicyAsCompleteModal = () => {
    updateModalStates({
      show: true,
      size: 'lg',
      children: (
        <MarkPolicyAsDone
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
          onClose={closeModal}
          policy={policy}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showDownloadPolicyDetailsModal = () => {
    updateModalStates({
      show: true,
      size: 'lg',
      children: (
        <ActionConfirmation
          action="download"
          resourceType={'policy'}
          resourceName={policy?.ref}
          onClose={closeModal}
          onConfirm={exportPolicyDetails}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showPaymentApprovalModal = (payment: any) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <ApproveModal
          onSubmitted={() => {
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
            closeModal();
          }}
          onCancel={closeModal}
          data={payment}
          title={'Approve Payment Edit'}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const getActions = (payment: any): ITableAction[] => {
    const actions = [];

    if (payment && payment?.paymentStatus !== PAYMENT_STATUS_STATES.SUCCESS) {
      actions.push(
        {
          label: 'Get paystack payment link',
          callback: () => {
            if (payment) generatePaymentLink(payment.id);
          }
        },
        {
          label: 'Send payment notification',
          callback: () => {
            if (payment) sendPaymentNotification(payment);
          }
        },
        {
          label: 'Initiate direct charge',
          callback: () => {
            if (payment) {
              InitiateHubtelDirectCharge(payment);
            }
          }
        }
      );

      if (user.isReviewer()) {
        actions.push({
          label: 'Edit',
          callback: () => {
            if (payment) showEditPaymentModal(payment);
          }
        });
      }

      if (payment?.paymentStatus == PAYMENT_STATUS_STATES.APPROVAL && user.isSuperAdmin())
        actions.push({
          label: 'Approve',
          callback: () => {
            if (payment) showPaymentApprovalModal(payment);
          }
        });
    }

    return actions;
  };

  const generatePaymentLink = (paymentId: string) => {
    getPaymentLink(paymentId)
      .then((response) => {
        setLoading(true);
        const paymentLink = response?.data?.data;
        showPaymentLinkModal(paymentLink);
      })
      .catch(() => {
        toast.error('Failed to generate payment link. Please try again later.');
      })
      .finally(() => setLoading(false));
  };

  const completeAllPayments = () => {
    policyId &&
      makeBulkPaymentForPolicy(policyId)
        .then((response) => {
          const paymentLink = response?.data?.data;
          showPaymentLinkModal(paymentLink);
        })
        .catch(() => {
          toast.error('Failed to generate payment link. Please try again later.');
        })
        .finally(() => setLoading(false));
  };

  const makePaymentForSelectedIds = async () => {
    try {
      if (selectedPayments) {
        const payload = {
          ...selectedPayments,
          paymentIds: selectedPayments
        };
        const response = await makeSelectedPaymentsForAPolicy(payload);
        const paymentLink = response?.data?.data;
        showPaymentLinkModal(paymentLink);
      }
    } catch (error: any) {
      toast.error(error.description ?? 'Failed to generate payment link. Please try again later.');
    }
  };

  const generateWebsitePaymentLink = () => {
    const url = `${getClientWebsiteUrl()}/policies/${policy?.ref}/pay`;
    const shareData = { url: url };

    shareContennt(shareData);
  };

  const showEditPaymentModal = (payment: any) => {
    updateModalStates({
      show: true,
      size: 'xl',
      children: (
        <PaymentEditModal
          onProceed={() => {
            closeModal();
            if (policyId) {
              queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
            }
          }}
          payment={payment}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showCustomerEmailVerificationModal = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <CustomerEmailVerificationModal
          policy={policy}
          close={closeModal}
          onProceed={() => {
            closeModal();
            const _policy = policy;

            if (_policy) {
              _policy.applicant.emailVerified = true;
              setPolicy(_policy);
            }
          }}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showRefereePhoneVerificationModal = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <RefereePhoneVerificationModal
          policy={policy}
          close={closeModal}
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const showCustomerPhoneVerificationModal = (phone: string) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <CustomerPhoneVerificationModal
          policy={policy}
          close={closeModal}
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
          phoneNumber={phone}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const manuallyVerifyVIN = async () => {
    const vehicleID = Number(policy?.vehicle?.id);
    try {
      if (vehicleID) {
        const response = await verifyVehicle(vehicleID);
        if (response) {
          window.location.reload();
          toast.success(response.message, {
            autoClose: 5000
          });
        }
      }
    } catch (error: any) {
      toast.error(error.description, {
        autoClose: 5000
      });
    }
  };

  const manuallyVerifyCustomerID = async () => {
    try {
      if (policyId) {
        const response = await verifyCustomerID(policyId);
        if (response) {
          window.location.reload();
          toast.success(response.message, {
            autoClose: 5000
          });
        }
      }
    } catch (error: any) {
      toast.error(error.description, {
        autoClose: 5000
      });
    }
  };

  const getAboutYouDetails = (): JSX.Element => {
    return (
      <div className="">
        <span className="font-semibold">{policy?.isApproved() ? 'Policy Holder' : 'Proposer'}</span>
        <div
          className={clsx(
            'grid border border-gray-300 p-2 rounded-lg h-full',
            user.isInsurer() ? 'grid-cols-1' : ' md:grid-cols-2 grid-cols-1 gap-5'
          )}>
          <div className="">
            <div className="flex flex-row justify-between py-1 ">
              <div className="text-gray-600">Name:</div>
              <Link
                to={`${getDashboardBase()}/customers/${policy?.applicant?.customerId}`}
                className="flex items-center gap-1 hover:underline underline-offset-2 text-blue-600">
                <span className="truncate font-medium">{policy?.applicant.fullName ?? ''}</span>
                <LinkIcon className="size-4" />
              </Link>
            </div>

            <PolicyDetailsItem
              name="Phone"
              value={
                policy?.applicant.phoneNumber ? (
                  <RedactInfo
                    infoType={REDACT_INFO_TYPES.PHONE}
                    info={getPhoneNumberFromShortCodeFormat(policy?.applicant.phoneNumber ?? '')}
                    dataId={policy?.ref ?? ''}
                    dataType="policy"
                    clickable={!user.isInsurer() && true}
                  />
                ) : (
                  getPhoneNumberFromShortCodeFormat(policy?.applicant.phoneNumber ?? '')
                )
              }>
              {policy?.applicant?.phoneVerified ? (
                <div className="">
                  <CheckIcon className=" h-4 w-4 rounded-full" />
                </div>
              ) : (
                <ToolTip message={`Click to verify`}>
                  <div
                    className=""
                    onClick={() =>
                      showCustomerPhoneVerificationModal(policy?.applicant?.phoneNumber ?? '')
                    }>
                    <XMarkIcon className="text-white p-[2px] h-4 w-4 rounded-full bg-red-700 cursor-pointer" />
                  </div>
                </ToolTip>
              )}
            </PolicyDetailsItem>

            <PolicyDetailsItem
              name="Other Phone"
              value={
                policy?.applicant.otherPhone ? (
                  <RedactInfo
                    infoType={REDACT_INFO_TYPES.PHONE}
                    info={getPhoneNumberFromShortCodeFormat(policy?.applicant.otherPhone ?? '')}
                    dataId={policy?.ref ?? ''}
                    dataType="policy"
                    clickable={!user.isInsurer() && true}
                  />
                ) : (
                  getPhoneNumberFromShortCodeFormat(policy?.applicant.otherPhone ?? '')
                )
              }>
              {policy?.applicant?.otherPhoneVerified ? (
                <div className="">
                  <CheckIcon className=" h-4 w-4 rounded-full" />
                </div>
              ) : (
                <ToolTip message={`Click to verify`}>
                  <div
                    className=""
                    onClick={() =>
                      policy?.applicant?.otherPhone &&
                      showCustomerPhoneVerificationModal(policy?.applicant?.otherPhone)
                    }>
                    <XMarkIcon className="text-white p-[2px] h-4 w-4 rounded-full bg-red-700 cursor-pointer" />
                  </div>
                </ToolTip>
              )}
            </PolicyDetailsItem>

            <PolicyDetailsItem name="Email" value={policy?.applicant.email ?? ''}>
              {policy?.applicant?.emailVerified ? (
                <div className="">
                  <CheckIcon className=" h-4 w-4 rounded-full" />
                </div>
              ) : (
                <ToolTip message={`Click to verify`}>
                  <div className="" onClick={showCustomerEmailVerificationModal}>
                    <XMarkIcon className="text-white p-[2px] h-4 w-4 rounded-full bg-red-700 cursor-pointer" />
                  </div>
                </ToolTip>
              )}
            </PolicyDetailsItem>

            <PolicyDetailsItem name="Address">
              <ToolTip
                message={
                  <RedactInfo
                    info={policy?.applicant?.address ?? ''}
                    totalToRedact={10}
                    dataId={policy?.ref ?? ''}
                    dataType="policy"
                  />
                }>
                <div className="font-medium truncate w-full max-w-32">
                  {policy?.applicant?.address && policy?.active ? (
                    <RedactInfo
                      info={policy?.applicant?.address}
                      totalToRedact={10}
                      dataId={policy?.ref ?? ''}
                      dataType="policy"
                    />
                  ) : (
                    policy?.applicant?.address ?? 'N/A'
                  )}
                </div>
              </ToolTip>
            </PolicyDetailsItem>

            <PolicyDetailsItem
              name="Date of Birth"
              value={
                policy?.applicant?.dateOfBirth ? (
                  <RedactInfo
                    info={moment(policy?.applicant.dateOfBirth).format('DD-MM-YYYY')}
                    totalToRedact={6}
                    dataId={policy?.ref ?? ''}
                    dataType="policy"
                    clickable={!user.isInsurer() && true}
                  />
                ) : (
                  moment(policy?.applicant.dateOfBirth).format('DD-MM-YYYY') ?? 'N/A'
                )
              }
            />

            {!user.isInsurer() && policy?.isRideSharing() && (
              <>
                <PolicyDetailsItem
                  name="Do you have any other liabilities?"
                  value={policy?.applicant.liability ? 'Yes' : 'No'}
                />
                {policy?.applicant?.liability && (
                  <>
                    <PolicyDetailsItem
                      name="How much are you owing?"
                      value={policy?.applicant.liabilityDebt ?? ''}
                    />
                  </>
                )}
              </>
            )}
          </div>

          <div className="">
            <PolicyDetailsItem name="ID Type" value={policy?.applicant.idType ?? ''} />

            <PolicyDetailsItem
              name="ID Number"
              value={
                policy?.applicant?.idNumber ? (
                  <RedactInfo
                    info={policy?.applicant.idNumber ?? ''}
                    totalToRedact={8}
                    dataId={policy?.ref ?? ''}
                    dataType="policy"
                    clickable={!user.isInsurer() && true}
                  />
                ) : (
                  policy?.applicant.idNumber ?? 'N/A'
                )
              }>
              <ToolTip message={`Click to verify`}>
                <IconStatus
                  cursorPointer
                  onclick={manuallyVerifyCustomerID}
                  status={policy?.applicant?.idVerified}
                />
              </ToolTip>
            </PolicyDetailsItem>

            {!user.isInsurer() && (
              <PolicyDetailsItem
                name="ID Expiry Date"
                value={moment(policy?.applicant.idExpiryDate ?? '').format('DD-MM-YYYY')}
              />
            )}

            {!user.isInsurer() && (
              <>
                <PolicyDetailsItem name="Occupation" value={policy?.applicant.occupation ?? ''} />

                <PolicyDetailsItem
                  name="Employer Name"
                  value={policy?.applicant?.employerName ?? ''}
                />
                <div className="flex flex-row justify-between ">
                  <span className="text-gray-600">Employer Address:</span>
                  <span className="truncate font-medium max-w-52">
                    {policy?.applicant.employerAddress ?? ''}
                  </span>
                </div>

                {/* <PolicyDetailsItem
                  name="Employer Address"
                  value={policy?.applicant?.employerAddress ?? 'N/A'}
                /> */}

                <PolicyDetailsItem
                  name="Employer Phone Number"
                  value={getPhoneNumberFromShortCodeFormat(
                    policy?.applicant?.employerPhoneNumber ?? ''
                  )}
                />

                <PolicyDetailsItem
                  name="Monthly Income Band"
                  value={policy?.applicant?.monthlyIncome ?? ''}
                />
              </>
            )}

            {!user.isInsurer() && policy?.isRideSharing() && policy?.applicant?.liability && (
              <>
                <PolicyDetailsItem
                  name="Do you have any other liabilities?"
                  value={policy?.applicant.liabilityDetails ?? ''}
                />
                <PolicyDetailsItem
                  name="How are you paying for the extra liabities?"
                  value={policy?.applicant.liabilityModeOfPayment ?? ''}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const getQuotePlan = (): JSX.Element => {
    return (
      <div className="">
        <span className="font-semibold">Quote Plan</span>
        <div className={'border border-gray-300 p-2 rounded-lg h-full'}>
          <div className="flex flex-row justify-between py-1 ">
            <div className="text-gray-600">Reference:</div>
            <Link
              to={`${getDashboardBase()}/quotes/${policy?.quote?.ref}`}
              className="flex items-center gap-1 hover:underline underline-offset-2 text-blue-600">
              <span className="truncate max-w-52 font-medium">{policy?.quote?.ref ?? ''}</span>
              <LinkIcon className="size-4" />
            </Link>
          </div>

          <PolicyDetailsItem
            name="Plan Type"
            value={
              policy?.quote?.installmentType
                ? getInstallmentPeriodFromKey(policy?.quote?.installmentType)
                : 'N/A'
            }
          />
          <PolicyDetailsItem name="Plan Period" value={policy?.quote?.period ?? 'N/A'} />

          <PolicyDetailsItem
            name="Initial Amount"
            value={policy?.quote?.quotePlan?.initialDeposit ?? 'N/A'}
          />
          <PolicyDetailsItem
            name="Installment Amount"
            value={policy?.quote?.quotePlan?.monthlyInstallment ?? 'N/A'}
          />
          <PolicyDetailsItem
            name="Total Premium"
            value={policy?.quote?.quotePlan?.totalPremium ?? 'N/A'}
          />
          <PolicyDetailsItem
            name="Outright Premium"
            value={policy?.quote?.quotePlan?.outRightPremium ?? 'N/A'}
          />
          <PolicyDetailsItem
            name="Expiry Date"
            value={moment(policy?.expiresOn).format('DD-MM-YYYY') ?? 'N/A'}
          />
        </div>
      </div>
    );
  };

  const getInsuranceInfo = (): JSX.Element => {
    return (
      <div className="">
        <span className="font-semibold">Insurance Information</span>
        <div className={'border border-gray-300 p-2 rounded-lg h-full'}>
          <PolicyDetailsItem name="Insurer Name" value={policy?.insurer?.name ?? 'N/A'} />
          <PolicyDetailsItem
            name="Cover Type"
            value={
              policy?.protectionType
                ? getVehicleProtectionFromKey(policy?.protectionType)?.title
                : 'N/A'
            }
          />
          <PolicyDetailsItem name="Cover Period" value={policy?.quote?.coverPeriod ?? 'N/A'} />
          <PolicyDetailsItem
            name="Premium"
            value={policy?.quote?.quotePlan?.outRightPremium ?? 'N/A'}
          />
          <PolicyDetailsItem name="MID Reference" value={policy?.midReference ?? 'N/A'} />
          <PolicyDetailsItem
            name="Insurer Reference"
            value={policy?.insurerPolicyNumber ?? 'N/A'}
          />
          <PolicyDetailsItem name="Sticker Number" value={policy?.midStickerNumber ?? 'N/A'} />
          <PolicyDetailsItem
            name="Inception Date"
            value={moment(policy?.startsOn).format('DD-MM-YYYY') ?? 'N/A'}
          />
          {/* <PolicyDetailsItem name="Expiry Date" value={moment(policy?.expiresOn).format("l") ?? 'N/A'} /> */}
        </div>
      </div>
    );
  };

  const getAboutVehicleDetails = (): JSX.Element => {
    return (
      <div className="">
        <span className="font-semibold">Vehicle</span>
        <div
          className={
            'grid grid-cols-1 md:grid-cols-2 gap-5 border border-gray-300 p-2 rounded-lg h-full'
          }>
          <div className="">
            <PolicyDetailsItem name="Vehicle Make" value={policy?.vehicle?.make ?? 'N/A'} />
            <PolicyDetailsItem name="Vehicle Model" value={policy?.vehicle?.model ?? 'N/A'} />
            <PolicyDetailsItem name="Vehicle Type" value={policy?.vehicle?.type.name ?? 'N/A'} />
            <PolicyDetailsItem
              name="Vehicle Color"
              value={sentenceCase(policy?.vehicle?.color ?? 'N/A')}
            />
            <PolicyDetailsItem
              name="Chassis / VIN Number"
              value={policy?.vehicle?.chassisNumber ?? 'N/A'}>
              <ToolTip message={`Click to verify`}>
                <>
                  <IconStatus
                    cursorPointer
                    onclick={manuallyVerifyVIN}
                    status={policy?.vehicle?.verified}
                  />
                </>
              </ToolTip>
            </PolicyDetailsItem>
            <PolicyDetailsItem name="CC" value={policy?.vehicle?.cc ?? 'N/A'} />
            <PolicyDetailsItem
              name="Manufactured Year"
              value={policy?.vehicle?.manufacturedYear ?? 'N/A'}
            />
            <PolicyDetailsItem
              name="Registration Year"
              value={policy?.vehicle?.registrationYear ?? 'N/A'}
            />
            <PolicyDetailsItem
              name="Vehicle Registration Number"
              value={policy?.vehicle?.registrationNumber ?? 'N/A'}
            />
            <PolicyDetailsItem
              name="Vehicle Usage"
              value={policy?.vehicle?.usage?.title ?? 'N/A'}
            />
          </div>

          <div className="">
            <PolicyDetailsItem
              name="Number of Passengers"
              value={policy?.vehicle?.maxSeat ?? 'N/A'}
            />
            <PolicyDetailsItem name="Vehicle Owner" value={policy?.vehicle?.ownerName ?? 'N/A'} />
            <PolicyDetailsItem
              name="Vehicle Owner's Phone"
              value={
                policy?.vehicle?.ownerPhoneNumber
                  ? getPhoneNumberFromShortCodeFormat(policy?.vehicle?.ownerPhoneNumber)
                  : 'N/A'
              }
            />
            <PolicyDetailsItem
              name="Vehicle Main Driver"
              value={policy?.vehicle?.driver.fullName ?? 'N/A'}
            />
            <PolicyDetailsItem
              name="Vehicle Region"
              value={sentenceCase(policy?.vehicle?.region ?? 'N/A')}
            />

            <PolicyDetailsItem
              name="Vehicle Insurance Value"
              value={
                numberWithCommas(
                  String(policy?.vehicle?.insuredValue ?? policy?.quote?.insuredValue.toFixed(2))
                ) ?? 'N/A'
              }
            />

            <PolicyDetailsItem
              name="Nature of Interest in Vehicle"
              value={
                policy?.vehicle?.natureOfInterest
                  ? capitalizeSentence(policy?.vehicle?.natureOfInterest)
                  : 'N/A'
              }
            />

            <PolicyDetailsItem
              name="Accessories Installed"
              value={policy?.vehicle?.installedAccessoriesDetails ?? 'N/A'}
            />

            <PolicyDetailsItem
              name="Accessories Installed Value"
              value={policy?.vehicle?.installedAccessoriesValue ?? 'N/A'}
            />
          </div>
        </div>
      </div>
    );
  };

  const getAboutPolicyDetails = (): JSX.Element => {
    return (
      <div className={policyDetailsContentStyles}>
        <PolicyDetailsItem
          name="Protection Type"
          value={policy?.quote?.protectionType?.title ?? 'N/A'}
        />

        {!user.isInsurer() && (
          <>
            <PolicyDetailsItem
              name="Installment Type"
              value={getInstallmentTypeFromKey(policy?.installmentType ?? '')}
            />

            <PolicyDetailsItem
              name="Installment Amount"
              value={`&#8373; ${numberWithCommas(
                String(policy?.quote?.quotePlan?.monthlyInstallment.toFixed(2))
              )}`}
            />
          </>
        )}

        <PolicyDetailsItem name="MID Reference" value={policy?.midReference ?? 'N/A'} />

        {!user.isInsurer() && (
          <PolicyDetailsItem
            name="Outright Premium"
            value={`&#8373; ${policy?.quote?.quotePlan.outRightPremium ?? 'N/A'}`}
          />
        )}

        {!user.isInsurer() && (
          <>
            <PolicyDetailsItem
              name="Initial Deposit"
              value={`&#8373; ${numberWithCommas(
                String(policy?.quote?.quotePlan?.initialDeposit.toFixed(2))
              )}`}
            />

            <PolicyDetailsItem
              name="Installment Period"
              value={
                policy?.quote?.quotePlan?.installmentPeriod
                  ? getInstallmentPeriodFromKey(policy?.quote?.quotePlan?.installmentPeriod, policy)
                  : 'N/A'
              }
            />

            <PolicyDetailsItem
              name="No. of Installments"
              value={
                policy?.quote?.period
                  ? getNumOfInstallmentsFromInstallmentPeriod(policy?.quote?.period)
                  : 'N/A'
              }
            />
          </>
        )}

        <PolicyDetailsItem
          name="Insurer Policy Number"
          value={policy?.insurerPolicyNumber ?? 'N/A'}
        />

        <PolicyDetailsItem
          name="Total Premium"
          value={`&#8373; ${policy?.quote?.quotePlan.totalPremium ?? 'N/A'}`}
        />

        <PolicyDetailsItem
          name="Policy Start Date"
          value={policy?.startsOn ? moment(policy?.startsOn).format('Do MMM, YYYY') : 'N/A'}
        />

        <PolicyDetailsItem
          name="Policy Expiry Date"
          value={
            policy?.expiresOn ? moment(policy?.expiresOn).format('Do MMM, YYYY h:mm a') : 'N/A'
          }
        />

        {!user.isInsurer() && (
          <PolicyDetailsItem name="Insurer" value={policy?.insurer?.name ?? 'N/A'} />
        )}

        <PolicyDetailsItem name="MID Sticker Number" value={policy?.midStickerNumber ?? 'N/A'} />

        <PolicyDetailsItem name="Owner" value={policy?.owner ? policy?.owner?.name : 'N/A'} />
      </div>
    );
  };

  const getOtherInformation = (): JSX.Element => {
    return (
      <div className="">
        <span className="font-semibold">Other Information</span>
        <div className={'border border-gray-300 p-2 rounded-lg'}>
          <PolicyDetailsItem
            name="Has vehicle been altered in any way?"
            value={policy?.vehicle?.vehicleAltered ? 'Yes' : 'No'}
          />

          {policy?.vehicle?.vehicleAltered && (
            <PolicyDetailsItem
              name="Vehicle Alteration Details"
              value={policy?.vehicle?.alterationDetails ?? ''}
            />
          )}

          <PolicyDetailsItem
            name="Is this vehicle in good repair state?"
            value={policy?.vehicle?.goodRepairState ? 'Yes' : 'No'}
          />

          {!policy?.vehicle?.goodRepairState && (
            <PolicyDetailsItem
              name="Vehicle repair state details"
              value={policy?.vehicle?.repairState ?? ''}
            />
          )}

          <PolicyDetailsItem
            name="Does driver have any disease or complications?"
            value={policy?.vehicle?.driver?.hasDisease ? 'Yes' : 'No'}
          />

          {policy?.vehicle?.driver?.hasDisease && (
            <PolicyDetailsItem
              name="Driver disease or complications"
              value={policy?.vehicle?.driver?.diseaseOrComplications ?? ''}
            />
          )}

          <PolicyDetailsItem
            name="Ever been declined by other insurer?"
            value={policy?.previouslyDeclined ? 'Yes' : 'No'}
          />

          {policy?.previouslyDeclined && (
            <PolicyDetailsItem
              name="Reasons for previously declined insurance"
              value={policy?.declinedInsuranceDetails ?? ''}
            />
          )}

          <PolicyDetailsItem
            name="Have you ever made a claim on another insurance policy?"
            value={policy?.previouslyIssuedClaim ? 'Yes' : 'No'}
          />

          {policy?.previouslyIssuedClaim && (
            <PolicyDetailsItem
              name="Details for previously issued claim"
              value={policy?.previousInsuranceClaims ?? ''}
            />
          )}

          <PolicyDetailsItem
            name="Is this vehicle on hire purchase?"
            value={policy?.vehicle?.interestedParties ? 'Yes' : 'No'}
          />
          {policy?.isYango() && (
            <>
              <PolicyDetailsItem
                name="Which park does driver belong?"
                value={policy?.owner?.name}
              />

              <PolicyDetailsItem
                name="Is driver connected to a park?"
                value={policy?.quote?.connectedDriver ? 'Yes' : 'No'}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const getRefereeDetails = (): JSX.Element => {
    return (
      <div className="mt-5">
        <span className="font-semibold">Referee Information</span>
        <div className={'border border-gray-300 p-2 rounded-lg'}>
          {' '}
          <PolicyDetailsItem name="Name" value={policy?.referee?.name ?? 'N/A'} />
          <PolicyDetailsItem name="Relationship" value={policy?.referee?.relationship ?? 'N/A'} />
          <PolicyDetailsItem name="Years known" value={policy?.referee?.yearsKnown ?? 'N/A'} />
          <PolicyDetailsItem
            name="Phone"
            value={getPhoneNumberFromShortCodeFormat(policy?.referee?.phone ?? 'N/A')}>
            {policy?.referee?.verified ? (
              <div className="">
                <CheckIcon className=" h-4 w-4 rounded-full" />
              </div>
            ) : (
              <ToolTip message={`Click to verify`}>
                <div className="" onClick={showRefereePhoneVerificationModal}>
                  <XMarkIcon className="text-white p-[2px] h-4 w-4 rounded-full bg-red-700 cursor-pointer" />
                </div>
              </ToolTip>
            )}
          </PolicyDetailsItem>
        </div>
      </div>
    );
  };

  const getCommunityDetails = (): JSX.Element => {
    return (
      <div className="">
        <span className="font-semibold">Community Information</span>
        <div className={'border border-gray-300 p-2 rounded-lg'}>
          {' '}
          <PolicyDetailsItem name="Name" value={policy?.community?.name ?? 'N/A'} />
          <PolicyDetailsItem
            name="Contact Person"
            value={policy?.community?.contactPersons[0]?.fullName ?? 'N/A'}
          />
          <PolicyDetailsItem name="Review Stage" value={policy?.community?.reviewStage ?? 'N/A'} />
          <PolicyDetailsItem
            name="Contact Person's Phone"
            value={
              policy?.community?.contactPersons[0]?.phone
                ? getPhoneNumberFromShortCodeFormat(policy?.community?.contactPersons[0]?.phone)
                : 'N/A'
            }
          />
          <PolicyDetailsItem name="Type" value={policy?.community?.type ?? 'N/A'} />
          <PolicyDetailsItem
            name="Contact Person's Email"
            value={policy?.community?.contactPersons[0]?.email ?? 'N/A'}
          />
        </div>
      </div>
    );
  };

  const getDocumentPlaceholder = (type: POLICY_DOCUMENT_TYPES | undefined): JSX.Element => {
    switch (type) {
      case POLICY_DOCUMENT_TYPES.INSURANCE_STICKER:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={PolicyStickerIcon} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.INSURANCE_CERTIFICATE:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={InsuranceCertificateIcon} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.CONTRACT_SUMMARY:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={ContractSummary} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.PAYMENT_AGREEMENT:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={PaymentAgreementIcon} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.LOAN_AGREEMENT:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={PaymentAgreementIcon} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.PAYMENT_LOAN_AGREEMENT:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={PaymentAgreementIcon} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.ID_CARD:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={IdCard} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.PASSPORT_PHOTO:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={OWNER_PHOTO} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.DRIVER_LICENSE_BACK:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={DriverLicenseIcon} className="size-10 " />
          </div>
        );
      case POLICY_DOCUMENT_TYPES.DRIVER_LICENSE_FRONT:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={DriverLicenseIcon} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.RENEWAL_NOTICE:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={RenewalNoticeIcon} className="size-10 " />
          </div>
        );

      case POLICY_DOCUMENT_TYPES.ROADWORTHY:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <img src={RoadWorthyIcon} className="size-10 " />
          </div>
        );
      case POLICY_DOCUMENT_TYPES.POLICY_SCHEDULE:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            {/* <img src={<GrDocumentTime />} className="size-10 " /> */}
            <GrDocumentTime className="size-10 " />
          </div>
        );
      case POLICY_DOCUMENT_TYPES.POLICY:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center">
            <IoDocumentText className="size-10 " />
          </div>
        );

      default:
        return (
          <div className="w-full max-h-20 border-[2px] border-opacity-30 border-black p-3 flex justify-center items-center"></div>
        );
    }
  };

  const previewDocument = (document: IPolicyDocument) => {
    window.open(document.docURL, '_blank');
  };

  const downloadDocument = (document: IPolicyDocument) => {
    downloadPolicyDocument(document.id)
      .then((response: any) => {
        if (response) {
          fileDownload(response.data, `Brolly Africa - ${document.docType}.pdf`);
        }
      })
      .catch(() => {
        toast.error('Failed to download document. Please try again later');
      });
  };

  const approvePolicy = () => {
    updateModalStates({
      show: true,
      children: (
        <PolicyApproval
          policy={policy}
          onApproved={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
          onCancel={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const declinePolicy = () => {
    updateModalStates({
      show: true,
      children: (
        <ReviewPolicy
          policy={policy}
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
          onCancel={closeModal}
          title="Decline Policy"
          titleIcon={<OnHoldIcon />}
          type={POLICY_REVIEW_STATES.DECLINED}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'lg'
    });
  };

  const cancelPolicy = () => {
    updateModalStates({
      show: true,
      children: (
        <ReviewPolicy
          policy={policy}
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
          onCancel={closeModal}
          title="Cancel Policy"
          titleIcon={<CancelPolicy />}
          type={POLICY_REVIEW_STATES.CANCELLED}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'lg'
    });
  };

  const requestToCancelPolicy = () => {
    updateModalStates({
      show: true,
      children: (
        <ReviewPolicy
          policy={policy}
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
          onCancel={closeModal}
          title="Request Policy Cancellation"
          titleIcon={<CancelPolicy />}
          type={POLICY_REVIEW_STATES.CANCELLED}
          request
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'lg'
    });
  };

  const submitForApproval = () => {
    updateModalStates({
      show: true,
      children: (
        <SubmitForApproval
          policy={policy}
          onSubmitted={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
          }}
          onCancel={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const onHoldHandler = () => {
    updateModalStates({
      show: true,
      children: (
        <ReviewPolicy
          policy={policy}
          onProceed={() => {
            closeModal();
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });

            if (policy) {
              policy.reviewStage = POLICY_REVIEW_STATES.ON_HOLD;
              setPolicy(policy);
            }
          }}
          onCancel={closeModal}
          title="Put Policy on Hold"
          titleIcon={<OnHoldIcon />}
          type={POLICY_REVIEW_STATES.ON_HOLD}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'lg'
    });
  };

  const createClaim = () => {
    policyId &&
      createClaimFromPolicy(policyId)
        .then((response: any) => {
          if (response) {
            toast(response.message ?? 'Failed to create claim from policy');
            navigate(`${getDashboardBase()}/claims/${response?.claimNumber}/edit`);
          }
        })
        .catch((error: any) => {
          toast(error.description ?? 'Failed to create claim from policy');
        })
        .finally(() => {});
  };

  const updateChecklist = (policy_detail: IPolicyDetail) => {
    switch (policy_detail.id) {
      case POLICY_DETAIL_SECTIONS.ABOUT_YOU:
        if (!aboutYouCheck) {
          if (policy?.isPersonalDetailsProvided()) {
            setAboutYouCheck(true);
          } else {
            toast.error(
              <ToastContent
                title={'Cannot mark as checked'}
                description="All required details about applicant have not yet been provided"
              />,
              {
                autoClose: 5000
              }
            );
          }
        } else {
          setAboutYouCheck(false);
        }
        break;

      case POLICY_DETAIL_SECTIONS.ABOUT_VEHICLE:
        if (!aboutVehicleCheck) {
          if (policy?.isVehicleDetailsProvided()) {
            setAboutVehicleCheck(true);
          } else {
            toast.error(
              <ToastContent
                title={'Cannot mark as checked'}
                description="All required details about vehicle have not yet been provided"
              />,
              {
                autoClose: 5000
              }
            );
          }
        } else {
          setAboutVehicleCheck(false);
        }
        break;

      case POLICY_DETAIL_SECTIONS.ABOUT_POLICY:
        if (!aboutPolicyCheck) {
          if (policy?.isPersonalDetailsProvided()) {
            setAboutPolicyCheck(true);
          } else {
            toast.error(
              <ToastContent
                title={'Cannot mark as checked'}
                description="All details about policy are not present."
              />,
              {
                autoClose: 5000
              }
            );
          }
        } else {
          setAboutPolicyCheck(false);
        }
        break;

      case POLICY_DETAIL_SECTIONS.OTHER_INFORMATION:
        if (!otherInfoCheck) {
          if (policy?.isExtraInfoProvided()) {
            setOtherInfoCheck(true);
          } else {
            toast.error(
              <ToastContent
                title={'Cannot mark as checked'}
                description="All details about policy are not present."
              />,
              {
                autoClose: 5000
              }
            );
          }
        } else {
          setOtherInfoCheck(false);
        }
        break;

      case POLICY_DETAIL_SECTIONS.REFEREE_DETAILS:
        if (!refereeCheck) {
          if (policy?.isExtraInfoProvided()) {
            setRefereeDetailsCheck(true);
          } else {
            toast.error(
              <ToastContent
                title={'Cannot mark as checked'}
                description="All details about policy are not present."
              />,
              {
                autoClose: 5000
              }
            );
          }
        } else {
          setRefereeDetailsCheck(false);
        }
        break;
    }
  };

  const getPolicyVehiclePhotos = (policyNumber: string) => {
    requestPolicyVehiclePhotos(policyNumber)
      .then((response: any) => {
        toast.success(response?.message ?? 'Policy vehicle  photos request successful', {
          autoClose: 3000
        });
      })
      .catch((error: any) => {
        toast.error(error.description ?? 'Policy vehicle  photos request failed. Try again', {
          autoClose: 3000
        });
      });
  };

  const getTableRowActions = (data: any) => {
    let actions = [
      {
        label: 'Update task',
        callback: () => showCreateTaskModal(data, true)
      }
    ];

    return actions;
  };

  const taskColumns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
            Cell: (row: any) => {
              return <span>{row.cell.value ? capitalizeSentence(row.cell.value) : 'N/A'}</span>;
            }
          },

          {
            Header: 'Description',
            accessor: 'description',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Type',
            accessor: 'type',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Assigned To',
            accessor: 'assignedTo.firstName',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value
                    ? capitalizeSentence(
                        row.row.original.assignedTo.firstName +
                          ' ' +
                          row.row.original.assignedTo.lastName
                      )
                    : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'completed',
            Cell: (row: any) => {
              return getStatus(row.cell.value);
            }
          },
          {
            Header: 'Due date',
            accessor: 'dueOn',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? moment(row.cell.value).format('MMM Do YY, h:mm a') : 'N/A'}
                </span>
              );
            }
          },
          {
            id: 'Action',
            Cell: (row: any) => {
              return (
                <div className="cursor-pointer flex justify-center">
                  {getTableRowActions(row.row.original).length > 0 && (
                    <PopOnMe actions={getTableRowActions(row.row.original)}>
                      <EllipsisVerticalIcon className="h-4 w-4" />
                    </PopOnMe>
                  )}
                </div>
              );
            }
          }
        ]
      }
    ],
    []
  );

  const claimsColumns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Claim Number',
            accessor: 'claimNumber',
            Cell: (row: any) => {
              return (
                <Link to={`${getDashboardBase()}/claims/${row?.cell.value}`} target="_blank">
                  <span className="hover:underline text-link-main">{row.cell.value ?? 'N/A'}</span>
                </Link>
              );
            }
          },

          {
            Header: 'Amount/ Cost',
            accessor: 'amount',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'NCD Effect',
            accessor: 'ncdEffect',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },

          {
            Header: 'Stage',
            accessor: 'claimStage',
            Cell: (row: any) => {
              return <ClaimStages status={row.cell.value} />;
            }
          },

          {
            Header: 'Paid',
            accessor: 'paid',
            Cell: (row: any) => {
              return <TwoStateVerifiedCheck verified={row.cell.value} />;
            }
          },
          {
            Header: 'Created On',
            accessor: 'createdOn',
            Cell: (row: any) => {
              return <span>{moment(row.cell.value).format('DD-MM-YY h:mm a') ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Updated On',
            accessor: 'updatedOn',
            Cell: (row: any) => {
              return <span>{moment(row.cell.value).format('DD-MM-YY h:mm a') ?? 'N/A'}</span>;
            }
          }
        ]
      }
    ],
    []
  );

  const showCreateTaskModal = (task?: ITask, isEdit = false) => {
    updateModalStates({
      show: true,
      size: 'xl',
      children: (
        <CreateTask
          onProceed={() => {
            setRefresh(true);
            closeModal();
          }}
          onClose={closeModal}
          id={policyId}
          isEdit={isEdit}
          task={task}
          type={'POLICY'}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const sendAgreementFormToCustomer = () => {
    if (policy) {
      updateModalStates({
        show: true,
        size: 'xl',
        children: (
          <SendAgreementFormModal
            policy={policy}
            onClose={closeModal}
            onProceed={(url: string) => {
              closeModal();
              const documentName = url.split('type=')[1];
              updateModalStates({
                show: true,
                size: 'xl',
                children: (
                  <CopyLink
                    onClose={closeModal}
                    link={url}
                    title={`${removeUnderscoresAndDashes(documentName, true)} LINK`}
                  />
                ),
                showTitleSection: false,
                classes: '!p-0 r-[5px]'
              });
            }}
          />
        ),
        showTitleSection: false,
        classes: '!p-0 r-[5px]'
      });
    }
  };

  const getPolicyActions = () => {
    const policyActions = [
      {
        label: 'Create task',
        callback: () => showCreateTaskModal()
      },
      {
        label: 'Regenerate payment objects',
        callback: () =>
          showGeneratePaymentObjectsModal('REGENERATE_PAYMENT_OBJECTS', invalidateQueries)
      }
    ];

    if (policy) {
      policyActions.push(
        {
          label: 'Download policy',
          callback: () => showDownloadPolicyDetailsModal()
        },
        {
          label: 'Request vehicle photos',
          callback: () => getPolicyVehiclePhotos(policy.ref)
        },
        {
          label: 'Send agreement form',
          callback: () => sendAgreementFormToCustomer()
        }
      );

      if (!policy.payments || policy.payments.length === 0) {
        policyActions.push({
          label: 'Generate payment objects',
          callback: () =>
            showGeneratePaymentObjectsModal('GENERATE_PAYMENT_OBJECTS', invalidateQueries)
        });
      }

      if (policy?.active || (policy.isExpired() && user.isAdmin())) {
        policyActions.push({
          label: 'Renew policy',
          callback: () => renewPolicy()
        });
      }

      if (policy?.active) {
        policyActions.push({
          label: 'Make claim',
          callback: () => createClaim()
        });

        if (user.isAdmin() || user.isReviewer() || user.isSuperAdmin()) {
          policyActions.push({
            label: 'Request policy cancellation',
            callback: () => requestToCancelPolicy()
          });
        }
      }

      if (!policy.active) {
        if (
          !(user.isSuperAdmin() || user.isReviewer() || user.isInsurer()) ||
          user.isInsurerPolicyAdmin()
        ) {
          if (!policy?.metaData?.completed) {
            policyActions.push(
              {
                label: 'Edit',
                callback: () => navigate(`${getDashboardBase()}/policies/${policy.ref}/edit`)
              },
              {
                label: 'Mark as done',
                callback: () => showMarkPolicyAsCompleteModal()
              }
            );
          }
        }
      }

      policyActions.push({
        label: `View / add ${policy?.updates?.length === 1 ? ' update' : ' updates'}`,
        callback: () => setShowUpdates(true)
      });

      if ((user.isAdmin() || user.isReviewer()) && !policy.isApproved()) {
        policyActions.push({
          label: 'Set to due later',
          callback: () => setPolicyToDueLater()
        });
      }
    }

    return policyActions;
  };

  const renewPolicy = () => {
    updateModalStates({
      show: true,
      size: '5xl',
      children: (
        <QuoteIframe
          onProceed={() => {
            closeModal();
          }}
          onClose={closeModal}
          isRenewPolicy={true}
          policyNumber={policy?.ref}
        />
      ),
      static: true
    });
  };

  const submitForReview = () => {
    updateModalStates({
      show: true,
      children: (
        <ReviewPolicy
          policy={policy}
          onProceed={() => {
            closeModal();
            navigate(`${getDashboardBase()}/policies/${policyId}`, { replace: true });
          }}
          onCancel={closeModal}
          title="Submit for review"
          titleIcon={<OnHoldIcon />}
          type={POLICY_REVIEW_STATES.IN_REVIEW}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'lg'
    });
  };

  const getButtonActions = () => {
    const policyActions = [];

    if (policy?.active) {
      if (user.isSuperAdmin()) {
        policyActions.push({
          label: 'Cancel policy',
          callback: cancelPolicy
        });
      }
    }

    if (!policy?.active) {
      if (policy?.metaData?.firstPaymentReceived && user?.isAdmin())
        policyActions.push({
          label: 'Submit for review',
          callback: submitForReview
        });

      if (user.isSuperAdmin()) {
        if (!policy?.isOnHold() && policy?.isInApproval()) {
          policyActions.push({
            label: 'On Hold',
            callback: onHoldHandler
          });
        }

        if (!policy?.isDeclined() && policy?.isInApproval()) {
          policyActions.push({
            label: 'Decline',
            callback: declinePolicy
          });
        }

        // if (policy?.isInApproval()) {
        //   policyActions.push({
        //     label: 'Approve',
        //     callback: approvePolicy
        //   });
        // }
      }

      if (user.isReviewer()) {
        if (!policy?.isOnHold() && policy?.isInReview()) {
          policyActions.push({
            label: 'On Hold',
            callback: onHoldHandler
          });
        }

        if (policy?.isOnHold() || policy?.isInReview() || policy?.isDueLater()) {
          policyActions.push({
            label: 'Submit for approval',
            callback: submitForApproval
          });
        }
      }
    }

    if (user.isSuperAdmin() && policy?.isInApproval()) {
      policyActions.push({
        label: 'Approve',
        callback: approvePolicy
      });
    }

    return policyActions;
  };

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsImageViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsImageViewerOpen(false);
  };

  const applyDiscount = () => {
    updateModalStates({
      show: true,
      children: (
        <ApplyDiscount
          closeModal={closeModal}
          onProceed={() => {
            queryClient.invalidateQueries({ queryKey: ['fetchPolicy'] });
            closeModal;
          }}
          policy={policy}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'lg'
    });
  };

  const getLatestInApprovalUpdate = () => {
    const inApprovalUpdates = policy?.updates?.filter(
      (update: IPolicyUpdate) => update.reviewStage === POLICY_REVIEW_STATES.IN_APPROVAL
    );

    if (inApprovalUpdates) {
      return inApprovalUpdates[inApprovalUpdates.length - 1];
    }
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className="">
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: any) => (
          <div className="">
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
          </div>
        )
      },
      {
        Header: 'M',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Due Date',
            accessor: 'dueDate',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YYYY') : 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Paid Date',
            accessor: 'dateOfPayment',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YYYY') : 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Policy Number',
            accessor: 'policyNumber',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Channel',
            accessor: 'channel',
            Cell: (row: any) => {
              return (
                <span className="">{getFormattedPaymentChannel(row.cell.value) ?? 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Processor',
            accessor: 'processor',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Amount',
            accessor: 'preAmount',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Charged Amount',
            accessor: 'amount',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Processor Ref',
            accessor: 'reference',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Status',
            accessor: 'paymentStatus',
            Cell: (row: any) => {
              return <PaymentStatus row={row} actions={getActions(row.row.original)} />;
            }
          }
          // {
          //   id: 'Action',
          //   Header: 'Actions',
          //   Cell: (row: any) => {
          //     if (getActions(row.row.original)?.length > 0)
          //       return (
          //         <div className="cursor-pointer flex justify-center">
          //           <PopOnMe actions={getActions(row.row.original)}>
          //             <EllipsisVerticalIcon className="h-4 w-4" />
          //           </PopOnMe>
          //         </div>
          //       );
          //   }
          // }
        ]
      }
    ],
    []
  );

  const mobileColumns = React.useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className="">
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: any) => (
          <div className="">
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
          </div>
        )
      },
      {
        Header: 'M',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            Cell: (row: any) => {
              return <span>{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Due Date',
            accessor: 'dueDate',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YYYY') : 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Paid Date',
            accessor: 'dateOfPayment',
            Cell: (row: any) => {
              return (
                <span>{row.cell.value ? moment(row.cell.value).format('DD/MM/YYYY') : 'N/A'}</span>
              );
            }
          },
          {
            Header: 'Policy Number',
            accessor: 'policyNumber',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Channel',
            accessor: 'channel',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Scheduled Amount',
            accessor: 'preAmount',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Penalty',
            accessor: 'incrementAmount',
            Cell: (row: any) => {
              return <span className="">{row.cell.value ?? 'N/A'}</span>;
            }
          },
          {
            Header: 'Total',
            accessor: 'amount',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Status',
            accessor: 'paymentStatus',
            Cell: (row: any) => {
              return <PaymentStatus row={row} />;
            }
          },
          {
            id: 'Action',
            Header: 'Actions',
            Cell: (row: any) => {
              if (getActions(row.row.original)?.length > 0)
                return (
                  <div className="cursor-pointer flex justify-center">
                    <PopOnMe actions={getActions(row.row.original)}>
                      <EllipsisVerticalIcon className="h-4 w-4" />
                    </PopOnMe>
                  </div>
                );
            }
          }
        ]
      }
    ],
    []
  );

  const setPolicyToDueLater = () => {
    updateModalStates({
      show: true,
      children: (
        <ReviewPolicy
          policy={policy}
          onProceed={() => {
            closeModal();
          }}
          onCancel={closeModal}
          title="Set to due later"
          titleIcon={<MdDateRange />}
          type={POLICY_REVIEW_STATES.DUE_LATER}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]',
      size: 'lg'
    });
  };

  const getPaymentActions = () => {
    let actions = [
      {
        label: 'Activate preapproval',
        callback: () => showHubtelPreapprovalModal()
      },
      {
        label: 'Regenerate payment objects',
        callback: () =>
          showGeneratePaymentObjectsModal('REGENERATE_PAYMENT_OBJECTS', invalidateQueries)
      },
      {
        label: 'Apply discount',
        callback: () => applyDiscount()
      }
    ];

    if (policy) {
      if (!policy.payments || policy.payments.length === 0) {
        actions.push({
          label: 'Generate payment objects',
          callback: () =>
            showGeneratePaymentObjectsModal('GENERATE_PAYMENT_OBJECTS', invalidateQueries)
        });
      }

      if (!policy.isFullPayment()) {
        actions.push(
          {
            label: 'Get website payment link',
            callback: () => generateWebsitePaymentLink()
          },
          {
            label: 'Activate recurring payment',
            callback: () => choosePaymentService()
          },
          {
            label: 'Complete all payments',
            callback: () => completeAllPayments()
          }
        );
      }

      if (policy.isFullPayment() && policy.getFirstUnpaidPayment()) {
        actions.push({
          label: 'Make payment',
          // callback: () => handlePayment(PAYMENT_OPTIONS.CARD, paymentData)
          callback: () => choosePaymentOption()
        });
      }

      if (selectedPayments && selectedPayments.length > 0) {
        actions.push({
          label: 'Make multiple payments',
          callback: () => makePaymentForSelectedIds()
        });
      }
    }

    return actions;
  };

  return (
    <div className={clsx('bg-white rounded-lg shadow-lg p-4 min-h-full text-sm')}>
      {isLoading ? (
        <div className="h-screen flex items-center justify-center w-full">
          <Loader message="Loading policy details. Please wait..." brollyLoader fullscreen />
        </div>
      ) : (
        <>
          {policy && (
            <div className="w-full pb-10">
              <div className="flex flex-col md:flex-row justify-between">
                <div className="flex items-center justify-start gap-5">
                  <div className="w-fit">
                    <BackButton
                      onClick={() => {
                        if (props.previousPath === `${getDashboardBase()}/dashboard`) {
                          navigate(`${getDashboardBase()}/dashboard`);
                        } else if (props.previousPath === `${getDashboardBase()}/payments`) {
                          navigate(
                            `${getDashboardBase()}/payments?filter=${searchParams.get(
                              'filter'
                            )}&page=${Number(searchParams.get('page'))}`
                          );
                        } else {
                          navigate(
                            `${getDashboardBase()}/policies?filter=${searchParams.get(
                              'filter'
                            )}&page=${Number(searchParams.get('page'))}`
                          );
                        }
                      }}
                    />
                  </div>

                  <div className="w-full min-w-fit text-2xl font-semibold">ID: {policy.ref}</div>

                  <PolicyStatus
                    status={capitalizeSentence(policy?.reviewStage ?? 'N/A')}
                    classes="rounded-full px-2  w-fit"
                  />
                </div>

                <div className="flex flex-col min-[400px]:flex-row space-x-2 items-center mt-6 md:mt-0 justify-between">
                  <div className=" flex items-center justify-center gap-x-3 mb-2 min-[400px]:mb-0">
                    {policy &&
                      (user.isSuperAdmin() ? (
                        <Link
                          to={`${getDashboardBase()}/teams/staff/${policy?.manager?.id}`}
                          className="flex items-center gap-1 hover:underline underline-offset-2 text-blue-600 whitespace-nowrap">
                          {policy.manager
                            ? `${policy.manager?.firstName} ${policy.manager?.lastName}`
                            : 'N/A'}
                        </Link>
                      ) : (
                        <span className="font-semibold  whitespace-nowrap">
                          {policy.manager
                            ? `${policy.manager?.firstName} ${policy.manager?.lastName}`
                            : 'N/A'}
                        </span>
                      ))}

                    {(user.isSuperAdmin() || user.isReviewer()) && (
                      <MdChangeCircle
                        className="size-5 bg-primary-main text-white flex items-center justify-center rounded-full p-1 cursor-pointer"
                        onClick={assignPolicyManager}
                      />
                    )}
                  </div>

                  {getPolicyActions()?.length > 0 && (
                    <PopOnMe
                      actions={getPolicyActions()}
                      popOptionClasses="!h-[35px] !min-w-[150px]">
                      <div className="cursor-pointer py-1 px-2  border border-primary-main rounded">
                        Actions
                      </div>
                    </PopOnMe>
                  )}
                </div>
              </div>

              {policy?.metaData && !(user?.isRideSharing() || user?.isInsurer()) && (
                <div className="mt-10">
                  <h2 className="font-semibold text-center mb-3">Policy Meta Data</h2>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-5 border border-primary-border items-center p-5">
                    <div className="flex items-center justify-between">
                      <span className="">Date Created</span>
                      <span className="font-bold">
                        {policy?.metaData?.createdOn
                          ? moment(policy?.metaData?.createdOn)?.format('DD/MM/YYYY hh:mm')
                          : 'N/A'}
                      </span>
                    </div>

                    <div className="flex items-center justify-between">
                      <span>Policy Start Date</span>
                      <span className="font-bold">
                        {policy?.startsOn
                          ? moment(policy?.startsOn)?.format('DD/MM/YYYY hh:mm')
                          : 'N/A'}
                      </span>
                    </div>

                    <div className="flex items-center justify-between">
                      <span className="">Time to Policy Completion</span>
                      <span className="font-bold">
                        {policy?.metaData?.minutesToCompletion
                          ? `${policy?.metaData?.minutesToCompletion} Minutes`
                          : 'N/A'}
                      </span>
                    </div>

                    <div className="flex items-center justify-between ">
                      <span>First Payment Received</span>
                      <span className="font-bold">
                        {policy?.metaData?.firstPaymentReceived ? 'Yes' : 'No'}
                      </span>
                    </div>

                    <div className="flex items-center justify-between ">
                      <span>First Payment On</span>
                      <span className="font-bold">
                        {policy?.metaData?.firstPaymentReceivedTime
                          ? moment(policy?.metaData?.firstPaymentReceivedTime)?.format(
                              'DD/MM/YYYY hh:mm'
                            )
                          : 'N/A'}
                      </span>
                    </div>

                    <div className="flex items-center justify-between ">
                      <span>Vehicle Photos Received</span>
                      <span className="font-bold">
                        {policy?.metaData?.vehiclePhotosReceived ? 'Yes' : 'No'}
                      </span>
                    </div>

                    <div className="flex items-center justify-between ">
                      <span className="">Policy Source</span>
                      <span className="font-bold">
                        {policy?.quote?.source
                          ? getFormattedLeadSource(policy?.quote?.source ?? '')
                          : 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {(user.isAdmin() || user.isReviewer()) && !policy?.active && (
                <div className="py-8 flex justify-center">
                  {policy.reviewStage === POLICY_REVIEW_STATES.IN_APPROVAL &&
                  policy.updates.length > 0 ? (
                    <MessagePrompt
                      type={'error'}
                      message={getLatestInApprovalUpdate()?.description}
                      classes="w-full lg:w-2/3"
                    />
                  ) : (
                    <MessagePrompt
                      type={'info'}
                      message={
                        'Kindly ensure all required information for policy have been provided for, then mark each section as checked to be able to submit the policy for approval and have the sticker issued.'
                      }
                      classes="w-full lg:w-2/3"
                    />
                  )}
                </div>
              )}

              {/* Policy details */}
              <div className="w-full space-y-5">
                {/* INSURER VIEW  AND RIDE-SHARING*/}
                {(user.isInsurer() || user.isRideSharing()) && (
                  <div className="w-full pt-5">
                    <div className="w-full  py-6 ">{getAboutVehicleDetails()} </div>
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5 ">
                      {getInsuranceInfo()}
                      {getOtherInformation()}
                    </div>
                  </div>
                )}

                {/* OTHER ACCESS RIGHTS VIEW */}
                {!user.isInsurer() && !user.isRideSharing() && (
                  <>
                    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-5 pt-10">
                      {/* Information Section */}
                      <div className="w-full col-span-2 space-y-6 ">
                        {getAboutYouDetails()}
                        {getAboutVehicleDetails()}
                      </div>

                      {/* Other Section */}
                      <div className="w-full md:col-span-1  ">
                        <span className="font-semibold pl-4"> Activity feed</span>
                        <div className=" py-5 overflow-y-auto h-full md:max-h-[34rem]">
                          {policy?.activityStreams && policy?.activityStreams?.length > 0 ? (
                            <ActivityStepper
                              activityStreams={policy.activityStreams}
                              orientation="vertical"
                              currentStep={0}
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center font-semibold">
                              No activity
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-5">
                      {getInsuranceInfo()}
                      {!user.isInsurer() && getQuotePlan()} {getOtherInformation()}
                      {policy && policy.community && getCommunityDetails()}
                      {policy?.isThirdParty() &&
                        !policy?.isFullPayment() &&
                        policy?.isCommercial() &&
                        !user.isInsurer() &&
                        !user.isRideSharing() &&
                        policy?.referee?.phone &&
                        !user.isRideSharing() &&
                        getRefereeDetails()}
                    </div>
                  </>
                )}
              </div>

              {/* Vehicle Images */}
              <>
                {policy &&
                  policy?.vehicle &&
                  policy?.vehicle?.images &&
                  policy?.vehicle?.images?.length > 0 &&
                  !user.isRideSharing() && (
                    <div className="mt-10">
                      <h1 className="text-[20px] font-[gilroy-medium] leading-[18.75px]">
                        Vehicle Images
                      </h1>

                      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full py-4 ">
                        {policy?.vehicle?.images?.map(
                          (vehicleImage: IVehicleImage, index: number) => (
                            <div
                              key={index}
                              onClick={() => openImageViewer(index)}
                              className="cursor-pointer hover:border-[1px] hover:border-primary-main hover:opacity-80 h-[250px] overflow-hidden flex items-center">
                              <img src={vehicleImage.docURL} className="w-full" />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}

                {isImageViewerOpen && policy?.vehicle?.images && (
                  <ImageViewer
                    src={policy?.vehicle?.images?.map((image: IVehicleImage) => image.docURL)}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                      backgroundColor: 'rgba(0,0,0,0.9)',
                      zIndex: 999
                    }}
                    closeOnClickOutside={true}
                  />
                )}
              </>

              <div className="flex items-center gap-x-10 mt-[56px] overflow-x-auto">
                <TabNavItem
                  id="payments"
                  title="Payments"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  count={policy?.payments && policy?.payments?.length}
                />
                <TabNavItem
                  id="tasks"
                  title="Tasks"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  count={policy?.tasks && policy?.tasks?.length}
                />
                <TabNavItem
                  id="documents"
                  title="Documents"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  count={
                    Number(policy?.documents?.length) + Number(policy?.account?.documents?.length)
                  }
                />
                <TabNavItem
                  id="claims"
                  title="Claims"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  count={claims && claims?.length}
                />
              </div>

              <div className="">
                <TabContent id={'payments'} activeTab={activeTab}>
                  {policy && policy.payments && policy.payments.length > 0 ? (
                    !user.isInsurer() &&
                    !user.isRideSharing() && (
                      <div className=" py-5 ">
                        <div className="flex flex-col md:flex-row md:justify-between md:items-center ">
                          <div>
                            <span className="font-bold">Total Premium:</span>{' '}
                            <span className="px-2">
                              {policy?.quote?.quotePlan?.totalPremium?.toFixed(2)}
                            </span>
                          </div>
                          <div>
                            <span className="font-bold">Total Paid:</span>{' '}
                            <span className="px-2">{policy?.totalPaid?.toFixed(2)}</span>
                          </div>
                          <div>
                            <span className="font-bold">Balance Remaining:</span>{' '}
                            <span className="px-2">{policy?.balanceRemaining?.toFixed(2)}</span>
                          </div>
                          <div className="flex justify-center items-center">
                            <span className="font-bold">Discount Applied:</span>{' '}
                            <div className="flex justify-center items-center">
                              <div className="px-2">{policy?.discount ? 'YES' : 'NO'}</div>
                              <div className="mt-1">
                                {policy?.discount && (
                                  <Tooltip
                                    direction="bottom"
                                    bgColor="bg-gray-200"
                                    message={'View discount info'}>
                                    <MyPopOver
                                      trigger={<FaEye size={16} />}
                                      closeOnOutsideClick={true}>
                                      <DiscountInfoModal
                                        code={policy?.discount?.code}
                                        name={policy?.discount?.name}
                                        value={policy?.discount?.value}
                                        type={policy?.discount?.type}
                                      />
                                    </MyPopOver>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center justify-end md:flex-none">
                            {getPaymentActions()?.length > 0 && (
                              <PopOnMe
                                actions={getPaymentActions()}
                                popOptionClasses="!h-[35px] !min-w-[150px] ">
                                <div className="cursor-pointer py-1 px-2  border border-primary-main rounded w-fit">
                                  Payment Actions
                                </div>
                              </PopOnMe>
                            )}
                          </div>
                        </div>
                        <div className="overflow-y-auto max-h-[400px] relative">
                          <GTable
                            showCheckboxes
                            selectionChanged={(
                              selectedObjects: any[],
                              selectedIndexes: number[]
                            ) => {
                              const paymentIdList = selectedObjects.map((payment) => payment.id);
                              setSelectedPayments(paymentIdList);
                              setSelectedPaymentIndexes(selectedIndexes);
                            }}
                            actionElement={
                              // screenWidth < 768 &&
                              getPaymentActions()?.length > 0 && (
                                <div className="flex justify-end w-full">
                                  <PopOnMe
                                    actions={getPaymentActions()}
                                    popOptionClasses="!h-[35px] !min-w-[150px]">
                                    <div className="cursor-pointer py-1 px-2  border border-primary-main rounded">
                                      Payment Actions
                                    </div>
                                  </PopOnMe>
                                </div>
                              )
                            }
                            stickyHeader
                            columns={screenWidth < 550 ? mobileColumns : columns}
                            data={policy.payments}
                            tableType="payment"
                            showSearch={false}
                            loading={loading}
                            tableContainerId="tableId"
                          />
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="h-20 flex items-center justify-center text-lg">
                      No payment found{' '}
                    </div>
                  )}
                </TabContent>

                <TabContent id={'tasks'} activeTab={activeTab}>
                  {/* Policy Tasks */}
                  {policy &&
                  policy.tasks &&
                  policy.tasks.length > 0 &&
                  !user.isInsurer() &&
                  !user.isRideSharing() ? (
                    <div className=" my-5 ">
                      <GTable
                        columns={taskColumns}
                        data={policy?.tasks}
                        classes="h-fit"
                        showSearch={false}
                        tableType={'task'}
                        stickyHeader
                      />
                    </div>
                  ) : (
                    <div className="h-20 flex items-center justify-center text-lg">
                      No task found{' '}
                    </div>
                  )}
                </TabContent>

                <TabContent id={'documents'} activeTab={activeTab}>
                  {!user.isRideSharing() && !user.isInsurer() && policy && (
                    <>
                      {/* Account Documents */}
                      {policy?.account &&
                        policy?.account?.documents &&
                        policy?.account?.documents?.length > 0 && (
                          <div className="w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 items-center gap-5 my-5">
                            {policy.account.documents.map(
                              (document: IPolicyDocument, index: number) => (
                                <div key={index}>
                                  <div className="min-h-[90px]">
                                    {getDocumentPlaceholder(document.docType)}
                                  </div>
                                  <div className="w-full bg-black h-[40px] flex items-center">
                                    <div className="flex justify-between items-center w-full px-4">
                                      <div className="flex items-center space-x-2 w-full flex-start">
                                        <PdfIcon className="w-[16px]" />
                                        <span className="text-white text-[16px] whitespace-nowrap">
                                          {document.title}
                                        </span>
                                      </div>
                                      <div className="flex items-center space-x-2 flex-end">
                                        <EyeIcon
                                          className="text-white w-[16px] cursor-pointer"
                                          onClick={() => previewDocument(document)}
                                        />
                                        <HiDownload
                                          className="text-white w-[16px] cursor-pointer"
                                          onClick={() => downloadDocument(document)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}

                      {/* Policy Documents */}
                      {policy.documents && policy.documents?.length > 0 && (
                        <div className="w-full">
                          <div className="w-full grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 items-center gap-5">
                            {policy.documents.map(
                              (document: IPolicyDocument) =>
                                (!user.isInsurer() ||
                                  (user.isInsurer() &&
                                    document.docType !==
                                      POLICY_DOCUMENT_TYPES.PAYMENT_AGREEMENT)) && (
                                  <div key={document.title}>
                                    <div className="min-h-[90px]">
                                      {getDocumentPlaceholder(document.docType)}
                                    </div>
                                    <div className="w-full bg-black h-[40px] flex items-center">
                                      <div className="flex justify-between items-center w-full px-4">
                                        <div className="flex items-center space-x-2 w-full flex-start">
                                          <PdfIcon className="w-[16px]" />
                                          <span className="text-white text-[16px] whitespace-nowrap">
                                            {document.title}
                                          </span>
                                        </div>
                                        <div className="flex items-center space-x-2 flex-end">
                                          <EyeIcon
                                            className="text-white w-[16px] cursor-pointer"
                                            onClick={() => previewDocument(document)}
                                          />
                                          <HiDownload
                                            className="text-white w-[16px] cursor-pointer"
                                            onClick={() => downloadDocument(document)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      )}

                      {/* No Documents Found */}
                      {!policy.account?.documents?.length && !policy.documents?.length && (
                        <div className="h-20 flex items-center justify-center text-lg">
                          No document found
                        </div>
                      )}
                    </>
                  )}
                </TabContent>

                <TabContent id={'claims'} activeTab={activeTab}>
                  <>
                    {!user.isRideSharing() && !user.isInsurer() && claims && claims.length > 0 && (
                      <div className=" my-5">
                        <GTable
                          columns={claimsColumns}
                          data={claims}
                          classes="h-fit"
                          showSearch={false}
                          tableType={'claim'}
                          onRowClick={(claim: Claim) => {
                            navigate(`${getDashboardBase()}/claims/${claim.claimNumber}`, {
                              replace: true
                            });
                          }}
                          loading={claimsLoading}
                          stickyHeader
                        />
                      </div>
                    )}

                    {/* No Claim Found */}
                    {!claims && (
                      <div className="h-20 flex items-center justify-center text-lg">
                        No claim found
                      </div>
                    )}
                  </>
                </TabContent>
              </div>

              {/* Action buttons */}
              <div className="flex py-4 justify-end">
                {getButtonActions()?.length > 0 && (
                  <PopOnMe actions={getButtonActions()} popOptionClasses="!h-[35px]">
                    <button className="flex justify-between items-center space-x-4 p-1 outline-none border-[2px] border-primary-main px-4 py-2 w-fit">
                      <span>Actions</span>
                      <ChevronDownIcon className="w-4" />
                    </button>
                  </PopOnMe>
                )}
              </div>
            </div>
          )}

          {!policy && (
            <div className="h-screen flex items-center justify-center w-full">
              <NotFoundComponent text="Policy not found" />{' '}
            </div>
          )}
          <PaymentModalComponent />
        </>
      )}

      {policy && showUpdates && (
        <PolicyUpdates
          show={showUpdates}
          onClose={() => {
            setShowUpdates(false);
          }}
          onProceed={() => {}}
          updates={policy?.updates ?? []}
          policyId={policy && policy.ref}
          onPolicyUpdated={(policy: Policy) => setPolicy(policy)}
        />
      )}

      <ModalComponent />
    </div>
  );
};

export const getStatus = (status: string) => {
  return status ? (
    <div
      className={`text-xs  w-fit px-5 flex items-center justify-center rounded ${SUCCESS_PAYMENT_STATUS_COLOR}`}>
      Done
    </div>
  ) : (
    <div className="text-xs  w-fit px-5 flex items-center justify-center rounded bg-gray-500 text-white">
      TO DO
    </div>
  );
};

type PolicyDetailsItemProps = {
  name: string;
  value?: string | number | JSX.Element;
  children?: React.ReactNode;
};

const PolicyDetailsItem = ({ name, value, children }: PolicyDetailsItemProps) => {
  return (
    <div className="flex flex-row justify-between py-1">
      <div className="text-gray-600 ">{name}:</div>
      <div className="flex items-center gap-1">
        <span className="truncate font-medium whitespace-nowrap">{value}</span>
        <>{children} </>
      </div>
    </div>
  );
};

export default PolicyDetails;
