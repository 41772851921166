import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Animate } from 'react-simple-animate';
import { toast } from 'react-toastify';
import { addPolicyUpdate } from '../../../api/requests';
import Button from '../../../components/form-control/Button';
import TextArea from '../../../components/form-control/TextArea';
import useRoute from '../../../hooks/routesHook';
import { IPolicyUpdate } from '../../../models/policy/IPolicy';
import { Policy } from '../../../models/policy/Policy';
import { getPolicyReviewStatus } from '../../../utils/miscFunctions';
import { ReactComponent as ReplyIcon } from './imgs/reply.svg';
import useUserAccount from '../../../hooks/userAccountHook';
import { ArrowUpIcon } from '@heroicons/react/24/solid';
import useLayoutHook from '../../../hooks/layoutHook';
import { IRequestResponse } from '../../../resources/interfaces';

interface IPolicyUpdates {
  onProceed: () => void;
  onClose: () => void;
  show: boolean;
  updates: IPolicyUpdate[];
  policyId: string;
  onPolicyUpdated: (policy: Policy) => void;
}

const PolicyUpdates = ({
  // onProceed,
  onClose,
  show = false,
  updates,
  policyId,
  onPolicyUpdated
}: IPolicyUpdates) => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [reply, setReply] = useState<string>('');
  const {screenWidth} = useLayoutHook()
  const { user } = useUserAccount();
  const { lockBackground, unlockBackground } = useRoute();
  const sectionRef = useRef<HTMLDivElement>(null);
  const topSection = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (show) {
      lockBackground();
    } else {
      unlockBackground();
    }
  }, [show]);

  const endStyles = {
    zIndex: 2147483647,
    position: 'absolute' as 'absolute',
    top: 100,
    right: screenWidth > 550 ? 0 : '20px',
    width: screenWidth > 550 ? '681px' : '100vw',
    minHeight: 'calc(100vh - 105px)'
  };

  const startStyles = {
    zIndex: 2147483647,
    position: 'absolute' as 'absolute',
    top: 100,
    right: screenWidth > 550 ? '-681px' : '-100vw',
    width: screenWidth > 550 ? '681px' : '100vw',
    minHeight: 'calc(100vh - 105px)'
  };

  const handleSubmit = () => {
    if (!reply) {
      toast.error('You need to provide a message to proceed.', {
        autoClose: 5000
      });
      return;
    }

    setSubmitting(true);
    addPolicyUpdate(policyId, { description: reply })
      .then((response: IRequestResponse) => {
        if (response) {
          console.log(response);
          onPolicyUpdated(new Policy(response?.data));
          setReply('');
        }
      })
      .finally(() => setSubmitting(false));
  };

  const scrollToTop = () => {
    if (topSection.current) {
      topSection.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Animate play={show} start={startStyles} end={endStyles}>
      <div ref={containerRef} className="bg-white border border-primary-main px-14 pl-18 sm:pl-14 py-4 shadow-full overflow-y-auto h-[calc(100vh-100px)]">
        <div className=" flex justify-end pl-[1rem] items-center" ref={topSection}>
          <XMarkIcon onClick={onClose} className="cursor-pointer size-5" />
        </div>

        <h3 className="text-[24px] font-semibold py-2">
          {updates.length > 0 ? 'These are updates on this policy' : 'Add an update'}
        </h3>

        {updates.map((update: IPolicyUpdate, index: number) => (
          <>
            <div
              key={index}
              className="min-h-[121px] border border-primary-main rounded p-6 space-y-3">
              <div className="flex justify-between items-center">
                <h3 className="font-semibold">
                  Policy {getPolicyReviewStatus(update.reviewStage ?? '')}
                </h3>

                <div className="flex items-center text-[10px] space-x-8 text-[#616161]">
                  <span>{update.staff}</span>
                  <span>{`${moment(update.date)?.format('DD/MM/YY')} | ${moment(
                    update.date
                  )?.format('HH:mm a')}`}</span>
                </div>
              </div>

              <div>{update.description}</div>

              <div className="flex items-center">
                <span
                  className={clsx(
                    'flex bg-primary-main rounded-full h-[16px] w-[16px] items-center justify-center mr-2',
                    { ['bg-[#DADADA]']: update.replies && update.replies?.length === 0 }
                  )}>
                  <ReplyIcon />
                </span>
                {update.replies && update?.replies?.length > 0 ? (
                  <>
                    <span>
                      {update.replies?.length}{' '}
                      {`${update.replies?.length === 1 ? 'reply' : 'replies'}`}
                    </span>
                  </>
                ) : (
                  <span className="text-[10px] text-[#616161]">Reply this update</span>
                )}
              </div>
            </div>
            <div className="px-8">
              <div
                className={clsx('border-l-[1px] border-primary-main', {
                  ['min-h-[1.5rem]']: index + 1 !== updates.length
                })}></div>
            </div>
          </>
        ))}

        {!user.isInsurerAgent() && (
          <div className="py-4 justify-between flex items-end space-x-4 " ref={sectionRef}>
            <div className="space-y-4 w-full">
              <TextArea
                name={'reason'}
                value={reply}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setReply(event.target.value);
                }}
                placeholder="Write an update..."
                rows={5}
              />
            </div>

            <div className=" flex justify-end space-x-6 relative">
              <div
                className=" rounded-full border border-primary-main absolute bottom-20 z-50 cursor-pointer p-2"
                onClick={scrollToTop}>
                <ArrowUpIcon className="h-5 w-5 " />
              </div>
              <Button
                label="Send"
                className="bg-primary-main w-fit px-8 py-2 h-[45px] rounded-[5px] text-base"
                onClick={handleSubmit}
                loading={submitting}
                disabled={submitting}
              />
            </div>
          </div>
        )}
      </div>
    </Animate>
  );
};

export default PolicyUpdates;
