/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../components/form-control/Input';
import Button from '../../components/form-control/Button';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { completeAccount } from '../../api/requests';
import { ICompleteAccountDTO, IRequestErrorResponse } from '../../resources/interfaces';
import { toast } from 'react-toastify';
import useUserAccount from '../../hooks/userAccountHook';
import { SIGN_IN } from '../../resources/links';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import {
  getFormattedPhoneNumber,
  getPhoneNumberFromShortCodeFormat,
  PhoneInputValidation
} from '../../utils/miscFunctions';
import { AxiosError } from 'axios';
import useModal from '../../hooks/modalHook';
import SuccessConfirmation from '../../components/elements/SuccessConfirmation';
import AuthWrapper from './AuthWrapper';

// const valueProps = [
//   'Create a customized store in 5mins',
//   'Reduce time spent on policy creation by 70%',
//   'AI sales team: create policies even when your team is absent',
//   'AI claims management to detect fraud etc.'
// ];

function CompleteAccount() {
  const [searchParams] = useSearchParams();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { updateAccount, signOut } = useUserAccount();
  const navigate = useNavigate();

  const { updateModalStates, ModalComponent, closeModal } = useModal();

  const userEmail = searchParams.get('ue');
  const token = searchParams.get('hc');
  const firstName = searchParams.get('firstname');
  const lastName = searchParams.get('lastname');
  const phone = searchParams.get('phone');

  useEffect(() => {
    signOut(false);
  }, []);

  const submitHandler = (values: any) => {
    if (userEmail && token) {
      setSubmitting(true);
      const payload: ICompleteAccountDTO = {
        email: userEmail.toLocaleLowerCase(),
        password: values.password,
        refCode: token,
        firstname: values.firstname,
        lastname: values.lastname,
        phone: getFormattedPhoneNumber(values.phone)
      };

      updateAccount({ token: token });

      completeAccount(payload)
        .then((response: any) => {
          updateModalStates({
            children: (
              <SuccessConfirmation
                onCancel={closeModal}
                title="Successsful"
                description={
                  response?.message ?? 'Your account setup is completed. Please log in to continue'
                }
                onConfirm={() => {
                  updateModalStates({
                    show: false
                  });
                  updateAccount({ token: '' });
                  navigate(SIGN_IN, { replace: true });
                }}
              />
            ),
            size: 'md',
            show: true,
            static: true
          });
        })
        .catch((error: AxiosError | IRequestErrorResponse | any) => {
          setSubmitting(false);
          toast.error(error?.message ?? 'The token link is expired. Please request for a new one.');
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <AuthWrapper pageTitle="Complete account">
      <>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
            email: userEmail ?? '',
            firstname: firstName ?? '',
            lastname: lastName ?? '',
            // phone: phone ? formatPhoneNumber("+" + phone) : "",
            phone: getPhoneNumberFromShortCodeFormat(phone ?? '')
          }}
          validateOnChange={false}
          validateOnBlur={true}
          validationSchema={Yup.object({
            password: Yup.string()
              .trim()
              .required('Password is required')
              .min(9, 'A minimum of 9 characters required')
              .test({
                message: 'User email cannot be used as password',
                test: (value) => value !== userEmail
              })
              .matches(
                /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
                'Password must include at least two of the following: an uppercase character, a number, a special character'
              ),
            confirmPassword: Yup.string()
              .required('Password confirmation is required')
              .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            email: Yup.string().email('Email is invalid').required('Email address is required.'),
            ...PhoneInputValidation
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, handleBlur, setFieldValue, touched }) => (
            <form
              className="px-2 py-4 md:px-8 my-7 bg-white rounded-[8px] w-full md:w-[500px]"
              onSubmit={handleSubmit}>
              <div className="mb-5">
                <p className="text-sm sm:text-base py-2 text-center  text-[#9CA3AF]">
                  Welcome, please complete your account.
                </p>
              </div>
              <div className="">
                <div className="flex space-x-4 my-5">
                  <Input
                    name="firstname"
                    label="First Name"
                    value={values.firstname}
                    type="text"
                    wrapperClasses=""
                    placeholder="Enter your first name"
                    required
                    error={errors.firstname}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={errors.firstname}
                  />

                  <Input
                    name="lastname"
                    label="Last Name"
                    value={values.lastname}
                    type="text"
                    wrapperClasses=""
                    placeholder="Enter your last name"
                    required
                    error={errors.lastname}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={errors.lastname}
                  />
                </div>

                <Input
                  name="email"
                  label="Email address"
                  value={values.email}
                  type="email"
                  wrapperClasses="my-5"
                  required
                  error={errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={errors.email}
                  disabled
                  append={<CheckCircleIcon className="w-7 text-[#00c07b] mr-2" />}
                />

                <Input
                  name="phone"
                  onBlur={handleBlur}
                  onChange={(phone: any) => {
                    setFieldValue('phone', phone.strip());
                  }}
                  value={values.phone}
                  error={errors.phone}
                  helperText={errors.phone}
                  label={'Mobile Phone'}
                  wrapperClasses="w-full"
                  disabled={!errors.phone}
                  append={!errors.phone && <CheckCircleIcon className="w-7 text-[#00c07b] mr-2" />}
                />

                {/* <IntlPhoneInput
                    onBlur={handleBlur}
                    onChange={(phone: any) => {
                      setFieldValue("phone", phone);
                    }}
                    value={values.phone}
                    error={errors.phone}
                    wrapperClasses="my-5"
                  /> */}

                <Input
                  name="password"
                  label="Password"
                  value={values.password}
                  type="password"
                  wrapperClasses="my-5"
                  showPasswordIcon
                  required
                  error={touched.password ? errors.password : undefined}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.password ? errors.password : undefined}
                  description={`Must be more than 8 characters. Include an uppercase character, a number and a special character`}
                />
                {errors.password && (
                  <div className="flex flex-col font-bold text-gray-500 text-sm">
                    <div className="">
                      <span> Password must:</span>
                      <div className="flex items-center gap-2">
                        <div className="h-2 w-2 bg-gray-500 rounded-full  "></div>{' '}
                        <span>Be between 9 and 64 characters</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="h-2 w-2 bg-gray-500 rounded-full  "></div>{' '}
                        <span>Includes at least two of the following:</span>
                      </div>
                    </div>

                    <div className="ml-5 flex flex-col">
                      <span>&#x2022; An uppercase character</span>
                      <span>&#x2022; A number</span>
                      <span>&#x2022; A special character</span>
                    </div>
                  </div>
                )}

                <Input
                  name="confirmPassword"
                  label="Confirm Password"
                  value={values.confirmPassword}
                  type="password"
                  wrapperClasses="my-5"
                  showPasswordIcon
                  required
                  error={touched.confirmPassword ? errors.confirmPassword : undefined}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.confirmPassword ? errors.confirmPassword : undefined}
                />

                <Button
                  type="submit"
                  label="Complete account"
                  bgColor="primary"
                  className="mt-5 w-full rounded-[8px] !bg-dark border-[1px] border-dark !text-white"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
              <div className="text-center mt-2 text-[13px]">
                Already have an account?{' '}
                <Link to={SIGN_IN} className="font-semibold ">
                  Sign in
                </Link>
              </div>
            </form>
          )}
        </Formik>
        <ModalComponent />
      </>
    </AuthWrapper>
  );
}

export default CompleteAccount;
