import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

export interface ITabNavItem {
  id: any;
  title: string;
  activeTab: string | null;
  setActiveTab: (id: string) => void;
  onTabClicked?: (id: string) => void;
  classes?: string;
  icon?: JSX.Element;
  count?: number;
}

const TabNavItem: React.FC<ITabNavItem> = ({
  id,
  title,
  activeTab,
  setActiveTab,
  classes,
  onTabClicked,
  icon,
  count,
}) => {

  const itemCount = count

  const handleClick = () => {
    setActiveTab(id);
    onTabClicked?.(id);
  };

  return (
    <li
      onClick={handleClick}
      className={clsx(
        'cursor-pointer font-medium w-fit list-none',
        activeTab === id ? 'border-b border-primary-main p-1' : '',
        classes
      )}
    >
      <div className="flex items-center gap-2 whitespace-nowrap">
        {icon}
        {title}
        {itemCount != null && (
          <div className="size-8 rounded-full p-2 bg-primary-surface flex items-center justify-center text-xs">
            {itemCount}
          </div>
        )}
      </div>
    </li>
  );
};

export default TabNavItem;
