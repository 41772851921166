import { LightBulbIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode } from "react";

const MessagePrompt = ({type = "info", message, classes, showInfoIcon = true}: IMessagePrompt): JSX.Element => {

    return (
        <div className={clsx(
                            "flex justify-center", 
                            classes
                            )}>
            <div className={clsx(
                            "lg:w-2/3 p-4 rounded rounded-8 flex flex-col justify-center items-center", 
                `${type === "info" ? "bg-info-surface" : type === "success" ? "bg-success-surface" : "bg-danger-surface"}`
                            )}>
                {showInfoIcon && (
                    <LightBulbIcon className="w-[1.5rem]" />
                )}
                
                <>
                    {message}
                </>
            </div>
        </div>
    )
}

export default MessagePrompt;

export interface IMessagePrompt {
    type: "success" | "info" | "error";
    message: string | JSX.Element | Element | ReactNode;
    classes?: string;
    showInfoIcon?: JSX.Element | ReactNode
}