import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  changeAccessRight} from '../../../api/requests';
import SelectDropdown, {
  ISelectDropdownItem
} from '../../../components/form-control/SelectDropdown';
import { ACCESS_RIGHTS, INSURER_ACCESS_RIGHTS } from '../../../resources/enums';
import { MdOutlineSecurity } from 'react-icons/md';
import { getFormattedAccessRight } from '../../../utils/miscFunctions';
import useUserAccount from '../../../hooks/userAccountHook';
import { User } from '../../../models/User';

export interface IChangeAccessRight {
  staffID: string;
  accessRight: ACCESS_RIGHTS;
}

const ChangeAccessRight = ({
  onProceed,
  onClose,
  staffID
}: {
  onProceed: () => void;
  onClose: () => void;
  staffID: string;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { getProfile } = useUserAccount();
  const user = new User(getProfile());

  const getAccessRights = () => {
    let accesses = [];
    if (user.isInsurer()) {
      accesses = Object.keys(INSURER_ACCESS_RIGHTS).map((access: any) => ({
        name: getFormattedAccessRight(access),
        value: access
      }));
    } else {
      accesses = Object.keys(ACCESS_RIGHTS).map((access: any) => ({
        name: getFormattedAccessRight(access),
        value: access
      }));
    }

    return accesses;
  };

  const submitHandler = (values: any) => {
    setSubmitting(true);
    const payload: IChangeAccessRight = {
      staffID: staffID,
      accessRight: values.accessRight
    };

    if (staffID)
      changeAccessRight(payload)
        .then((response: any) => {
          toast.success(response.message ?? 'Admin access has been updated successfully', {
            autoClose: 5000
          });
          onProceed();
        })
        .catch((error: any) => {
          toast.error(`${error.description}`);
        })
        .finally(() => setSubmitting(false));
  };
  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <div className="flex items-center justify-center gap-1">
            <div className="flex items-center justify-center bg-white h-[45px] w-[45px] rounded-full">
              <MdOutlineSecurity className="h-[24px] w-[24px]" />
            </div>
            <h2 className="font-[gilroy-bold] text-lg">Change Access Rights</h2>
          </div>
          <XMarkIcon onClick={onClose} width={'18px'} height={'18px'} className="cursor-pointer" />
        </div>
      </div>
      <div className=" mt-[26px]">
        <span className="text-center px-4">Select a new access right for this user</span>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            accessRight: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            // email: Yup.string().email('Email provided invalid.').required('Email is requied.')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="">
                <SelectDropdown
                  name="accessRight"
                  list={getAccessRights()}
                  listSelectedValue={values.accessRight}
                  onChange={(val: ISelectDropdownItem | null) => {
                    setFieldValue('accessRight', val);
                  }}
                  onBlur={handleBlur}
                  placeholder="Select access right"
                  error={touched.accessRight ? errors.accessRight : undefined}
                  helperText={touched?.accessRight ? errors.accessRight : undefined}
                  required
                />
              </div>

              <div className="flex justify-between items-center">
                <div className="">
                  <Button
                    type="button"
                    label="Cancel"
                    className="w-[120px] px-4 border border-primary-border rounded"
                  />
                </div>
                <div className="">
                  <Button
                    type="submit"
                    label="Submit"
                    bgColor="primary"
                    className="w-[120px] px-4 rounded"
                    loading={submitting}
                    disabled={submitting}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangeAccessRight;
