import React, { ReactElement, ReactNode, Suspense } from 'react';
import Loader from './Loader';
import TopLoaderComponent from './topLoader/TopLoaderComponent';
import { useSelector } from 'react-redux';
import { getTopLoadingState } from '../states/slices/globalSlice';

interface IPageLoaderderProps {
  children: JSX.Element | ReactElement | ReactNode;
}

function PageLoader({ children }: IPageLoaderderProps) {
  const showTopLoading = useSelector(getTopLoadingState);

  return (
    <Suspense
      fallback={
        // <div className="w-full h-full flex items-center justify-center">
        //     <Loader size="lg" brollyLoader />
        // </div>
        <>
          {showTopLoading && (
            <TopLoaderComponent
              isLoading={showTopLoading}
              pastDelay={false}
              timedOut={false}
              retry={() => {}}
              error={null}
            />
          )}
        </>
      }>
      {children}
    </Suspense>
  );
}

export default PageLoader;
