import React, {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { Listbox, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
  CheckIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/24/solid';
import _ from 'lodash';
import { ScrollToFormikError } from '../elements/ScrollToFormikError';

export interface ISelectDropdownItem {
  id?: string;
  name: string;
  value: string | number | null;
}

export interface ISelectDropdownProps {
  selectInputClassName?: string;
  label?: string;
  listSelectedValue?: number | string | null;
  showAvatar?: boolean;
  search?: boolean;
  onChange: Dispatch<SetStateAction<ISelectDropdownItem | null>> | any;
  onBlur?: (e: React.FocusEvent<any, Element>) => void | null | any;
  list?: Array<ISelectDropdownItem>;
  grouped?: boolean;
  groupedList?: Record<string, Array<ISelectDropdownItem>>;
  placeholder?: string;
  description?: JSX.Element | React.ReactElement | string | number | any;
  name?: string;
  error?: string | undefined;
  disabled?: boolean;
  required?: boolean;
  helperText?: string | undefined;
  sort?: boolean;
  emptyListMessage?: string;
  searchPlaceHolder?: string;
  wrapperClasses?: string;
  editable?: boolean;
  IconClass?: string;
}

function SelectDropdown<T>({
  selectInputClassName,
  label,
  listSelectedValue,
  onChange,
  list = [],
  description,
  name,
  error,
  disabled = false,
  required = false,
  helperText,
  onBlur,
  sort = false,
  searchPlaceHolder = 'Search ...',
  wrapperClasses,
  editable = true,
  placeholder,
  emptyListMessage,
  search,
  IconClass
}: ISelectDropdownProps): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const fieldRef = useRef<HTMLDivElement>(null);

  const [searchResult, setSearchResult] = useState<Array<ISelectDropdownItem>>([]);
  const searchedList = _.isEmpty(searchResult) && searchValue.length < 1 ? list : searchResult;

  const onSearchChange = (evt: ChangeEvent<HTMLInputElement> | undefined) => {
    const val = evt?.target.value ?? '';

    setSearchValue(val);
    const srchList: Array<ISelectDropdownItem> = [];

    list.forEach((listItem) => {
      if (listItem.name?.toString().toLowerCase().includes(val)) srchList.push(listItem);
    });
    setSearchResult(srchList);
  };

  const getListItemDisplayName = () => {
    return list.find((listItem: ISelectDropdownItem) => listItem.value === listSelectedValue)?.name;
  };

  return (
    <Listbox
      ref={fieldRef}
      value={
        list.find((listItem: ISelectDropdownItem) => listItem.value === listSelectedValue)?.value
      }
      onChange={onChange}
      disabled={disabled}
      as={'div'}
      className={clsx({ ['animate-wiggle']: error && required }, wrapperClasses)}>
      {({ open }) => (
        <>
          {/* Label */}
          {label && (
            <Listbox.Label
              className={clsx('block text-sm font-medium text-gray-700 whitespace-nowrap', {
                ['!text-danger-main']: error && required
              })}>
              {label} <span className="!text-danger-main">{required && '*'}</span>
              {description && (
                <p
                  className={clsx('text-xs text-gray-400 italic', {
                    ['text-danger-border']: error && required
                  })}>
                  {description}
                </p>
              )}
            </Listbox.Label>
          )}

          <div className="mt-1 relative">
            {/* Input Field */}
            <div>
              <Listbox.Button
                name={name}
                className={clsx(
                  'relative w-full bg-white border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-primary-main focus:border-primary-border sm:text-sm',
                  {
                    ['bg-red-200 !border-danger-main focus:ring-danger-main focus:border-danger-border']:
                      error && required
                  },
                  selectInputClassName
                )}
                onBlur={!open ? onBlur : (e: any) => null}>
                {listSelectedValue ? (
                  <span className="flex items-center">
                    <span className="ml-3 block truncate">
                      {/* {listSelectedValue} */}
                      {getListItemDisplayName()}
                    </span>
                  </span>
                ) : (
                  <span className="text-gray-400 text-sm">
                    {placeholder ? placeholder : 'Select Option'}
                  </span>
                )}

                {!disabled && (
                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none border-none">
                    {open ? (
                      <ChevronUpIcon
                        className={clsx('h-5 w-5 text-gray-400', IconClass)}
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronDownIcon
                        className={clsx('h-5 w-5 text-gray-400', IconClass)}
                        aria-hidden="false"
                      />
                    )}
                  </span>
                )}
              </Listbox.Button>

              {/* Helper Text */}
              <div className={clsx('text-xs text-gray-400', { ['!text-danger-main']: error })}>
                {helperText}
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-40 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {_.isEmpty(list) ? (
                  // List Empty
                  <div className="w-full flex justify-center items-center h-20 flex-col">
                    <h4 className="font-medium !text-red-400">No Option&#40;s&#41; Available!</h4>
                    <small className="text-xs text-gray-500">
                      {emptyListMessage ? emptyListMessage : 'Refresh to try again'}
                    </small>
                  </div>
                ) : (
                  <>
                    {/* Search */}
                    {search && (
                      <div className="px-2 py-4 relative">
                        <div className="py-1 border-b-2 border-gray-200">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="sm:text-sm">
                              <MagnifyingGlassIcon className="h-5 w-5 stroke-gray-400" />
                            </span>
                          </div>

                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="focus:ring-primary-main outline-0 focus:border-primary-main block w-full pl-10 text-md sm:text-sm"
                            placeholder={searchPlaceHolder}
                            value={searchValue}
                            onChange={(e) => onSearchChange(e)}
                          />
                        </div>
                      </div>
                    )}

                    {/* List */}
                    <div className="px-1 pb-2 pt-1">
                      <>
                        {(sort ? searchedList.sort() : searchedList).map((listItem, key) => (
                          <Listbox.Option
                            key={key}
                            className={({ active, selected }) =>
                              clsx(
                                active ? 'bg-primary-surface' : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-0 text-sm hover:cursor-pointer',
                                { ['bg-primary-main']: selected }
                              )
                            }
                            value={listItem.value}>
                            {({ selected }) => (
                              <div className="flex items-center gap-x-1">
                                <span
                                  className={clsx(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'ml-3 block truncate text-sm w-fit'
                                  )}>
                                  {listItem.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={clsx(
                                      selected ? 'text-white' : 'text-gray-900'
                                      // 'absolute inset-y-0 right-0 flex items-center pr-1'
                                    )}>
                                    <CheckIcon className="h-5 w-5 " aria-hidden="true" />
                                  </span>
                                ) : null}
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </>
                    </div>
                  </>
                )}
              </Listbox.Options>
            </Transition>
          </div>
          <ScrollToFormikError fieldName={name ?? ''} fieldRef={fieldRef} />
        </>
      )}
    </Listbox>
  );
}

export default SelectDropdown;
