import { Formik } from 'formik';
import * as Yup from 'yup';
import SelectDropdown, { ISelectDropdownItem } from '../form-control/SelectDropdown';
import { DOWNLOAD_RESOURCE_TYPES, INSURER_STATUS_OPTIONS } from '../../resources/enums';
import SelectMenu from '../form-control/SelectMenu';
import DatePickerInput from '../form-control/DatePickerInput';
import { subDays } from 'date-fns';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Button from '../form-control/Button';
import moment from 'moment';
import { INPUT_DATE_FORMAT } from '../../resources/constants';
import { downloadInsurerPolicies, getAllInsurers } from '../../api/requests';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Insurer } from '../../models/insurer/Insurer';

interface IDownloadPromptProps {
  onProceed: (data: any) => void;
  onClose: () => void;
  resourceType: DOWNLOAD_RESOURCE_TYPES;
}

interface IFormData {
  insurer: number;
  status: boolean;
  from: Date;
  to: Date;
}

const SuperInsurerDownload = (props: IDownloadPromptProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [insurers, setInsurers] = useState<Insurer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getInsurers();
  }, []);

  const getInsurers = (page = 0) => {
    getAllInsurers(page, 50)
      .then((response: any) => {
        const _insurers = response?.data?.content?.map((_insurer: any) => {
          return new Insurer(_insurer);
        });
        setInsurers(_insurers);
      })
      .catch((error: any) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <h2>Export Policies</h2>
          <XMarkIcon
            onClick={props.onClose}
            width={'11px'}
            height={'11px'}
            className="cursor-pointer"
          />
        </div>
      </div>
      <Formik
        initialValues={{
          insurerId: '',
          status: '',
          from: subDays(new Date(), 7),
          to: new Date()
        }}
        validationSchema={Yup.object().shape({
          from: Yup.date().typeError('Date type error').required('Date from required')
        })}
        onSubmit={(values) => {
          setSubmitting(true);

          const payload: any = {
            ...values,
            status: values.status === 'ACTIVE' ? true : false,
            from: moment(values.from).format(INPUT_DATE_FORMAT),
            to: moment(values.to).format(INPUT_DATE_FORMAT)
          };
          downloadInsurerPolicies(payload)
            .then((response: any) => {
              window.open(response.documentUrl, '_blank');
              props.onClose();
              toast.success(
                response.message ?? 'Successful request to download insurer policies.',
                {
                  autoClose: 3000
                }
              );
            })
            .catch((error: any) => {
              toast.error(error.description, {
                autoClose: 3000
              });
            })
            .finally(() => setSubmitting(false));
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="px-4 py-[30px] bg-white space-y-4" onSubmit={handleSubmit}>
            <SelectDropdown
              label="Insurer"
              name="insurerId"
              listSelectedValue={values.insurerId}
              onChange={(val: ISelectDropdownItem | null) => {
                setFieldValue('insurerId', val);
              }}
              onBlur={handleBlur}
              search
              searchPlaceHolder="Search by insurer ..."
              list={insurers
                ?.filter((insurer) => insurer.status === 'ACTIVE')
                .map(
                  (insurer: Insurer) =>
                    ({
                      name: insurer.name,
                      value: insurer.id
                    } as ISelectDropdownItem)
                )}
              placeholder="Select insurer"
              error={touched.insurerId ? errors.insurerId : undefined}
              helperText={touched?.insurerId ? errors.insurerId : undefined}
              required
            />
            <SelectMenu
              name="status"
              label="Status"
              listSelectedValue={values.status}
              onChange={(value: string) => {
                setFieldValue('status', value);
              }}
              list={Object.values(INSURER_STATUS_OPTIONS)}
              error={touched.status ? errors.status : undefined}
              helperText={touched.status ? errors.status : undefined}
            />

            <div className="flex space-x-4 w-full justify-between">
              <DatePickerInput
                id={'from'}
                label="From"
                selected={values.from}
                onChange={(date: Date | null) => {
                  setFieldValue('from', date);
                }}
                classes="w-full"
                showYearDropdown
                allowFutureSelections={false}
                error={touched.from && errors.from !== undefined ? String(errors.from) : undefined}
                helperText={
                  touched.from && errors.from !== undefined ? String(errors.from) : undefined
                }
                dateFormat={'dd/MM/yyyy'}
              />

              <DatePickerInput
                id={'to'}
                selected={values.to}
                onChange={(date: Date | null) => {
                  setFieldValue('to', date);
                }}
                label="To"
                classes="w-full"
                showYearDropdown
                allowFutureSelections={false}
                error={touched.to && errors.to !== undefined ? String(errors.to) : undefined}
                helperText={touched.to && errors.to !== undefined ? String(errors.to) : undefined}
                dateFormat={'dd/MM/yyyy'}
              />
            </div>

            <div className="flex justify-end w-full">
              <Button
                label="Submit"
                type="submit"
                className="bg-primary-main px-6 rounded border-none"
                loading={submitting}
                disabled={submitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SuperInsurerDownload;
