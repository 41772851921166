import React from 'react';
import { BellIcon } from '@heroicons/react/24/outline';
import Tooltip from '../ToolTip';
import { GoReport } from 'react-icons/go';



const ReportIcon = () => {
  return (
    <div className="relative h-8 w-8 rounded-full p-1 bg-white hover:bg-primary-main ease-in-out duration-200	">
      <Tooltip direction="bottom" bgColor="bg-gray-200" message={'Create slack report'}>
        <GoReport size={'25px'} />
      </Tooltip>
    </div>
  );
};

export default ReportIcon;
