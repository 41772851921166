import { POLICY_REVIEW_STATES } from "../../resources/enums";
import { IAddress, ICountry } from "./IAddress";

export class Address {
    city: string;
    country: ICountry;
    createdOn: Date | undefined;
    id: string;
    postalCode: string;
    region: string;
    streetName: string;
    town: string;
    updatedOn: Date | undefined;

    constructor(Imodel: IAddress){
        this.city= Imodel.city;
        this.country= Imodel.country;
        this.createdOn= Imodel.createdOn ? new Date(Imodel.createdOn) : undefined;
        this.id= Imodel.id;
        this.postalCode = Imodel.postalCode;
        this.region = Imodel.regionState;
        this.streetName = Imodel.streetName;
        this.town = Imodel.town;
        this.updatedOn = Imodel.updatedOn ? new Date(Imodel.updatedOn) : undefined;
    }
}