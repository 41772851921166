/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

interface IInitialState {
  LAUNCH_CHAT: boolean;
}

const initialState: IInitialState = {
  LAUNCH_CHAT: false
};

const liveChatSlice = createSlice({
  name: 'livechat',
  initialState,
  reducers: {
    LAUNCH_CHAT: (state, action: PayloadAction<typeof initialState.LAUNCH_CHAT>) => {
      state.LAUNCH_CHAT = !state.LAUNCH_CHAT;
    }
  }
});

export const getChatLaunchState = (state: RootState): boolean => {
  return state?.livechat?.LAUNCH_CHAT;
};

export const { LAUNCH_CHAT } = liveChatSlice.actions;
export default liveChatSlice.reducer;
