import React, { ReactElement, ReactNode } from "react";
import {Helmet} from "react-helmet";


interface IPageWrapperProps {
    children: JSX.Element | ReactElement | ReactNode | null;
    title?: string;
    className?:  ReactNode | null;
}

function PageWrapper({children, title}: IPageWrapperProps) {

    return (
        <>
            <Helmet>
                <title>Brolly - {title}</title>
            </Helmet>
            
            {children}
        </>
    );
}

export default PageWrapper;
