import { useEffect, useState } from "react";
import useUserAccount from "../../../hooks/userAccountHook";

const QuoteIframe = ({
    // onProceed,
    onClose,
    isRenewPolicy = false,
    policyNumber
}: {
    onProceed: () => void;
    onClose: () => void;
    isRenewPolicy?: boolean;
    policyNumber?: string;
}) => {
    const { getInsurer } = useUserAccount();
    const [loading, setLoading] = useState(true);
    const insurer = getInsurer();
    const [widgetUrl, setWidgetUrl] = useState<string>(getWidgetUrl);

    useEffect(() => {
        setWidgetUrl(getWidgetUrl());
    }, [insurer, isRenewPolicy, policyNumber])

    const handleLoad = () => {
        setLoading(false);
    };

    function getWidgetUrl(): string {
        return `${process.env.REACT_APP_CLIENT_WEBSITE}/widgets/admin?insurer=${insurer?.id}&renewPolicy=${isRenewPolicy}&policyNumber=${policyNumber}`;
    }

    return (
      <>
        {loading && (
          <div
            className="h-screen flex items-center"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}>
            Loading...
          </div>
        )}

        {/* {showWidget && ( */}
        <div
          onClick={onClose}
          className="cursor-pointer rounded-lg h-8 w-fit flex justify-center items-center absolute right-1 ">
          <span className="font-bold text-[24px]">X</span>
        </div>

        <iframe
          onLoad={handleLoad}
          src={widgetUrl}
          title="Brolly Insurance... "
          width="100%"
          height="700"></iframe>
        {/* )} */}
      </>
    );
}

export default QuoteIframe;