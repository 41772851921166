/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  MdOutlineVisibility as RevealIcon,
  MdOutlineVisibilityOff as HideIcon
} from 'react-icons/md';
import { useFormikContext } from 'formik';
import { ScrollToFormikError } from '../elements/ScrollToFormikError';
// import "intl-tel-input/build/css/intlTelInput.css";
// import intlTelInput from "intl-tel-input";

interface IInputProps {
  id?: string;
  label?: string;
  description?: string;
  disabled?: boolean;
  prepend?: JSX.Element | React.ReactElement | string | number | any;
  append?: JSX.Element | React.ReactElement | string | number | any;
  type?:
    | 'text'
    | 'number'
    | 'email'
    | 'tel'
    | 'password'
    | 'search'
    | 'checkbox'
    | 'radio'
    | 'date'
    | 'file'
    | 'url'
    | 'hidden';
  name?: string;
  // countryCode?: boolean;
  // defaultCountryCode?: "gh"
  placeholder?: string;
  min?: number;
  max?: number;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | null | any;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void | null | any;
  onClick?: () => void;
  error?: string | undefined;
  required?: boolean;
  helperText?: string;
  autoComplete?: boolean;
  className?: string;
  wrapperClasses?: string;
  showPasswordIcon?: boolean;
  wiggle?: boolean;
  readOnly?: boolean;
  prependClasses?: string;
  scrollToFormik?: boolean;
}

function Input({
  id,
  label,
  description,
  disabled = false,
  prepend,
  append,
  type = 'text',
  name,
  placeholder,
  readOnly = false,
  min,
  max,
  value,
  onChange,
  onKeyDown,
  onBlur,
  onClick,
  error,
  required = false,
  helperText,
  autoComplete = false,
  className,
  wrapperClasses,
  showPasswordIcon = false,
  // countryCode = false,
  wiggle = true,
  prependClasses,
  scrollToFormik = true
}: IInputProps): JSX.Element {
  // const inputRef = createRef<HTMLInputElement>();
  const [showPassword, setShowPassword] = useState(false);
  const [currentType, setCurrentType] = useState(type);
  const fieldRef = useRef<HTMLInputElement>(null);

  

  // useEffect(() => {
  //     if(countryCode && type == "tel") {
  //         if(inputRef.current) {
  //             const input = intlTelInput(inputRef.current, {
  //                 utilsScript: "/js/intl-tel-input-utils.min.js",
  //                 customContainer: "w-full",
  //                 onlyCountries: ["gh"],
  //                 initialCountry: "gh",
  //                 // preferredCountries: ["gh"],
  //                 // separateDialCode: true
  //             });
  //         }
  //     }
  // }, [countryCode, type]);

  const toggleRevealHidePassword = () => {
    setCurrentType(currentType == 'password' ? 'text' : 'password');
    setShowPassword(!showPassword);
  };

  return (
    <div className={clsx('w-full', { ['animate-wiggle']: error && required }, wrapperClasses)}>
      {/* Label */}
      {label && (
        <label
          htmlFor={id}
          className={clsx('block text-sm font-medium ', {
            ['text-danger-main']: error && required
          })}>
          {label} <span className="text-danger-main">{required && '*'}</span>
          {description && (
            <p
              className={clsx('text-xs text-gray-400 italic', {
                ['text-danger-border']: error && required
              })}>
              {description}
            </p>
          )}
        </label>
      )}

      <div className={clsx('relative', { ['mt-1']: Boolean(label) })}>
        <div className="relative w-full">
          <input
            ref={fieldRef}
            type={currentType}
            onClick={onClick}
            name={name}
            id={id}
            readOnly={readOnly}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            className={clsx(
              'relative border border-gray-300 shadow-sm text-left cursor-default rounded',
              'focus:outline-none focus:ring-1 focus:ring-primary-surface focus:border-primary-main sm:text-sm',
              'flex items-center placeholder:text-gray-400',
              type === 'checkbox' ? 'h-4 w-4 rounded-sm text-primary-main' : 'w-full py-2',
              type !== 'checkbox' ? (prepend ? 'pl-12' : 'pl-3') : null,
              type !== 'checkbox' ? (append ? 'pr-10' : 'pr-3') : null,
              disabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-white',
              {
                ['bg-red-200 !border-danger-main focus:ring-danger-main focus:border-danger-border']:
                  error && required
              },
              error && '!border-danger-main',
              className,
              { ['animate-wiggle']: Boolean(error) && wiggle },
              readOnly && 'border-none',
              { ['border-red-200 focus:ring-red-200 focus:border-red-200']: !value && required }
            )}
            placeholder={placeholder}
            disabled={disabled}
            min={min}
            max={max}
            value={value}
            autoComplete={autoComplete ? undefined : String(autoComplete)}
          />

          {prepend && (
            <div
              className={clsx(
                'absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none w-12 sm:w-15 h-full text-[#004E60]',
                prependClasses
              )}>
              {prepend}
            </div>
          )}

          {type == 'password' && showPasswordIcon ? (
            <div className="absolute inset-y-0 right-0 p-3 flex items-center cursor-pointer text-[#004E60]">
              {showPassword ? (
                <HideIcon className="h-5 w-5" onClick={toggleRevealHidePassword} />
              ) : (
                <RevealIcon className="h-5 w-5" onClick={toggleRevealHidePassword} />
              )}
            </div>
          ) : (
            <>
              {append && (
                <div className="absolute inset-y-0 right-3  flex items-center text-[#004E60]">
                  {append}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Helper Text */}
      <div className={clsx('text-xs text-gray-400', { ['!text-danger-main']: error })}>
        {helperText}
      </div>
      {scrollToFormik && <ScrollToFormikError fieldName={name ?? id ?? ''} fieldRef={fieldRef} />}
    </div>
  );
}

export default Input;
